<template>
    <div>
        <!-- //没查询的时候显示 -->
        <div class="cont1continer">
            <div class="cont1boxs">
                <div class="cont1top">
                    <div class="cont1title">国家政策</div>
                    <router-link tag="a" :to="'/policyList/4/'"><div class="wtchmore">查看更多<img src="../static/img/moreimg.png" alt=""></div></router-link>
                </div>
                <div class="innerbox">
                    <div class="inners" v-for="(item,i) in news4" :key="i" v-if="i<4">
                        <router-link tag="a" :to="'/detail/2/'+item.id"><div>{{item.title}}</div></router-link>
                        <div>{{item.releaseTime}}</div>
                    </div>
                </div>
            </div>
            <div class="cont1boxs">
                <div class="cont1top">
                    <div class="cont1title">省级政策</div>
                    <router-link tag="a" :to="'/policyList/1/'"><div class="wtchmore">查看更多<img src="../static/img/moreimg.png" alt=""></div></router-link>
                </div>
                <div class="innerbox">
                    <div class="inners" v-for="(item,i) in news1" :key="i" v-if="i<4">
                        <router-link tag="a" :to="'/detail/2/'+item.id"><div>{{item.title}}</div></router-link>
                        <div>{{item.releaseTime}}</div>
                    </div>
                </div>
            </div>
            <div class="cont1boxs">
                <div class="cont1top">
                    <div class="cont1title">厅局政策</div>
                    <router-link tag="a" :to="'/policyList/2/'"><div class="wtchmore">查看更多<img src="../static/img/moreimg.png" lt=""></div></router-link>
                </div>
                <div class="innerbox" style="padding-bottom: 0;">

                    <div class="inners" v-for="(item,i) in news2" :key="i" v-if="i<4">
                        <router-link tag="a" :to="'/detail/2/'+item.id"><div>{{item.title}}</div></router-link>
                        <div>{{item.releaseTime}}</div>
                    </div>
                </div>
            </div>
            <div class="cont1boxs">
                <div class="cont1top">
                    <div class="cont1title">地区政策</div>
                    <router-link tag="a" :to="'/policyList/3/'"><div class="wtchmore">查看更多<img src="../static/img/moreimg.png" alt=""></div></router-link>
                </div>
                <div class="innerbox">
                    <div class="inners" v-for="(item,i) in news3" :key="i" v-if="i<4">
                        <router-link tag="a" :to="'/detail/2/'+item.id"><div>{{item.title}}</div></router-link>
                        <div>{{item.releaseTime}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "policyInit",
        data(){
            return {
                news1:[],
                news2:[],
                news3:[],
                news4:[]
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init(){
                this.form = {...this.initForm};
                this.getNews(1); //三个政策
                this.getNews(2);
                this.getNews(3);
                this.getNews(4);
            },
            async getNews(type){
                let newType = 1;
                let res = await this.$api.policyNews({newType,type});
                this['news'+type] = res.result.records;
            },
        }
    }
</script>

<style scoped>

    .cont1container{
        margin-bottom: 130px;
    }
    .cont1boxs .cont1top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 41px;
        padding-bottom: 18px;
        border-bottom: 3px solid #075aa6;
    }
    .cont1boxs .cont1top .cont1title{
        color: rgb(20, 82,181);
        font-size: 25px;
    }
    .cont1boxs .cont1top .wtchmore{
        color: #ccc;
        cursor: pointer;
    }
    .cont1boxs .innerbox{
        padding-bottom: 30px;
    }
    .cont1boxs{
        border-bottom: 1px solid #ccc;
    }
    .cont1boxs:last-child{
        border-bottom: none;
    }
    .cont1boxs .innerbox .inners{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        color: #333;
    }
    .cont1boxs .innerbox .inners a {
        color: #333;
    }
    .cont1boxs .innerbox .inners a:hover {
        color: rgb(20, 82,181);
        text-decoration: underline;
    }
</style>