<template>
	<!--主体代码-->
	<div id="zhl_12">
		<div id="box">
			<div class="nr clearfix">
				<div class="left">
					<!-- <div class="masking" v-if="show == true">
            <span>登陆后查看更多信息</span>
            <el-button type="primary" @click="gotoLogin">登录后查看</el-button>
          </div> -->
					<el-form ref="form" @validate="aa" :model="form" :rules="rules" label-width="100px">
						<div class="pt">问题咨询区</div>
						<div class="baodanqu">
							<el-form-item label="咨询类型" prop="consultationId">
								<el-select v-model="form.consultationId" placeholder="请选择咨询类型">
									<el-option v-for="item of options[1].result" :key="item.id" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>

							<div class="xxxi">
								<img src="../../../static/img/zhl_lantu.jpg" />
								<p>&nbsp;详细信息</p>
							</div>

							<el-form-item label="标题名称:" prop="title">
								<el-input v-model="form.title"></el-input>
							</el-form-item>
							<el-form-item label="内容描述:" prop="questionContent">
								<el-input type="textarea" placeholder="请用一句话概述您的资讯内容？ 如：企业减税咨询" :rows="4"
									v-model="form.questionContent"></el-input>
							</el-form-item>
							<el-form-item label="上传附件:">
								<el-upload class="upload-demo" :action="action" :on-change="handleChange"
									:file-list="fileList">
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
							</el-form-item>
							<el-form-item label="是否公开:" prop="isOpen">
								<el-radio v-model="form.isOpen" label="1">不公开</el-radio>
								<el-radio v-model="form.isOpen" label="2">公开</el-radio>
							</el-form-item>
						</div>
						<div class="baodanqu2">
							<div class="xxxi" style="margin-top: 20px">
								<img src="../../../static/img/zhl_lantu.jpg" />
								<p>&nbsp;企业信息</p>
							</div>

							<el-form-item label="企业名称:" prop="companyName">
								<el-input v-model="form.companyName"></el-input>
							</el-form-item>
							<div style="display: flex">
								<el-form-item label="所属地区:" prop="city">
									<el-select v-model="form.city" placeholder="请选择">
										<el-option v-for="item in options[2].result" :key="item.id" :label="item.city"
											:value="item.cityid + ''">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label-width="0" prop="area">
									<el-select v-model="form.area" placeholder="请选择">
										<el-option v-for="item in areas" :key="item.id" :label="item.area"
											:value="item.areaid + ''">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
							<el-form-item label="注册地址:" prop="companyAddress">
								<el-input placeholder=" 请填写详细地址" v-model="form.companyAddress"></el-input>
							</el-form-item>
							<el-form-item label="联系人:" prop="lianxinren">
								<el-input placeholder=" 请填写联系人" v-model="form.lianxinren"></el-input>
							</el-form-item>
							<el-form-item label="手机号码:" prop="phone">
								<el-input placeholder=" 请填写手机号码" v-model="form.phone"></el-input>
							</el-form-item>
							<el-form-item label="短信验证码:" prop="smsCode">
								<div class="codediv">
									<el-input class="codeinput" type="number" v-model="form.smsCode"
										placeholder="请输入验证码"></el-input>
									<CodeBtn :phone="form.phone" codeType="getCode5"></CodeBtn>
								</div>
							</el-form-item>
							<el-button class="zhl_tijiao" type="primary" @click="submit()">提 交</el-button>
						</div>
					</el-form>
				</div>
				<div class="right">
					<div class="cjwt">常见问题</div>
					<div class="jiangli">
						<div class="quesbox" v-for="(item, i) in quesList" :key="i">
							<div class="question">{{ item.problems }}</div>
							<div class="answer" v-html="item.answers"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import validators from "../../../util/validators.js";
	import base from "../../../util/request/base"; //获取上传文件的接口
	import CodeBtn from "../../../components/logincpn/CodeBtn";

	export default {
		name: "question",
		components: {
			CodeBtn
		},
		data() {
			return {
				action: base.uploadFile,
				// show:'true',
				fileList: [],
				options: [],
				areas: [],
				form: {
					consultationId: "",
					title: "",
					questionContent: "",
					isOpen: "1",
					companyName: "",
					city: "",
					area: "",
					companyAddress: "",
					lianxinren: "",
					phone: "",
					smsCode:"",
					questimeConsultationEnclosure: "",
				},
				quesList: [],
				rules: {
					consultationId: [{
						required: true,
						message: "请选择咨询类型",
						trigger: ["blur", "change"],
					}, ],
					isOpen: [{
						required: true,
						message: "请选择是否公开",
						trigger: ["blur", "change"],
					}, ],
					title: [{
						required: true,
						message: "请输入标题名称",
						trigger: "change"
					}, ],
					questionContent: [{
						required: true,
						message: "请输入内容描述",
						trigger: "change"
					}, ],
					companyName: [{
						required: true,
						message: "请输入企业名称",
						trigger: "change"
					}, ],
					city: [{
						required: true,
						message: "请选择地区",
						trigger: ["blur", "change"],
					}, ],
					area: [{
						required: true,
						message: "请选择地区",
						trigger: ["blur", "change"],
					}, ],
					companyAddress: [{
						required: true,
						message: "请输入注册地址",
						trigger: "change"
					}, ],
					lianxinren: [{
						required: true,
						message: "请输入联系人",
						trigger: "change"
					}, ],
					phone: [{
						required: true,
						validator: validators.checkPhone,
						trigger: ["blur", "change"],
					}, ],
					smsCode: [{
							required: true,
							message: "请输入短信验证码",
							trigger: "blur"
						},]
					},
				};
			},
			computed: {
					userId() {
						return this.$store.state.userInfo;
					},
				},
				mounted() {
					this.getOptions();
					// let userId = this.$store.state.userInfo;
					// if (!userId) {
					//   this.$alert("请登录后再试", "提示", {
					//     confirmButtonText: "确定",
					//     callback: (action) => {
					//       this.gotoLogin();
					//     },
					//   });
					// }
				},
				watch: {
					"form.city": {
						handler(data) {
							//do something
							this.getArea(data);
						},
					},
				},
				methods: {
					gotoLogin() {
						this.$router.push("/login");
					},
					aa() {
						// if (!this.userId) {
						//   this.$message({
						//     message: "请先登录",
						//     center: true,
						//   });
						// }
					},
					submit() {
						this.$refs.form.validate(async (valid) => {
							if (valid) {
								//验证表单
								let userId = this.userId;
								let res = await this.$api.questime({
									...this.form,
									userId
								});
								if (res.success) {
									this.$message({
										message: res.message,
										center: true,
										type: 'success',
										offset: 250,
										onClose: () => {
											this.$router.push("/");
										}
									});
								}
								// if (!userId) {
								//   this.$alert("请登录后再试", "标题名称", {
								//     confirmButtonText: "确定",
								//     callback: (action) => {
								//       this.$router.push("/login");
								//     },
								//   });
								// } else {
								//   let res = await this.$api.questime({ ...this.form, userId });
								//   if (res.success) {
								//     this.$message({
								//       message: res.message,
								//       center: true,
								//       type: 'success',
								//       offset: 250,
								//       onClose:()=>{
								//         this.$router.push("/");
								//       }
								//     });
								//   }
								// }
							} else {
								return false;
							}
						});
					},
					async getOptions() {
						let res = await Promise.all([
							this.$api.commonProblem(),
							this.$api.projectDic({
								type: 14
							}),
							this.$api.getCity({
								provinceId: 130000
							}),
						]);
						this.options = res;
						this.quesList = res[0].result.records;
					},
					async getArea(id) {
						let res = await this.$api.getArea({
							cityId: id
						});
						this.areas = res.result;
						// console.log(res);
					},
					handleChange(file, fileList) {
						this.fileList = fileList.slice(-3);
						if (file.response) {
							if (this.form.questimeConsultationEnclosure.length > 0) {
								this.form.questimeConsultationEnclosure +=
									"," + file.response.message;
							} else {
								this.form.questimeConsultationEnclosure += file.response.message;
							}
						}
					},
				},
		};
</script>

<style scoped>
	#zhl_12 {
		position: relative;
	}

	.masking {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		height: 101%;
		background-color: white;
		z-index: 20;
		backdrop-filter: blur(8px);
		background-color: rgba(255, 255, 255, 0.25);
		flex-direction: column;
	}

	.masking span {
		margin-bottom: 20px;
	}

	.question,
	.answer {
		position: relative;
	}

	.question:before,
	.answer:before {
		content: "";
		display: inline-block;
		width: 20px;
		height: 20px;
		background: url(../../../static/img/laba1.png);
		background-size: contain;
		position: absolute;
		left: -35px;
		top: 4px;
	}

	.answer:before {
		background: url(../../../static/img/laba2.png);
		background-size: contain;
	}

	#zhl_12 #box {
		width: 100%;
		/*height:2367px;*/
	}

	#zhl_12 button {
		cursor: pointer;
	}

	#zhl_12 .banner img {
		width: 100%;
		height: 340px;
		min-width: 1200px;
		/*width: expression_r(document.body.clientWidth < 1200 ? "1200px": "auto" );*/
	}

	#zhl_12 .nr {
		width: 1200px;
		/*height: 1446px;*/
		margin: 50px auto;
	}

	#zhl_12 .nr .left {
		width: 788px;
		height: 1246px;
		border: 1px solid #ebebeb;
		float: left;
		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.36);
		border-radius: 10px;
		/*border-top: none;*/
		position: relative;
	}

	#zhl_12 .nr .right {
		width: 395px;
		height: 1246px;
		border: 1px solid #ebebeb;
		float: right;
		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.36);
		border-radius: 10px;
		overflow-x: hidden;
		overflow-y: auto;
		/*border-top: none;*/
	}

	.jiangli {
		font-size: 14px;
		line-height: 31px;
		padding: 25px 0px 0px 50px;
	}

	#zhl_12 .pt {
		width: 100%;
		height: 80px;
		color: #3ca9fe;
		border-bottom: 1px solid #eeeeee;
		line-height: 80px;
		padding-left: 15px;
		font-size: 18px;
	}

	#zhl_12 .baodanqu {
		width: 720px;
		height: 490px;
		margin: 18px auto;
		padding-top: 15px;
		font-size: 14px;
		border-bottom: 1px solid #eeeeee;
	}

	#zhl_12 .xinghao {
		color: #de2865;
	}

	#zhl_12 .zxlx {
		width: 325px;
		height: 35px;
		border: 1px solid #cccccc;
		border-radius: 5px;
		margin-top: 10px;
	}

	#zhl_12 .xxxi {
		width: 90px;
		height: 25px;
		margin-top: 40px;
		line-height: 20px;
		margin-left: 5px;
		font-size: 18px;
		display: flex;
		align-items: center;
		padding-bottom: 45px;
	}

	#zhl_12 .xxxi img {
		display: inline-block;
		float: left;
		margin-right: 5px;
	}

	#zhl_12 .baodanqu2 {
		width: 720px;
		height: 490px;
		margin: 18px auto;
		padding-top: 15px;
		font-size: 14px;
	}

	.zhl_tijiao {
		width: 200px;
		height: 55px;
		/*background-color: #3ca9fc;*/
		/*color: #FFFFFF;*/
		font-size: 18px;
		/*text-align: center;*/
		/*line-height: 55px;*/
		margin-left: 50%;
		margin-top: 40px;
		transform: translateX(-50%);
		border-radius: 5px;
	}

	.cjwt {
		width: 100%;
		height: 80px;
		border-bottom: 1px solid #efefef;
		line-height: 80px;
		padding-left: 18px;
		font-size: 18px;
	}

	input {
		padding-left: 6px;
	}

	textarea {
		padding-left: 6px;
	}

	select {
		padding-left: 8px;
	}
</style>
