<template>
    <div>
        <el-form :model="form" size="mini"
                 ref="form" label-width="120px" class="demo-ruleForm">
            <!--            债务融资情况-->
            <div>
                <el-tag class="tagBox" effect="dark" type="info">债务融资情况</el-tag>
                <div>
                    <div class="inline">
                        <el-form-item label="银行贷款(万元):" prop="financeDebtBankloans">
                            <el-input v-model="form.financeDebtBankloans"></el-input>
                        </el-form-item>
                        <el-form-item label="民间借贷(万元):" prop="financeDebtPrivatelending">
                            <el-input v-model="form.financeDebtPrivatelending"></el-input>
                        </el-form-item>
                    </div>
                    <div class="inline">
                        <el-form-item label="小贷担保情况:" prop="financeDebtSmallloan">
                            <el-input v-model="form.financeDebtSmallloan"></el-input>
                        </el-form-item>
                        <el-form-item label="其他:" prop="financeDebtOrther">
                            <el-input v-model="form.financeDebtOrther"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <!--            股权融资经历-->
            <div>
                <div class="exprenceTitle">
                    <el-tag class="tagBox" effect="dark" type="info">股权融资经历</el-tag>
<!--                    <button data-v-7af8c3fe="" type="button" @click="addExperience()"-->
<!--                            class="el-button el-button&#45;&#45;primary el-button&#45;&#45;small">-->
<!--                        <i class="el-icon-circle-plus-outline"></i>-->
<!--                    </button>-->
                </div>
                <div class="exprenceBox">
                    <div class="inline">
                        <el-form-item label="融资轮次:" prop="companyFinancingTurn">
                            <el-select v-model="form.companyFinancingTurn" placeholder="请选择">
                                <el-option v-for="(item,i) in companyFinancingTurnOption" :key="item.id"
                                           :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
<!--                        <el-form-item align="right">-->
<!--                            <button data-v-7af8c3fe="" type="button" @click="subExperience(i)"-->
<!--                                    class="el-button el-button&#45;&#45;default el-button&#45;&#45;small">-->
<!--                                <i class="el-icon-remove-outline"></i>-->
<!--                            </button>-->
<!--                        </el-form-item>-->
                    </div>
                    <div class="inline">
                        <el-form-item label="融资年月:" prop="companyFinancingTime">
                            <el-date-picker type="date" placeholder="选择日期" v-model="form.companyFinancingTime" value-format="yyyy-MM-dd"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="估值(万元):" prop="companyValuation">
                            <el-input v-model="form.companyValuation"></el-input>
                        </el-form-item>
                    </div>
                    <div class="inline">
                        <el-form-item label="融资额度(万元):" prop="companyFinancingAmount">
                            <el-input type="number" v-model="form.companyFinancingAmount"></el-input>
                        </el-form-item>
                        <el-form-item label="对赌协议:" prop="companyAgreement">
                            <el-radio v-model="form.companyAgreement" label="1">无</el-radio>
                            <el-radio v-model="form.companyAgreement" label="2">有</el-radio>
                        </el-form-item>
                    </div>
                    <div class="inline">
                        <el-form-item label="协议内容:" prop="companyAgreementContent">
                            <el-input v-model="form.companyAgreementContent"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label-width="0">
                        <el-table
                                :header-cell-style="{color:'#000',fontWeight:'normal'}"
                                :data="financing"
                                style="width: 100%">
                            <el-table-column label="投资人" width="270" align="center">
                                <template slot-scope="scope">
                                    <el-form-item label-width="0"
                                                  :prop="'companyFinancing.' + scope.$index + '.touziren'">
                                        <el-input v-model="scope.row.touziren"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="投资额度（万元）"
                                    align="center"
                                    width="270">
                                <template slot-scope="scope">
                                    <el-form-item label-width="0"
                                                  :prop="'companyFinancing.' + scope.$index + '.edu'">
                                        <el-input type="number" v-model="scope.row.touziedu"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="占股比例（%）"
                                    align="center"
                                    width="270">
                                <template slot-scope="scope">
                                    <el-form-item label-width="0"
                                                  :prop="'companyFinancing.' + scope.$index + '.bili'">
                                        <el-input type="number" v-model="scope.row.zhangubili"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    align="center">
                                <template slot="header" slot-scope="scope">
                                    <div @click="addTable(scope.$index)">
                                        <i data-v-7af8c3fe="" size="small" class="el-icon-circle-plus"></i>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <div @click="subTable(scope.$index)">
                                        <i data-v-7af8c3fe="" size="small" class="el-icon-remove-outline"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </div>
            </div>
            <!--            政策性资金支持-->
            <div>
                <el-tag class="tagBox" effect="dark" type="info">政策性资金支持</el-tag>
                <div>
                    <div class="inline">
                        <el-form-item label="政策性资金支持:" prop="financeFinancialsupport">
                            <el-radio v-model="form.financeFinancialsupport" label="1">是</el-radio>
                            <el-radio v-model="form.financeFinancialsupport" label="2">否</el-radio>
                        </el-form-item>
                        <el-form-item label="资金名称:" prop="financeCapital">
                            <el-input v-model="form.financeCapital"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <!--                融资需求-->
            <div>
                <div class="exprenceTitle">
                    <el-tag class="tagBox" effect="dark" type="info">融资需求</el-tag>
                    <button data-v-7af8c3fe="" type="button" @click="addEquity()"
                            class="el-button el-button--primary el-button--small">
                        <i class="el-icon-circle-plus-outline"></i>
                    </button>
                </div>
                <div>
                    <div class="exprenceBox" v-for="(item,i) in demand" :key="i">
                        <div class="inline">
                            <el-form-item label="拟融资方式:"
                                          :prop="'companyDemand.' + i + '.companyFinancingType'">
                                <el-select v-model="item.companyFinancingType" placeholder="请选择" @change="gotoChange(item.companyFinancingType)">
                                    <el-option v-for="(item,i) in companyFinancingTypeOption" :key="item.id"
                                               :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item align="right">
                                <button data-v-7af8c3fe="" type="button" @click="subEquity(i)"
                                        class="el-button el-button--default el-button--small">
                                    <i class="el-icon-remove-outline"></i>
                                </button>
                            </el-form-item>
                        </div>
                        <div class="inline">
                            <el-form-item label="详述:" 
                                          :prop="'companyDemand.' + i + '.companyFinancingDetail'">
                                <el-input v-model="item.companyFinancingDetail"></el-input>
                            </el-form-item>
                            <el-form-item label="拟融资用途:"
                                          :prop="'companyDemand.' + i + '.companyFinancingPurpose'">
                                <el-input v-model="item.companyFinancingPurpose"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
            <!--                并购重组需求-->
            <div>
                <el-tag class="tagBox" effect="dark" type="info">并购重组需求</el-tag>
                <div>

                    <el-form-item label="并购重组需求:" prop="companyMerger">
                        <el-checkbox-group v-model="form.companyMerger">
                            <el-checkbox label="1" name="type">现金收购资产</el-checkbox>
                            <el-checkbox label="2" name="type">现金收购股权</el-checkbox>
                            <el-checkbox label="3" name="type">股权收购资产</el-checkbox>
                            <el-checkbox label="4" name="type">股权置换</el-checkbox>
                            <el-checkbox label="5" name="type">资产置换</el-checkbox>
                            <el-checkbox label="6" name="type">其他</el-checkbox>
                            <!-- <el-checkbox v-for="(item,index) in companyMergerOption" v-model="item.checked" :key="index" :label="item.value" name="type" :class="item.checked?'is-checked':''">{{item.label}}</el-checkbox> -->
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="详述:" prop="companyDetail">
                        <el-input :rows="5" type="textarea" v-model="form.companyMergerDetail"></el-input>
                    </el-form-item>

                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import ColumnData from '../../../../util/datas/ColumnData';
    import validators from "../../../../util/validators";

    export default {
        name: "Form4",
        data() {
            return {
                form: {
                    financeDebtBankloans: '',
                    financeDebtPrivatelending: '',
                    financeDebtSmallloan: '',
                    companyFinancingTurn: '',
                    companyFinancingTime: '',
                    companyFinancingAmount: '',
                    companyAgreement: '',
                    companyAgreementContent: '',
                    companyValuation: '',
                    financeFinancialsupport: '',
                    financeCapital: '',
                    companyMerger: [],
                    companyMergerDetail: '',
                    companyFinancing: [],//融资经历
                    companyDemand: [],// 融资需求
                },
                // rules: {
                //     financeDebtBankloans: [{required: true, message: '银行贷款为必填项,若无填"0"', trigger: 'blur'},],
                //     financeDebtPrivatelending: [{required: true, message: '民间借贷为必填项,若无填"0"', trigger: 'blur'},],
                //     financeDebtSmallloan: [{required: true, message: '小贷担保情况为必填项', trigger: 'blur'},],
                //     companyFinancingTurn: [{required: true, message: '融资轮次为必填项', trigger: 'blur'},],
                //     companyFinancingTime: [{required: true, message: '融资年月为必填项', trigger: 'blur'},],
                //     companyFinancingAmount: [{required: true, message: '融资额度为必填项', trigger: 'blur'},],
                //     touziren: [{required: true, message: '投资人为必填项', trigger: 'blur'},],
                //     edu: [{required: true, message: '投资额度为必填项', trigger: 'blur'},],
                //     bili: [{required: true, message: '比例为必填项', trigger: 'blur'},],
                //     companyFinancingType: [{required: true, message: '拟融资方式为必填项', trigger: ['blur', 'change']},],
                //     companyFinancingDetail: [{required: true, message: '详述为必填项', trigger: 'blur'},],
                //     companyFinancingPurpose: [{required: true, message: '融资用途为必填项', trigger: 'blur'},],
                // },
                financing: [],
                demand: [],
                defaultEquity: {
                    companyFinancingType:'',
                    companyFinancingDetail:'',
                    companyFinancingPurpose:'',
                },
                defaultExperience: {
                    touziren: '',
                    touziedu: '',
                    zhangubili: '',
                },
                companyFinancingTurnOption:[
                    {id:1,label:'种子轮',value:1},
                    {id:2,label:'天使轮',value:2},
                    {id:3,label:'Pre-A轮',value:3},
                    {id:4,label:'A轮',value:4},
                    {id:5,label:'A+轮',value:5},
                    {id:6,label:'B轮',value:6},
                    {id:7,label:'C轮',value:7},
                    {id:8,label:'D轮',value:8},
                    {id:9,label:'新三板定增',value:9},
                    {id:10,label:'Pre-IPO',value:10},
                    {id:11,label:'战略投资',value:11},
                    {id:12,label:'并购',value:12},
                    {id:13,label:'其他',value:13},
                ],
                companyFinancingTypeOption:[
                    {id:1,label:'银行贷款',value:1},
                    {id:2,label:'债务融资',value:2},
                    {id:3,label:'股权融资',value:3},
                    {id:4,label:'其他途径',value:4},
                ],
                companyMergerOption: [
                    {id:1,label: '现金收购资产',value:1},
                    {id:2,label: '现金收购股权',value:2},
                    {id:3,label: '股权收购资产',value:3},
                    {id:4,label: '股权置换',value:4},
                    {id:5,label: '资产置换',value:5},
                    {id:6,label: '其他',value:6}
                ]
            }
        },
        mounted() {
            this.initData();
            this.companyMessage();
        },
        methods: {
            async companyMessage(){
                let userId = this.$store.state.userInfo.id
                let res = await this.$api.companyMessage({userId});
                for(let key in res.result.company){ //回显数据
                    if(key in this.form){
                        this.form[key] = res.result.company[key];
                    }
                }
                if(res.result.companyFinancingList.length>0){
                    this.financing = res.result.companyFinancingList;
                }
                if(res.result.companyDemandList.length>0){
                    this.demand = res.result.companyDemandList;
                }
                if(res.result.companyMergerList.length>0) {
                    this.form.companyMerger = res.result.companyMergerList;
                }
                //拟融资方式将value值转化为label
                for(let i = 0;i<this.demand.length;i++) {
                    for(let j=0;j<this.companyFinancingTypeOption.length;j++) {
                        if(this.demand[i].companyFinancingType == this.companyFinancingTypeOption[j].value) {
                            this.demand[i].companyFinancingType = this.companyFinancingTypeOption[j].value;
                        }
                    } 
                }
                //融资轮次将value值转化为label
                this.form.companyFinancingTurn = res.result.company.companyFinancingTurn
                this.companyFinancingTurnOption.forEach((item,index)=>{
                    if(item.value == this.form.companyFinancingTurn){
                        this.form.companyFinancingTurn = item.label
                    }
                    return;
                })
                //并购重组需求
                var arr1 = []
                this.form.companyMerger.forEach((item)=>{
                    arr1.push(item.companyMerger)
                })
                this.form.companyMerger = arr1
            },
            initData() {
                this.financing.push(JSON.parse(JSON.stringify(this.defaultExperience)))
                this.demand.push(JSON.parse(JSON.stringify(this.defaultEquity)))
            },
            //提交
            onSubmit() {
                if(this.form.companyFinancingTurn != null) {
                    this.companyFinancingTurnOption.forEach((item)=>{
                        if(item.label == this.form.companyFinancingTurn){
                            this.form.companyFinancingTurn = item.value
                        }
                        return;
                    })
                    this.form.companyFinancingTurn = String(this.form.companyFinancingTurn)
                }
                
                
                this.form.companyFinancing = JSON.stringify(this.financing)
                this.form.companyDemand = JSON.stringify(this.demand)
                var arr = []
                for(var i=0;i<this.form.companyMerger.length;i++) {
                    arr.push(parseInt(this.form.companyMerger[i]))
                }
                this.form.companyMerger = JSON.stringify(arr)
                // this.$refs.form.validate((valid) => {
                    
                    // if (valid) { //验证表单
                        this.$emit('submit', this.form,4,)
                    // } else {
                        // return false;
                    // }
                // });
            },
            //添加 股权融资经历 投资人
            addTable() {
                this.financing.push(JSON.parse(JSON.stringify(this.defaultExperience)))
            },
            //移除 股权融资经历 投资人
            subTable(i2) {
                if (this.financing.length > 1) {
                    this.financing.splice(i2, 1)
                } else {
                    this.$message({
                        showClose: true,
                        message: '至少填写一位投资人情况!',
                        type: 'warning'
                    });
                }

            },
            //添加 股权融资经历
            addExperience() {
                this.financing.push(JSON.parse(JSON.stringify(this.defaultExperience)))
            },
            //移除 股权融资经历
            subExperience(i) {
                this.financing.splice(i, 1)
            },
            //移除 融资需求
            subEquity(i) {
                this.demand.splice(i, 1)
            },
            //添加 融资需求
            addEquity() {
                this.demand.push(this.defaultEquity)
            }

        }
    }
</script>

<style scoped>
    .inline {
        display: flex;
    }

    .inline .el-form-item {
        width: 50%;
    }

    .tagBox {
        margin: 15px 0;
    }

    .tableBox th {
        margin-bottom: 15px;
    }

    .exprenceTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .exprenceBox {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        padding: 20px;
        box-sizing: border-box;
    }
</style>