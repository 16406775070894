<template>
    <!-- 脚部代码 -->
    <footer>
        <div class="block-2">
            <div class="main w">
                <div class="left-text">
                    <div class="box1">
                        河北省企业股改上市服务平台
                        <span>|</span> <router-link tag="a" to="/aboutus" style="color:#fff;cursor:pointer;margin-right:10px;">关于我们</router-link>
                        <img src="../static/img/footer-phone.jpg" alt="">
                        <span style="color:#72c6f4;margin-left:6px;">服务热线 : 0311-68050939</span>
                    </div>
                  <p>指导单位 : 河北省地方金融管理局（中共河北省委金融委员会办公室） </p>
                  <p>主办单位 : 雄安股权交易所</p>
                    <p><a style="color:#FFF;font-size: 14px;" target="_blank" href="https://beian.miit.gov.cn">冀ICP备13017039号 </a></p>
                </div>
                <div class="right-img">
                    <div class="box1">
                        <img width="120" height="120" src="../static/img/footer-QR-1.jpg" alt="">
                        <p>关注微信公众号</p>
                    </div>
					<div class="box1">
					    <img width="120" height="120" src="../static/img/guanquan_qrcode.png" alt="">
					    <p>河北股权交易所</p>
					</div>
<!--                    <div class="box2">-->
<!--                        <img width="120" height="120"  src="../static/img/footer-QR-2.jpg" alt="">-->
<!--                        <p>河北省金融服务平台</p>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

    /* 这是脚部代码 */

    /* 这是脚部代码 */
    footer{
        width: 100%;
    }
    footer .block-2{
        background-color: #075aa6;
        height: 307px;
        padding-top: 53px;
    }
    footer .block-2 .main{
        display: flex;
        justify-content: space-between;
    }
    footer .block-2 .main .left-text .box1{
        height: 25px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: white;
        margin-bottom: 30px;
    }
    footer .block-2 .main .left-text .box1 span:nth-child(1){
        margin-left: 15px;
        margin-right: 15px;
    }
    footer .block-2 .main .left-text .box1 img:nth-child(2){
        margin-left: 16px;
        margin-right: 6px;
    }
    footer .block-2 .main .left-text .box1 span:nth-child(3){
        color: #72c6f4 !important;
    }
    footer .block-2 .main .left-text > p{
        font-size: 16px;
        color: white;
    }
    footer .block-2 .main .left-text > p:nth-child(2){
        margin-bottom: 11px;
    }
    footer .block-2 .main .left-text > p:nth-child(3){
        margin-bottom: 36px;
    }
    footer .block-2 .main .left-text > p:last-child{
        color: #72c6f4;
    }
    footer .block-2 .main .right-img{
        display: flex;
        color: white;
    }
    footer .block-2 .main .right-img > div{
        width: 150px;
        text-align: center;
    }
    footer .block-2 .main .right-img > div > img{
        margin-bottom: 10px;
        width: 118px;
    }
</style>
