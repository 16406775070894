<template>
  <div class="container">
    <span class="container_title">隐私政策</span>
    重要提示<br>
    河北省企业股改上市服务平台（以下简称“本系统”）的隐私政策（以下简称“本政策”）解释了我们收集、处理（本政策提及的“处理”，包括我们对您个人信息的使用、共享、存储及保护等行为）您个人信息的目的、方式和范围，您对个人信息的控制，以及我们保护您个人信息的适当程序等。本政策适用于任何通过我们（包括但不限于本系统网页、移动应用、短信平台、H5及小程序等）所收集到的个人信息。我们建议您完整地阅读本政策，以帮助您了解维护自己隐私权及个人信息的方式。
    本政策依据截至生效日适用的法律、法规制定，同时也参考了适用的国家及行业标准、国际协议及惯例。如国家法律法规对本政策内容有另行规定或您与我们另行约定的条款与本政策不一致的，以另行规定、约定为准。
    当您同意我们的服务协议，或您登录、访问小程序或网页端，或您实际使用我们提供的任一产品或服务时，请您确认您已阅读并理解本政策条款。
    引言
    1. 个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。包括姓名、出身日期、身份证件号码、个人生物识别信息、住址、通信通讯地址及联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。
    2. 个人敏感信息是指一旦泄露、非法提供或滥用可能危害个人人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。包括但不限于身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下(含)儿童的个人信息等。
    3. 在您使用本系统服务时，我们可能会收集、储存、使用和分享您的相关个人信息。我们通过本政策向您说明，我们如何收集、储存、使用和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。
    4. 基于国家相关法律法规的规定，以及为向您提供服务及提升服务品质、向您提供安全、顺畅、高效的体验的目的，我们按照本政策收集、储存、使用和分享您的信息。
    本政策将帮助您了解以下内容： 一、我们的承诺 二、我们收集个人信息的目的及范围
    三、我们如何收集个人信息 四、我们如何使用个人信息 五、我们如何存储个人信息
    六、我们如何共享个人信息 七、我们如何保护个人信息
    八、我们如何使用COOKIES等自动收集工具 九、您对个人信息的控制 十、免责声明
    十一、变更通知 十二、未成年人个人信息特别约定 十三、适用法律与争议解决
    十四、其他 一、我们的承诺
    1.我们仅在必要的、合理的范围内收集、处理您的个人信息我们承诺仅在必要、合理的范围内收集、处理您的个人信息。我们收集、处理您的个人信息是为了遵守国家法律法规的规定，以及通过我们的平台向您提供个性化、更优质的产品（如有）或服务（包括支持我们开发新产品或服务，或完善已有产品或服务功能。）
    2.我们仅在您知悉并同意的前提下收集、处理您的个人信息我们承诺会公开坦诚地告知您何种个人信息被收集、处理，及其原因、方式。我们尊重您的选择，我们收集、处理您的个人信息的范围、目的及方式如有任何重大改变或变动时，我们承诺将及时告知您相关变动信息。
    3.我们尊重您对个人信息的管理我们确保您提供的个人信息不被恶意篡改、损毁或缺失。我们理解并尊重您对个人信息的关注，并为您提供个人信息的查询、更正及删除等渠道。
    4.我们将采取必要的措施保证您的个人信息安全我们承诺在必要能力范围内采取必要的安全保障措施，以保障您的个人信息安全，包括但不限于通过符合现行适用的法律法规及标准要求的硬件、软件、数据、人员、物理环境及其基础设施防护措施保护您的个人信息安全；不仅如此，我们还会与相关员工签署保密协议或相关承诺书并通过对员工进行安全意识培训，敦促其遵守本政策。
    二、我们收集个人信息的目的及范围
    本政策所指的“个人信息”是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。当您访问、登录我们的网站或小程序时，或您使用我们的产品或服务时，您会向我们主动提供您的个人信息，我们也会通过自动收集工具、第三方收集您的信息。我们收集信息的目的及范围：
    1.用于身份识别，或联系您，或完善我们的服务体系（例如确保您需要的服务信息更加精准、便捷、高效地到达）的个人信息，包括您在我们平台的注册账户，或使用我们提供的各项产品或服务时您向我们提供的包括但不限于您的平台注册信息、真实姓名、身份证信息、居住地址、电话号码、即时通信账户信息、电子邮件地址、生物信息（例如声纹、指纹）等信息。
    2. 此外，为了完善和提升我们的产品或服务的质量和精准度，我们还会收集包括但不限于您的IP地址、设备标识符、硬件型号、操作系统版本，与我们的服务相关的日志信息等信息。
    三、我们如何收集个人信息
    1.您直接提供给我们的信息您通过小程序或网页端注册我们的平台账户、申请或使用我们的产品或服务，或通过电子邮件、电话或其他方式联系我们时，可能向我们提供一些个人信息（具体范围详见“我们收集个人信息的目的及范围”部分）。若您不提供这些信息或提供的信息有误，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
    2.来自我们平台主动收集的信息除了您提供给我们的个人信息外，我们可能直接通过Cookies等自动收集工具收集您的信息，如您的IP地址、设备标识号、硬件型号、操作系统版本、位置以及与我们的服务相关的日志信息。若您拒绝我们收集此类信息，我们将无法全部或部分为您提供相关产品或服务的功能，但不会影响我们向您提供核心的产品或服务。
    3.来自您的共享信息当您使用我们的产品或服务与家人和朋友分享内容，或邀请他人使用我们的产品或服务时，或者在必要情形下需通过您的家人和朋友与您进行联系时，我们可能会收集您提供的与上述人士有关的信息，如姓名、电子邮件地址以及电话号码等。若您拒绝我们收集此类信息，我们将无法完成相关服务或礼品的发放或者及时与您联系。
    4.在法律法规允许的范围内，我们可能会在以下情形中收集并使用您的个人信息无需经过您的授权同意：
    1) 与国家安全、国防安全有关的；
    2) 与公共安全、公共卫生、重大公共利益有关的；
    3) 与犯罪侦查、起诉、审判和判决执行等有关的；
    4) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您同意的；
    5) 所收集的个人信息是您自行向社会公众公开的；
    6) 从合法公开披露的信息中收集到的个人信息，如合法的新闻报道、政府信息公开等渠道；
    7) 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    8) 法律法规规定的其他情形。 四、我们如何使用个人信息
    我们在不同的场景中，使用您个人信息的目的、方式和范围略有差异，具体如下：
    1.向您提供我们的各项产品或服务，或支持、改进我们产品或服务的质量，或用于可能的争议解决我们可能会使用您直接提供给我们的，或是来自其他第三方的信息，包括但不限于您真实身份信息、电子身份信息、职业信息、生物信息、账户信息等。
    2.使您知晓自己使用的我们各项产品或服务的情况，或了解我们的其他服务，在您同意的前提下，向您发送服务状态通知，展现或推荐相关程度更高的产品、信息流或广告、推广信息及优惠信息我们可能会使用您直接提供给我们的，或是来自其他第三方的信息，包括但不限于您的真实身份信息、电子身份信息、IP地址、设备标识符、硬件型号、操作系统版本，与我们的服务相关的日志信息，您的学历、职业信息、婚姻状况，以及其他交易信息。
    3.与您沟通或回应您的疑问和请求，或是向您提供更新信息，包括但不限于我们的服务协议、本政策、条款和条件方面的变动或其他我们认为需要告知您的变动我们可能会使用您直接提供给我们的，或是来自其他第三方的信息，包括但不限于您的真实身份信息等。
    4.用于监测我们平台的性能和稳定性，优化性能、支持、改进线上服务质量我们可能通过Cookies等自动收集工具收集的信息，包括但不限于您的IP地址、设备标识号、硬件型号、操作系统版本、位置以及与我们的服务相关的日志信息等。
    5.用于我们的内部风险控制我们可能会使用您直接提供给我们的，或是来自其他第三方的信息，通过委托第三方建立数据模型或者建立性格测试模型，用于特征分析和用户画像，帮助我们进行内部风控，包括但不限于您的真实身份信息、电子身份信息、生物信息、职业信息、账户信息、交易信息、您的出行信息等。
    6.根据您的要求，向您的家人和朋友提供我们相关产品或服务信息等，或者在必要情形下需通过您的家人和朋友与您进行联系等当您使用我们的产品或服务与家人和朋友分享内容，或邀请他人使用我们的产品或服务时，或者在必要情形下需通过您的家人和朋友与您进行联系时，我们可能会使用您提供的与上述人士有关的信息，如姓名、电子邮件地址以及电话号码等。
    7.其他您个人信息的潜在用途1) 我们通过技术手段对您的个人信息进行去标识化处理使信息无法识别主体的情况下，对该等数据的挖掘、分析/利用（包括商业化利用）和与第三方共享将无须再获得您的同意或授权；
    2) 预防或禁止非法的活动； 3) 遵守法律法规的义务； 4) 经您许可的其他用途。
    五、我们如何存储个人信息
    1.保存的地域您的个人信息储存于中华人民共和国境内，我们不会在境外存储您的个人信息。
    2.保存的期限除非法律法规另有规定，我们将按如下方式及期间保存您的信息： 1) 您使用我们产品或服务期间，我们将综合考虑为您提供产品或服务的必要性，根据业务需要持续为您保存或删除个人信息；
    2) 但是，即便有第1) 款之规定，您仍有权自主决定删除这些信息或者在相关技术设置允许条件下注销账户；
    3) 进一步的，即便有第2) 款之规定，在某些特定情况下，有且仅为了遵守国家安全、网络安全等法律规定的法定义务或政府命令，我们仍将继续保存您的信息，直至法定义务免除。
    3.跨境转移除非获得您的特别同意，或者满足法律规定的评估及许可程序，我们不会跨境转移您的个人信息。
    六、我们如何共享个人信息
    我们会以高度的勤勉义务对待您的个人信息，不会与关联公司、合作伙伴以外的任何公司、组织和个人共享您的个人信息。
    1.与我们共享信息的合作伙伴1) 技术服务类合作伙伴。基于您的授权及设定，收取您的位置信息及设备信息并提供给技术服务商，特殊情境下会包含您另行填写的个人信息，以获取他们的支持，这些支持包括但不限于提供技术基础设施服务、提供产品内或产品链接后的功能型服务，分析我们线上服务的使用方式、衡量广告和服务的有效性、提供客户服务和调查与分析。
    2) 广告、数据分析类合作伙伴。未经您的授权，我们不会将您的个人信息与提供广告、分析服务的非合作伙伴共享。但我们可能会将使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助其在不识别您个人身份信息的前提下提升广告及服务信息的精准度及有效触达率。
    3) 金融类合作伙伴。根据您的授权，或您与相关合作伙伴签订的协议或文件，或基于法律法规、法律程序的要求或强制性的政府要求或司法裁定，或金融类等机构的合法需求，对您的个人信息进行查询、综合统计、分析或加工等处理，并将数据结果分享给合作伙伴。
    2.共享要求在共享信息前，我们会与共享个人信息的第三方签署严格的数据安全和保密协定，要求他们采取相关的保密和安全措施来处理上述信息。我们不会将您的个人信息转让给除关联公司、合作伙伴外的公司、组织和个人，但以下情况除外：
    1) 事先获得您的明确授权或同意；
    2) 根据法律法规、法律程序的要求或强制性的政府要求或司法裁定我们必须提供；
    3) 符合您签署的其它法律文件（如本条款）的约定而使用；
    4) 在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私权政策的约束，否则我们会要求该公司、组织重新向您征求授权同意。
    七、我们如何保护个人信息
    我们会采取适当的、符合业界标准的安全措施和技术手段，以保护您的个人信息，包括但不限于制定了严格的内部信息管理流程、对数据进行加密、定岗定责、控制访问权限、采取安全措施等，以防止其丢失、被非法使用、受到未授权访问或泄漏、被篡改或毁坏。所有的个人信息被加密储存并放置于专业防火墙内并请您理解且明白，由于存在的各种各样的恶意手段，即便尽力采取上述措施，您的信息仍有可能被泄漏、毁损或灭失。我们对此不承担任何承诺或保证，也对此不负任何责任。
    您在使用我们的产品或服务时，请妥善保管好您的账号及验证码，我们会通过您的账号及验证码来识别您的身份。一旦您泄漏了账号及验证码，您可能会丢失您的信息，并可能产生对您不利的法律后果。如您发现您的账号及验证码因任何原因已经或将受到泄漏时，您应该立即和我们取得联系，但在我们知悉此种情况和采取行动前，我们对此不负任何责任。
    我们仅允许有必要知晓这些信息的人员，在采取合理的措施验证身份之后，访问或使用这些信息。同时，我们会严格要求他们履行保密及安全义务，如果未能履行这些义务，其会被追究法律责任或被终止与我们的劳动、合作关系。
    为了保护您的信息安全，如果您的个人信息有误，我们会在严格验证并核实申请人身份后，根据您的要求访问、修正或删除相关信息（除非我们出于合法的原因而必须保留这些个人信息）。
    如果一旦发生安全事件影响到您的个人信息安全的，我们将通过您预留的邮箱、电话、系统推送等方式通知您，并告知您降低或防范相关风险的建议等信息, 难以逐一告知个人信息主体时，我们会采取合理有效的方式发布公告。
    必要时，我们将根据适用的法律、法规通知主管部门。同时，我们会及时处置系统漏洞、网络攻击、病毒侵袭及网络侵入等安全风险。在发生危害网络安全的事件时，我们会按照网络安全事件应急预案，及时采取相应的补救措施，并按照规定向有关主管部门报告。
    八、我们如何使用Cookies等自动收集工具
    当您使用小程序或通过网页端访问时，我们使用Cookies和其他类似技术来记录信息，包括但不限于您的IP地址、设备标识符、硬件型号、操作系统版本、位置以及与我们的服务相关的日志等信息。此等技术帮助我们更好地了解用户的行为，告诉我们您浏览了我们网站的哪些部分，可以了解服务效果并加以改善。
    请您理解，我们平台中的许多Cookies起着至关重要的作用：例如，当您登录我们账号时，Cookies记录着您的账号信息，使您省去重复输入注册信息等步骤，或帮助您判断您的账户安全。其它的Cookies帮助我们了解您使用我们平台的方式，从而使我们改进线上工作。对此我们使用相关的分析工具分析我们平台的加载时间、访客使用的方式，以及我们平台的访客查看最多的信息。它们还帮助我们发现我们平台中没有达到预期效果的部分，促使我们采取修复措施，为您打造更好用的平台。
    所有最受欢迎的网络浏览器都允许您管理Cookies。您可以选择接受或拒绝所有Cookies，或拒绝具体类别的Cookies。如果您关闭Cookies后，您仍可以使用我们平台的部分内容，但有些有用部分将不能使用，并且，将可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
    九、您对个人信息的控制
    我们非常尊重您对个人信息的控制权限，并为您提供了控制您个人信息的方法。您有权利查询、更正、管理、删除自己的个人信息并保护自己的隐私。
    您对个人信息的更新、管理或删除的行为，我们原则上不收取费用，但对于多次重复、超出合理限度的请求，我们将视情况可能会收取一定成本费用或者延期、拒绝响应您的请求。
    如果您想访问、更新、管理或删除您的个人信息，您可以通过如下方式完成：
    1) 在相关技术设置允许的情况下，登陆您的平台注册账户访问、更新、管理或删除；
    2) 通过我们公布的方式，联系我们，我们非常愿意为您提供可能的协助。 在以下情形中，按照法律法规要求，我们将无法响应您的更新、管理或删除等请求：
    1) 与国家安全、国防安全有关的；
    2) 与公共安全、公共卫生、重大公共利益有关的；
    3) 与犯罪侦查、起诉和审判等有关的；
    4) 有充分证据表明您存在主观恶意或滥用权利的；
    5) 响应您的请求将会导致您或其他个人、组织的合法权益受到严重损害的；
    6）其他我们认为合理的无法响应的事项。 十、免责声明
    请您理解，互联网并非绝对安全的环境，电子邮件、即时通讯、社交软件、交易平台等与其他用户的交流方式无法确定是否完全加密，请您在进行交互使用时，注意保护您个人信息的安全。
    请您理解，由于计算机及互联网技术的飞速发展及同步速度的限制，可能存在或出现各种恶意或非恶意的攻击手段。虽然我们持续致力于提升和加强安全措施，以保护您的个人信息免遭意外或破坏，但仍无法始终保证您的个人信息的百分之百安全。
    请您理解，您使用产品或服务时所用的系统和通讯网络，或硬件设备等我们均无法控制，请您了解并注意保护您的个人信息安全。
    请您注意，我们的网站、产品、应用软件和服务中可能含有第三方网站、产品或服务的链接。第三方收集的可能涉及您的个人信息，须遵守第三方关于个人信息的规定。我们希望你能了解这些第三方的隐私政策。我们会尽商业上的合理努力去要求这些第三方主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，亦不对这些主体的行为及后果承担任何责任。如果您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
    如同所有的企业都可能面临的问题一样，考虑到信息技术、网络空间本身的特点，在某些特殊情况下（例如黑客攻击、电信故障等），尽管我们采取了法律、法规等规定的必要的措施，但仍然存在个人信息泄露的风险。除非本隐私政策有其他规定，为了保护您的人身及财产安全，我们特别提醒您不要通过公共网络，向我们或任何以我们名义为您提供服务的第三方，提供您的账户密码、验证码、通信内容等信息。
    十一、变更通知
    我们将根据业务模式定期或不定期审核、更新本政策，该等修订构成本政策的一部分。
    未经您的明确同意，我们不会削减您按照本政策所享有的权利。
    我们会通过合理的方式发布本政策所做的变更。若您继续使用我们的产品或服务，即表示您同意修订后的政策条款。
    如发生重大变更，我们将会采取更具有提示作用的方式告知用户。如果有需要，我们还将会与您确认您是否对我们的改动满意。本政策所指的重大变更包括但不限于：
    1) 我们的服务模式发生重大变化； 2) 个人信息公开披露的主要对象发生变化；
    3) 个人信息处理的权利及其行使方式发生重大变化；
    4) 我们的联络方式及投诉渠道发生变化； 5）其他合理的变化。
    十二、未成年人个人信息特别约定
    我们非常重视未成年人的个人信息保护，我们的网站、应用及相关产品或服务均不以未成年人为目标客户。我们不会故意收集未成年人的任何个人信息，除非您主动提供且因获取某些产品或服务必须提供某些未成年人的必要信息外。
    如果您是未成年人，请不要尝试注册或登录访问本系统网页端或小程序，或不要以其它方式向我们提供任何您的个人信息。如果我们发现经疏忽获取了未成年人的个人信息，我们将尽快删除该等信息。
    十三、适用法律与争议解决
    1.适用法律本政策的执行、解释及争议的解决均适用中华人民共和国（不含港澳台地区）法律，且不考虑任何冲突法。
    2.争议解决当您以适当的方式开始、继续使用我们的产品或服务超过10日的，视为您充分理解并同意本政策。您理解并完全同意，在您明示拒绝前，我们依据本政策收集和使用的个人信息仍然有效。
    您和我们就本政策内容或其执行发生任何争议的，双方应友好协商解决；如双方无法协商解决争议时，可以提交石家庄仲裁委员会仲裁裁决。
    十四、其他
    如果您对本政策有任何疑问、意见或建议，或您在使用我们产品或服务时，就您的个人信息的收集、使用、共享、访问、删除、更正等相关事宜有任何意见、建议、投诉或举报，或您在使用产品或服务时遇到哪些问题，您可以联系我们，我们将会在法律要求的期限内或合理期限内及时答复您。
  </div>
</template>

<script>
export default {
  name: "UserPolicy",
};
</script>

<style scoped>
.container {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
}
.container_title {
  display: block;
  width: 150px;
  margin: auto 0;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}
</style>