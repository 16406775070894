<template>
  <div>
    <div class="box3">
      <div class="block-1">
        河北省企业股改平台企业

        <div class="yearOrTotal" v-if="show">
          <!-- <div>2020-2022</div>
          <div>总共：{{total}}家</div> -->
        <!-- tab切换 -->
          <div :class="{active:cur=='1'}" @click="change(1)">2020年({{total1}})</div>
          <div :class="{active:cur=='2'}" @click="change(2)">2021年({{total2}})</div>
        </div>
<!--        <p v-else>2020-2022</p>-->
      </div>
      <div class="block-2">
        <div>序号</div>
        <div>企业名称</div>
        <!-- <div>签约</div> -->
        <div>股改</div>
        <div>辅导</div>
        <div>申报</div>
        <div>上市</div>
      </div>
    </div>
    <div class="box4">
      <div class="block-text" v-for="(item, index) in result" :key="index">
        <div>{{ index + 1 }}</div>
        <div v-if="show">
          <!-- <a href="javascript:;">{{ item.companyName }}</a> -->
          <block v-if="item.status == 3">
            <router-link
              :to="'/companyProfiles/' + item.id + '/' + item.status"
              tag="a"
              >{{ item.companyName }}</router-link
            >
          </block>
          <block v-else>
            <a v-if="item.linkUrl" :href="item.linkUrl" target="_blank">{{
              item.companyName
            }}</a>
            <router-link
              v-else-if="item.details"
              :to="'/companyProfiles/' + item.id + '/' + item.status"
              tag="a"
              >{{ item.companyName }}</router-link
            >

            <a href="javascript:;" v-else>{{ item.companyName }}</a>
          </block>
        </div>
        <div v-else>
          <a href="javascript:;">{{ item.companyName }}</a>
        </div>
        <!-- <div><span v-if="item.status>=2">√</span></div> -->
        <div><span v-if="item.status >= 3">√</span></div>
        <div><span v-if="item.status >= 4">√</span></div>
        <div><span v-if="item.status >= 5">√</span></div>
        <div><span v-if="item.status >= 6">√</span></div>
      </div>
    </div>
    <div class="paginationbox">
      <el-pagination
        background
        v-if="show_page"
        :current-page="pageNo"
        :page-size="pageSize"
        @current-change="currentChange"
        layout="prev, pager, next"
        :page-count="pageCount"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "tutoringTable",
  props: {
    companyName: String,
    type: String,
    time:Array
  },
  data() {
    return {
      show_page: true,
      pageSize: 10, //每页显示数量
      pageCount: 1, //数据总数
      pageNo: 1,
      result: [],
      show: true,
      total: '',
      cur:1,
      total1:'',
      total2: ''
    };
  },
  watch: {
    type(data) {
      this.getMsg();
      this.pageNo = 1;
      this.getTotal()
    },
    companyName() {
      this.pageNo = 1;
    },
  },
  mounted() {
    this.getMsg();
    this.getRouter()
    this.getTotal()
  },
  methods: {
    change(i) {
      this.cur = i;
    },
    getRouter(){
      let n = this.$route.path;
      if(n.indexOf("coach") > 0) {
        this.show = true
      }else {
        this.show = false
      }
    },
    toDetail() {
      this.$router.push("/companyProfiles");
    },
    // 获取总数
    async getTotal() {
      let type = this.type;
      let res = await this.$api.getTotal({type})
      this.total1 = res.result.xiaolei1
      this.total2 = res.result.xiaolei2
    },
    currentChange(e) {
      //页数改变
      this.pageNo = e;
      this.getMsg();
    },
    async getMsg() {
      this.page_show = false;
      let companyName = this.companyName;
      let pageNo = this.pageNo;
      let type = this.type;
      let beginTime = '';
      let endTime = '';
      if(this.time.length>0){
        beginTime = this.getDate(this.time[0]);
        endTime = this.getDate(this.time[1]);
      }else{
        beginTime = '';
        endTime = '';
      }
      let res = await this.$api.fudaoCompany({
        companyName,
        pageNo,
        type,
        beginTime,
        endTime,
      });
      this.result = res.result.records;
      this.total = res.result.total;
      this.pageCount = res.result.pages;
      this.$nextTick(() => {
        this.page_show = true;
      });
    },
    getDate(n) {
      let Y = n.getFullYear() + "-";
      let M =
        (n.getMonth() + 1 < 10 ? "0" + (n.getMonth() + 1) : n.getMonth() + 1) +
        "-";
      let D = (n.getDate() < 10 ? "0" + n.getDate() : n.getDate()) + " ";
      return Y + M + D;
    },
  },
};
</script>

<style scoped>
.box3 .block-1 {
  width: 100%;
  height: 85px;
  font-size: 23px;
  color: #0a3f67;
  font-weight: bold;
  text-align: center;
  margin: 20px 0 0 0;
}
.box3 .block-1 p {
  font-size: 13px;
  margin-top: 20px;
}
.yearOrTotal {
  width: 180px;
  height: 40px;
  border: 1px solid #409EFF;
  border-radius: 20px;
  margin: 10px auto 0;
  display: flex;
}
.yearOrTotal div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  font-size: 13px;
  color: #409EFF;
  border-radius: 20px 0 0 20px;
  cursor: pointer;
}
.yearOrTotal div:last-child {
  border-radius: 0 20px 20px 0;
}
.yearOrTotal div.active {
  color: #fff;
  background-color: #409EFF;
}
.box3 .block-2 {
  width: 100%;
  height: 45px;
  display: flex;
}

.box3 .block-2 div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #0a3f67;
  font-weight: bold;
}

.box4 .block-text:hover {
  opacity: 0.8;
}

.box3 .block-2 div:nth-child(1) {
  min-width: 70px;
  width: 70px;
  padding-left: 28px;
}

.box3 .block-2 div:nth-child(2) {
  min-width: 354px;
  /* width: 354px; */
  padding-left: 65px;
}

.box3 .block-2 div:nth-child(3) {
  min-width: 224px;
  width: 224px;
}

.box3 .block-2 div:nth-child(4) {
  min-width: 165px;
  width: 165px;
}

.box3 .block-2 div:nth-child(5) {
  min-width: 165px;
  width: 165px;
}

.box3 .block-2 div:nth-child(6) {
  min-width: 165px;
  width: 165px;
}

.box3 .block-2 div:nth-child(7) {
  min-width: 165px;
  width: 165px;
}

.box4 {
  width: 100%;
  padding-top: 11px;
  margin-bottom: 32px;
}

.box4 .block-text {
  display: flex;
  height: 51px;
}

.box4 .block-text:nth-child(2n + 1) {
  background-color: #d8edfe;
}

.box4 .block-text div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* border: 1px solid black; */
  padding-left: 8px;
  cursor: pointer;
}

.box4 .block-text div a {
  color: #333;
}

.box4 .block-text div a:hover {
  color: #1685de;
}

.box4 .block-text div:nth-child(1) {
  min-width: 70px;
  width: 70px;
  font-weight: bold;
  padding-left: 35px;
}

.box4 .block-text div:nth-child(2) {
  min-width: 354px;
  /* width: 354px; */
  padding-left: 35px;
}

.box4 .block-text div:nth-child(3) {
  min-width: 210px;
  width: 210px;
}

.box4 .block-text div:nth-child(4) {
  min-width: 175px;
  width: 175px;
}

.box4 .block-text div:nth-child(5) {
  min-width: 165px;
  width: 165px;
}

.box4 .block-text div:nth-child(6) {
  min-width: 165px;
  width: 165px;
}

.box4 .block-text div:nth-child(7) {
  min-width: 165px;
  width: 165px;
}
</style>
