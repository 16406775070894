<template>
    <div id="zsy">
        <!-- <TopBannerBox :positions="positions"></TopBannerBox> -->
		<div class="ppt"></div>
        <!--导航条-->
        <div class="details_nav w">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item,i) in currNav.childrens" :key="i"
                                    :to="{ path: item.path }">{{item.name}}
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{currNav.name}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="details">
            <h1 class="detailTitle">{{info[currNav.titleKey]}}</h1>
            <div class="detailtopbox">
                <span class="time" v-for="(item,i) in currNav.titleList" :key="i">
                    {{item.show}}：{{info[item.key]}}
                </span>&nbsp;
            </div>
            <div v-html="info.details">

            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "DynamicDetail",
        data() {
            return {
                type: '',
                id: '',
                currNav: {},
                info: {},
                navigations: [
                    {
                        type: 1,
                        activeName: 'mosService',
                        name: '国家政策详情',
                        portName: 'companyFinalDetail',
                        childrens: [{name: '国家政策', path: '/mosNews/1'}],
                        titleKey: 'title',
                        titleList: [{key: 'releaseTime', show: '发布时间'}]
                    },
                    {
                        type: 2,
                        activeName: 'mosService',
                        name: '省级政策详情',
                        portName: 'companyFinalDetail',
                        childrens: [{name: '省级政策', path: '/mosNews/2'}],
                        titleKey: 'title',
                        titleList: [{key: 'releaseTime', show: '发布时间'}]
                    },
                ],
                positions:"4"
            }
        },
        created() {
            this.type = this.$route.params.type  //获取详情页类型
            
            this.id = this.$route.params.id   //获取详情id
            this.currNav = this.navigations.filter((data) => {  //找出该详情页类型
                return data.type == this.type
            })[0]
			
            this.$store.commit('changeActive', this.currNav.activeName)
            this.getMsg();
        },
        methods: {
            async getMsg() { //获取详情
                let id = this.id;
                let res = await this.$api[this.currNav.portName]({id});
                this.info = res.result;
                console.log(res);
            }
        },
        components: {
			
		}
    }
</script>

<style scoped>
    .time {
        margin: 0px 20px 0px 0px;
        padding: 0px;
    }

    .detailtopbox {
        margin: 10px 0px 0px;
        padding: 0px;
        font-family: 微软雅黑, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        color: #a1a1a1;
        text-align: center;
        font-size: 14px;
    }

    .detailTitle {
        margin: 0px;
        padding: 0px 0px 20px;
        font-size: 22px;
        font-family: 微软雅黑, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        text-align: center;
        border-bottom: 1px solid #f0f0f0;
    }

    .support_banner {
        width: 100%;
        height: 340px;
    }

    .support_banner img {
        width: 100%;
        height: 100%;
        min-width: 1200px;
    }

    .details {
        width: 1200px;
        margin: 0 auto;
        background-color: #fff;
        margin-bottom: 70px;
        box-shadow: 0px 0px 20px #888;
        margin-top: 20px;
        padding: 40px;
    }
	
	.ppt {
		width: 100%;
		height: 339px;
		background-image: url("../../static/img/wsy_img1.png");
	}
</style>
<style>
    #zsy .details img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
</style>
