//接口
// const BASE_URL = 'http://127.0.0.1:3000/'
// const BASE_URL = 'http://218.11.9.39/guquan/'
const BASE_URL = 'https://ipo.hebotc.com:/guquan/'
// const BASE_URL = 'http://192.168.101.23:8090/jeecg-boot/'
export default {
    domainURL: BASE_URL + 'sys/common/static/',
    uploadFile: BASE_URL + 'sys/common/upload',
    //#region 注册
    province: BASE_URL + 'stockright/web/province/queryProvinceList',  //三级联动获取省份
    city: BASE_URL + 'stockright/web/province/queryCityList',  //三级联动获取城市
    area: BASE_URL + 'stockright/web/province/queryAreaList',  //三级联动获取地区
    getCode: BASE_URL + 'stockright/web/user/sedRegisterSms', //注册验证码
    getCode2: BASE_URL + 'stockright/web/user/sedLoginSms', //登录验证码
    getCode3: BASE_URL + 'stockright/web/user/sedChangeSms', //修改手机号短信
    getCode4: BASE_URL + 'stockright/web/signup/sedRegisterSms', //路演报名发送短信
    getCode5: BASE_URL + 'stockright/web/enclosureApply/sedSms', //融资申请发送短信
    registor: BASE_URL + 'stockright/web/user/register', //企业注册
    loginInPwd: BASE_URL + 'stockright/web/user/passwordLogin', //企业账号密码登录
    loginInPwd2: BASE_URL + 'stockright/web/user/governmentPasswordLogin', //政府账号密码登录
    loginInCode: BASE_URL + 'stockright/web/user/smsLogin', //验证码登录
    randomImg: BASE_URL + 'stockright/web/user/randomImage', //获取验证码图片
    //#endregion

    //用户登录日志
    loginLog: BASE_URL + 'stockright/web/userLog/add',

    //region首页
    listedCompany: BASE_URL + 'stockright/web/companyList/list', //上市公司
    NEEQ: BASE_URL + 'stockright/web/companyThird/list', //新三板公司
    examination: BASE_URL + 'stockright/web/companyTrail/list', //在审企业
    counseling: BASE_URL + 'stockright/web/companyCoach/list', //在辅导企业
    coachNum: BASE_URL + 'stockright/web/companyCoach/queryNumber', //上市数据各个总数
    cityNum: BASE_URL + 'stockright/web/companyCoach/queryList', //各个城市数据
    bannerImg: BASE_URL + 'stockright/web/banner/list', //轮播图
    bannerDetail: BASE_URL + 'stockright/web/banner/queryById', //轮播图详情
    abroad: BASE_URL + 'stockright/web/companyHongkongstocks/list', //境外
    shingle: BASE_URL + 'stockright/web/companySJSList/queryList', //石交所挂牌数
    queryIPO: BASE_URL + 'stockright/web/ipoBond/queryIPO', //融资数据

    //endregion

    //#region 投融资动态
    ipoFinancing: BASE_URL + 'stockright/web/ipoFinancing/list', //首发融资
    ipoRefinancing: BASE_URL + 'stockright/web/ipoRefinancing/list', //股权再融资
    ipoBond: BASE_URL + 'stockright/web/ipoBond/list', //债权融资
    //endregion

    //region 融资服务

    enclosureApply: BASE_URL + 'stockright/web/enclosureApply/add', //融资申请
    getenclosureApply: BASE_URL + 'stockright/web/enclosureApply/list', //融资需求
    financingServiceIntroduce: BASE_URL + 'stockright/web/financingServiceIntroduce/queryPageLists', //融资服务列表

    //endregion

    //region 培训路演
    radshowTran: BASE_URL + 'stockright/web/radshowTran/list', //路演上市培训
    radshowLine: BASE_URL + 'stockright/web/radshowLine/list', //云直播
    radshowOnlineCourses: BASE_URL + 'stockright/web/radshowOnlineCourses/list', //线上课程
    policyNews: BASE_URL + 'stockright/web/policy/list', //政策新闻
    calendar: BASE_URL + 'stockright/web/radshowOnlineCourses/queryActivitylList', //活动日历
    radshowDetail: BASE_URL + 'stockright/web/radshow/queryById',
    //活动专题
    //路演
    radshowCompany: BASE_URL + 'stockright/web/radshowCompany/list', //参加路演企业列表查询
    readshowNumber: BASE_URL + 'stockright/web/radshowNumber/queryList', //参加路演期数列表查询
    radshowLink: BASE_URL + 'stockright/web/radshowLink/queryList', //参加路演赞助人物/机构媒体
    radshowDemeanor: BASE_URL + 'stockright/web/radshowDemeanor/queryList', //参加路演风采
    querySpecial: BASE_URL + 'stockright/web/radshowOnlineCourses/querySpecialList', //根据专题获取专题内容
    radshowsignup: BASE_URL + 'stockright/web/signup/add', //路演报名
    radshowBannerImg: BASE_URL + 'stockright/web/radshow/queryList', //上市路演banner
    //#endregion

    //region 上市资源库
    serviceOrganization: BASE_URL + 'stockright/web/serviceOrganization/list', //平台服务机构
    industryList: BASE_URL + 'stockright/web/industry/queryList', //行业列表查询
    //#endregion

    //region 线上咨询，申请
    questime: BASE_URL + 'stockright/web/questimeConsultation/add', //线上咨询
    applys: BASE_URL + 'stockright/web/applys/add', //上市申请表
    applyspolicy: BASE_URL + 'stockright/web/policy/queryList', //上市补贴申请
    commonProblem: BASE_URL + 'stockright/web/commonProblem/list',//常见问题
    //endregion

    //region 各个详情页接口
    policyDetail: BASE_URL + 'stockright/web/policy/queryById', //政策、新闻
    radshowTranDetail: BASE_URL + 'stockright/web/radshowTran/queryById', //路演上市培训
    radshowOncurDetail: BASE_URL + 'stockright/web/radshowOnlineCourses/queryById', //路演上市培训
    serviceOrganizationDetail: BASE_URL + 'stockright/web/serviceOrganization/queryById', //平台服务机构
    radshowLineDetail: BASE_URL + 'stockright/web/radshowLine/queryById', //路演直播

    //endregion

    //region 个人中心
    editCompanyMessage: BASE_URL + 'stockright/web/company/editCompanyMessage', //公司个人中心提交表单
    companyMessage: BASE_URL + 'stockright/web/company/queryCompanyMessageByUserId', //查询公司基本信息
    queryPlateNumber: BASE_URL + 'stockright/web/companyList/queryPlateNumber', //各个上市板块的数据统计
    IndustryNumber: BASE_URL + 'stockright/web/companyList/queryIndustryNumber', //各个行业的数据统计
    companyStatisticsList: BASE_URL + 'stockright/web/companyStatistics/list',
    //endregion

    //region其他
    projectDic: BASE_URL + 'stockright/web/projectDic/queryList', //数据字典
    fudaoCompany: BASE_URL + 'stockright/web/companyCoach/queryListfudao', //河北省上市辅导企业
    fudaoCompanyDetail: BASE_URL + 'stockright/web/companyCoach/queryById', //河北省上市辅导企业详情
    houbeiCompany: BASE_URL + 'stockright/web/companyCoach/listHoubeiList', //平台上市企业后备库
    applyed: BASE_URL + 'stockright/web/applys/list', //上市补贴已申请（已办）
    noapply: BASE_URL + 'stockright/web/applys/policyList', //上市补贴已申请（待办）
    radshowSigup: BASE_URL + 'stockright/web/radshowSigup/list', //路演直播已报名
    radshowApply: BASE_URL + 'stockright/web/radshowSigup/add', //路演直播报名
    changePassword: BASE_URL + 'stockright/web/user/changePassword', //修改密码
    changePhone: BASE_URL + 'stockright/web/user/changePhone', //-修改手机号
    linkUrl: BASE_URL + 'stockright/web/linkUrl/queryList', //友情链接


	queryFundCompanyList:BASE_URL + "stockright/web/enclosureApply/queryFundCompanyList",
	queryFundDetailsList:BASE_URL + "stockright/web/enclosureApply/queryFundDetailsList",
    //endregion
    // 上市辅导，获取总数
    getTotal:BASE_URL + "stockright/web/companyCoach/queryListfudaoNumber",
	queryFundNews:BASE_URL + "stockright/web/enclosureApply/queryFundNews",
	companyFinalDetail:BASE_URL + "stockright/web/enclosureApply/queryById",

	queryNewsSource:BASE_URL+"stockright/web/enclosureApply/queryNewsSource",

}
