<template>
	<div id="zjj_support">
		<div class="ppt"></div>
		<div class="details_nav w">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/mosService' }">融资服务</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/CompanyService/1349914829750788098' }">政府投资引导资金</el-breadcrumb-item>
				<el-breadcrumb-item>{{listName}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<section id="section2">
			<div class="w newsbox" v-if="type==1">
				<div class="source">
					<div class="item" @click="changeSource(item)" :class="item.value==currentSource.value?'active':''" v-for="(item,index) in sourceList" :key="index">{{item.text}}</div>
				</div>
				<div class="cont1continer">
					<div class="cont1boxs">
						<div class="cont1top">
							<div class="cont1title">{{listName}}</div>
							<!-- <div class="wtchmore">查看更多<img src="./img/moreimg.png" alt=""></div> -->
						</div>
						<div class="innerbox">
							<div class="inners" v-for="(item,i) in news" :key="i">
								<router-link tag="a" :to="'/companyFinalDetail/'+type+'/'+item.id">
									<div class="overhid">{{item.title}}</div>
								</router-link>
								<div>{{item.releaseTime}}</div>
							</div>
						</div>
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="page" :page-sizes="[10, 20, 30, 40,50]" :page-size="pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
			<div class="w" v-if="type==2">
				<div class="cont1continer" style="width: 1200px;">
					<div class="cont1boxs">
						<div class="cont1top">
							<div class="cont1title">{{listName}}</div>
							<!-- <div class="wtchmore">查看更多<img src="./img/moreimg.png" alt=""></div> -->
						</div>
						<div class="innerbox">
							<div class="inners" v-for="(item,i) in news" :key="i">
								<router-link tag="a" :to="'/companyFinalDetail/'+type+'/'+item.id">
									<div class="overhid">{{item.title}}</div>
								</router-link>
								<div>{{item.releaseTime}}</div>
							</div>
						</div>
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="page" :page-sizes="[10, 20, 30, 40,50]" :page-size="pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		name: "mosNews",
		data() {
			return {
				news: [],
				type:1,
				page:1,
				pageSize:10,
				total:0,
				listName:"",
				sourceList:"",
				currentSource:"",
			}
		},
		mounted() {
			if(this.$route.params.type != '' && this.$route.params.type!=undefined){
				this.type = this.$route.params.type;
			}
			
			if(this.type==1){
				this.listName = "国家政策";
			}else{
				this.listName = "省级政策";
			}
			
			this.getNewsSource();
		},
		methods: {
			handleSizeChange(val) {
				this.pageSize = val;
				this.getNews();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getNews();
			},
			changeSource(item){
				this.currentSource = item;
				this.getNews();
			},
			async getNews() {
				let param = {};
				if(this.type==1){
					param = {
						type:this.type,
						pageNo:this.page,
						pageSize:this.pageSize,
						source:this.currentSource.value
					}
				}else{
					param = {
						type:this.type,
						pageNo:this.page,
						pageSize:this.pageSize,
					}
				}
				let res = await this.$api.queryFundNews(param);
				this.news = res.result.records;
				this.total = res.result.total;
			},
			async getNewsSource(){
				let res =await this.$api.queryNewsSource({});
				this.sourceList = res.result;
				this.currentSource = res.result[0];
				this.getNews();
			},
		},
		components: {
		}

	}
</script>

<style scoped>
	.newsbox{
		display: flex;
		justify-content: space-between;
	}
	
	.source{
		width:220px;
		margin-top:50px;
	}
	
	.source .item{
		padding:15px;
		border-top:1px solid lightgray;
		border-left:1px solid lightgray;
		border-right:1px solid lightgray;
		color: #333;
		font-weight: 500;
		letter-spacing:1px;
		cursor: pointer;
	}
	
	.source .item:nth-last-of-type(1){
		border-bottom:1px solid lightgray;
	}
	
	.source .active{
		background-color: #075aa6;
		color: white;
	}
	
	.cont1continer {
		width:930px;
		margin-bottom: 130px;
	}

	.cont1boxs .cont1top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 41px;
		padding-bottom: 18px;
		border-bottom: 3px solid #075aa6;
	}

	.cont1boxs .cont1top .cont1title {
		color: rgb(20, 82, 181);
		font-size: 25px;
	}
	
	
	.news-box{
		
	}

	.cont1boxs .cont1top .watchmore {
		color: #ccc;
	}

	.cont1boxs .innerbox {
		padding-bottom: 30px;
	}

	.cont1boxs {
		border-bottom: 1px solid #ccc;
	}

	.cont1boxs:last-child {
		border-bottom: none;
	}

	.cont1boxs .innerbox .inners {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 0;
		color: #333;
	}

	.cont1boxs .innerbox .inners a {
		color: #333;
	}

	.overhid {
		max-width: 1000px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.cont1boxs .innerbox .inners a:hover {
		color: rgb(20, 82, 181);
		text-decoration: underline;
	}

	#zjj_support .support_banner {
		width: 100%;
		height: 340px;
	}

	#zjj_support .support_banner img {
		width: 100%;
		height: 100%;
		min-width: 1200px;
	}

	#zjj_support #section1 form .box div.fabu_data span {
		display: inline-block;
		flex-shrink: 0;
		height: 31px;
		line-height: 31px;
		color: #6b6a68;
	}


	#zjj_support #section1 form .box .box_son input,
	#zjj_support #section1 form .box .box_son select {
		border: 1px #e8e8e8 solid;
		border-radius: 3px;
		width: 304px;
		height: 40px;
		padding-left: 12px;
		font-size: 13px;
		outline: none;
		color: #6b6a68;
	}

	#zjj_support #section1 form .box .box_son button {
		width: 68px;
		height: 36px;
		border: 1px #eaebef solid;
		border-radius: 3px;
		color: #616469;
		font-size: 12px;
		background-color: #fff;
		cursor: pointer;
	}

	#zjj_support #section2 {
		/* margin-bottom: 39px; */
	}

	.sda {
		margin-top: 39px !important;
	}

	#zjj_support #section2 .container h4 {
		height: 87px;
		font-size: 13px;
		color: #272725;
		padding-top: 29px;
		font-weight: normal;
		border-bottom: 1px solid #e4e4e4;
		padding-left: 29px;
	}

	#zjj_support #section2 .container h4 span {
		margin-right: 15px;
	}

	#zjj_support #section2 .container h4 span b {
		color: #fe0000;
		font-weight: normal;
	}

	#zjj_support #section2 .container ul li {
		padding-left: 29px;
		padding-right: 28px;
		font-size: 12px;
		border-bottom: 1px solid #e4e4e4;
	}
	
	
	.ppt {
		width: 100%;
		height: 339px;
		background-image: url("../../static/img/wsy_img1.png");
	}
</style>
