<template>
    <div id="zsy">
        <div class="support_banner">
            <img src="../../static/img/zjj_support_banner.png"/>
        </div>
        <!--导航条-->
        <div class="details_nav w">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/roadShow' }">培训路演</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/training' }">上市路演</el-breadcrumb-item>
                <el-breadcrumb-item>预告详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="details">
<!--            <h1 class="detailTitle">{{info[currNav.titleKey]}}</h1>-->
            <div v-html="info.details">

            </div>
            <div class="footbutton2" v-if="show">
                <button type="button"  @click="baoming"  class="button4"><span>企业/机构报名</span></button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "roadShowSwiperDetail",
        data(){
            return{
                id:'',
                info:'',
                nowTime: '',
                endTime: '',
                show: true
            }
        },
        mounted() {
            this.id = this.$route.params.id   //获取详情id
            this.getMsg();
        },
        created: function() {
            // 获取当前时间的时间戳
            this.nowTime = new Date().getTime();
        },
        methods:{
            async getMsg() { //获取详情
                let id = this.id;
                let res = await this.$api.radshowDetail({id});
                this.info = res.result;
                // 将获取到的结束时间转化为时间戳
                this.endTime = Date.parse(new Date(res.result.beginTime))
                if(this.nowTime > this.endTime) {
                    this.show = false;
                     this.$message({
                        type: 'warning',
                        center: true,
                        offset: 500,
                        message: '报名已过期，请关注下次路演!'
                    });
                }else {
                    this.show = true
                }
            },
            baoming(){
                this.$router.push('/registration/')
            }

        }

    }
</script>

<style scoped>
    .time {
        margin: 0px 20px 0px 0px;
        padding: 0px;
    }

    .detailtopbox {
        margin: 10px 0px 0px;
        padding: 0px;
        font-family: 微软雅黑, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        color: #a1a1a1;
        text-align: center;
        font-size: 14px;
    }

    .detailTitle {
        margin: 0px;
        padding: 0px 0px 20px;
        font-size: 22px;
        font-family: 微软雅黑, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        text-align: center;
        border-bottom: 1px solid #f0f0f0;
    }

    .support_banner {
        width: 100%;
        height: 340px;
    }

    .support_banner img {
        width: 100%;
        height: 100%;
        min-width: 1200px;
    }

    .details {
        width: 1200px;
        margin: 0 auto;
        background-color: #f6f6f6;
        margin-bottom: 70px;
        box-shadow: 0px 0px 20px #888;
        margin-top: 20px;
        padding: 40px;
    }
    .footbutton2 {
        width: 190px;
        height: 71px;
        border: 2px #33a8c7 solid;
        border-radius: 11px;
        padding: 3px 3px 4px 4px;
        margin: 20px auto;

    }
    .button3, .button4 {
        width: 178px;
        height: 60px;
        border-radius: 11px;
        background-color: #33a8c7;
        cursor: pointer;
    }
    button span {
        font-size: 24px;
        color: #FFFFFF;
        display: inline-block;
        font-weight: bold;
        height: 37px;
    }
</style>
<style>
    #zsy .details img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
</style>