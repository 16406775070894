<template>
    <div class="dataaNull">
        暂无数据
    </div>
</template>

<script>
    export default {
        name: "dataNull"
    }
</script>

<style scoped>
.dataaNull{
    width: 100%;
    height: 100%;
   display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #aaa;
    font-size: 25px;
}
</style>