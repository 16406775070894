<template>
    <div>
        {{currTime}}
    </div>
</template>

<script>
    export default {
        name: "CountDown",
        data() {
            return {
                currTime:''
            }
        },
        props: {
            time1: {
                type: Number,
                default(){
                    return 0;
                }
            },
            time2: {
                type: Number,
                default(){
                    return 0;
                }
            }
        },
        mounted() {
            this.setTime();
        },
        methods: {
            setTime() {
                var that = this;
                let day = '00';
                let hou = '00';
                let min = '00';
                let sec = '00';
                var interval = setInterval(function timestampToTime() {
                    let currDate = new Date().valueOf();
                    var date = (that.time1 || currDate) - (that.time2 || currDate);
                    //new Date当前的时间戳，也可以换成自定义的时间戳
                    if (date > 0) {
                        let time = date / 1000;
                        // console.log(time);
                        // 获取时、分、秒,毫秒
                        day = parseInt(time / (60 * 60 * 24)) < 10 ? ('0' + parseInt(time / (60 * 60 * 24))) : parseInt(time / (60 * 60 * 24))
                        hou = parseInt((time % (60 * 60 * 24)) / 3600) < 10 ? ('0' + parseInt((time % (60 * 60 * 24)) / 3600)) : parseInt((time % (60 * 60 * 24)) / 3600)
                        min = parseInt(((time % (60 * 60 * 24)) % 3600) / 60) < 10 ? ('0' + parseInt(((time % (60 * 60 * 24)) % 3600) / 60)) : parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
                        sec = parseInt(((time % (60 * 60 * 24)) % 3600) % 60) < 10 ? ('0' + parseInt(((time % (60 * 60 * 24)) % 3600) % 60)) : parseInt(((time % (60 * 60 * 24)) % 3600) % 60);
                    } else {
                        //活动已结束，全部设置为'00'
                        // console.log("aaa")
                        day = "00",
                            hou = "00",
                            min = "00",
                            sec = "00"
                    }
                    that.currTime = day=='00'?`${hou}时 ${min}分 ${sec}秒`:`${day}天 ${hou}时 ${min}分 ${sec}秒`
                }, 1000)
            }
        },
    }
</script>

<style scoped>

</style>