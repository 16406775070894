<template>
    <div class="tab">
        <div class="tab1" :class="{'active':currval==i}"
             :style="style"
             v-for="(item,i) of tabList" :key="item.id"
             @click="changeCur(i)">
            {{item.label}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "CenterTab2",
        props:{
            style:{
                type:Object,
                default(){
                    return {}
                }
            },
            tabList:{
                type:Array,
                default(){
                    return []
                }
            }
        },
        data(){
            return {
                currval:0
            }
        },
        methods: {
            changeCur(i){
                this.currval = i;
                this.$emit('change',i)
            }
        }
    }
</script>

<style scoped>
    .tab{
        width: 100%;
        height: 76px;
        display: flex;
        /* margin-bottom: 50px; */
        border:1px solid #ECECEC;
        box-sizing: border-box;
    }
    .tab1{
        flex: 1;
        background-color: #f5f5f5;
        text-align: center;
        line-height: 76px;
        color: #404040;
        cursor: pointer;
        transition: all 0.2s;
    }
    .tab .active{
        background-color: #3ca9fc;
        color: #FFF;
    }
</style>