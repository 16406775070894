<template>
    <div>
        <TopBannerBox positions="4"></TopBannerBox>
        <section class="s1 w clearfix">
            <div class="one" v-for="(item,i) in topPageList" :key="item.id">
                <div class="onein">
                    <div class="oneinyuan">
                        <div class="oneinyabox">{{item.name}}</div>
                    </div>
                </div>
                <router-link tag="a" :to="item.url" class="lj">了解详情</router-link>
            </div>
        </section>
        <section class="s2">
            <div class="s2in w clearfix">
                <div class="s2inleft">
                    <div class="s2intitle">
                        投融资动态
                    </div>
                    <FinancingBox></FinancingBox>
                </div>
                <div class="s2right">
                    <div class="s2intitle">
                        融资需求
                    </div>
                    <div class="s2rightbom">
                        <div class="s2rightbom1">
                            <div class="s2rightbomtitle">企业名称</div>
                            <div class="s2rightbomtitle">期望融资金额</div>
                        </div>
                        <div id="news">
                            <ul class="rzxq_ul" :class="{anim:animate==true}">
                                <li v-for="(item,i) in needList" :key="item.id">
                                    <div class="p1">{{item.companyName|nameHide}}</div>
                                    <div class="p1">{{item.enclosurePrice}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import FinancingBox from "../../components/FinancingBox";
    import TopBannerBox from "../../components/TopBannerBox";

    export default {
        name: "MosService",
        components: {
            TopBannerBox,FinancingBox
        },
        data() {
            return {
                topPageList:[
                    {id:1,name:'股权服务',url:'/equityService/1349914694723559425'},
                    {id:2,name:'债券服务',url:'/equityService/1349914776202108930'},
                    {id:3,name:'银行助贷',url:'/equityService/1349914829750788098'},
                    {id:3,name:'投资引导资金',url:'/CompanyService/1349914829750788098'},
                ],
                needList: [
                    {id: 1, name: '石家庄啊有限公司', money: '500万元'},
                    {id: 2, name: '河北省阿萨大大科技有限公司', money: '300万元'},
                    {id: 3, name: '辛集市阿萨大大科技有限公司', money: '500万元'},
                    {id: 4, name: '啊啊阿萨大大科技有限公司', money: '500万元'},
                    {id: 5, name: '石家庄阿萨大大科技有限公司', money: '200万元'},
                    {id: 6, name: 'g撒阿萨大大科技有限公司', money: '500万元'},
                    {id: 7, name: '石家庄阿萨大大科技有限公司', money: '500万元'},
                    {id: 8, name: '松大阿萨大大科技有限公司', money: '500万元'},
                    {id: 9, name: '石从阿萨大大科技有限公司', money: '500万元'},
                    {id: 10, name: '给方法阿萨大大科技有限公司', money: '500万元'},
                ],
                //滚动效果
                animate: false,
            }
        },
        async mounted() {
            await this.getNeed();
            if (this.needList.length > 8) {
                setInterval(this.showMarquee, 2000);
            }
        },
        methods: {
            //滚动代码
            showMarquee() {
                this.animate = true;
                setTimeout(() => {
                    this.needList.push(this.needList.shift())
                    this.animate = false;
                }, 1000);
            },
            async getNeed(){
                let res = await this.$api.getenclosureApply();
                this.needList = res.result.records;
                console.log(res);
            }
        },
        filters: {
            nameHide(name = '') {
                let newStr = name;
                if(name.length>6){
                    let char = '';
                    const leftnum = 3; //前面字符显示个数
                    const rightnum = 3; //后面字符显示个数
                    for (let i = 0, len = name.length - (leftnum + rightnum); i < len; i++) {
                        char += '*';
                    }
                    newStr = name.substr(0, leftnum) + char + name.substr(-rightnum, rightnum);
                }
                return newStr;
            }
        }
    }
</script>

<style scoped>
    .anim{
        transition: all 1s;
        transform: translateY(-40px);
    }

    .s1 {
        height: 542px;
        padding-top: 65px;
		display: flex;
		justify-content: space-between;
    }

    .one {
        width: 285px;
        height: 400px;
        border-radius: 13px;
        background-color: #3ca9fc;
        text-align: center;
        padding-top: 30px;
    }

    .one:nth-child(3) {
        margin-right: 0px;
    }

    .onein {
        width: 270px;
        height: 257px;
        background-color: #59cef1;
        display: inline-block;
        padding-top: 61px;
    }

    .oneinyuan {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #3ca9fc;
        margin: 0 auto;
        text-align: center;
        /* padding-top: 32px; */
        color: #FFF;
        font-size: 26px;
        font-weight: bold;
		display: flex;
		align-items:center;
    }
    .oneinyabox{
        width:80%;
        margin: 0 auto;
    }

    .lj {
        width: 209px;
        height: 42px;
        border-radius: 5px;
        background-color: #FFF;
        display: inline-block;
        margin-top: 42px;
        text-align: center;
        line-height: 42px;
        color: #3fa9ff;
        font-size: 18px;
    }

    .s2 {
        background-color: #d8edfe;
        width: 100%;
        height: 572px;
        margin-bottom: 75px;
    }

    .s2in {
        padding-top: 40px;
    }

    .s2inleft {
        width: 586px;
        height: 489px;
        float: left;
    }

    .s2intitle {
        height: 35px;
        width: 100%;
        margin-bottom: 27px;
        border-bottom: 2px #9a9899 solid;
        padding-left: 10px;
        line-height: 33px;
        font-size: 23px;
        color: #41a5fa;
        font-weight: bold;
    }

    .s2leftb {
        height: 427px;
        width: 100%;
        margin-top: 27px;
    }

    .tablegroup {
        height: 52px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .table {
        width: 192px;
        height: 38px;
        background-color: #f0f0f0;
        margin-top: 14px;
        float: left;
        text-align: center;
        line-height: 38px;
        font-size: 18px;
        margin-right: 3px;
        cursor: pointer;
    }

    .teblebom {
        height: 375px;
        width: 100%;
        display: block;
    }

    .none {
        height: 375px;
        width: 100%;
        display: none;
    }

    .yihang {
        height: 123px;
        color: #000000;
        background-color: #FFF;
        display: flex;
        margin-bottom: 3px;
        min-width: 586px;
    }

    .yihang:hover {
        background-color: #F0F0F0;
        cursor: pointer;
    }

    .yige {
        height: 100%;
        flex: 1;
        line-height: 30px;
        padding-top: 34px;
        text-align: center;
        font-size: 20px;
    }

    .lanzi {
        color: #40abf9;
    }

    .s2right {
        width: 586px;
        height: 489px;
        float: right;
    }

    .s2rightbom {
        height: 413px;
        width: 100%;
        margin-top: 41px;
        /*background-color: #fff;*/
        /*padding-top: 35px;*/
    }

    .s2rightbom1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
    }

    .s2rightbomtitle {
        width: 49.5%;
        height: 38px;
        text-align: center;
        line-height: 38px;
        font-size: 18px;
        background: #f0f0f0;
    }

    .p1 {
        width: 50%;
        color: #83888e;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
    }

    .active {
        height: 52px;
        line-height: 52px;
        background-color: #dc3338;
        color: #fff;
        font-size: 19px;
    }

    #news, #news1, #news2, #news3 {
        width: 100%;
        height: 375px;
        overflow: hidden;
        padding-top: 16px;
        background: #fff;
    }

    .rzxq_ul, .tablebom ul {
        width: 100%;
        height: 100%;
    }

    .rzxq_ul li {
        width: 100%;
        /* height: 50px; */
        display: flex;
    }

    #news1, #news2, #news3 {
        margin-top: 0;
        height: 378px;
    }

</style>
