<template>
    <div id="zjj_index">
        <section id="section3">
            <div id="tb3"></div>
        </section>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    export default {
        name: "Industrycount",
        data(){
            return {
                showtabb: 1,
                XList:[], //x轴数据
                YList:[], //y轴数据
            }
        },
        mounted() {
            this.getMsg();
        },
        methods: {
            async getMsg(){
              let res = await this.$api.IndustryNumber();
              for(let item of res.result){

                      this.XList.push(item.industry);
                      this.YList.push(item.number);

              }
                this.init();
                console.log(res);
            },
            init(){
                var myChart2 = echarts.init(document.getElementById('tb3'))
                var option2 = {
                    title:{
                        text: '所属行业'
                    },
                    color: ['#3398DB'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.XList,
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            barWidth: '60%',
                            data: this.YList
                        }
                    ]
                };
                myChart2.setOption(option2);
            }
        }

    }
</script>

<style scoped>
    #section3{
        margin-top: 50px;
    }
    #tb3 {
        width: 1200px;
        height: 600px;
        margin: auto;
    }
</style>