<template>
    <div id="HRX">
        <section class="w">
            <TopBannerBox positions="7"></TopBannerBox>
            <div id="shuzi" class="w clearfix">
                <div id="anniu" class="w clearfix">
                    <div class="er">
                        <span>企业性质：</span>
                        <el-select popper-class="selectFrom"
                                   v-model="form.companyType"
                                   placeholder="全部"
                                    @change="typeChange">
                            <el-option value="">全部</el-option>
                            <el-option
                                    v-for="item in cpnType"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="er">
                        <span>注册地：</span>
                        <el-select popper-class="selectFrom"
                                   v-model="form.companyCity"
                                   placeholder="全部"
                                   @change="typeChange">
                            <el-option value="">全部</el-option>
                            <el-option
                                    v-for="item in cityList"
                                    :key="item.id"
                                    :label="item.city"
                                    :value="item.cityid">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="er">
                        <span>所属行业：</span>
                        <el-select popper-class="selectFrom"
                                   v-model="form.companyIndustryId"
                                   placeholder="全部"
                                   @change="typeChange">
                            <el-option value="">全部</el-option>
                            <el-option
                                    v-for="item in industrys"
                                    :key="item.id"
                                    :label="item.industryName"
                                    :value="item.industryName">
                            </el-option>
                        </el-select>
                    </div>
                    <!--                    <div class="er">-->
                    <!--                        <span>主营业务:</span>-->
                    <!--                        <select  class="bg3" v-model="form.operation">-->
                    <!--                            <option><span>生物科技</span></option>-->
                    <!--                            <option><span>生物科技</span></option>-->
                    <!--                            <option><span>仪器制造</span></option>-->
                    <!--                            <option><span>生物制药</span></option>-->
                    <!--                            <option><span>科技研发</span></option>-->
                    <!--                            <option><span>生物科技</span></option>-->
                    <!--                            <option><span>物流管理</span></option>-->
                    <!--                            <option><span>设备制作</span></option>-->
                    <!--                        </select>-->
                    <!--                    </div>-->
                </div>
                <div class="box2">
                    <el-input
                            placeholder="请输入内容"
                            v-model="form.companyName">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                    <el-button type="primary" @click="Search">搜索</el-button>
                </div>
            </div>
            <AgencyTable :form="form" ref="AgencyTable"></AgencyTable>
        </section>
    </div>
</template>

<script>
    import TopBannerBox from "../../components/TopBannerBox";
    import AgencyTable from "./AgencyTable";
    export default {
        name: "AgencyLibrary",
        data() {
            return {
                cpnType: [
                    {id: 1, label: '民营企业', value: '民营企业(2813)'},
                    {id: 2, label: '中央国企', value: '中央国企(437)'},
                    {id: 3, label: '地方国企', value: '地方国企(780)'},
                    {id: 4, label: '中外合资', value: '中外合资(262)'},
                    {id: 5, label: '外商独资', value: '外商独资(263)'},
                    {id: 6, label: '其他', value: '其他'},
                ],
                cityList: [],
                industrys: [],
                form: {
                    companyName:'',//搜索框
                    companyType: '',
                    companyCity: '',
                    companyIndustryId: '',
                    operation: '',
                },
            }
        },
        mounted() {
            this.initMsg();
        },
        methods: {
            Search(){
                this.form.pageNo = 1;
                this.$refs.AgencyTable.getMsg()
            },
            async initMsg() {
                let res = await Promise.all([
                    this.$api.getCity({provinceId: 130000}),
                    this.$api.industryList()
                ])
                this.cityList = res[0].result;
                this.industrys = res[1].result;
                // console.log(res);
            },
            typeChange(){ //下拉框改变
                this.$refs.AgencyTable.getMsg()
            },
        },
        components: {
            TopBannerBox,AgencyTable
        }
    }
</script>
<style lang="scss">
   #HRX .selectFrom {
        color: #fff;
        .el-select-dropdown__item.hover,
        .el-select-dropdown__item:hover {
            background: #3ca9fc;
        }

    }
   #HRX .el-select .el-input__inner{
        background: #3ca9fc!important;
        color: #fff!important;
        &::placeholder{
            color: #fff;
        }
    }
</style>
<style scoped>

    .el-input {
        width: 290px;
        margin-right: 10px;
    }

    .box2 {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        padding-left: 128px;
        border-bottom: 2px dashed #d2d2d2;
    }

    #HRX section {
        width: 100%;
        /* 	background-color: aqua; */
    }

    #HRX #box {
        width: 100%;
        /* 	background-color: red; */
    }

    #HRX .width {
        width: 100%;
        height: 340px;
        min-width: 1200px;
    }

    #HRX #shuzi {
        height: 150px;
        border-bottom: 1px dotted #f6f6f6;
    }

    #HRX #anniu {
        /* 	height: 100px; */
        /* 	background-color: blueviolet; */
        padding-top: 43px;
        margin-bottom: 24px;
    }

    #HRX #anniu {
        display: flex;
        display: -webkit-flex;
        display: -webkit-box;
        font-size: 13px;
        height: 74px;
    }

    #HRX .er {
        flex: 1;
        -webkit-flex: 1;
        -webkit-box-flex: 1;
        /*line-height: 31px;*/
        padding-left: 15px;
        display: flex;
        align-items: center;
    }
    #HRX .er span {
        margin-left: 11px;
        float: left;
        margin-right: 10px;
    }

    #HRX .er a {
        background-color: #3ca9fc;
        color: white;
        /* 	height: 31px; */
        padding: 0px 15px;
        line-height: 31px;
        display: block;
        float: left;
    }

    #HRX .er .bg {
        background-color: #1786e0;
        color: white;
        margin-left: 25px;
    }

    #HRX .er .bg3 {
        background-color: #3ca9fc;
        color: white;
        display: block;
        float: left;
        padding: 7px;
        border: 0px;
        box-shadow: none;
        cursor: pointer;
        outline: none;
    }

    .bg3 option {
        display: block;
    }

    #HRX #suosou {
        /* 	height: 100px; */
        /* 	background-color: #00FFFF; */
        padding-top: 25px;
    }

    #HRX #suosou #daxiao {
        width: 400px;
        background-color: chocolate;
        margin-left: 110px;
        position: relative;
    }

    .biaodan {
        width: 291px;
        height: 35px;
        border-radius: 5px;
        border: 1px solid #8b8b8b;
        padding-left: 30px;
        position: absolute;
        left: 0px;
        z-index: 1;
        float: left;
        background-color: #f9f9f9;
    }

    .imgs {
        float: left;
        position: absolute;
        z-index: 5;
        margin: 5px 3px;
    }

    #daxiao .smt {
        width: 75px;
        height: 35px;
        background-color: #3ca9fc;
        border-radius: 5px;
        color: white;
        margin-left: 8px;
        position: absolute;
        left: 295px;
    }


</style>
