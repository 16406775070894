<template>
    <div class="textright3" id="box3">
        <div class="r3title">您好,欢迎来到河北省企业股改上市服务平台</div>
        <div class="topbordbox">
            <CenterTab :tab-list="tabList1" @change="changeTab1"></CenterTab>
            <div class="bbinnerCon">
                <div class="bbinnerbox zc_list">
                    <div class="bbinners" v-for="(item,i) in list1" :key="i">
                        <router-link tag="a" :to="'/detail/'+item.detailType+'/'+item.id">
                            <div class="bbinnertit">{{item.name}}</div>
                        </router-link>
                        <div class="bbinnerdate">{{item.time}}</div>
                    </div>
                </div>
            </div>
        </div>
                <div class="topbordbox" style="display:none;">
                    <CenterTab :tab-list="tabList2" @change="changeTab2"></CenterTab>
                    <div class="bbtmCon">
                        <div class="bbbtmbox dbsx_list">
                            <router-link to="/declare" tag="a" class="bbbtms" v-for="(item,i) in list2" :key="i">
                                <div class="bbbtmtop">
                                    <div class="bbbtmtopl">{{item.policy_dictText}}</div>
                                    <div class="bbbtmtopr" v-if="currTab2==0">去办理></div>
                                </div>
<!--                                <div class="bbbtmbtm">-->
<!--                                    <div>河北省地方金融监督管理局</div>-->
<!--                                    <div class="bbbtmbtmmiddle">活动通知</div>-->
<!--                                    <div>2020-04-04</div>-->
<!--                                </div>-->
                            </router-link>
                        </div>
                        <div class="bbbtmbox dbsx_list" style="display: none;">

                        </div>
                    </div>
                </div>
    </div>
</template>

<script>
    import CenterTab from "../../../components/CenterTab";

    export default {
        name: "accont",
        data() {
            return {
                tabList1: [
                    {id: 1, label: '扶持政策'},
                    {id: 2, label: '上市路演'},
                    {id: 3, label: '企业培训'},
                ],
                tabList2: [
                    {id: 1, label: '待办事项'},
                    {id: 2, label: '已办事项'},
                ],
                currTab1:0,
                currTab2:0,
                list1: [],
                list2:[],
                maxNum: 5, //数据显示数量
            }
        },
        computed:{
            userId(){
                return this.$store.state.userInfo.id;
            }
        },
        mounted() {
            this.getAgency()
            this.applyspolicy()
        },
        methods: {
            changeTab1(i) {
                this.list1 = [];
                this.currTab1 = i;
                if (i == 0) {
                    this.getAgency();
                } else if (i == 1) {
                    this.readshowNumber();
                } else if (i == 2) {
                    this.radshowTran();
                }
                // console.log(i);
            },
            changeTab2(e) {
                this.list2 = [];
                this.currTab2 = e;
                if(e==0){
                    this.applyspolicy();
                }else if(e==1){
                    this.getMsg();
                }
                // console.log(i);
            },
            //获取政策列表
            async getAgency() {
                let newType = 1;
                let res = await this.$api.policyNews({newType});
                for (let item of res.result.records) {
                    if (this.list1.length < this.maxNum) {
                        this.list1.push({
                            id: item.id,
                            name: item.title,
                            time: item.releaseTime,
                            detailType: 2,
                        })
                    }
                }


                // console.log(res);
            },
            //参加路演期数列表查询
            async readshowNumber() {
                let res = await this.$api.readshowNumber();
                for (let item of res.result) {
                    if (this.list1.length < this.maxNum) {
                        this.list1.push({
                            id: item.id,
                            name: item.rasshowName,
                            time: item.rasshowTime,
                            detailType: 1,
                        })
                    }
                }
                // console.log(res);
            },
            //企业培训
            async radshowTran() {
                let res = await this.$api.radshowTran();
                for (let item of res.result.records) {
                    if (this.list1.length < this.maxNum) {
                        this.list1.push({
                            id: item.id,
                            name: item.tile,
                            time: item.time,
                            detailType: 3,
                        })
                    }

                }
                // console.log(res);
            },

            async getMsg(){  //已办
                let userId = this.userId;
                let res = await this.$api.applyed({userId});
                this.list2 = res.result.records;
                console.log(res);
            },
            async applyspolicy(){  //待办
                 let userId = this.userId;
                let res = await this.$api.noapply({userId});
                for(let item of res.result){
                    this.list2.push({
                        value:item.id,
                        policy_dictText:item.title
                    })
                }
                // console.log(res);
            },

        }
        ,
        components: {
            CenterTab
        }
    }
</script>

<style scoped>
    .textright3, .textright2 {
        /*margin-left:25px;*/
        width: 873px;
        padding: 39px 0px;
        background: #fff;
    }

    .r3title {
        font-size: 20px;
        /*font-weight: bold;*/
        color: #FF0036;
        padding: 0 46px;
        /*text-align: center;*/
    }

    .topbordbox {
        border: 1px solid #ECECEC;
        margin-bottom: 20px;
    }

    .bbinnerbox .bbinners {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
        padding: 0 46px;
    }

    .bbinnerbox .bbinners .bbinnertit {
        font-size: 16px;
        color: #363636;
        max-width: 600px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .bbinnerbox .bbinners .bbinnerdate {
        font-size: 16px;
        color: #9D9D9D;
    }

    .bbbtmbox .bbbtms {
        display: inline-block;
        width:100%;
    }

    .bbbtmbox .bbbtms .bbbtmtop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #2A2A2A;
        padding:  0 46px;
        margin: 15px 0 0;
    }
    .bbbtmbox .bbbtms:last-child {
        margin-bottom: 15px;
    }
    /* .bbbtmbox .bbbtms .bbbtmtop .bbbtmtopl {
        widows: 100%;
        overflow: hidden;
    } */
    .bbbtmbox .bbbtms .bbbtmtopr {
        font-size: 16px;
        color: #2695FE;
    }

    .bbbtmbox .bbbtms .bbbtmbtm {
        display: flex;
        align-items: center;
        margin-top: 12px;
        font-size: 12px;
        color: #7E7E7E;
    }

    .bbbtmbtmmiddle {
        margin: 0 97px 0 65px;
    }
</style>