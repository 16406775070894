<template>
  <div id="zjj_support">
    <div class="banner">
      <img src="../../static/img/xq.jpg" style="width:100%;" />
    </div>
    <section id="section2">
      <div class="w">
        <div class="cont1continer">
          <div class="cont1boxs">
            <div class="cont1top">
              <div class="cont1title">{{ title }}</div>
              <!-- <div class="wtchmore">查看更多<img src="./img/moreimg.png" alt=""></div> -->
            </div>
            <div class="innerbox">
              <div class="inners" v-for="(item, i) in news" :key="i">
                <router-link tag="a" :to="'/detail/2/' + item.id"
                  ><div class="overhid">{{ item.title }}</div></router-link >
                <div>{{ item.releaseTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paginationbox">
        <el-pagination
          background
          v-if="show_page"
          :current-page="pageNo"
          :page-size="pageSize"
          @current-change="currentChange"
          layout="prev, pager, next"
          :page-count="pageCount"  >
        </el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "policyList",
  data() {
    return {
      type: "",
      news: [],
      title: "",
      show_page: true,
      pageNo: 1,
      pageSize: 10, //每页显示数量
      pageCount: 0, //总页数
      total: 0, //数据总数
    };
  },
  created() {
    this.type = this.$route.params.type;
    if (this.type == 4) {
      this.title = "国家政策";
    } else if (this.type == 1) {
      this.title = "省级政策";
    } else if (this.type == 2) {
      this.title = "厅局政策";
    } else if (this.type == 3) {
      this.title = "地区政策";
    }
  },
  mounted() {
    this.getNews();
  },
  methods: {
    async getNews() {
      let newType = 1;
      let type = this.type;
      let pageNo = this.pageNo;
      let pageSize = this.pageSize;
      let res = await this.$api.policyNews({ newType, type, pageNo,pageSize });
      this.news = res.result.records;
      this.total = res.result.total;
      this.pageCount = res.result.pages;
      this.$nextTick(() => {
        this.page_show = true;
      });
    },
    currentChange(e) {
      //页数改变
      this.pageNo = e;
      this.getNews();
    },
  },
};
</script>

<style scoped>
.cont1container {
  margin-bottom: 130px;
}

.cont1boxs .cont1top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 41px;
  padding-bottom: 18px;
  border-bottom: 3px solid #075aa6;
}

.cont1boxs .cont1top .cont1title {
  color: rgb(20, 82, 181);
  font-size: 25px;
}

.cont1boxs .cont1top .watchmore {
  color: #ccc;
}

.cont1boxs .innerbox {
  padding-bottom: 30px;
}

.cont1boxs {
  border-bottom: 1px solid #ccc;
}

.cont1boxs:last-child {
  border-bottom: none;
}

.cont1boxs .innerbox .inners {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  color: #333;
}

.cont1boxs .innerbox .inners a {
  color: #333;
}
.overhid {
  max-width: 1000px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cont1boxs .innerbox .inners a:hover {
  color: rgb(20, 82, 181);
  text-decoration: underline;
}

#zjj_support .support_banner {
  width: 100%;
  height: 340px;
}

#zjj_support .support_banner img {
  width: 100%;
  height: 100%;
  min-width: 1200px;
}

#zjj_support #section1 form .box div.fabu_data span {
  display: inline-block;
  flex-shrink: 0;
  height: 31px;
  line-height: 31px;
  color: #6b6a68;
}

#zjj_support #section1 form .box .box_son input,
#zjj_support #section1 form .box .box_son select {
  border: 1px #e8e8e8 solid;
  border-radius: 3px;
  width: 304px;
  height: 40px;
  padding-left: 12px;
  font-size: 13px;
  outline: none;
  color: #6b6a68;
}

#zjj_support #section1 form .box .box_son button {
  width: 68px;
  height: 36px;
  border: 1px #eaebef solid;
  border-radius: 3px;
  color: #616469;
  font-size: 12px;
  background-color: #fff;
  cursor: pointer;
}

#zjj_support #section2 {
  /* margin-bottom: 39px; */
}

.sda {
  margin-top: 39px !important;
}

#zjj_support #section2 .container h4 {
  height: 87px;
  font-size: 13px;
  color: #272725;
  padding-top: 29px;
  font-weight: normal;
  border-bottom: 1px solid #e4e4e4;
  padding-left: 29px;
}

#zjj_support #section2 .container h4 span {
  margin-right: 15px;
}

#zjj_support #section2 .container h4 span b {
  color: #fe0000;
  font-weight: normal;
}

#zjj_support #section2 .container ul li {
  padding-left: 29px;
  padding-right: 28px;
  font-size: 12px;
  border-bottom: 1px solid #e4e4e4;
}
</style>
