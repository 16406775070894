<template>
    <div id="muwenhao">
        <!--主题内容-->
        <div class="box11 clearfix">
            <div class="one-left w">
                <img :src="details.imageUrl|imgUrl" class="mwhoneimg"/>

                <div class="one-right">
                    <div class="one-right-top">
                        <p>{{details.title}}</p>

                        <span>直播专题： {{details.projectSpecialId}}</span><br/>
                        <span class="span1">开始时间： {{details.beginTime}}</span><br/>
                        <span class="span1">结束时间： {{details.endTime}}</span><br/>
                        <span>主办单位： {{details.organizer}}</span><br/>
                    </div>
                    <div class="one-right-bottom clearfix">
                        <div v-if="details.uLiveState==1">
                            <button class="bm" @click="dialogVisible = true">点击报名</button>
                            <button class="button">
                                <span>预约中</span>
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#iconshipin"></use>
                                </svg>
                            </button>
                        </div>
                        <div v-else-if="details.uLiveState==2">
                            <p>直播已开始</p>
                            <button class="button">
                                <span>进入直播</span>
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#iconshipin"></use>
                                </svg>
                            </button>
                        </div>
                        <div v-else-if="details.uLiveState==3">
                            <p>直播已结束</p>
                            <button class="button">
                                <span>直播回顾</span>
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#iconshipin"></use>
                                </svg>
                            </button>
                        </div>
                        <div class="span2_top clearfix">
                            <div class="span2_top_left">
                                <span class="span2">分享到</span>
                                <share :config="config"></share>
                            </div>
                            <!--                            <div class="button_right">-->
                            <!--                                <button class="button2">-->
                            <!--                                    <div class="span3_top">-->
                            <!--                                        <svg class="icon" aria-hidden="true">-->
                            <!--                                            <use xlink:href="#iconshoucang"></use>-->
                            <!--                                        </svg>-->
                            <!--                                        <span class="span3"> 收藏</span>-->
                            <!--                                    </div>-->
                            <!--                                </button>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section>
            <div class="two w clearfix">
<!--                //左侧详情-->
                <div class="two-left">
                    <div class="t1"></div>
                    <span class="span1">详情介绍</span>
                    <div class="detailbox" v-html="details.details"></div>
                </div>
<!--                右侧想滚直播-->
                <div class="two-right clearfix">
                    <div class="t2"></div>
                    <span class="span2">相关直播</span>
                    <div class="two-box1" @click="toOther(item.id)"
                         v-for="(item,i) in aboutLive" :key="item.id">
                        <img :src="item.imageUrl|imgUrl" class="mwhthreeimg"/>
                        <button class="button2" v-if="item.uLiveState==1">
                            <span class="span4">预约中</span>
                        </button>
                        <button class="button2" v-if="item.uLiveState==2">
                            <span class="span4">进入直播</span>
                        </button>
                        <button v-if="item.uLiveState==3">
                            <span class="span4">已结束</span>
                        </button>
                        <span class="span5">{{item.beginTime}}</span>
                        <p>{{item.title}}</p>
                        <span class="span6">{{item.organizer}}</span>
                    </div>
                </div>
            </div>
        </section>
        <!--        //点击报名弹窗-->
        <el-dialog
                title="报名"
                :visible.sync="dialogVisible"
                width="30%">
            <el-form ref="applyForm" :rules="rules" :model="applyForm" label-width="98px">
                <el-form-item label="企业名称" prop="company">
                    <el-input v-model="applyForm.company"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="lianxiren">
                    <el-input v-model="applyForm.lianxiren"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="applyForm.phone"></el-input>
                </el-form-item>
				<el-form-item label="短信验证码" prop="smsCode">
					<div class="codediv">
						<el-input class="codeinput" type="number" v-model="applyForm.smsCode"
							placeholder="请输入验证码"></el-input>
						<CodeBtn :phone="applyForm.phone" codeType="getCode5"></CodeBtn>
					</div>
				</el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="checkApply">报 名</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import methods from "../../util/methods";
    import validators from '../../util/validators.js';
	import CodeBtn from "../../components/logincpn/CodeBtn";
    export default {
        name: "UliveDetail",
		components:{
			CodeBtn
		},
        data() {
            return {
                id: '',
                aboutLive: [],
                details: {},
                dialogVisible:false, // 点击报名
                applyForm:{
                    company:'',
                    lianxiren:'',
                    phone:'',
					smsCode:""
                },
                config: {  //搜索配置i
                    sites: ['wechat','qq', 'weibo',  'douban'],
                    dispaly: ['google', 'facebook', 'twitter'],
                    wechatOrcodeTitle: '微信扫一扫：分享',
                    wechatOrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
                },
                rules:{
                    phone: [{required: true,validator: validators.checkPhone, trigger: ['blur', 'change']}],
                    lianxiren: [{ required: true, message: '请输入联系人', trigger: 'blur' },],
                    company: [{ required: true, message: '请输入企业名称', trigger: 'blur' },],
					smsCode: [{
						required: true,
						message: "请输入短信验证码",
						trigger: "blur"
					}, ],
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.id = this.$route.params.id   //获取详情id
                this.getMsg();
            },
            checkApply(){
                this.$refs.applyForm.validate((valid) => {
                    if (valid) { //验证表单
                        this.applyForm.rsdshowLineId = this.details.id
                        // if(this.$store.state.userInfo){
                        //    this.applyForm.userId = this.$store.state.userInfo.id;
                        //    this.apply();
                        // }else {
                        //     methods.toLogin()
                        // }
						// this.applyForm.userId = this.$store.state.userInfo.id;
						this.apply();
                    } else {
                        return false;
                    }
                });
            },
            async apply(){
                let res = await this.$api.radshowApply(this.applyForm);
                if(res.success){
                    this.$message({
                        message:'报名成功!',
                        type: 'success',
                        center: true
                    })
                    this.$refs.applyForm.resetFields();
                    this.dialogVisible = false
                }

            },
            async getMsg() {
                let id = this.id;
                let res = await Promise.all([
                    this.$api.radshowLineDetail({id}),
                    this.$api.radshowLine()
                ])
                this.details = res[0].result;
                console.log(this.details)
                this.details.uLiveState = methods.uLiveState(res[0].result.beginTime, res[0].result.endTime)
                this.aboutLive = []
                for (let item of res[1].result.records) {
                    if (this.aboutLive.length < 2) {
                        item.uLiveState = methods.uLiveState(item.beginTime, item.endTime)
                        this.aboutLive.push(item)
                    }
                }
                console.log(res);
            },
            toOther(id) {
                this.$router.push('/uliveDetail/' + id);
                this.init();
            }
        }

    }
</script>

<style scoped>
    @import '../../static/css/shareImg.css';
    .detailbox {
        width: 700px;
    }

    .bm {
        width: 120px;
        height: 40px;
        background-color: #2d8dfd;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        margin: 10px 0;
    }

    #muwenhao .box11 {
        width: 100%;
        height: 644px;
        background-color: #2d8dfd;
        padding-top: 70px;
    }

    #muwenhao .one-left {
        width: 1200px;
        height: 494px;
        background-color: #ffffff;
        border-radius: 9px;
    }

    #muwenhao .one-left .mwhoneimg {
        display: inline-block;
        width: 731px;
        height: 460px;
        margin: 19px 17px 19px 19px;
    }

    #muwenhao .one-right {
        width: 433px;
        height: 494px;
        float: right;
    }

    #muwenhao .one-right p {
        font-size: 23px;
        color: #252525;
        display: inline-block;
        padding: 34px 50px 22px 0;
    }

    #muwenhao .one-right-top {
        width: 433px;
        height: 262px;
        border-bottom: 1px #939b9e dashed;
    }

    #muwenhao .one-right span {
        font-size: 14px;
        color: #939b9e;
        margin-left: 5px;
        display: block;
    }

    #muwenhao .one-right-bottom {
        width: 433px;
        height: 213px;
    }

    #muwenhao .one-right-bottom p {
        font-size: 17px;
        color: #8e9396;
    }

    #muwenhao .one-right-bottom .button {
        width: 398px;
        height: 51px;
        background-color: #ff9503;
        border-radius: 2px;
        margin-bottom: 20px;
        padding: 16px 22px;
    }

    #muwenhao .one-right-bottom .button span {
        color: #fffefc;
        float: left;
    }

    #muwenhao .one-right-bottom .button svg {
        font-size: 20px;
        color: #FFFFFF;
        vertical-align: middle;
        float: right;
    }

    #muwenhao .one-right-bottom .span2_top {
        width: 398px;
        height: 35px;
        display: flex;
        align-items: center;
    }

    #muwenhao .one-right-bottom .span2_top_left {
        flex: 1;
        flex-shrink: 0;
        /*float: left;*/
        display: flex;
        align-items: center;
    }

    #muwenhao .one-right-bottom .span2 {
        font-size: 13px;
        color: #a9aeaa;
        float: left;
        margin-top: 6px;
        display: inline-block;
        vertical-align: middle;
    }

    #muwenhao .one-right-bottom .span2_top svg {
        width: 35px;
        height: 35px;
        vertical-align: middle;
    }

    #muwenhao .one-right-bottom .span2_top .icon1 {
        fill: #76c44d;
    }

    #muwenhao .one-right-bottom .span2_top .icon2 {
        fill: #56b6e8;
    }

    #muwenhao .one-right-bottom .span2_top .icon3 {
        fill: #ff7230;
    }

    #muwenhao .one-right-bottom .span2_top .icon4 {
        width: 35px;
        height: 35px;
        transform: scale(1.1);
        fill: #47ae45;
    }

    #muwenhao .one-right-bottom .button2 {
        width: 101px;
        height: 31px;
        background-color: #ebf5ff;
        border-radius: 13px;
        text-align: center;
        padding: 0 26px;
        display: inline-block;
        float: right;
    }

    #muwenhao .one-right-bottom .span3_top svg {
        width: 20px;
        height: 20px;
        display: inline-block;
    }

    #muwenhao .one-right-bottom .span3_top {
        width: 90px;
        display: flex;
        align-items: center;
    }

    #muwenhao .one-right-bottom .span3 {
        font-size: 13px;
        color: #a9aeaa;
        display: inline-block;
    }

    section {
        width: 100%;
        /*height: 1220px;*/
    }

    #muwenhao .two {
        width: 1200px;
        /*height: 1220px;*/
        padding-bottom: 112px;
    }

    #muwenhao .two-left {
        width: 748px;
        /*height: 1217px;*/
        border: 1px #dcdcdc solid;
        border-radius: 8px;
        margin-top: 34px;
        padding: 27px 24px 37px 24px;
        float: left;

    }

    #muwenhao .t1 {
        width: 4px;
        height: 23px;
        background-color: #3ca9fb;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
    }

    #muwenhao .two .span1 {
        vertical-align: middle;
        font-size: 24px;
        color: #3ca9fb;
    }

    #muwenhao .two .mwhtwoimg {
        width: 700px;
        height: 1106px;
        margin-top: 25px;
    }

    #muwenhao .two-right {
        width: 410px;
        /*height: 1217px;*/
        border: 1px #dcdcdc solid;
        border-radius: 8px;
        margin-top: 34px;
        padding: 26px 26px 25px 30px;
        float: right;
    }

    #muwenhao .t2 {
        width: 4px;
        height: 23px;
        background-color: #3ca9fb;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
    }

    #muwenhao .two .span2 {
        vertical-align: middle;
        font-size: 24px;
        color: #3ca9fb;
    }

    #muwenhao .two .two-box1 {
        width: 352px;
        height: 436px;
        border: 1px #f0f0f0 solid;
        box-sizing: border-box;
        margin-top: 36px;
        cursor: pointer;
    }

    #muwenhao .two .mwhthreeimg {
        width: 350px;
        height: 221px;
        margin-bottom: 9px;
        object-fit: contain;
    }

    #muwenhao .two .two-box1 button {
        width: 74px;
        height: 25px;
        background-color: #999997;
        border-radius: 9px;
        float: left;
        margin-left: 12px;
    }

    #muwenhao .two .two-box1 .button2 {
        background-color: #ff9503;
    }

    #muwenhao .two .two-box1 .span4 {
        font-size: 14px;
        color: #dfddd0;
    }


    #muwenhao .two .two-box1 .span5 {
        font-size: 14px;
        color: #abb7c3;
        float: right;
        margin-right: 14px;
    }

    #muwenhao .two .two-box1 p {
        font-size: 21px;
        color: #212121;
        padding: 65px 22px 37px 27px;
    }

    #muwenhao .two .two-box1 .span6 {
        font-size: 18px;
        color: #585858;
        padding-top: 8px;
        padding-left: 16px;
        border-top: 1px #f0f0f0 solid;
    }

    #muwenhao .two .two-box2 {
        width: 350px;
        height: 437px;
        border: 1px #f0f0f0 solid;
        margin-top: 25px;
    }

    #muwenhao .two .mwhfourimg {
        width: 352px;
        height: 235px;
        margin-bottom: 9px;
    }

    #muwenhao .two .two-box2 button {
        width: 74px;
        height: 25px;
        background-color: #999997;
        border-radius: 9px;
        float: left;
        margin-left: 12px;
    }

    #muwenhao .two .two-box2 .span7 {
        font-size: 14px;
        color: #dfddd0;
    }

    #muwenhao .two .two-box2 .span8 {
        font-size: 14px;
        color: #abb7c3;
        float: right;
        margin-right: 14px;
        margin-top: 15px;
    }

    #muwenhao .two .two-box2 p {
        font-size: 21px;
        color: #464646;
        padding: 65px 22px 37px 27px;
    }

    #muwenhao .two .two-box2 .span9 {
        font-size: 18px;
        color: #585858;
        padding-top: 8px;
        padding-left: 16px;
        border-top: 1px #f0f0f0 solid;
    }
</style>
<style>
    .detailbox img {
        max-width: 100%;
        height: auto;
    }
</style>