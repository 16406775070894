import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        activePage: 'home',
        token: localStorage.getItem("token")  || '',
        userInfo: JSON.parse(localStorage.getItem("userInfo")) || '', //其中身份type： 1政府 2企业
        beforeLoginPage:'', //跳转登录之前的页面路由
    },
    mutations: {
        changeBeforeLoginPage(state, payload){
            state.beforeLoginPage = payload
        },
        changeActive(state, payload) {
            state.activePage = payload;
        },
        setUserInfo(state, payload) {
            state.userInfo = payload;
            window.sessionStorage.setItem('userInfo', JSON.stringify(payload))
        },
        setToken(state, payload) {
            state.token = payload
            window.sessionStorage.setItem('token', payload)
        },
    },
    actions: {},
    getters: {
        getStorage(state) {
            if (!state.token) {
                state.token = localStorage.getItem('token');
                state.userInfo = JSON.parse(localStorage.getItem('userInfo'))
            }
            return state.token
        },
    },
    modules: {}
})
