<template>
  <!--    //概览-->
  <div id="zjj_index">

    <section id="section3">
      <div class="w">
        <div class="data" id="asdd" v-cloak>
          <div>
            <ul class="data_dets tabBox" v-show="showtabb == 1">
              <li>
                <div class="pic">
                  <img src="../../../static/img/zjj_index_img7.png" />
                </div>
                <div class="num">
                  <strong>{{ coachNum.listCount }}</strong>
                  <p>沪深京上市企业数</p>
                  <a href="javascript:;" @click="openTable(1)">详情</a
                  ><span>></span>
                </div>
              </li>
              <li>
                <div class="pic">
                  <img src="../../../static/img/zjj_index_img8.png" width="73px" height="81px"/>
                  </div>
                  <div class="num">
                      <strong>{{coachNum.jingwai}}</strong>
                      <p>境外上市企业数</p>
                      <a href="javascript:;" @click="openTable(9)">详情</a><span>></span>
                  </div>
              </li>
              <li>
                <div class="pic">
                  <img
                    src="../../../static/img/zjj_index_img9.png"
                    width="73px"
                    height="81px"
                  />
                </div>
                <div class="num">
                  <strong>{{ coachNum.thirdCount }}</strong>
                  <p>新三板企业数</p>
                  <a href="javascript:;" @click="openTable(2)">详情</a
                  ><span>></span>
                </div>
              </li>
              <li>
                <div class="pic">
                  <img src="../../../static/img/zjj_index_img8.png" width="73px" height="81px"/>
                  </div>
                  <div class="num">
                      <strong>{{coachNum.sjsguapai}}</strong>
                      <p>河交所挂牌数</p>
                      <a href="javascript:;" @click="openTable(10)">详情</a><span>></span>
                  </div>
              </li>
            </ul>
            <ul class="data_dets tabBox" v-show="showtabb == 1" style="margin-top:20px;">
               <li>
                <div class="pic">
                  <img src="../../../static/img/zjj_index_img9.png" />
                </div>
                <div class="num">
                  <strong>{{ coachNum.trailCount }}</strong>
                  <p>在审企业数</p>
                  <a href="javascript:;" @click="openTable(3)">详情</a
                  ><span>></span>
                </div>
              </li>
              <li>
                <div class="pic">
                  <img
                    src="../../../static/img/zjj_index_img10.png"
                    width="73px"
                    height="81px"
                  />
                </div>
                <div class="num">
                  <strong>{{ coachNum.coachCount }}</strong>
                  <p>在辅导企业数</p>
                  <a href="javascript:;" @click="openTable(4)">详情</a
                  ><span>></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <HomeTableBox ref="table"></HomeTableBox>
      <div class="sec3_container w">
        <mapCpn :title="true"></mapCpn>
        <div class="chart">
          <div id="tb1"></div>
          <!-- 饼状图 -->
          <div id="tb2"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as echarts from "echarts";
import mapCpn from "../../../components/mapCpn";
import HomeTableBox from "../../home/HomeTableBox";
export default {
  name: "Survey",
  data() {
    return {
      showtabb: 1,
      coachNum: {}, //公司总数
      countDatas: {
        XList: [],
        data1: [],
        data2: [],
        data3: [],
        data4: [],
        data5: [],
      },

    };
  },
  mounted() {

    this.getinitDatas();
  },
  methods: {
    //并行获取数据
    async getinitDatas() {
      let res = await Promise.all([
        this.$api.coachNum(), //上市数据各个总数
        // this.$api.queryPlateNumber({ pageSize: 80 }), //各个上市板块的数据统计
        this.$api.companyStatisticsList()
      ]);
      this.coachNum = res[0].result;
      res = res[1].result.records;

      //提取数据
      for (let item of res) {
        item.mouthTime = item.mouthTime.slice(0,7)
        this.countDatas.XList.push(item.mouthTime);
        this.countDatas.data1.push(item.huNumber || 0);
        this.countDatas.data2.push(item.shenNumber || 0);
        this.countDatas.data3.push(item.zhongNumber || 0);
        this.countDatas.data4.push(item.chuangNumber || 0);
        this.countDatas.data5.push(item.keNumber || 0);
      }
      this.initEchart();
    },
    openTable(i) {
      this.$refs.table.openTable(i);
    },
    initEchart() {
      var myChart = echarts.init(document.getElementById("tb1"));
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        title: {
          text: "上市数量统计",
        },
        legend: {
          right: "0",
          data: ["沪主板", "深主板", "中小板", "创业板", "科创板"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.countDatas.XList,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "沪主板",
            type: "bar",
            data: this.countDatas.data1,
          },

          {
            name: "深主板",
            type: "bar",
            data: this.countDatas.data2,
          },
          {
            name: "中小板",
            type: "bar",
            data: this.countDatas.data3,
          },
          {
            name: "创业板",
            type: "bar",
            data: this.countDatas.data4,
          },
          {
            name: "科创板",
            type: "bar",
            data: this.countDatas.data5,
          },
        ],
      };
      myChart.setOption(option);
      var myChart1 = echarts.init(document.getElementById("tb2"));
      var option1 = {
        title: {
          text: "上市板块分布",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "right",
          y: "center", //图例上下居中
          data: ["沪主板", "深主板", "中小板", "创业板", "科创板"],
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              { value: this.countDatas.data1[0], name: "沪主板" },
              { value: this.countDatas.data2[0], name: "深主板" },
              { value: this.countDatas.data3[0], name: "中小板" },
              { value: this.countDatas.data4[0], name: "创业板" },
              { value: this.countDatas.data5[0], name: "科创板" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart1.setOption(option1);
    },
  },
  components: {
    mapCpn,
    HomeTableBox,
  },
};
</script>

<style scoped>
.sec3_container {
  display: flex;
  justify-content: space-between;
  padding-top: 49px;
}
.sec3_container .echarts img {
  width: 590px;
  height: 747px;
}
.chart {
  width: 600px;
  display: flex;
  flex-direction: column;
}

#tb1 {
  width: 600px;
  height: 400px;
}

#tb2 {
  width: 100%;
  height: 400px;
}

#section3 {
  margin-top: 50px;
}

.tabBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.tabBox li {
  width: 225px;
  height: 90px;
  cursor: pointer;
  display: flex;
  background: #fff;
  border: 1px solid #efefef;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
}
.tabBox li .pic {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabBox li img {
  width: 40px;
  height: auto;
}
.tabBox li .num {
  width: 60%;
}
.tabBox li .num strong {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
.tabBox li .num p {
  font-size: 14px;
  color: #666;
}
.tabBox li a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: #666;
}
.tabBox li a:hover {
  color: #366dc5;
}
</style>
