<template>
    <div id="zsy">
        <TopBannerBox :positions="positions"></TopBannerBox>
        <!--导航条-->
        <div class="details_nav w">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item,i) in currNav.childrens" :key="i"
                                    :to="{ path: item.path }">{{item.name}}
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{currNav.name}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="details">
            <h1 class="detailTitle">{{info[currNav.titleKey]}}</h1>
            <div class="detailtopbox">
                <span class="time" v-for="(item,i) in currNav.titleList" :key="i">
                    {{item.show}}：{{info[item.key]}}
                </span>&nbsp;
            </div>
            <div v-html="info.details">

            </div>

        </div>
    </div>
</template>

<script>
import TopBannerBox from "../../components/TopBannerBox";
    export default {
        name: "DynamicDetail",
        data() {
            return {
                type: '',
                id: '',
                currNav: {},
                info: {},
                navigations: [
                    {
                        type: 1,
                        activeName: 'policy',
                        name: '动态详情',
                        portName: 'policyDetail',
                        childrens: [{name: '工作动态', path: '/workNews'}],
                        titleKey: 'title',
                        titleList: [{key: 'releaseTime', show: '发布时间'}]
                    },
                    {
                        type: 2,
                        activeName: 'policy',
                        name: '政策详情',
                        portName: 'policyDetail',
                        childrens: [{name: '扶持政策', path: '/policy'}],
                        titleKey: 'title',
                        titleList: [{key: 'releaseTime', show: '发布时间'}]
                    },
                    {
                        type: 3,
                        activeName: 'roadShow',
                        name: '培训详情',
                        portName: 'radshowTranDetail',
                        childrens: [{name: '上市培训', path: '/listedTraining'}],
                        titleKey: 'tile',
                        titleList: [{key: 'time', show: '发布时间'},{key: 'source', show: '来源'}]
                    },
                    {
                        type: 4,
                        activeName: 'roadShow',
                        name: '课程详情',
                        portName: 'radshowOncurDetail',
                        childrens: [{name: '在线课程', path: '/onlineCourses'}],
                        titleKey: 'title',
                        titleList: []
                    },
                    {
                        type: 5,
                        activeName: 'agencyLibrary',
                        name: '机构详情',
                        portName: 'serviceOrganizationDetail',
                        childrens: [{name: '辅导机构', path: '/companyLibrary'}],
                        titleKey: 'name',
                        titleList: []
                    },
                ],
                positions:"2"
            }
        },
        created() {
            this.type = this.$route.params.type  //获取详情页类型
            if(this.type == "5") {
                this.positions = 8
            }
            this.id = this.$route.params.id   //获取详情id
            this.currNav = this.navigations.filter((data) => {  //找出该详情页类型
                return data.type == this.type
            })[0]
            this.$store.commit('changeActive', this.currNav.activeName)
            this.getMsg();
        },
        methods: {
            async getMsg() { //获取详情
                let id = this.id;
                let res = await this.$api[this.currNav.portName]({id});
                this.info = res.result;
                console.log(res);
            }
        },
        components: {
			TopBannerBox
		}
    }
</script>

<style scoped>
    .time {
        margin: 0px 20px 0px 0px;
        padding: 0px;
    }

    .detailtopbox {
        margin: 10px 0px 0px;
        padding: 0px;
        font-family: 微软雅黑, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        color: #a1a1a1;
        text-align: center;
        font-size: 14px;
    }

    .detailTitle {
        margin: 0px;
        padding: 0px 0px 20px;
        font-size: 22px;
        font-family: 微软雅黑, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        text-align: center;
        border-bottom: 1px solid #f0f0f0;
    }

    .support_banner {
        width: 100%;
        height: 340px;
    }

    .support_banner img {
        width: 100%;
        height: 100%;
        min-width: 1200px;
    }

    .details {
        width: 1200px;
        margin: 0 auto;
        background-color: #fff;
        margin-bottom: 70px;
        box-shadow: 0px 0px 20px #888;
        margin-top: 20px;
        padding: 40px;
    }
</style>
<style>
    #zsy .details img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
</style>
