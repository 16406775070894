<template>
    <div class="textright2">
        <div class="topbordbox">
            <CenterTab2 :tab-list="tabList" @change="changeTab"></CenterTab2>
            <div class="bbtmCon">
                <div class="bbbtmbox bt_list">
                    <div class="bbbtms" style="border: none;">
                        <!-- <div class="bbbtmtop"> -->
                            <!-- <div class="bbbtmtopl"></div> -->
<!--                             <a href="declare.html">-->
<!--                             <div class="bbbtmtopr">去申请></div>-->
<!--                             </a>-->
                        <!-- </div> -->
                        <router-link to="/declare" tag="a"
                                     class="bbbtmbtm" v-for="(item,i) in list1" :key="item.id">
                             <div class="titlebox">{{item.policy_dictText}}</div>
                             <div class="bbbtmbtmmiddle">活动通知</div>
                             <div class="bbbtmbtmTime">{{item.createTime}}</div>
                        </router-link>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CenterTab2 from "../../../components/CenterTab2";
    export default {
        name: "Subsidy",
        data(){
            return {
                list1:[],
                tabList:[
                    {id:1,label:'未申请'},
                    {id:2,label:'已申请'},
                ]
            }
        },
        mounted() {
            this.applyspolicy();
        },
        computed:{
            userId(){
                return this.$store.state.userInfo.id;
            }
        },
        methods: {
            changeTab(e){
                if(e==0){
                    this.applyspolicy();
                }else if(e==1){
                    this.getMsg();
                }
            },
            async getMsg(){  //已申请
                let userId = this.userId;
                // let res = await this.$api.applyed({userId});
                // this.list1 = res.result.records;
                // console.log(res);
            },
            async applyspolicy(){  //未申请
                // let res = await this.$api.applyspolicy();
                // this.list1 = [];
                // for(let item of res.result){
                //     this.list1.push({
                //         value:item.id,
                //         policy_dictText:item.title
                //     })
                // }
                // console.log(res);
            },
        },
        components: {
            CenterTab2
        }
    }
</script>

<style scoped>
    .textright2{
        width: 100%;
        background-color: #fff;
    }
    .titlebox{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .topbordbox{
        border: 1px solid #ECECEC;
        margin-bottom: 20px;
    }

    .bbbtmbox .bbbtms{
        padding: 20px 40px;
        border: 1px solid #ECECEC;
    }
    .bbbtmbox .bbbtms .bbbtmtop{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #2A2A2A;
    }
    .bbbtmbox .bbbtms .bbbtmtopr{
        font-size: 16px;
        color: #2695FE;
    }
    .bbbtmbox .bbbtms .bbbtmbtm:first-child {
        margin-top: 0;
    }
    .bbbtmbox .bbbtms .bbbtmbtm{
        display: flex;
        align-items: center;
        margin-top: 12px;
        font-size: 16px;
        color: #7E7E7E;
    }
    .bbbtmbtmmiddle{
        flex-shrink: 0;
        margin: 0 0px 0 25px;
    }
    .bbbtmbtmTime{
        flex-shrink: 0;
    }

</style>