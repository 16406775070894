<template>
<!--    统计-->
    <div  id="zjj_index">
        <section id="section3">
            <div class="w">
                <div class="data" id="asdd" v-cloak>
                    <!--                新三板企业数据-->
                    <h3>本辖区公司统计</h3>
                    <el-table :data="NEEQData"  border>
                        <el-table-column v-for="(item,i) in NEEQColumn" :key="item.data" :prop=item.prop :label=item.label></el-table-column>
                    </el-table>

                </div>
            </div>
        </section>
    </div>

</template>

<script>
    export default {
        name: "count",
        data(){
            return {
                NEEQColumn: [
                    { prop: 'city', label: '辖区', width: '120' },
                    { prop: 'listCount', label: '上市公司数', width: '120' },
                    { prop: 'thirdCount', label: '新三板企业数', width: '120' },
                    { prop: 'trailCount', label: '在审企业数', width: '120' },
                    { prop: 'coachCount', label: '在辅导企业数', width: '120' },
                    { prop: 'houbei', label: '后备企业数', width: '120' },
                ],
                NEEQData: [],
            }
        },
        mounted() {
            this.getMsg()
        },
        computed: {
        },
        methods: {
            async getMsg(){
                let res = await this.$api.cityNum(); //城市数据
                let res2 = await this.$api.coachNum(); //河北省总数
                this.NEEQData = res.result
                this.NEEQData.unshift({...res2.result,city:'河北省'});
                // console.log(res);
            }
        },
    }
</script>

<style scoped>
    .data{
        width: 1200px;
        margin: 0 auto;
        margin-top: 50px;
        padding: 20px;
        background: #fff;

    }
    h3{
        margin: 20px 0;
    }
</style>