import { render, staticRenderFns } from "./Coach.vue?vue&type=template&id=3e49590c&scoped=true&"
import script from "./Coach.vue?vue&type=script&lang=js&"
export * from "./Coach.vue?vue&type=script&lang=js&"
import style0 from "./Coach.vue?vue&type=style&index=0&id=3e49590c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e49590c",
  null
  
)

export default component.exports