<template>
    <div>
        <AgencyTable></AgencyTable>
    </div>
</template>

<script>
    import AgencyTable from "../../resourceLibrary/AgencyTable";
    export default {
        name: "Reserve",
        components:{
            AgencyTable
        }
    }
</script>

<style scoped>

</style>