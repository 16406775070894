<template>
  <div class="container">
    <span class="container_title">用户服务协议</span> 特别提示<br>
    1.“河北省企业股改上市服务平台”（以下简称“本系统”）及相关服务由石家庄股权交易所提供，您可以获授权使用本系统及获取相关服务。
    2.在您首次使用本系统服务时，应一次性阅览本服务协议内容，您需就全部条款内容进行详细阅读，点击确认同意，除非本服务协议内容发生实质性变更，否则您在授权期限内后续使用本系统服务时，系统将默认您勾选同意，不会提示您再次点击签署。您使用本系统服务即默认您自愿接受本服务协议全部内容约束。
    第一章 引言
    1.为正常使用本系统的服务，您应当详细阅读并遵守《用户服务协议》（简称“《本协议》”）内容约定。
    2.请您务必审慎阅读、充分理解各项内容条款。除非您已阅读并接受《本协议》所有条款，否则您将无权使用本系统提供的各项服务。您使用本系统的服务即默认您已充分阅读并完全接受《本协议》的条款约束。
    第二章 声明与承诺
    1.您保证，在您使用本系统服务之时，您具备完全的民事行为能力，能独立承担民事责任。
    2.您保证，在您点击阅读并同意《本协议》，并开始使用本系统服务时，即表示您认可在本系统的所有操作都是您本人操作以及真实意愿，表示您认可可以通过本系统收录您的个人信息。
    第三章 用户信息收集以及隐私权保护
    1.您同意并授权本单位及本系统技术支持方，在您通过使用本系统服务为验证使用主体身份信息之目的而收集您的个人及企业信息数据，收集信息范围包括但不限于如下范畴：
    1.1您的身份信息及影像，包括但不限于姓名/名称、证件号码/证照号码、证件类型/证照类型、住所地、手机号码、法定代表人信息以及其他与身份认定相关的信息等；
    1.2您作为企业用户时登记的企业信息，包括但不限于企业名称、统一社会信用代码、行业信息、组织机构信息、法定代表人信息等；
    1.3您在身份验证或企业认证过程中产生的图像视频数据等（如有）；
    1.4其他与您身份验证相关的个人信息等。
    上述信息仅用于为您提供身份验证服务。本单位承诺自行并要求合作伙伴对上述信息严格保密，同时采取多重安全防护措施保障信息安全。
    2.本单位将采用行业标准惯例以保护您的个人资料，但鉴于技术限制，本单位不能确保您的全部私人通讯及其他个人资料不会通过本协议中未列明的途径泄露出去。
    3.本单位有义务根据有关法律要求向司法机关和政府部门提供您的个人资料。在您未能按照与本协议、本单位有关规则时，本单位有权根据自己的判断、有关协议和规则、国家生效裁决文书披露您的个人资料（包括但不限于在本单位及互联网络上公布您的违法、违约行为，并有关将该内容记入任何与您相关的信用资料、档案或数据库），本单位对此不承担任何责任。
    4.关于具体的《隐私政策》，本单位将会在官方宣传渠道（包括本系统网页、小程序等）发布，发布渠道以本单位最终确认为准。您可以点击详细阅读《隐私政策》条款内容。根据法律法规及现实情况变化，本单位保留未来《隐私政策》单方修订权并及时发布。
    第四章 不保证及使用限制 1.不保证
    因为本系统或者涉及的第三方网站的设备、系统存在缺陷、黑客攻击、网络故障、电力中断、计算机病毒或其他不可抗力因素造成的损失，本单位均不负责赔偿，您的补救措施只能是与本单位协商终止本协议并停止使用本系统。但中国现行法律、法规另有规定的除外。
    2.使用限制
    2.1 您承诺合法使用本系统提供的服务及网站内容。您不得利用本系统从事侵害他人合法权益之行为，不得在本系统从事任何可能违反中国的法律、法规、规章和政府规范性文件的行为或者任何未经授权的行为，如擅自进入本单位未公开系统、不正当使用账号密码和网站的任何内容等。
    2.2 您不得使用本单位提供的服务或其他电子邮件转发服务发出垃圾邮件或其他可能违反本协议的内容。
    2.3 本单位没有义务监测网站内容，但是您确认并同意本单位有权不时地根据法律、法规、政府要求透露、修改或者删除必要的信息，以便更好地运营本系统并保护自身及本系统的其他合法用户。
    2.4 本系统中全部内容的版权均属于本系统所有，该等内容包括但不限于文本、数据、文章、设计、源代码、软件、图片、照片及其他全部信息（以下称“网站内容”）。系统内容受中华人民共和国著作权法及各国际版权公约的保护。未经本单位事先书面同意，您承诺不以任何方式、不以任何形式复制、模仿、传播、出版、公布、展示系统内容，包括但不限于电子的、机械的、复印的、录音录像的方式和形式等。您承认系统内容是属于本单位的财产。未经本单位书面同意，您亦不得将本单位包含的资料等任何内容镜像到任何其他网站或者服务器。任何未经授权对系统内容的使用均属于违法行为，本单位将追究您的法律责任。
    2.5 由于您违反本协议或任何法律、法规或侵害第三方的权利，而引起第三方对本单位提出的任何形式的索赔、要求、诉讼，本单位有权向您追偿相关损失，包括但不限于本单位的法律费用、名誉损失、及向第三方支付的补偿金等。
    第五章、 协议终止及账户的暂停、停用或终止
    1. 除非本单位终止本协议或者您申请终止本协议且经本单位同意，否则本协议始终有效。在您违反了本协议、相关规则，或在相关法律法规、政府部门的要求下，本单位有权通过电子邮件通知等方式终止本协议、关闭您的账户或者限制您使用本系统。但本单位的终止行为不能免除您根据本协议或在本单位生成的其他协议项下的还未履行完毕的义务。
    2. 您若发现有第三人冒用或盗用您的用户账户及密码，或其他任何未经合法授权的情形，应立即以有效方式通知本单位，要求本单位暂停相关服务，否则由此产生的一切责任由您本人承担。同时，您理解本单位对您的请求采取行动需要合理期限，在此之前，本单位对第三人使用该服务所导致的损失不承担任何责任。
    3. 您决定不再使用用户账户时，应向本单位申请停用该账户。经本单位审核通过后，本单位方可对您账户进行正式停用操作。
    4. 本单位有权基于单方独立判断，在认为可能发生危害交易安全等情形时，不经通知而先行暂停、中断或终止向您提供本协议项下的全部或部分服务，并将注册资料移除或删除，且无需对您或任何第三方承担任何责任。前述情形包括但不限于：
    （1） 本单位认为您提供的个人资料不具有真实性、有效性或完整性；
    （2） 本单位发现有疑义或有违法之虞时；
    （3） 本单位认为您的账户涉嫌传销、被冒用或其他本单位认为有风险之情形；
    （4） 本单位认为您已经违反本协议中规定的各类规则及精神；
    （5） 本单位基于交易安全等原因，根据其单独判断需先行暂停、中断或终止向您提供本协议项下的全部或部分会员服务，并将注册资料移除或删除的其他情形。
    5. 您同意在必要时，本单位无需进行事先通知即有权终止提供用户账户服务，并可能立即暂停、关闭或删除您的用户账户及该用户账户中的所有相关资料及档案。
    6. 您同意，用户账户的暂停、中断或终止不代表您责任的终止，您仍应对您使用本单位服务期间的行为承担可能的违约或损害赔偿责任，同时本单位仍可保有您的相关信息。
    第六章、 通知
    本系统将通过您在本系统登记预留的联系方式发送通知，如您的联系方式有变动，为保障您正常使用服务，请您及时联系工作人员完成信息更新。本协议项下的通知如以公示方式作出，一经在本系统公示即视为已经送达。除此之外，其他向您个人发布的具有专属性的通知将由本系统向您在注册时提供的电子邮箱或手机发送，一经发送即视为已经送达。请您密切关注您的电子邮箱、手机短信。您同意本系统出于服务目的，可以向您的电子邮箱和手机发送有关通知或提醒。您同意并确认后，若您设置了不接收有关通知或提醒，则您有可能收不到该等通知信息，您不得以您未收到或未阅读等表示消息未送达于您。
    第七章、 送达
    您确认本人预留的手机号码和电子邮箱，为仲裁文书送达地址及短信通知号码。本单位及其合作方、法院/仲裁机构、法院执行部门向您发出的任何通知及法律文书，以电子邮件或者短信等形式发出，送至约定邮箱或者手机号码，收件人指定系统接收材料或者通知之日，即视为收到送达。如您需变更联络邮箱或者手机号码的，应当向本单位及其合作方书面予以确认。
    第八章、 其他
    1.本单位拥有本协议单方修订权。有权依据法律法规变化及实际情况变化，在必要时修订《本协议》条款，本单位将会通过本单位官方渠道（包括本系统网页、小程序等）向您发出通告，提示修改内容，具体的发布渠道以本单位最终确认为准。如果您不同意《本协议》条款修改内容，您可以主动终止使用本系统服务。如果您需继续使用本系统服务，则视为您主动接受《本协议》的任何条款内容变动。
    2.本单位对本协议拥有最终的解释权。本协议及本单位有关页面的相关名词可互相引用参照，如有不同理解，则以本协议条款为准。此外，若本协议的部分条款被认定为无效或者无法实施时，本协议中的其他条款仍然有效。
  </div>
</template>

<script>
export default {
    name:'UserService'
};
</script>

<style scoped>
.container{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
}
.container_title{
    display: block;
    width: 150px;
    margin: auto 0;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
}
</style>