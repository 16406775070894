<template>
    <section id="back">
        <div class="sectionin w clearfix">
            <div class="sectioninleft">
                <p class="p1">
                    欢迎来到
                </p>
                <p class="p2">
                    河北省企业股改上市服务平台
                </p>
                <div class="tiao"></div>
            </div>
            <div class="sectioninright" v-loading="loading">
                <div class="title">
                    <div :class="{'active':identity==1}" id="zflink" @click="changeIdentity(1)">
                        <span class="svg1">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#iconqiye"></use>
                            </svg>
                        </span>
                        <span class="acspan">政府登录</span>
                    </div>
                    <div :class="{'active':identity==2}" id="qylink" @click="changeIdentity(2)">
                        <span class="svg1">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#iconqiye"></use>
                            </svg>
                        </span>
                        <span class="acspan">企业登录</span>
                    </div>
                </div>
                <div class="tab" v-if="identity==2" id="qybox">
                    <div class="anniugroup">
                        <div class="anniu" :class="{'anniu_active':loginType==1}" @click="changeLoginType(1)" >
                            短信验证登录
                        </div>
                        <div class="anniu" :class="{'anniu_active':loginType==2}" @click="changeLoginType(2)">
                            密码登录
                        </div>
                    </div>
                    <LoginInPhone @submit="submit" v-if="loginType==1"></LoginInPhone>
                    <LoginInName @submit="submit" v-else></LoginInName>
                     <p class="hmzh">
                         还没账号<router-link tag="a" to="/register">快速注册</router-link>
                     </p>
                </div>
                <div class="tab" style="margin-top: 90px" v-else id="zfbox">
                    <LoginInName @submit="submit"></LoginInName>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import LoginInName from "../../components/logincpn/LoginInName";
    import LoginInPhone from "../../components/logincpn/LoginInPhone";
    export default {
        name: "Login",
        data(){
            return{
                identity:1,
                loginType:1,
                loading:false,
            }
        },
        computed:{
          login(){ //修改登录接口
              if(this.identity==1){
                 return 'loginInPwd2'
              }else if(this.identity==2){
                 if(this.loginType==1){
                     return 'loginInCode'
                 } else {
                     return 'loginInPwd'
                 }
              }
          }
        },
        mounted() {
            let token = localStorage.getItem("token")
            if(token) {
                this.$router.push('/')
            }
        },
        methods:{
            async getCode(params){
                try{
                    let res = await this.$api[this.login](params);
                    this.loading = false;
                    let type = res.result.user.type;
                    this.$store.commit('setToken',res.result.token);
                    this.$store.commit('setUserInfo',res.result.user);
                    localStorage.setItem('token',res.result.token)
                    localStorage.setItem('userInfo',JSON.stringify(res.result.user))
                    this.toCenter(type)
                    if(res.code == 200) {
                        
                        let id = res.result.user.id
                        var cip = returnCitySN["cip"];
                        let res1 = await this.$api.loginLog({userId:id,ips:cip})
                        console.log(res1)
                    }
                    
                }catch (e) {
                    this.loading = false;
                }
            },
            changeIdentity(i){
                this.identity = i
            },
            changeLoginType(i){
                this.loginType = i
            },
            toCenter(type){
                let page = this.$store.state.beforeLoginPage;
                if(page){
                    this.$router.push(page)
                }else {
                    if(type==1){
                        //政府登录
                        this.$router.push('/govermentCenter')
                    }else if(type==2){
                        //企业登录
                        this.$router.push('/companyCenter')
                    }
                }



            },
            companyLogin(){

            },
            submit(data){
                this.loading = true;
                this.getCode(data)
            }

        },
        created() {

        },
        components: {
            LoginInName,LoginInPhone
        }
    }
</script>

<style scoped>
    #back{
        width: 100%;
        height: 700px;
        /* background-color: #003263; */
        background-image: url('../../static/img/bg2.jpg');
    }
    .sectionin{
        padding-top: 68px;
        background-color: transparent;
    }
    .sectioninleft{
        height: 513px;
        width: 689px;
        float: left;
        padding-top: 171px;
        padding-left: 46px;
    }
    .p1{
        font-size: 38px;
        color: #FFF;
        line-height: 38px;
    }
    .p2{
        font-size: 38px;
        color: #FFF;
        line-height: 38px;
        font-weight: bold;
        margin-top: 20px;
    }
    .tiao{
        width: 182px;
        height: 5px;
        background-color: #FFF;
        margin-top: 32px;
    }
    .sectioninright{
        width: 511px;
        height: 513px;
        background-color: #FFF;
        float: left;
        padding-top: 27px;
    }
    .title{
        font-size: 24px;
        font-weight: normal;
        line-height: 28px;
        color: #2d2d2d;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        margin: 0 auto;
        cursor: pointer;
    }
    .title .active{
        font-size: 28px;
        font-weight: bold;
    }
    .title .active .acspan{
        border-bottom: 2px solid #3da3f5;
        transition: all 0.2s;
    }
    .svg1{
        color: #3da3f5;
    }
    .tab{
        width: 353px;
        margin:  0 auto;
        margin-top: 30px;
    }
    .anniugroup{
        display: flex;
        margin-bottom: 20px;
    }
    .anniu{
        height: 39px;
        flex: 1;
        color: #545454;
        text-align: center;
        line-height: 39px;
        cursor: pointer;
    }
    .anniu_active{
        border-bottom: 2px solid #3ba9fe;
    }
    .anniucativeblue{
        height: 2px;
        width: 176px;
        background-color: #3ba9fe;
        transition: mrgin-left 0.5s;
    }
    .shoujihao{
        width: 353px;
        height: 49px;
        border: 1px #dfdfdf solid;
        border-radius:4px ;
        padding-left: 12px;
    }
    .shoujihaodiv{
        position: relative;
        margin-bottom: 18px;
    }
    .panduan1{
        position: absolute;
        right: 13px;
        top: 13px;
        color: green;
    }
    .svg2{
        color: green;
        display: none;
    }
    .svg3{
        color: red;
        display: none;
    }
    .tuxingdiv{
        margin-bottom: 18px;
    }
    .txinput{
        position: relative;
        float: left;
    }
    .txyz{
        width: 240px;
        height: 49px;
        border: 1px #dfdfdf solid;
        border-radius:4px ;
        padding-left: 12px;
    }
    .panduan2{
        position: absolute;
        right: 13px;
        top: 13px;
        color: green;
    }
    .img1{
        float: right;
    }
    .yzm{
        width: 240px;
        height: 49px;
        border: 1px #dfdfdf solid;
        border-radius:4px ;
        padding-left: 12px;
    }
    .yanzhengmadiv{
        margin-top: 18px;
    }
    .yzminput{
        position: relative;
        float: left;
    }
    .hqbtn{
        width: 103px;
        height: 49px;
        background-color: #3ca9fc;
        border-radius:4px;
        color: #FFF;
        text-align: center;
        line-height: 49px;
        float: right;
        cursor: pointer;
    }
    .dl{
        width: 100%;
        height: 49px;
        background-color: #3ca9fc;
        border-radius:4px;
        text-align: center;
        line-height: 49px;
        color: #FFF;
        margin-top: 18px;
        font-size: 14px;
    }
    .hmzh{
        text-align: center;
        color: #838383;
        margin-top:50px;
    }
    .hmzh a{
        color: #24adff;
    }
    .form{
        padding-top: 14px;
    }
    .form1{
        display: block;
    }

    /*from2*/
    .form2{
        /*display: none;*/
    }
    .form3{
        margin-top: 70px;
    }
    .dldiv{
        position: relative;
    }
    .dlinp,.mminp{
        width: 100%;
        height: 49px;
        border: 1px #dfdfdf solid;
        border-radius:4px ;
        padding-left: 12px;
        margin-bottom: 18px;
    }
    .dl2{
        width: 100%;
        height: 49px;
        background-color: #3ca9fc;
        border-radius:4px;
        text-align: center;
        line-height: 49px;
        color: #FFF;
        font-size: 14px;
    }


    footer{
        width: 100%;
        height: 286px;
        background-color: #085aa6;
    }
    .footerin{
        height: 100%;
        color: #FFF;
    }
    .footerinl{
        width: 693px;
        height: 100%;
        float: left;
        padding-top: 74px;
        font-size: 18px;
    }
    .p3{
        line-height: 18px;
        margin-bottom: 30px;
    }
    .p3 span{
        margin-left: 40px;
    }
    .p4{
        line-height: 18px;
        margin-bottom: 16px;
    }
    .p5{
        color: #8dc0ef;
    }
    .footerinr{
        width: 432px;
        height: 100%;
        padding-top: 53px;
        padding-left: 163px;
        float: right;
    }
    .erweima1{
        float: left;
    }
    .p6{
        font-size: 14px;
        line-height: 14px;
        margin-top: 11px;
        text-align: center;
    }
    .erweima1:nth-child(2){
        margin-left: 20px;
    }

</style>
