<template>
	<div>
		<div class="img">
			<img src="../../static/img/cp.png" />
		</div>
		<section id="con" class="w">
			<div class="box">
				<div class="login">
					<div class="title">
						<h2 class="h2">融资意向</h2>
					</div>
					<div class="formContain">
						<el-form ref="form" :model="form" :rules="rules" label-width="100px">
							<el-form-item label="融资金额：" prop="enclosurePrice">
								<el-input placeholder="请输入融资金额(万元)" type="number" v-model="form.enclosurePrice">
								</el-input>
							</el-form-item>
							<el-form-item label="融资期限：" prop="enclosureTimeId" v-if="options[0]">
								<el-select v-model="form.enclosureTimeId" placeholder="请选择融资期限">
									<el-option v-for="(item, i) in options[0].result" :key="item.id" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="融资类型：" prop="enclosureTypeId" v-if="options[1]">
								<el-select v-model="form.enclosureTypeId" placeholder="请选择融资类型">
									<el-option v-for="(item, i) in options[1].result" :key="item.id" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="资金用途：">
								<el-input placeholder="请输入资金用途" v-model="form.purpose">
								</el-input>
							</el-form-item>
							<el-form-item label="担保方式：" prop="guaranteeId" v-if="options[2]">
								<el-select v-model="form.guaranteeId" placeholder="请选择担保方式">
									<el-option v-for="(item, i) in options[2].result" :key="item.id" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="企业名称：" prop="companyName">
								<el-input placeholder="请输入企业名称" v-model="form.companyName">
								</el-input>
							</el-form-item>
							<el-form-item label="联系人：" prop="companyContacts">
								<el-input placeholder="请输入联系人" v-model="form.companyContacts">
								</el-input>
							</el-form-item>
							<el-form-item label="联系电话：" prop="companyPhone">
								<el-input type="number" v-model="form.companyPhone" placeholder="请输入联系电话"></el-input>
							</el-form-item>
							<el-form-item label="短信验证码:" prop="smsCode">
								<div class="codediv">
									<el-input class="codeinput" type="number" v-model="form.smsCode"
										placeholder="请输入验证码"></el-input>
									<CodeBtn :phone="form.companyPhone" codeType="getCode5"></CodeBtn>
								</div>
							</el-form-item>
						</el-form>
						<el-button class="btnstyle" v-loading="loading" @click="submit('form')" type="primary">提交
						</el-button>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import validators from "../../util/validators.js";
	import CodeBtn from "../../components/logincpn/CodeBtn";


	export default {
		name: "startApply",
		data() {
			return {
				options: [],
				form: {
					enclosurePrice: "",
					enclosureTimeId: "",
					enclosureTypeId: "",
					purpose: "",
					guaranteeId: "",
					companyName: "",
					companyContacts: "",
					companyPhone: "",
					smsCode: "",
				},
				rules: {
					companyPhone: [{
						validator: validators.checkPhone,
						trigger: ["blur", "change"]
					}, ],
					enclosurePrice: [{
						required: true,
						message: "请输入融资金额",
						trigger: "blur"
					}, ],
					enclosureTimeId: [{
						required: true,
						message: "请选择融资期限",
						trigger: "blur"
					}, ],
					enclosureTypeId: [{
						required: true,
						message: "请选择融资类型",
						trigger: "blur"
					}, ],
					purpose: [{
						required: false,
						message: "请输入资金用途",
						trigger: "blur"
					}, ],
					guaranteeId: [{
						required: true,
						message: "请选择担保方式",
						trigger: "blur"
					}, ],
					companyContacts: [{
						required: true,
						message: "请输入联系人",
						trigger: "blur"
					}, ],
					companyName: [{
						required: true,
						message: "请输入企业名称",
						trigger: "blur"
					}, ],
					companyPhone: [{
						required: true,
						message: "请输入联系电话",
						trigger: "blur"
					}, ],
					smsCode: [{
						required: true,
						message: "请输入短信验证码",
						trigger: "blur"
					}, ],
				},
				loading: false,
			};
		},
		mounted() {
			this.getOptions();
			let userId = this.$store.state.userInfo;

			if (userId) {
				this.form.companyName = userId.companyName;
				this.form.companyPhone = userId.phone;
				this.form.companyContacts = userId.userName;
			} else {
				// this.gotoLogin()
			}
		},
		methods: {

			gotoLogin() {
				this.$router.push("/login");
			},
			sendcode() {
				const reg = 11 && /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/;
				if (this.form.companyPhone == "") {
					this.$message({
						message: "手机号不能为空",
						center: true,
					});
					return;
				}
				if (!reg.test(this.form.companyPhone)) {
					this.$message({
						message: "请输入正确的手机号",
						center: true,
					});
					return;
				} else {
					// console.log(this.form.companyPhone);
					this.getCode();
				}
			},
			//提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//验证表单
						// console.log(this.form);
						this.sendMsg();
					} else {
						return false;
					}
				});
			},
			async sendMsg() {
				let userId = this.$store.state.userInfo ?
					this.$store.state.userInfo.id : "";
				this.loading = true;
				let res = await this.$api.enclosureApply({
					...this.form,
					userId
				});
				this.loading = false;
				if (res.success) {
					this.$alert(res.message, "", {
						confirmButtonText: "确定",
						callback: (action) => {
							this.$router.push("/");
						},
					});
				}
				// if (!userId) {
				// 	this.$alert("请登录后再试", "", {
				// 		confirmButtonText: "确定",
				// 		callback: (action) => {
				// 			this.$router.push("/login");
				// 		},
				// 	});
				// } else {
				// 	this.loading = true;
				// 	let res = await this.$api.enclosureApply({
				// 		...this.form,
				// 		userId
				// 	});
				// 	this.loading = false;
				// 	if (res.success) {
				// 		this.$alert(res.message, "", {
				// 			confirmButtonText: "确定",
				// 			callback: (action) => {
				// 				this.$router.push("/");
				// 			},
				// 		});
				// 	}
				// }
			},
			async getOptions() {
				let res = await Promise.all([
					this.$api.projectDic({
						type: 1
					}),
					this.$api.projectDic({
						type: 2
					}),
					this.$api.projectDic({
						type: 3
					}),
				]);
				this.options = res;
			},
		},
		components: {
			CodeBtn,
		},
	};
</script>

<style scoped>
	.btnstyle {
		margin: 50px auto;
		display: block;
	}

	.el-select {
		display: block;
	}

	.formContain {
		width: 600px;
		margin: 0 auto;
		margin-top: 100px;
		transform: scale(1.2);
	}

	.img {
		width: 100%;
		height: 340px;
		min-width: 1200px;
	}

	.img img {
		width: 100%;
		min-width: 1200px;
		height: 340px;
	}

	#con {
		padding: 63px 0;
	}

	.box {
		width: 100%;
		/*height: 1140px;*/
		border: 1px #dcdcdc solid;
		box-shadow: 2px 2px 3px 1px #dcdcdc;
		border-radius: 16px;
	}

	.login {
		margin: 40px 33px 85px 50px;
	}

	.title {
		height: 60px;
		width: 100%;
		border-bottom: 4px solid #3ca9fc;
		/* border-bottom-left-radius: 13px; */
		overflow: hidden;
	}

	.h2 {
		margin: 0 auto;
		width: 170px;
		height: 60px;
		background-color: #3ca9fc;
		color: white;
		font-size: 23px;
		border-radius: 13px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.list {
		padding-top: 72px;
		padding-left: 132px;
	}

	.list li {
		margin-bottom: 27px;
	}

	.user {
		height: 78px;
		line-height: 78px;
		font-size: 28px;
		width: 150px;
		float: left;
		color: #333;
		text-align: right;
	}

	.user span {
		color: #ef6160;
		margin-right: 13px;
	}

	.list li input {
		margin-left: 58px;
		padding-left: 28px;
		border: solid 2px #d9dce3;
		height: 78px;
		line-height: 78px;
		font-size: 28px;
		float: left;
		color: #333;
		width: 510px;
		margin-right: 16px;
		border-radius: 12px;
	}

	input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #b3bdc9;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #b3bdc9;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #b3bdc9;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #b3bdc9;
	}

	.list li .select {
		width: 510px;
		height: 78px;
		float: left;
		margin-left: 58px;
		padding-left: 28px;
		padding-right: 20px;
		margin-right: 16px;
		border-radius: 12px;
		border: solid 2px #d9dce3;
	}

	.list li select {
		height: 74px;
		line-height: 74px;
		width: 462px;
		border-radius: 12px;
		border: none;
		font-size: 28px;
		color: #b3bdc9;
	}

	.wan {
		line-height: 78px;
		font-size: 28px;
		color: #2e2c42;
		padding-left: 15px;
	}

	.tips {
		line-height: 78px;
		font-size: 28px;
		padding-left: 20px;
	}

	.okey {
		margin: 0 auto;
		margin-top: 43px;
		width: 206px;
		height: 60px;
		background-color: #3ca9fc;
		font-size: 28px;
		border-radius: 9px;
		color: #fff;
		display: block;
		cursor: pointer;
	}

	.tips_true,
	.tips_false {
		line-height: 78px;
		font-size: 28px;
		padding-left: 20px;
	}

	.tips_true {
		color: green;
	}

	.tips_false {
		color: red;
	}

	.proper {
		color: green;
	}

	.wrong {
		color: red;
	}
</style>
