<template>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item
                label-width="0"
                prop="username">
            <el-input type="number" v-model="form.username" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label-width="0" prop="imgcode">
            <div class="codediv">
                <el-input class="codeinput" placeholder="请输入图形验证"
                          v-model="form.imgcode">
                </el-input>
                <randomImg @getCode="getCode"></randomImg>
            </div>
        </el-form-item>
        <el-form-item label-width="0" prop="password">
            <div class="codediv">
                <el-input class="codeinput" type="number" v-model="form.password" placeholder="请输入验证码"></el-input>
                <CodeBtn :phone="form.username" codeType="getCode2" ></CodeBtn>
            </div>
        </el-form-item>
        <button type="button" @click="submit('form')" class="zc">登录</button>
    </el-form>
</template>

<script>
    import randomImg from "./randomImg";
    import validators from '../../util/validators.js'
    import CodeBtn from "./CodeBtn";
    export default {
        name: "LoginInPhone",
        data(){
            var checkCode = (rule, value, callback) => {
                if(!value){
                    return callback(new Error('图形验证码不能为空'))
                }else {
                    if(value.toLowerCase() == this.code.toLowerCase()){
                        callback();
                    }else {
                        return callback(new Error('请输入正确的图形验证码'));
                    }
                }
            }
            return {
                code:'',
                form:{
                    username:'',
                    imgcode:'',
                    code:'',
                },
                rules: {
                    username: [
                        {validator: validators.checkPhone, trigger: ['blur', 'change']}
                    ],
                    password: [
                        { required: true, message: '请输入验证码', trigger: 'blur' },
                    ],
                    imgcode: [
                        {validator: checkCode, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            getCode(code){
                this.code = code;
            },
            //提交
            submit(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) { //验证表单
                        this.$emit('submit',this.form)
                    } else {
                        return false;
                    }
                });
            }
        },
        components:{
            CodeBtn,randomImg
        },
        created() {
            //回车登录
            let that = this;
            document.onkeypress = function(e) {
                var keycode = document.all ? event.keyCode : e.which;
                if (keycode == 13) {
                    that.submit('form');
                    return false;
                }
            };
        }
    }
</script>

<style scoped>
    .codeinput{
        margin-right: 10px;
    }
    .codediv{
        display: flex;
    }
    .imgcode{
        width: 100px;
        height: 40px;
        object-fit: contain;
    }
    .zc {
        width: 100%;
        height: 49px;
        background-color: #3ba8fb;
        border-radius: 4px;
        color: #FFF;
        cursor: pointer;
        margin-top: 20px;
    }
</style>