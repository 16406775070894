<template>
	<div>
		<div class="ppt"></div>
		<!--导航条-->
		<div class="details_nav w">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/mosService' }">融资服务</el-breadcrumb-item>
				<el-breadcrumb-item>政府投资引导资金</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="w">
			<div class="news">
				<div class="news-cate">
					<div class="title">
						国家政策
						<span class="more" @click="more(1)">更多 >></span>
					</div>
					<div class="list">
						<div class="item" v-for="(item,index) in newsList" :key="index" @click="jump(item)">
							<div class="item-title">{{item.title}}</div>
<!--							<div class="item-time">{{item.releaseTime}}</div>-->
						</div>
					</div>
				</div>
				<div class="news-cate">
					<div class="title">省级政策<span @click="more(2)" class="more">更多 >></span></div>
					<div class="list">
						<div class="item" v-for="(item,index) in newsList2" :key="index" @click="jump(item)">
							<div class="item-title">{{item.title}}</div>
<!--							<div class="item-time">{{item.releaseTime}}</div>-->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="boxcontain">
			<div>
				<div class="leftlist">
					<div v-for="(item,i) in tarbarList" :key="i">
						<div class="tarbox" :class="{active:currenti == i}" @click="change(i)">
							<div class="tar-name">{{item.FundCompany.companyName}}</div>
						</div>
						<div class="child">
							<div class="item" @click="changeChild(child,cix)" v-if="currenti == i" :class="{actives:currentChildIndex == cix}"
								v-for="(child,cix) in item.fundsSubCompanyList">{{child.companyName}}</div>
						</div>
					</div>

				</div>
			</div>
			<div class="rightList">
				<div class="apply">
					<el-button round @click="jumpBan" type="primary">在线办理</el-button>
				</div>
				<div class="empty" v-if="details==''">没有内容~</div>
				<div v-else class="card" v-for="(item,index) in details" :key="index">
					<div class="titles">{{item.details.updateBy}}</div>
					<div class="content" v-html="item.details.details"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CompanyServices",
		data() {
			return {
				currenti: 0,
				tarbarList: [],
				details: [],
				currentChild: "",
				newsList:[],
				newsList2:[],
				currentChildIndex: -1,
				id: '1349914694723559425'
			}
		},
		mounted() {
			this.id = this.$route.params.id;
			this.getMsg();
			this.queryFundNews(1);
			this.queryFundNews(2);
		},
		methods: {
			more(type){
				this.$router.push({
					name:"mosNews",
					params:{
						type:type
					}
				})
			},
			change(i) {
				this.currenti = i;
				this.currentChild = '';
				this.currentChildIndex = -1;
				this.queryFundDetailsList();
			},
			changeChild(obj, index) {
				this.currentChild = obj;
				this.currentChildIndex = index;
				this.queryChildFundDetailsList();
			},
			jump(item) {
				console.log(item);
				this.$router.push({
					name: "companyFinalDetail",
					params:{type:item.type,id:item.id}
				})
			},
			async queryFundNews(type){
				let res = await this.$api.queryFundNews({type:type,pageSize:6});
				if(type==1){
					this.newsList = res.result.records;
				}else{
					this.newsList2 = res.result.records;
				}

			},
			async getMsg() {
				let res = await this.$api.queryFundCompanyList();
				this.tarbarList = res.result;
				console.log(this.tarbarList)
				this.queryFundDetailsList();
			},
			jumpBan(){
				this.$router.push({
					path:"/StartApply"
				})
			},
			async queryFundDetailsList() {
				let res = await this.$api.queryFundDetailsList({
					companyId: this.tarbarList[this.currenti].FundCompany.id
				});
				this.details = res.result;
				console.log(this.details)
			},
			async queryChildFundDetailsList() {
				let res = await this.$api.queryFundDetailsList({
					companyId: this.currentChild.id
				});
				this.details = res.result;
				console.log(this.details)
			},
		}
	}
</script>

<style scoped>
	.news{
		display: flex;
		justify-content: space-between;
	}

	.news .news-cate{
		width:49%;
	}


	.news .news-cate .title{
		background-color: rgb(38, 149, 254);
		padding:15px;
		color:white;
		font-weight: bold;
		font-size:20px;
		text-align: center;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		position:relative;
	}


	.news .news-cate .title .more{
		position:absolute;
		top:20px;
		font-size:14px;
		right:15px;
		cursor: pointer;
		font-weight: 400;
	}

	.news .news-cate .list{
		min-height:330px;
		background:#ededed;
	}

	.news .news-cate .list .item{
		display:flex;
		justify-content: space-between;
		padding:15px;
		border-bottom:1px solid white;
		transition: all .3s;
		cursor:pointer;
	}

	.news .news-cate .list .item:hover{
		color:rgb(38, 149, 254);
	}

	.news .news-cate .list .item .item-title{
		/*width:530px;*/
		white-space:nowrap;
		overflow: hidden;
		text-overflow:ellipsis;
	}

	.rightList {
		width: 100%;
	}

	.imgWidth {
		width: 100%;
		height: 100%;
	}

	.rightList .apply {
		display: flex;
		justify-content: flex-end;
	}

	.card {
		padding-left: 20px;
		padding-right: 20px;
	}

	.card .titles {
		width: 100%;
		color: rgb(38, 149, 254);
		font-weight: bold;
		font-size: 20px;
		padding-bottom: 10px;
		border-bottom: 2px solid rgb(38, 149, 254);
	}

	.card .content {
		padding-top: 20px;
	}

	.empty {
		padding: 50px;
		text-align: center;
		color: #999;
	}

	.details_nav {
		height: 124px;
		line-height: 124px;
		text-align: left;
		color: #555555;
		font-size: 22px
	}

	.details_nav a {
		color: #555555;
		font-size: 22px
	}

	.boxcontain {
		display: flex;
		width: 1200px;
		margin: 0 auto;
		margin-top:50px;
		margin-bottom: 100px;
	}

	.leftlist {
		width: 247px;
		flex-shrink: 0;
		margin-right: 20px;
		background-color: none;
		box-shadow: -2px -1px 20px 0px #ccc;
	}

	.leftlist .tarbox {
		width: 257px;
		height: 71px;
		font-size: 19px;
		display: flex;
		color: #666;
		border-bottom: 1px solid lightgray;
		align-items: center;
		padding-left:5px;
		margin-left:-10px;
		background: url("../../static/img/tarbarbg_eb.png") #ededed no-repeat;
		background-size: contain;
		/* justify-content: center; */
		cursor: pointer;
	}

	.leftlist .tarbox .tar-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 20px;
		padding-right: 20px;
	}

	.leftlist .child{
		/* margin-left:30px; */
	}

	.leftlist .child .item {
		padding: 15px;
		padding-left:27px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		/* margin:10px; */
	}


	.leftlist .child .actives {
		background-color: rgba(38, 149, 254,0.5);
		color: white;
	}

	.leftlist .active {
		font-weight: bold;
		transform: scale(1.1);
		color: #fff;
		font-size: 22px;
		background: url("../../static/img/tarbarbg.png") rgb(38, 149, 254) no-repeat;
		background-size: contain;
		box-shadow: -2px -1px 20px 0px #ccc;
		border-bottom: none;

	}

	.leftlist .tarbox:last-child {
		border-bottom: 0;
	}

	.rightbox {
		min-width: 930px;
		flex: 1;
	}

	.rightbox .innerbox {}

	.rightbox .innerbox .boxcont {
		background: #f9f9f9;
		line-height: 2;
		padding: 20px;
		text-indent: 35px;
	}

	.rightbox .innerbox .boxcont img {
		width: 100%;
	}

	.rightbox .innerbox .boxtitle {
		height: 50px;
		font-size: 20px;
		color: #fff;
		background: rgb(38, 149, 254);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 35px;
	}

	.rightbox .innerbox .imgWidth img {
		width: 100%;
		height: auto;
	}

	.sqbotn {
		margin: 0 auto;
		margin-top: 50px;
		border-radius: 10px;
		width: 150px;
		color: black !important;
		height: 40px;
		background: rgb(38, 149, 254);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	a {
		color: black !important;
	}

	.asdddbox {
		display: flex;
		align-items: center;
	}

	.asddd {
		padding-left: 20px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ppt {
		width: 100%;
		height: 339px;
		background-image: url("../../static/img/wsy_img1.png");
	}
</style>
