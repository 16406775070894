<template>
    <div>
        <div class="ppt"></div>
        <!--导航条-->
        <div class="details_nav w">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/mosService' }">融资服务</el-breadcrumb-item>
                <el-breadcrumb-item>债权服务</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="boxcontain">
            <div>
                <div class="leftlist">
                    <div class="tarbox"
                         v-for="(item,i) in tarbarList" :key="i"
                         :class="{active:currenti == i}"
                         @click="currenti = i"
                    >{{item}}</div>
                </div>
            </div>
            <div class="rightList">
                <div class="rightbox" v-show="currenti==0">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox">
                                <img src="../../static/img/zq1.jpg" alt="">
                                <!-- <div class="asddd">
                                    股权登记融资是融资方以其自身极第三人持有依法可以转让和出质的上市公司非上市股份有限公司有限责任公司的服权作为质押物
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">业务介绍</div>
                        <div class="boxcont">
                            公司债是股份公司为筹措资金以发行债券的方式向社会公众募集的债。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">精选案例</div>
                        <div class="boxcont">
                            2020年11月11日河北建设交通投资有限公司（176382 20冀通Z2）成功发行了8亿元，票面利率4.53%，存续期10年。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">在线申请</div>
                        <a href="lky_index.html"><div class="sqbotn">在线申请</div></a>
                    </div>
                </div>
                <div class="rightbox" v-show="currenti==1">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox">
                                <img src="../../static/img/zq2.jpg" alt="">
                                <!-- <div class="asddd">
                                    股权变更融资是融资方以其自身极第三人持有依法可以转让和出质的上市公司非上市股份有限公司有限责任公司的服权作为质押物
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">业务介绍</div>
                        <div class="boxcont">
                            企业债是指境内具有法人资格的企业，依照法定程序发行、约定在一定期限内还本付息的有价证券。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">精选案例</div>
                        <div class="boxcont">
                            2020年10月16日邯郸城市发展投资集团有限公司（176382 20冀通Z2）成功发行了8亿元，票面利率4.53%，存续期10年。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">在线申请</div>
                        <a href="lky_index.html"><div class="sqbotn">在线申请</div></a>
                    </div>
                </div>
                <div class="rightbox" v-show="currenti==2">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox">
                                <img src="../../static/img/zq3.jpg" alt="">
                                <!-- <div class="asddd">
                                    股权变更融资是融资方以其自身极第三人持有依法可以转让和出质的上市公司非上市股份有限公司有限责任公司的服权作为质押物
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">业务介绍</div>
                        <div class="boxcont">
                            资产证券化是指以基础资产未来所产生的现金流为偿付支持，通过结构化设计进行信用增级，在此基础上发行资产支持证券的过程。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">精选案例</div>
                        <div class="boxcont">
                            2020年11月17日（蚁借03A）发行25.8亿元，利率5.1%,存续期526天。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">在线申请</div>
                        <a href="lky_index.html"><div class="sqbotn">在线申请</div></a>
                    </div>
                </div>
                <div class="rightbox" v-show="currenti==3">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox">
                                <img src="../../static/img/zq4.jpg" alt="">
                                <!-- <div class="asddd">
                                    定向增发融资是融资方以其自身极第三人持有依法可以转让和出质的上市公司非上市股份有限公司有限责任公司的服权作为质押物
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">业务介绍</div>
                        <div class="boxcont">
                            短期融资券指企业在银行间债券市场发行(即由国内各金融机构购买不向社会发行)和交易并约定在一年期限内还本付息的有价证券。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">精选案例</div>
                        <div class="boxcont">
                            2020年2月24日河北钢铁集团有限公司（20河钢集CP001）发行2亿元，利率2.85%，发行期1年。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">在线申请</div>
                        <a href="lky_index.html"><div class="sqbotn">在线申请</div></a>
                    </div>
                </div>
                <div class="rightbox" v-show="currenti==4">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox">
                                <img src="../../static/img/zq5.jpg" alt="">
                                <!-- <div class="asddd">
                                    定向增发融资是融资方以其自身极第三人持有依法可以转让和出质的上市公司非上市股份有限公司有限责任公司的服权作为质押物
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">业务介绍</div>
                        <div class="boxcont">
                            可转换为股票的债券是债券持有人可按照发行时约定的价格将债券转换成公司的普通股票的债券。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">精选案例</div>
                        <div class="boxcont">
                            2020年12月2日唐山冀东水泥股份有限公司（冀东转债）发行28.2亿元，利率0.2%，年限6年。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">在线申请</div>
                        <a href="lky_index.html"><div class="sqbotn">在线申请</div></a>
                    </div>
                </div>
                <div class="rightbox" v-show="currenti==5">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox">
                                <img src="../../static/img/zq6.jpg" alt="">
                                <!-- <div class="asddd">
                                    定向增发融资是融资方以其自身极第三人持有依法可以转让和出质的上市公司非上市股份有限公司有限责任公司的服权作为质押物
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">业务介绍</div>
                        <div class="boxcont">
                            中期票据是介于商业票据和公司债券之间的一种融资方式，期限范围最短9个月最长30年。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">精选案例</div>
                        <div class="boxcont">
                            2020年12月10日，河北交通投资集团公司（冀交投MTN004）发行15亿元，利率4.4%，存续期3年。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">在线申请</div>
                        <a href="lky_index.html"><div class="sqbotn">在线申请</div></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DebtService",
        data(){
            return {
                currenti:0,
                tarbarList:['公司债','企业债','资产证券化','短期融资券','可转换为股票的债券','中期票据']
            }
        }
    }
</script>

<style src="../../static/css/service.css" scoped>
</style>