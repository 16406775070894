<template>
    <div id="lzy">
        <section>
            <!-- 企业信息 -->
            <div class="right_content">
                <!-- 审核通过 -->
                <div class="consent_block" v-if="show">
                    <img src="../../../../static/img/lzy_05_02.png" alt="">
                    <span>{{mes}}</span>
                </div>
                <!-- 企业信息title -->
                <div class="block3_title">
                    <p>企业信息</p>
                    <div class="bottom_block"></div>
                </div>
                <!-- 企业具体信息 -->
                <div class="tog_block_5">
                    <div class="tog_btn">
                        <div class="message"
                             :class="{'active':tab==1}"
                             @click="tab=1"
                        >基本信息
                        </div>
                        <div class="message"
                             :class="{'active':tab==2}"
                             @click="tab=2">股权结构及企业经营情况
                        </div>
                        <div class="message"
                             :class="{'active':tab==3}"
                             @click="tab=3">上市筹备情况
                        </div>
                        <div class="message"
                             :class="{'active':tab==4}"
                             @click="tab=4">金融支持情况
                        </div>
                    </div>
                </div>
                <div class="formbox">
                    <Form1 ref="form1" @submit="getFormInfo" v-show="tab==1"></Form1>
                    <Form2 ref="form2" @submit="getFormInfo" v-show="tab==2"></Form2>
                    <Form3 ref="form3" @submit="getFormInfo" v-show="tab==3"></Form3>
                    <Form4 ref="form4" @submit="getFormInfo" v-show="tab==4"></Form4>
                </div>
                <el-button type="primary" @click="submit" style="padding:6px 20px;margin:10px 0;">提交</el-button>
            </div>
        </section>
    </div>

</template>

<script>
    import Form1 from "./Form1";
    import Form2 from "./Form2";
    import Form3 from "./Form3";
    import Form4 from "./Form4";

    export default {
        name: "Information",
        data() {
            return {
                tab: 1,
                allData: {
                    userId:'',
                    id: ''
                },
                show: true,
                mes: '',
                
            }
        },
        mounted() {
            this.companyMessage()
        },
        methods: {
            getFormInfo(e, type) {
                this.allData = {...this.allData,...e}
                console.log(e)
            },
            submit() {
                //让form执行getFormInfo
                this.$refs.form1.onSubmit();
                this.$refs.form2.onSubmit();
                this.$refs.form3.onSubmit();
                this.$refs.form4.onSubmit();

                this.dealData();
            },
            //处理数据
            dealData(){
                this.allData.userId = this.$store.state.userInfo.id; //获取userid
                this.allData.isCompanyRedchip = this.allData.isCompanyRedchip?'1':'2';
                //console.log(this.allData); //4个表单数据
                this.sendMsg();
            },
            async sendMsg(){
                let res = await this.$api.editCompanyMessage(this.allData);
                 if (res.success) {
                this.$message({
                    message: res.message,
                    center: true,
                    type: 'success',
                    offset: 250,
                    onClose:()=>{
                        // this.$router.push("/companyCenter/information");
                    }
                });
                }
            },
            async companyMessage(){
                let userId = this.$store.state.userInfo.id
                let res = await this.$api.companyMessage({userId});
                this.allData.id = res.result.company.id;
                let status = res.result.company.examineStatus;
                if(status == 1) {
                    this.mes = "企业信息审核中"
                }else if(status == 2) {
                    this.mes = "企业信息已审核通过"
                }else {
                    this.mes = "企业信息审核失败"
                }
            },
        },
        components: {
            Form1, Form2, Form3, Form4
        }
    }
</script>

<style scoped>
    #lzy {
        width: 100%;
        margin-bottom: 20px;
    }
    #lzy section .right_content {
        background-color: #fff;
        padding: 0 10px;
        box-sizing: border-box;
    }
    #lzy section .right_content .consent_block {
        font-size: 17px;
        height: 35px;
        width: 100%;
        background-color: #f1f9ec;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    #lzy section .right_content .consent_block img {
        margin: 0 12px;
    }

    #lzy section .right_content .consent_block span {
        font-size: 16px;
        color: #66c428;
    }

    #lzy section .right_content .block3_title {
        height: 35px;
        display: flex;
        align-items: flex-start;
        border-bottom: 4px solid #c6e0ff;
        position: relative;
    }

    #lzy section .right_content .block3_title span {
        color: red;
    }

    #lzy section .right_content .block3_title p {
        color: #333333;
    }

    #lzy section .right_content .block3_title .bottom_block {
        height: 4px;
        position: absolute;
        bottom: -4px;
        width: 40px;
        background-color: #2398f8;
    }

    #lzy section .right_content .dete_block {
        margin-bottom: 35px;
    }

    #lzy section .right_content .dete_block input {
        border: 1px solid #e5e5e5;
        padding-left: 35px;
        height: 32px;
        font-size: 15px;
    }

    #lzy section .right_content .tog_block_5 {
        width: 100%;
        margin-top: 20px;
    }

    #lzy section .right_content .tog_block_5 .tog_btn {
        width: 100%;
        display: flex;
        height: 39px;
    }

    #lzy section .right_content .tog_block_5 .tog_btn > .active {
        background-color: #2895fc;
        color: white;
    }

    #lzy section .right_content .tog_block_5 .tog_btn > div {
        height: 100%;
        flex: 1;
        background-color: #f6f7fb;
        color: #646569;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        transition: all 0.3s;
    }


</style>