<template>
  <div id="zjj_support">
    <div class="support_banner">
      <img src="../../static/img/aboutus.png" />
    </div>
    <div class="w">
      <div class="content">
        <div class="title">平台简介<div class="line"></div></div>
        <div class="detail">
          <p>河北省股改上市服务平台是由省金融办指定的全省唯一一家推动企业股改上市的综合性服务平台。平台集政策信息发布、培训辅导、融资路演、股改规范、企业上市及并购于一体，以“线上+线下”相结合的模式 ，提升企业上市意愿，协助政府部门及时掌握企业上市进程，精准推进本区域上市培育工作，在推动资本市场服务实体经济实现高质量发展，促进我省经济结构调整和产业转型升级方面具有重要意义。</p>
        </div>
        <div class="mapImg">
          <img src="../../static/img/mapImg.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
#zjj_support {
  width: 100%;
}
.support_banner {
  width: 100%;
}
.support_banner img {
  width: 100%;
  min-width: 1200px;
  height: 100%;
}
  .content {
    width: 100%;
    margin: 70px 0;
  }
  .content .title {
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 10px;
  }
  .content .title .line {
    width: 120px;
    height: 2px;
    background-color: #79b1d4;
    margin-top: 6px;
  }
  .detail {
    width: 100%;
    margin: 20px 0;
  }
  .detail p {
    text-indent: 40px;
    font-size: 20px;
    color: #333;
    font-weight: 500;
    line-height: 40px;
  }
  .mapImg{
    width: 100%;
    margin: 30px 0;
  }
</style>
