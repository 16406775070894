<template>
    <el-form :model="form"  size="mini"
              label-width="145px" ref="form" class="demo-ruleForm">
        <!--        股权结构-->
        <div>
            <el-tag class="tagBox" effect="dark" type="info">股权结构</el-tag>
            <el-form-item label-width="0">
                <el-table
                        :header-cell-style="{color:'#000',fontWeight:'normal'}"
                        :data="stockright"
                        style="width: 100%">
                    <el-table-column label="股东性质" :width="tableWidth" align="center">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyStockright.' + scope.$index + '.shareholderType'">
                                <el-select v-model="scope.row.shareholderType" placeholder="请选择">
                                    <el-option v-for="(item,i) in shareholderTypeOptions" :key="item.id"
                                            :label="item.label" :value="item.value"></el-option>
<!--                                    shareholderTypeOptions-->
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="股东名称"
                            align="center"
                            :width="tableWidth">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyStockright.' + scope.$index + '.shareholderName'">
                                <el-input v-model="scope.row.shareholderName"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="股东持股比例（%）"
                            align="center"
                            :width="tableWidth">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyStockright.' + scope.$index + '.shareholderShareholdingratio'">
                                <el-input type="number" v-model="scope.row.shareholderShareholdingratio"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="持股数量（万股）"
                            align="center"
                            :width="tableWidth">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyStockright.' + scope.$index + '.shareholderShareholdingnum'">
                                <el-input type="number" v-model="scope.row.shareholderShareholdingnum"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center">
                        <template slot="header" slot-scope="scope">
                            <div @click="addTable(scope.$index)">
                                <i data-v-7af8c3fe="" size="small" class="el-icon-circle-plus"></i>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <div @click="subTable(scope.$index)">
                                <i data-v-7af8c3fe="" size="small" class="el-icon-remove-outline"></i>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </div>
        <!--        企业经营情况-->
        <div>
            <div class="exprenceTitle">
                <el-tag class="tagBox" effect="dark" type="info">企业经营情况</el-tag>
                <!--                //企业经营情况加减暂时注掉、、加号-->
                <!--                <button data-v-7af8c3fe="" type="button" @click="addExperience()"-->
                <!--                        class="el-button el-button&#45;&#45;primary el-button&#45;&#45;small">-->
                <!--                    <i class="el-icon-circle-plus-outline"></i>-->
                <!--                </button>-->
            </div>
            <div class="exprenceBox">
                <div class="inline">
<!--                    <el-form-item label="主营业务名称:" prop="companyMainbusinessName">-->
<!--                        <el-input v-model="form.companyMainbusinessName"></el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item align="right">
                        <!--                        //企业经营情况加减暂时注掉、、减号-->
                        <!--                        <button data-v-7af8c3fe="" type="button" @click="subExperience(i)"-->
                        <!--                                class="el-button el-button&#45;&#45;default el-button&#45;&#45;small">-->
                        <!--                            <i class="el-icon-remove-outline"></i>-->
                        <!--                        </button>-->
                    </el-form-item>
                </div>
                <el-form-item label-width="0">
                    <el-table
                            :header-cell-style="{color:'#000',fontWeight:'normal'}"
                            :data="management"
                            style="width: 100%">
                        <el-table-column label="主营服务/产品" :width="tableWidth2" align="center">
                            <template slot-scope="scope">
                                <el-form-item label-width="0"
                                              :prop="'companyManagement.' + scope.$index + '.companyMainbusinessName'">
                                    <el-input v-model="scope.row.companyMainbusinessName"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="简介及用途" :width="tableWidth2" align="center">
                            <template slot-scope="scope">
                                <el-form-item label-width="0"
                                              :prop="'companyManagement.' + scope.$index + '.companyContent'">
                                    <el-input v-model="scope.row.companyContent"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="营收占比(%)" :width="tableWidth2" align="center">
                            <template slot-scope="scope">
                                <el-form-item label-width="0"
                                              :prop="'companyManagement.' + scope.$index + '.revenue'">
                                    <el-input type="number" v-model="scope.row.revenue"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="净利润率(%)"
                                align="center"
                                :width="tableWidth2">
                            <template slot-scope="scope">
                                <el-form-item label-width="0"
                                              :prop="'companyManagement.' + scope.$index + '.compnayRate'">
                                    <el-input type="number" v-model="scope.row.compnayRate"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="竞争对手"
                                align="center"
                                :width="tableWidth2">
                            <template slot-scope="scope">
                                <el-form-item label-width="0"
                                              :prop="'companyManagement.' + scope.$index + '.companyCompetitor'">
                                    <el-input v-model="scope.row.companyCompetitor"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="center">
                            <template slot="header" slot-scope="scope">
                                <div @click="addexTable(scope.$index)">
                                    <i data-v-7af8c3fe="" size="small" class="el-icon-circle-plus"></i>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                <div @click="subexTable(scope.$index)">
                                    <i data-v-7af8c3fe="" size="small" class="el-icon-remove-outline"></i>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </div>
        </div>
        <!-- 主要财务指标-->
        <div>
            <el-tag class="tagBox" effect="dark" type="info">主要财务指标</el-tag>
            <el-form-item label-width="0">
                <el-table
                        :header-cell-style="{color:'#000',fontWeight:'normal'}"
                        :data="finance"
                        style="width: 100%">
                    <el-table-column
                            label="年度"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.year'">
                                <el-input v-model="scope.row.year"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="营业收入(万元)"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.companyBusinessIncome'">
                                <el-input type="number" v-model="scope.row.companyBusinessIncome"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="利润总额(万元)"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.profitTotal'">
                                <el-input type="number" v-model="scope.row.profitTotal"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="净利润(万元)"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.companyBusinessProfit'">
                                <el-input type="number" v-model="scope.row.companyBusinessProfit"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="经营性现金流(万元)"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.ompanyCashFlow'">
                                <el-input type="number" v-model="scope.row.ompanyCashFlow"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="净资产(万元)"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.companyNetAssets'">
                                <el-input type="number" v-model="scope.row.companyNetAssets"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="总资产(万元)"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.companyTotalAssets'">
                                <el-input type="number" v-model="scope.row.companyTotalAssets"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="研发投入金额（万元）"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.companyDevelopmentAmount'">
                                <el-input type="number" v-model="scope.row.companyDevelopmentAmount"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="研发投入占营业收入比重（%）"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.companyDevelopmentRatio'">
                                <el-input type="number" v-model="scope.row.companyDevelopmentRatio"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="纳税额（万元）"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.companyPaytaxes'">
                                <el-input type="number" v-model="scope.row.companyPaytaxes"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="市值/估值(万元)"
                            align="center"
                            :width="tableWidth3">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.companyMarketvalue'">
                                <el-input type="number" v-model="scope.row.companyMarketvalue"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否经审计" :width="tableWidth3" align="center">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyFinance.' + scope.$index + '.isCompanyAudit'">
                                <el-select v-model="scope.row.isCompanyAudit" placeholder="请选择">
                                    <el-option label="是" value="1"></el-option>
                                    <el-option label="否" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center">
                        <template slot="header" slot-scope="scope">
                            <div @click="addIndicator(scope.$index)">
                                <i data-v-7af8c3fe="" size="small" class="el-icon-circle-plus"></i>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <div @click="subIndicator(scope.$index)">
                                <i data-v-7af8c3fe="" size="small" class="el-icon-remove-outline"></i>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </div>
        <!--        近两年研发投入情况:-->
        <div>
            <el-form-item label="近两年研发投入情况:"></el-form-item>
            <div class="inline">
                <el-form-item label="研发投入金额(万元):" prop="companyDevelopmentInvestment">
                    <el-input v-model="form.companyDevelopmentInvestment"></el-input>
                </el-form-item>
                <el-form-item label="研发投入占比(%):" prop="companyDevelopmentInvestmentProportion">
                    <el-input v-model="form.companyDevelopmentInvestmentProportion"></el-input>
                </el-form-item>
            </div>
            <el-form-item label-width="670px"
                          required
                          label="请填写两年一期财务指标（两年指近两年，一期指半年报），上半年填写的无需提供半年报财务指标。"></el-form-item>
        </div>
        <!--        主要管理团队-->
        <div>
            <el-tag class="tagBox" effect="dark" type="info">主要管理团队</el-tag>
            <el-form-item label-width="0">
                <el-table
                        :header-cell-style="{color:'#000',fontWeight:'normal'}"
                        :data="team"
                        style="width: 100%">
                    <el-table-column label="姓名" :width="tableWidth4" align="center">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyTeam.' + scope.$index + '.name'">
                                <el-input v-model="scope.row.name"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="职务"
                            align="center"
                            :width="tableWidth4">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyTeam.' + scope.$index + '.posts'">
                                <el-input v-model="scope.row.posts"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="个人经历"
                            align="center"
                            :width="tableWidth4">
                        <template slot-scope="scope">
                            <el-form-item label-width="0"
                                          :prop="'companyTeam.' + scope.$index + '.introduction'">
                                <el-input v-model="scope.row.introduction"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center">
                        <template slot="header" slot-scope="scope">
                            <div @click="addManagement(scope.$index)">
                                <i data-v-7af8c3fe="" size="small" class="el-icon-circle-plus"></i>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <div @click="subManagement(scope.$index)">
                                <i data-v-7af8c3fe="" size="small" class="el-icon-remove-outline"></i>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </div>
    </el-form>

</template>

<script>
    export default {
        name: "Form2",
        data() {
            return {
                form: {
                    name: '',
                    region: '',
                    companyDevelopmentInvestment:'',
                    companyDevelopmentInvestmentProportion:'',
                    companyStockright: [], //股权结构
                    companyManagement: [], //企业经营情况
                    companyFinance: [], //主要财务指标
                    companyTeam: [] //主要管理团队

                },
                tableWidth: 200,
                tableWidth2: 160,
                tableWidth3: 80,
                tableWidth4: 270,
                stockright: [], //股权结构
                management: [], //企业经营情况
                finance: [], //主要财务指标
                team: [], //主要管理团队
                defaultTable: {
                    shareholderType: '',
                    shareholderName: '',
                    shareholderShareholdingratio: "",
                    shareholderShareholdingnum: ''
                },//股权结构默认
                defaultExperience: {
                    companyMainbusinessName: '',
                    companyContent: '',
                    revenue: "",
                    compnayRate: '',
                    companyCompetitor: ''
                },
                defaultIndicator: {
                    year:'',
                    companyBusinessIncome:'',
                    profitTotalL: '',
                    companyBusinessProfit:'',
                    ompanyCashFlow:'',
                    companyNetAssets:'',
                    companyTotalAssets:'',
                    companyDevelopmentAmount:'',
                    companyDevelopmentRatio: '',
                    companyPaytaxes:'',
                    companyMarketvalue:'',
                    isCompanyAudit:'',
                },//主要财务指标默认
                defaultManagement: {
                    name:'',
                    posts:'',
                    introduction:'',
                },//主要管理团队默认
                // rules: {
                //     shareholderType: [{required: true, message: '股东性质为必选项', trigger: ['blur', 'change']},],
                //     shareholderName: [{required: true, message: '股东名称为必填项', trigger: 'blur'},],
                //     shareholderShareholdingratio: [{required: true, message: '股东持股比例为必填项', trigger: 'blur'},],
                //     shareholderShareholdingnum: [{required: true, message: '持股数量（万股）为必填项', trigger: 'blur'},],
                //     require: [{required: true, message: '必填项', trigger: ['blur', 'change']},],
                //     companyDevelopmentInvestment: [{required: true, message: '请输入研发投入金额(万元)', trigger: 'blur'},],
                //     companyDevelopmentInvestmentProportion: [{required: true, message: '请输入研发投入占比', trigger: 'blur'},],
                //     name: [{required: true, message: '姓名为必填项', trigger: 'blur'},],
                //     posts: [{required: true, message: '职务为必填项', trigger: 'blur'},],
                //     introduction: [{required: true, message: '个人经历为必填项', trigger: 'blur'},],
                // },
                shareholderTypeOptions:[
                    {id:1,label:'国家持股',value:1},
                    {id:2,label:'法人持股',value:2},
                    {id:3,label:'自然人持股',value:3},
                    {id:4,label:'外资持股',value:4},
                    {id:5,label:'集体持股',value:5},
                ]
            }
        },
        mounted() {
            this.initData();
            this.companyMessage();
        },
        methods: {
            async companyMessage(){
                let userId = this.$store.state.userInfo.id
                let res = await this.$api.companyMessage({userId});
                for(let key in res.result.company){ //回显数据
                    if(key in this.form){
                        this.form[key] = res.result.company[key];
                    }
                }
                if(res.result.companyStockrightList.length>0){
                    this.stockright = res.result.companyStockrightList;
                }
                if(res.result.companyManagementList.length>0){
                    this.management = res.result.companyManagementList;
                }
                if(res.result.companyFinanceList.length>0){
                    this.finance = res.result.companyFinanceList;
                }
                if(res.result.companyTeamList.length>0){
                    this.team = res.result.companyTeamList;
                }
                // console.log(res);
            },
            onSubmit() {
                console.log(this.stockright)
                this.form.companyStockright = JSON.stringify(this.stockright)
                this.form.companyManagement = JSON.stringify(this.management)
                this.form.companyFinance = JSON.stringify(this.finance)
                this.form.companyTeam = JSON.stringify(this.team)
                // this.$refs.form.validate((valid) => {
                //     if (valid) { //验证表单
                //         this.$emit('submit', this.form,2)
                //     } else {
                //         return false;
                //     }
                // });
                this.$emit('submit', this.form,2)
               
            },
            initData() {
                this.stockright.push(JSON.parse(JSON.stringify(this.defaultTable)))
                this.management.push(JSON.parse(JSON.stringify(this.defaultExperience)))
                this.team.push(JSON.parse(JSON.stringify(this.defaultManagement)))
                this.finance.push(JSON.parse(JSON.stringify(this.defaultIndicator)))
            },
            //添加 股权结构
            addTable(i) {
                this.stockright.push(JSON.parse(JSON.stringify(this.defaultTable)))
            },
            //移除 股权结构
            subTable(i) {
                if (this.stockright.length > 1) {
                    this.stockright.splice(i, 1)
                } else {
                    this.$message({
                        showClose: true,
                        message: '至少填写1项!',
                        type: 'warning'
                    });
                }
            },
            //添加 企业经营情况 主营业务
            addexTable() {
                this.management.push(JSON.parse(JSON.stringify(this.defaultExperience)))
            },
            //移除 企业经营情况 主营业务
            subexTable( i2) {
                if (this.management.length > 1) {
                    this.management.splice(i2, 1)
                } else {
                    this.$message({
                        showClose: true,
                        message: '至少填写1个企业经营情况!',
                        type: 'warning'
                    });
                }
            },
            //添加 企业经营情况
            addExperience() {
                this.management.push(JSON.parse(JSON.stringify(this.defaultExperience)))
            },
            //移除 企业经营情况
            subExperience(i) {
                if (this.management.length > 1) {
                    this.management.splice(i, 1)
                } else {
                    this.$message({
                        showClose: true,
                        message: '至少填写1个主营业务名称!',
                        type: 'warning'
                    });
                }
            },
            // 添加 主要财务指标
            addIndicator() {
                this.finance.push(JSON.parse(JSON.stringify(this.defaultIndicator)))
            },
            // 移除 主要财务指标
            subIndicator(i) {
                if (this.finance.length > 1) {
                    this.finance.splice(i, 1)
                } else {
                    this.$message({
                        showClose: true,
                        message: '至少填写一年财务指标!',
                        type: 'warning'
                    });
                }
            },
            // 添加主要管理团队
            addManagement() {
                this.team.push(JSON.parse(JSON.stringify(this.defaultManagement)))
            },
            // 移除主要管理团队
            subManagement(i) {
                if (this.team.length > 1) {
                    this.team.splice(i, 1)
                } else {
                    this.$message({
                        showClose: true,
                        message: '至少填写1位管理人员!',
                        type: 'warning'
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .inline {
        display: flex;
    }

    .inline .el-form-item {
        width: 50%;
    }

    .tagBox {
        margin: 15px 0;
    }

    .tableBox th {
        margin-bottom: 15px;
    }

    .exprenceTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .exprenceBox {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        padding: 20px;
        box-sizing: border-box;
    }
</style>