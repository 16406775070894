import {Message} from "element-ui";
import router from "../router";
import store from '../store/index'

function toLogin(msg = '请登录'){
    if(router.history.current.name!='login'){
        store.commit('setUserInfo','');
        store.commit('setToken','');
        store.commit('changeBeforeLoginPage',router.currentRoute.fullPath); //把跳转登录之前的页面路由存到vuex
        Message({
            message:msg,
            center:true
        })

        router.push('/login')
    }
}

//判断直播是否开始
function uLiveState(startTime,endTime) {
    let currentTime = new Date().valueOf();
    startTime = new Date(startTime).valueOf();
    endTime = new Date(endTime).valueOf();
    if(startTime>currentTime){
        // '未开始'
        return 1
    }else if(endTime>currentTime&&currentTime>startTime){
        // '直播中'
        return 2
    }else if(currentTime>endTime){
        // '已结束'
        return 3
    }

}

export default {
    toLogin,uLiveState
}