<template>
  <!-- 主题内容 -->
  <section style="background: #F6F6F6;">
    <!-- 引入主页大图 -->
    <div class="big_img">
      <img src="../../../static/img/lzy_05_1.png" alt="" />
    </div>

    <div style="position:relative;">
      <!-- <div class="masking" v-if="show == true">
        <span>登陆后查看更多信息</span>
        <el-button type="primary" @click="gotoLogin">登录后查看</el-button>
      </div> -->
      <div data-v-22107272="" class="tabClass clearfix">
        <div
          v-for="(item, i) in tabs"
          :key="i"
          class="l tabItemClass"
          :class="{ activeTabClass: currClass == item.activeRouter }"
          @click="changecurrClass(item.activeRouter, item.ifreamScr)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="ifreambox">
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GovermentCenter",
  data() {
    return {
      currClass: "survey",
      tabs: [
        {
          index: 1,
          activeRouter: "survey",
          name: "上市概览",
          ifreamScr: "/govermentCenter/survey",
        },
        {
          index: 6,
          activeRouter: "finance",
          name: "融资统计",
          ifreamScr: "/govermentCenter/finance",
        },
        {
          index: 2,
          activeRouter: "areacount",
          name: "区域统计",
          ifreamScr: "/govermentCenter/areacount",
        },
        {
          index: 3,
          activeRouter: "industryCount",
          name: "行业统计",
          ifreamScr: "/govermentCenter/industryCount",
        },
        {
          index: 4,
          activeRouter: "coach",
          name: "上市辅导",
          ifreamScr: "/govermentCenter/coach",
        },
        // {
        //   index: 5,
        //   activeRouter: "reserve",
        //   name: "后备企业",
        //   ifreamScr: "/govermentCenter/reserve",
        // },
        {
          index: 6,
          activeRouter: "intest",
          name: "上市智测",
          ifreamScr: "/govermentCenter/intest",
        },
      ],
    };
  },
  mounted() {
    //   登录校验
    let userId = this.$store.state.userInfo;
    if (userId == null || userId.type == 2) {
      this.gotoLogin()
    }
    this.currClass = this.$route.meta.tabbar_active;
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login");
    },
    changecurrClass(survey, url) {
      this.currClass = survey;
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.big_img img {
  width: 100%;
  min-width: 1200px;
}
.masking {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 101%;
  background-color: white;
  z-index: 20;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.25);
  flex-direction: column;
}
.masking span {
  margin-bottom: 20px;
}
.tabClass {
  width: 1200px;
  margin: 0 auto;
  height: 60px;
  display: flex;
  align-items: center;
  /*border-bottom: 2px solid #e4e4e4;*/
  /*padding-bottom: 10px;*/
  /*margin-bottom: 20px;*/
}
.tabClass .activeTabClass {
  color: #1452b4;
  background: #f3f3f3;
  border-bottom: 2px solid #1452b4;
}

.tabClass .tabItemClass {
  font-size: 14px;
  margin-right: 10px;
  background: #ededed;
  padding: 8px 15px;
  color: #777;
  border-radius: 2px;
  cursor: pointer;
}

.tabClass .tabItemClass {
  font-weight: 700;
  position: relative;
  top: 2px;
}

.l {
  float: left;
}

.ifreambox {
  width: 100%;
  /*height: 110vh;*/
}
</style>
