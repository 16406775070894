import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import api from "./util/request/api";

Vue.prototype.$api = api

import filters from './util/filters'

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])//插入过滤器名和对应方法
})

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css';
Vue.use(Share)

Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
    store.getters.getStorage
    // if (to.matched.some(m => !m.meta.auto)) {
        // if (store.getters.getStorage) {
        //     next()
        // } else {
        //     Vue.prototype.$message.warning('检测到您还未登录,请登录后操作！')
        //     next('/login')
        // }
    // }
    next()

})

new Vue({
    router,
    store,
    render: h => h(App),
    mounted () {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')
