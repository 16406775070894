<template>
    <div>
        <div id="biao" class="w clearfix">
            <table id="whgao" class="w clearfix">
                <tr class="titletr">
                    <td colspan="7" style="text-align: center; padding: 25px 0px; ">
                        <h3>平台上市企业后备库</h3>
                    </td>
                </tr>
                <tr style="font-weight: bold;" class="leftjian">
                    <td style="width: 100px;">序号</td>
                    <td>企业名称</td>
                    <td>注册地</td>
                    <td>企业性质</td>
                    <td>所属行业</td>
                </tr>
                <tr v-for="(item,i) in records" :key="item.id">
                    <td>{{i+1}}</td>
                    <td>{{item.companyName}}</td>
                    <td>{{(item.companyProvince_dictText || '') + (item.companyCity_dictText || '') + (item.companyArea_dictText || '')}}</td>
                    <td>{{item.companyType_dictText}}</td>
                    <td>{{item.companyIndustryId}}</td>
                </tr>
            </table>

        </div>
        <div class="paginationbox">
            <el-pagination
                    background
                    :page-size="pageSize"
                    :current-page="form.pageNo"
                    @current-change="currentChange"
                    layout="prev, pager, next"
                    :page-count="pageCount">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AgencyTable",
        props:{
            form:{
                type:Object,
                default(){
                    return{}
                }
            }
        },
        data(){
            return {
                pageSize: 10, //每页显示数量
                pageCount: 1, //页数
                pageNo:1,
                records:[]
            }
        },
        mounted() {
            this.getMsg();
        },
        methods: {
            async getMsg() {
                let pageNo = this.pageNo;
                let res = await this.$api.houbeiCompany({...this.form,pageNo});
                this.pageCount = res.result.pages
                this.records = res.result.records
                console.log(res);
            },
            currentChange(e) {
                //页数改变
                this.pageNo = e
            }
        }
    }
</script>

<style scoped>

    #biao {
        border-top: 1px dotted #d9d9d9;
        font-size: 15px;
        color: #093e68;
        padding-bottom: 35px;
        border-bottom: 1px dotted #d9d9d9;
    }

    #biao .leftjian td {
        padding-left: 35px;
    }

    #whgao {
        text-align: left;
    }

    #whgao td {
        padding: 15px 35px;
    }

    #whgao tr:nth-child(2n-1) {
        background: #d8edfe;
    }

    #whgao .titletr {
        background: #fff !important;
    }

    .button-set {
        font-size: 12px;
    }

    #HRX .matop {
        padding-top: 47px;
    }

</style>