<template>
    <div id="lbs">
        <section>
            <div class="lbs1">
                <div class="w lbs2">
                    <span class="lbs3">上市培训</span>
                    <div class="lbs4">
                        <div class="lbs5">
                            <span>服务区域:</span>
                            <ul class="lbs55">
                                <li :class="{lbss1:currIndex ==0}"
                                    @click="Search('',0,1)">
                                    <a href="javascript:;">不限</a>
                                </li>
                                <li @click="Search(item.cityid,i+1,1)"
                                    :class="{lbss1:currIndex ==i+1}"
                                    v-for="(item,i) in cityList" :key="item.cityid">
                                    <a href="javascript:;">{{item.city}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="lbs6">
                            <span>活动时间:</span>
                            <ul class="lbs56">
                                <li :class="{lbss1:currIndex2 ==0}"
                                    @click="Search('',0,2)">
                                    <a href="###">不限</a>
                                </li>
                                <li @click="Search(item.id,i+1,2)"
                                    :class="{lbss1:currIndex2 ==i+1}"
                                    v-for="(item,i) in times" :key="item.id">
                                    <a href="###">{{item.name}}</a>
                                </li>
                            </ul>
<!--                            <input type="text" placeholder="请选择日期" id="date">-->
<!--                            <span class="icon-time">-->
<!--							<svg class="icon" aria-hidden="true">-->
<!--							  <use xlink:href="#iconrili"></use>-->
<!--							</svg>-->
<!--						</span>-->
                        </div>
<!--                        <div class="lbs7">-->
<!--                            <span>展开筛选条件</span>-->
<!--                        </div>-->
                        <div class="sc">
                            <input type="text" v-model="form.keyWord" placeholder="请输入关键字查询" class="sd">
                            <img src="../../static/img/lzy_03_2.png" @click="toSearch" style="width: 16px;cursor: pointer">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bs1 w">
                <div class="bs2">
                    <div class="bs3">
                        <span class="lbss3" :class="{'active':sort==0}" @click="changeSort('default')">默认排序</span>
                    </div>
                    <div class="bs4">
                        <span class="lbss3" :class="{'active':sort!=0}" @click="changeSort('time')">
                            <span>活动开始时间</span>
                            <img class="jiantou" :class="{'jiantouTop':sort==2}"
                                 src="../../static/img/pxdown.png" alt="">
                        </span>
                    </div>
                    <!-- <div class="bs5">
                        <div class="bs7">
                            <p>为您找到<span class="bs77">{{total}}</span>条结果</p>
                        </div>
                    </div> -->
                </div>
                <div class="bsss1">
                    <div class="bsss2">
                        <div class="bs13">
                            <ul>
                                <router-link tag="li" :to="'/detail/3/'+item.id"
                                v-for="(item,i) in dataList" :key="i">
                                    <div>
<!--                                        src="http://218.11.9.39/guquan/sys/common/static/temp/px1_1610526147283.png"-->
                                        <img class="imgboxccc" :src="item.image|imgUrl" />
                                    </div>
                                    <div class="bs14">
                                        <span class="bs15">已结束</span>
                                        <span class="bs16">{{item.time}}</span>
                                        <span class="bs17">{{item.tile}}</span>
                                    </div>
                                    <div class="bs18">
                                        <span>{{item.source}}</span>
                                    </div>
                                </router-link>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="paginationbox">
                    <el-pagination
                            background
                            :current-page="form.pageNo"
                            :page-size="pageSize"
                            @current-change="currentChange"
                            layout="prev, pager, next"
                            :page-count="pageCount">
                    </el-pagination>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "ListedTraining",
        data(){
            return {
                form:{
                    searcheTime:'',
                    city:'',
                    keyWord:'',
                    pageNo:1,
                    daoxu:'',
                    zhengxu:''
                },
                dataList:[],
                currIndex:0,
                currIndex2:0,
                times:[{id:1,name:'今天'},{id:2,name:'明天'},{id:3,name:'本周'},{id:4,name:'本月'}],
                cityList:[],
                sort:0,

                pageSize:10, //每页显示数量
                pageCount:1, //页数.
                total:0
            }
        },
        computed:{
        },
        mounted(){
            this.getMsg();
            this.getCity();
        },
        methods: {
            //daoxu不为空就是倒序，zhengxu不为空就是正序，都不为空或都为空就是默认。后台写的操蛋逻辑
            changeSort(type){ //default默认，time时间
                if(type=='default'){
                    this.sort = 0;
                    this.form.zhengxu = this.form.daoxu = '';
                } else if(type=='time'){
                    if(this.sort==1){
                        this.sort = 2;
                        this.form.zhengxu= '1';
                        this.form.daoxu = '';
                    }else{
                        this.sort = 1;
                        this.form.zhengxu= '';
                        this.form.daoxu = '1';
                    }
                }
                this.form.pageNo = 1;
                this.getMsg();
            },
            toSearch(){
                this.form.pageNo = 1;
                this.getMsg();
            },
            currentChange(e){
                //页数改变
                this.form.pageNo = e;
                this.getMsg();
            },
            async Search(val,index,type){
                if(type==1){
                    this.currIndex = index
                    this.form.city = val
                }else if(type==2){
                    this.currIndex2 = index;
                    this.form.searcheTime = val
                }
                this.form.pageNo = 1;
                this.getMsg();
            },
            async getMsg(){
                let res = await this.$api.radshowTran(this.form);
                this.dataList = res.result.records
                this.pageCount = res.result.pages
                this.total = res.result.total
                console.log(res);
            },
            async getCity(){
                let res = await this.$api.getCity({provinceId: 130000});
                this.cityList = res.result;
            }
        }
    }
</script>

<style scoped>
    .imgboxccc{
        width: 270px;
        height: 180px;
        object-fit: cover;
    }
    #lbs .lbs1{
        background-image: url(../../static/img/lbs1.gif);

        min-width: 1200px !important;
        width: 100%;
        /*height: 340px;*/
    }
    #lbs .lbs2{
        position: relative;
        height: 340px;
        padding-top: 100px;
        padding-left: 50px;
    }
    #lbs .lbs3{
        display: block;
        font-size: 46px;
        color: white;
    }
    #lbs .lbs4{
        width: 100%;
        position: absolute;
        top: 220px;
        left: 0px;
        background:#ffffff;
        /*height: 230px;*/
        border: 1px #dcdcdc solid;
        border-radius: 5px;
        padding-left: 15px;
        padding-right: 15px;
        box-shadow: 0px 5px 5px 0px #dcdcdc;
    }
    .lbs4 .sc {
        width: 224px;
        height: 38px;
        border-radius: 19px;
        border: 1px #cccccc solid;
        background-color: #fff;
        margin : 20px 0;
        margin-left: 918px;
        display: flex;
        align-items: center;
    }
    .lbs4 .sc .sd {
        width: 160px;
        height: 36px;
        margin-left: 24px;
        color: #b8b6b9;
        font-size: 15px;
        line-height: 36px;
    }
    #lbs .lbs5{
        height: 97px;
        width: 100%;
        border-bottom: 1px dashed #ebebeb;
        padding-left:5px ;
        padding-top: 50px;
        font-size: 14px;
        display: flex;

    }
    #lbs .lbs5 span,#lbs .lbs6 span{
        display: block;
        margin-right: 20px;
        height: 25px;
        width: 60px;
        line-height: 25px;
        color: #707271;
        flex-shrink: 0;
    }
    #lbs .lbs5 li,#lbs .lbs6 li{
        /*width: 55px;*/
        height: 25px;
        float: left;
        margin-left: 7px;
        margin-bottom: 5px;
        text-align: center;
        line-height: 25px;
        cursor: pointer;
    }
    #lbs .lbss1 a,#lbs .lbss2 a{
        color: #fffefa !important;
        background:#268eff;
        border-radius: 10px;
    }
    #lbs .lbs5 li a,#lbs .lbs6 li a{
        color: #1f1f1f;
    }
    #lbs .lbs5 span,#lbs .lbs5 ul,#lbs .lbs6 span,#lbs .lbs6 ul{
        float: left;
    }
    #lbs .lbs55 a, #lbs .lbs56 a{
        display: inline-block;
        /*width: 55px;*/
        padding: 0 15px;
        height: 25px;
    }
    #lbs .lbs6{
        position: relative;
        padding-left:5px ;
        padding-top: 20px;
        font-size: 14px;
        width: 100%;
        height: 70px;
        border-bottom: 1px dashed #ebebeb;
    }
    #lbs .lbs7{
        width: 100%;
        height: 65px;
        text-align: center;
        line-height: 65px;
        font-size: 14px;
    }
    #lbs .bs1{
        margin-top: 145px;
        /*height: 926px;*/
        border: 1px #dcdcdc solid;
        box-shadow: 2px 0px 2px 2px #dcdcdc;
        border-radius: 10px;
    }
    #lbs .bs2{
        width: 100%;
        height: 60px;
        border-bottom: 2px #e8e8e8 solid;
    }
    #lbs .bs3{
        float: left;
        font-size: 14px;
        text-align: center;
        line-height: 60px;
        width: 105px;
        height:100%;
        border-right:1px #e8e7ec solid  ;
    }
    #lbs .lbss3{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .jiantou{
        width: 23px;
        height: 23px;
        object-fit: contain;
    }
    .jiantouTop{
        transform: rotate(180deg);
    }
    #lbs .active{
        color: #007bff;
    }
    #lbs .bs4{
        line-height: 60px;
        border-right:1px #e8e7ec solid  ;
        text-align: center;
        font-size: 14px;
        width: 165px;
        float: left;
        color: #9c9c9e;
    }
    #lbs .bs5{
        line-height: 60px;
        border-right:1px #e8e7ec solid  ;
        font-size: 14px;
        float: left;
        width: 680px;
        height: 100%;
    }
    #lbs .bs6{
        width: 116px;
        height: 60px;
        float: left;
        margin-left: 40px;
    }
    #lbs .bs6 span{
        float: left;
        color: #7e7d79;
        margin-left: 8px;
    }
    #lbs .bs66{
        float: left;
        margin-top: 20px;
        height: 18px;
        width: 18px;
    }
    #lbs .bs7{
        color: #3e414a;
        float: right;
        margin-right: 10px;
    }
    #lbs .bs77{
        font-weight: bold;
    }
    #lbs .bs8{
        line-height: 60px;
        height: 60px;
        height: 100%;
    }
    #lbs .bs88{
        font-size: 14px;
        margin-left: 110px;
        float: left;
    }
    #lbs .bs11,#lbs .bs12{
        margin-left: 10px;
        width: 35px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border: 1px solid #ededed;
        background: #ffffff;
        cursor: pointer;

    }
    #lbs .bs12{
        border-left: none;
        margin-left: -4px;
    }
    #lbs .bs13{
        /*float: left;*/
        padding-top: 20px;
        padding-left: 25px;
        padding-bottom: 50px;
        /*height: 795px;*/
        width: 1200px;
    }
    #lbs .bs13 ul{
        /*height: 775px;*/
    }
    #lbs .bs13 li{
        margin-bottom: 40px;
        margin-right: 20px;
        float: left;
        border: 1px solid #ededed;
        width: 270px;
        height: 340px;
    }
    #lbs .bs13 li:hover{
        box-shadow: 0px 0px 10px 5px #999;
        cursor: pointer;
    }
    #lbs .b13 img{
        width: 100%;
        height: 180px;
    }
    #lbs .bs14{
        padding-top: 8px;
        padding-left:10px ;
        width: 100%;
        height: 125px;
        border-bottom: 1px solid #ededed;
    }
    #lbs .bs15{
        text-align: center;
        line-break: 20px;
        display: inline-block;
        font-size: 14px;
        width: 55px;
        height: 20px;
        background: #9a9a9a;
        border-radius: 10px;
        color: #fcf7fb;
    }
    #lbs .bs16{
        color: #afa3a3;
        display: inline-block;
        font-size: 12px;
        float: right;
    }
    #lbs .bs17{
        width: 228px;
        font-size: 15px;
        display: inline-block;
        margin-top: 30px;
        margin-left: 7px;
    }
    #lbs .bs18{
        color: #464646;
        line-height: 30px;
        font-size: 14px;
        padding-left: 15px;
    }
    #lbs section{
        /*height: 1413px;*/
        margin-bottom: 80px;
    }


    /*时间控件增加css*/
    #date{
        padding-left: 10px;
        margin-left: 120px;
        margin-top: -8px;
        position: relative;
        border:1px solid #ccc;
        width: 250px;
        height: 40px;
        float: left;
    }
    .icon-time{
        position: absolute;
        left: 734px;
        top: 20px;
    }
    .dateTimeWrap{
        position: absolute !important;
        /*background-color: #fff !important;
        border: 1px #ccc solid !important;*/
    }
    /*section 长容器*/
    .bsss1{
        overflow: hidden;
        position: relative;
        width: 1200px;
        /*height:1150px;*/
    }
    .bsss2{
        /*position: absolute;*/
        /*left: 0;*/
        /*width: 3600px;*/
        /*height: 795px;*/
        float: left;
        transition: left 1s;
    }

    .icon {
        width: 1em;
        height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
    .button-set > div:nth-child(1){
        color: #d0d0d2;
    }
    .button-set > div:nth-child(2){
        color: #333;
    }
    .button-set > div:nth-child(3){
        color: #d0d0d2;
    }
    .button-set > div:nth-child(4){
        color: #333;
    }

    .button-set > div:nth-child(6),
    div:nth-child(7),div:nth-child(9){
        cursor: default;
        border: 0px solid transparent !important;
    }
</style>
