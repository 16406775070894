<template>
    <div>
        <div class="left1">
            <ul class="title">
                <li :class="{'active':rongzitab==1}" @click="change(1)">首发融资</li>
                <li :class="{'active':rongzitab==2}" @click="change(2)">股权再融资</li>
                <li :class="{'active':rongzitab==3}" @click="change(3)">债券融资</li>
            </ul>
            <div class="dets">
                <div class="big_dets clearfix">
                    <div class="cons" id="news1">
                        <ul :class="{anim:animate==true}" v-if="listbox.length>0"
                            @mouseenter="moving=false" @mouseleave="moving=true">
                            <li v-for="(item,i) in listbox" :key="i" class="libox">
                                <a href="javascript:;" class="clearfix">
                                    <div class="child">
                                        <strong>{{item.name}}</strong>
                                        <p>{{item.num}}</p>
                                    </div>
                                    <div class="child">
                                        <span>{{item.date}}</span>
                                        <p class="time">{{item.dateType}}</p>
                                    </div>
                                    <div class="child">
                                        <span>{{item.money}}亿元</span>
                                        <p class="time">{{item.moneyType}}</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <dataNull v-else />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dataNull from "./dataNull";
    export default {
        name: "FinancingBox",
        data() {
            return {
                listbox: [],
                rongzitab: 1,
                //滚动效果
                animate: false,
                moving: true,
                interval:''

            }
        },
        mounted() {
            this.change(1);
        },
        methods: {
            //滚动代码
            showMarquee() {
                if (this.moving) {
                    this.animate = true;
                    setTimeout(() => {
                        this.listbox.push(this.listbox.shift())
                        this.animate = false;
                    }, 1000);
                }
            },
            async change(i) {
                //切换tab改变数据
                clearInterval(this.interval)
                this.rongzitab = i;
                let res;
                this.listbox = [];
                if (i == 1) {
                    res = await this.$api.ipoFinancing();
                    for(let item of res.result.records){
                        this.listbox.push({
                            name: item.financingName,
                            num: item.financingCode,
                            date: item.shangshiTime,
                            dateType: '上市时间',
                            money: item.financingPrice,
                            moneyType: '首发融资额'
                        })
                    }
                } else if (i == 2) {
                    res = await this.$api.ipoRefinancing();
                    for(let item of res.result.records){
                        this.listbox.push({
                            name: item.financingName,
                            num: item.financingCode,
                            date: item.shangshiTime,
                            dateType: "融资时间",
                            money: item.issueScale,
                            moneyType: "融资额"
                        })
                    }
                } else if (i == 3) {
                    res = await this.$api.ipoBond();
                    for(let item of res.result.records){
                        this.listbox.push({
                            name: item.bondName,
                            num: item.bondCode,
                            date: item.issueTime,
                            dateType: '融资时间',
                            money: item.issuePrice,
                            moneyType: "融资额"
                        })
                    }
                }
                console.log(res.result.records);
                if (this.listbox.length > 3) {
                    console.log("sdasdasd");
                    this.interval = setInterval(this.showMarquee, 2000);
                }
            }
        },
        components:{
            dataNull
        }
    }
</script>

<style scoped>
    .anim {
        transition: all 1s;
        transform: translateY(-123px);
    }

    .left1 {
        width: 587px;
        height: 428px;
    }

    .left1 .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .left1 .title li {
        font-size: 18px;
        width: 192px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        background-color: #f0f0f0;
        transition: all 0.3s;
        cursor: pointer;
    }

    .left1 .title li.active {
        height: 52px;
        line-height: 52px;
        background-color: #dc3338;
        color: #fff;
        font-size: 19px;
    }

    .left1 .dets {
        width: 587px;
        height: 375px;
        overflow: hidden;
    }

    .left1 .dets .big_dets {
        width: 1761px;
        height: 375px;
    }

    .left1 .dets .big_dets .cons {
        width: 587px;
        height: 375px;
        overflow: hidden;
        float: left;
        text-align: center;
        background: #fff;
    }

    .left1 .dets .big_dets .cons a {
        display: block;
        height: 123px;
        background-color: #fff;
        /*padding: 40px 50px 54px;*/
        color: #3ea9fb;
        font-size: 19px;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left1 .dets .big_dets .cons a:hover {
        background-color: #F0F0F0;
        transition: all 0.5s;
    }

    .left1 .dets .big_dets .cons a.li2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .left1 .dets .big_dets .cons a .child{
        font-size: 21px;
        flex: 1;
    }

    .left1 .dets .big_dets .cons a .child1 {
        /*margin-left: 120px;*/
    }
    .libox{
        border: 1px solid #d8edfe;
        box-sizing: border-box;
    }

    .left1 .dets .big_dets .cons a .time {
        color: #4c5053;
        font-size: 15px;
    }
</style>
