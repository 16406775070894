<template>
    <div class="support_banner">
<!--        <img v-if="imgg" :src="imgg.imgUrl|imgUrl"/>-->
<!--        轮播图数量大于1才显示箭头-->
        <el-carousel height="340px"
                     :arrow="imgUrlList.length<=1?'never':''"
                     :indicator-position="imgUrlList.length<=1?'none':''">
            <el-carousel-item v-for="(item,i) in imgUrlList" :key="item.id" >
                <a v-if="item.linkUrl" target="_blank" :href="item.linkUrl"><img :src="item.imgUrl|imgUrl"/></a>
                <router-link tag="a" :to="'/roadShowSwiperDetail/' +  item.id"
                             v-else-if="item.details&&positions==6">
                    <img :src="item.imgUrl|imgUrl"/>
                </router-link>
                <img v-else :src="item.imgUrl|imgUrl"/>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
    export default {
        name: "TopBannerBox",
        props:{
            positions:String,
        },
        data(){
            return {
                imgUrlList:[],
            }
        },
        computed: {
          imgg(){
              return this.imgUrlList[this.imgUrlList.length-1];
          }
        },
        mounted() {
            this.getBanner();
        },
        methods:{
            async getBanner(){
                let positions = this.positions
                let res = await this.$api.bannerImg({positions})
                this.imgUrlList = res.result;
            }
        }
    }
</script>

<style scoped>
    .support_banner {
        width: 100%;
        height: 340px;
    }

    .support_banner img {
        width: 100%;
        height: 100%;
        min-width: 1200px;
        object-fit: cover;
    }
</style>