<template>
    <div id="yyj">
        <section class="ss">
            <img src="../../static/img/yyj_tu5.png"/>
            <div class="sa w">
                <div class="xskc">线上课程</div>
                <div class="sb">
                    <span class="ff">课程类型:</span>
                    <a href="javascript:;"
                       :class="{Any:currIndex ==0}" @click="Search('',0)"
                    >不限</a>
                    <a href="javascript:;"
                       v-for="(item,i) in areas" :key="item.id"
                       :class="{Any:currIndex ==i+1}" @click="Search(item.id,i+1)"
                    >{{item.name}}</a>
                </div>
                <div class="sc">
                    <input type="text" v-model="form.keyWord" placeholder="请输入关键字查询" class="sd">
                    <img src="../../static/img/lzy_03_2.png"  @click="toSearch"  style="width: 16px;cursor: pointer">
                </div>
            </div>
        </section>
        <section class="aa w clearfix" style="margin-bottom:100px; margin-top: 250px;border: 1px #dbdbdb solid;border-radius:9px ;
	padding: 28px  22px 0 22px;box-shadow: 1px 1px 3px 1px #CCCCCC; ">
            <div class="ab clearfix">
                <router-link tag="div" :to="'/detail/4/'+item.id" class="ac"
                             v-for="(item,i) in dataList" :key="item.id">
                    <img class="imgboxaa" :src="item.onlineImgae|imgUrl"/>
                    <div class="ag line-1">{{item.title}}</div>
                </router-link>
            </div>
            <div class="paginationbox">
                <el-pagination
                        background
                        :current-page="form.pageNo"
                        :page-size="pageSize"
                        @current-change="currentChange"
                        layout="prev, pager, next"
                        :page-count="pageCount">
                </el-pagination>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "OnlineCourses",
        data(){
            return {
                form:{
                    projectTypeId:'',
                    keyWord:'',
                    pageNo:1,
					pageSize:12, //每页显示数量
                },
                dataList:[],
                currIndex:0,
                areas:[],

                pageCount:1, //页数
            }
        },
        mounted(){
            this.getMsg();
            this.getOptions();
        },
        methods: {
            currentChange(e){
                //页数改变
                this.form.pageNo = e;
                this.getMsg();
            },
            toSearch(){
                this.form.pageNo = 1;
                this.getMsg();
            },
            Search(val,index){
                this.currIndex = index;
                this.form.projectTypeId = val;
                this.form.pageNo = 1;
                this.getMsg();
            },
            async getMsg(){
                let res = await this.$api.radshowOnlineCourses(this.form);
                this.pageCount = res.result.pages
                this.dataList = res.result.records
                console.log(res);
            },
            async getOptions(){
                let res = await this.$api.projectDic({type:4});
                this.areas = res.result;
            }
        }
    }
</script>

<style scoped>
    /*body*/
    .imgboxaa{
        width: 270px;
        height: 180px;
        object-fit: cover;
    }
    span{
        display: inline-block;
    }
    #yyj{
        height: 100%;
        overflow-x: hidden;
        background-color: #ffffff;
    }

    #yyj .ss{
        width: 100%;
        height: 339px;
        background-image: url(../../static/img/yyj_tu5.png);
        margin-bottom: -100px;

    }
    #yyj .ss .sa{
        height: 235px;
        border: 1px #dcdcdc solid;
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 10px 0 0 38px;
        line-height: 35px;
        margin:0 auto;
        position: relative;
        bottom: 118px;
        box-shadow: 1px 1px 6px 1px #CCCCCC;

    }

    #yyj .sa a{
        border-radius: 18px;
        padding: 6px 14px;
        color:  #000000;
        font-size: 12px;
        margin-right: 10px;
        border: 1px solid #fff;
    }
    #yyj .sa a:hover{
        border: 1px solid #3494ff;
        color: #3494ff;
    }
    #yyj .sa a.Any{
        border: 1px solid #3494ff;
        color: #3494ff;
    }
    #yyj .xskc{
        font-size: 45px;
        color: #FFFFFF;
        position: relative;
        bottom: 110px;
        left: 10px;
    }


    #yyj .ss .sa .sb{
        width: 1141px;
        height: 82px;
        background-color: #fff;
        border-bottom: 2px #f1f1f1 dashed;
        text-shadow: 0px 0px 2px #CCCCCC;
    }
    #yyj .ff{
        font-size: 13px;
        color: #676568;
        margin-right: 20px;

    }
    #yyj .fa{
        font-size: 13px;
        color: #3494ff;
        padding: 0px 13px 0px 13px;
        border: 1px #3494ff solid;
        border-radius: 18px;
        margin-left: 18px;
    }
    #yyj .fb:hover{
        color: #3494ff;
        padding: 0px 13px 0px 13px;
        border: 1px #3494ff solid;
    }
    #yyj .fb{
        padding: 0px 13px 0px 13px;
        border: 1px #FFFFFF solid;
        border-radius: 18px;
        min-width: 60px;
        font-size: 13px;
        color: #373923;
        margin-left: 10px;

    }
    #yyj .fc{
        font-size: 13px;
        color: #373923;
        margin-left: 10px;
        padding: 0px 13px 0px 13px;
        border: 1px #FFFFFF solid;
        border-radius: 18px;
    }
    #yyj .fc:hover{
        color: #3494ff;
        padding: 0px 13px 0px 13px;
        border: 1px #3494ff solid;
    }
    #yyj .fd{
        margin-right: 10px;
        color: #000000;

    }
    #yyj .skip{
        font-size: 12px;
        color: #373923;
        margin-left: 5px;
        margin-right: 10px;
    }
    #yyj .ss .sa .sc{
        width: 224px;
        height: 38px;
        border-radius: 19px;
        border: 1px #cccccc solid;
        background-color: #fff;
        margin-top: 20px;
        margin-left: 918px;
    }
    #yyj .ss .sa .sc .sd{
        width: 144px;
        height: 33px;
        margin-left: 24px;
        color: #b8b6b9;
        font-size: 15px;
    }

    #yyj .icon{
        color: #000000;
        width: 17px;
        height: 17px;
        position: relative;
        right: -10px;
    }

    .se{
        margin-left: 146px;
        font-size: 12px;
        color: #373923;
        padding: 0px 13px 0px 13px;
        border: 1px #FFFFFF solid;
        border-radius: 18px;

    }

    .se:hover{
        color: #3494ff;
        padding: 0px 13px 0px 13px;
        border: 1px #3494ff solid;
    }




























    /*图片最大边框*/
    #yyj .aa{
        margin-top: 600px;
        /*height: 932px;*/
        border: 1px #dbdbdb solid;
        border-radius:9px ;
        padding: 28px  22px;
        min-width: 1200px;
    }
    /*存放一行图片的大边框*/
    #yyj .aa .ab .ac,.ad,.ae,.af{
        width: 274px;
        height: 249px;
        float: left;
        border: 1px #f0f0f0 solid;
        margin-bottom: 22px;
    }
    #yyj .aa .ab .ac:nth-child(4n){
        margin-right: 0;
    }



    #yyj .aa .ab .ac img{
        transition: all 0.6s;
    }
    #yyj .aa .ab .ad img{
        transition: all 0.6s;
    }
    #yyj .aa .ab .ae img{
        transition: all 0.6s;
    }
    #yyj .aa .ab .af img{
        transition: all 0.6s;
    }


    #yyj .aa .ab .ac img:hover{
        opacity: 0.7;
        cursor: pointer;
    }
    #yyj .aa .ab .ad img:hover{
        opacity: 0.7;
        cursor: pointer;
    }
    #yyj .aa .ab .ae img:hover{
        opacity: 0.7;
        cursor: pointer;
    }
    #yyj .aa .ab .af img:hover{
        opacity: 0.7;
        cursor: pointer;
    }



    #yyj .aa .ab .ac,.ad,.ae{
        margin-right: 19px;
    }

    #yyj .aa .ab .ac .ag{
        width: 274px;
        height: 63px;
        padding: 17px 23px 0 10px;
        font-size: 15px;
        color: #79737f;
    }
    #yyj .aa .ab .ad .ah{
        width: 274px;
        height: 63px;
        padding: 17px 23px 0 10px;
        font-size: 15px;
        color: #79737f;
    }
    #yyj .aa .ab .ae .ai{
        width: 274px;
        height: 63px;
        padding: 17px 23px 0 10px;
        font-size: 15px;
        color: #79737f;
    }
    #yyj .aa .ab .af .aj{
        width: 274px;
        height: 63px;
        padding: 17px 23px 0 10px;
        font-size: 15px;
        color: #79737f;
    }
</style>
