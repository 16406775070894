<template>
    <div>
        <div class="ppt"></div>
        <!--导航条-->
        <div class="details_nav w">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/roadShow' }">培训路演</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/training' }">上市路演</el-breadcrumb-item>
                <el-breadcrumb-item>企业详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="boxcontain">
            <div>
                <div class="leftlist">
                    <div class="tarbox"
                         v-for="(item,i) in msg" :key="i"
                         :class="{active:currenti == i}"
                         @click="currenti = i"
                    >{{item.radshowCompany}}</div>
                </div>
            </div>
            <div class="rightList">
                <div class="rightbox" v-html="msg[currenti].details">
<!--                    <div class="innerbox">-->
<!--                        <div class="boxtitle">项目介绍</div>-->
<!--                        <div class="boxcont">-->
<!--                            工业物联网（智慧轧辊精细管理系统）解决方案：系统通过前端感知设备（工业智能传感器、定位基站、数传基站等）采集轧辊轧制量、轧制工作环境、轧辊工作状态趋势等数据，经数据清洗、分类和筛选后向应用层提供大数据检索支撑服务，便于轧辊企业对轧辊生产、运输流转、生产加工、报废回收全生命周期跟踪管理，为轧辊租赁定价及轧辊生产工艺改进提供可靠决策数据；便于钢铁企业快速查找轧辊（定位）、轧辊配对、轧辊备辊计划、轧辊工作状态预警及人员定位管理，实现全程自动化管理，提高工作效率。物联网传感器的特点：1、精准定位：通过特殊的产品部署方式，综合蓝牙测距、UWB定位，以及智能传感器行为判断等信息进行综合研判，实现在钢厂环境下10cm的定位精度。2、适用恶略环境：采用IP69防护等级可以在高温、高湿、高粉尘的恶劣工况下使用,工作温度-20~60°，85°环境标签不会损坏;3、电力自持：可不充电自持电力18个月；4、商业价值：全面收集轧辊全生命周期的精准位置、转速等数据，为优化生产工艺，提高生产效率、提高产品质量、降低生产成本提供强有力的数据支撑，也为轧辊的“全线总包、配套供给、租赁使用、功能计价”新型商业模式。-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="innerbox">-->
<!--                        <div class="boxtitle">基本信息</div>-->
<!--                        <div class="boxcont">-->
<!--                            <p>公司成立于1999年，注册资本3257.8万元。公司面向政府公共安全领域聚焦政法信息化整体解决方案+工业物联网云管理平台及传感器设计制造做顶层设计和战略部署，研发打造集顶端全智能管理平台、前端智能采集设备、后端大数据分析及挖掘为一体的全方位产品生态圈，以集成项目的形式推动产品落地应用。公司先后荣获河北省高新技术企业、河北省软件企业、ISO/IEC27001信息安全管理体系认证、河北省安防系统一级资质等并拥有软件著作权证书（母子公司合计）58项，拟申请实用新型专利2项，发明专利2项。</p>-->
<!--                            <table>-->
<!--                                <tbody>-->
<!--                                <tr>-->
<!--                                    <td>企业名称：</td>-->
<!--                                    <td>河北瑞普通信技术股份有限公司</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>工商注册号：</td>-->
<!--                                    <td>91130501713144450D</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>法定代表人：</td>-->
<!--                                    <td>张树甫</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>成立时间：</td>-->
<!--                                    <td>1999-03-01</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>注册资本：</td>-->
<!--                                    <td>3258万元</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>所属行业：</td>-->
<!--                                    <td>系统集成,物联网</td>-->
<!--                                </tr>-->
<!--                                <tr>-->
<!--                                    <td>网址：</td>-->
<!--                                    <td>http://www.rpkj.com/</td>-->
<!--                                </tr>-->
<!--                                </tbody>-->
<!--                            </table>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "roadShowDetail",
        data(){
            return {
                currenti:0,
                tarbarList:['河北瑞普通信技术股份有限公司','明盾技术河北有限公司','邢台众力汽车配套有限公司','邢台纳科诺尔精轧科技股份有限公司','河北道荣新能源科技有限公司','河北旺多牧业股份有限公司'],
                msg:'',
            }
        },
        mounted() {
            this.radshowCompany();
        },
        methods: {
            //参加路演企业列表查询
            async radshowCompany(){
                let radshowNumberId = this.$route.params.id   //获取详情id
                let res = await this.$api.radshowCompany({radshowNumberId});
                this.msg = res.result;
            },
        }
    }
</script>
<style scoped src="../../static/css/service.css"></style>
<style scoped>
    .leftlist {
        width: 322px;
    }
    .leftlist .tarbox {
        font-size: 16px;
    }
    .leftlist .active {
        font-size: 19px;
    }
    .innerbox:first-child .boxtitle {
        margin-top: 0;
    }
    .innerbox table {
        width: 100%;
        background-color: #fff;
        margin: 20px 0 0;
    }
    .innerbox table tr {
        width: 100%;
        border: 1px solid #ccc;
    }
    .innerbox table tr td {
        border: 1px solid #ccc;
        padding-left: 10px;
        box-sizing: border-box;
    }
    .rightbox{

    }
</style>
