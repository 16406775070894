<template>
  <div id="declare">
    <!-- banner -->
    <div class="banner">
      <img src="../../static/img/xq.jpg" />
    </div>
    <div class="container">
      <!--导航条-->
      <div class="details_nav w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/policy' }"
            >工作动态</el-breadcrumb-item
          >
          <el-breadcrumb-item>动态详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="content">
        <div class="title">上市企业补贴申请表</div>
        <!-- <div class="masking" v-if="show == true">
          <span>登陆后查看更多信息</span>
          <el-button type="primary" @click="gotoLogin">登录后查看</el-button>
        </div> -->
        <table>
          <tr>
            <td class="act">补贴名称</td>
            <td colspan="3" style="background-color: #f6f6f6">
              <el-select
                style="width: 100%"
                v-model="form.policy"
                placeholder="请选择"
                @change="handleCommissioer"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="act">统一信用代码</td>
            <td colspan="3">
              <input
                v-model="form.creditCode"
                type="text"
                placeholder="请填写统一信用代码"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td class="act">企业名称</td>
            <td colspan="3">
              <input
                v-model="form.companyName"
                type="text"
                placeholder="请填写企业名称"
                disabled
              />
            </td>
          </tr>
          <tr>
            <td class="act">注册地址</td>
            <td colspan="3">
              <input
                v-model="form.ascriptionAddress"
                type="text"
                placeholder="请填写注册地址"
              />
            </td>
            <!-- <div> -->
            <!--                        //#region-->
            <!--                        <td colspan="3">-->
            <!--                            <div  class="areabox">-->
            <!--                                <div class="dizhi da">-->
            <!--                                    <select v-model="form">-->
            <!--                                        <option>省</option>-->
            <!--                                    </select>-->
            <!--                                    <img src="../../static/img/xiala.png">-->
            <!--                                </div>-->
            <!--                                <div class="dizhi da">-->
            <!--                                    <select>-->
            <!--                                        <option>市</option>-->
            <!--                                    </select>-->
            <!--                                    <img src="../../static/img/xiala.png">-->
            <!--                                </div>-->
            <!--                                <div class="dizhi da">-->
            <!--                                    <select>-->
            <!--                                        <option>区</option>-->
            <!--                                    </select>-->
            <!--                                    <img src="../../static/img/xiala.png">-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </td>-->
            <!--                        //#endregion-->
            <!-- </div> -->
          </tr>
          <tr>
            <td class="act">归属地址</td>
            <td colspan="3" style="background-color: #fff">
              <div class="citys">
                <div class="dizhi">
                  <el-select
                    v-model="nowCity"
                    style="width:100%;border:none;"
                    placeholder="市级"
                  >
                    <el-option
                      v-for="item in citys"
                      :key="item.id"
                      :label="item.city"
                      :value="item.cityid"
                    >
                      {{ item.city }}
                    </el-option>
                  </el-select>
                </div>
                <div class="dizhi" style="margin-left:10px;">
                  <el-select
                    style="width:100%;"
                    placeholder="区级"
                    v-model="nowArea"
                  >
                    <el-option
                      v-for="item in citysChilds"
                      :key="item.areaid"
                      :label="item.area"
                      :value="item.areaid"
                    >
                      {{ item.area }}
                    </el-option>
                  </el-select>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="act">法人</td>
            <td>
              <input
                v-model="form.legalPerson"
                type="text"
                placeholder="请填写法人"
              />
            </td>
            <td class="act">手机或固定电话</td>
            <td>
              <input
                v-model="form.legalPersonPhone"
                type="text"
                placeholder="请填写手机或固定电话"
              />
            </td>
          </tr>
          <tr>
            <td rowspan="2" class="act">联系人</td>
            <td rowspan="2">
              <input
                v-model="form.contacts"
                type="text"
                placeholder="请填写联系人"
              />
            </td>
            <td class="act">手机或固定电话</td>
            <td>
              <input
                v-model="form.contactsPhone"
                type="text"
                placeholder="请填写手机或固定电话"
              />
            </td>
          </tr>
          <tr>
            <td class="act" style="background-color: #e6f1f7">E-mail</td>
            <td>
              <input
                v-model="form.contactsMail"
                type="text"
                placeholder="请填写联系人邮箱	"
              />
            </td>
          </tr>
          <tr>
            <td class="act">银行基本户(此处请填写开户银行名称)</td>
            <td colspan="3">
              <input
                v-model="form.bankDeposit"
                type="text"
                placeholder="请填写银行基本户"
              />
            </td>
          </tr>
          <tr>
            <td class="act">银行账号</td>
            <td colspan="3">
              <input
                v-model="form.bankCard"
                type="text"
                placeholder="请填写银行账号"
              />
            </td>
          </tr>
          <tr>
            <td class="act">申报项目内容</td>
            <td colspan="3">
              <input
                type="text"
                placeholder="请填写申报项目内容"
                v-model="form.evidence"
              />
            </td>
          </tr>
          <tr>
            <td>证明材料</td>
            <td
              colspan="3"
              style="background-color: #fff; color: #848484; font-weight: 400"
            >
              见下方区域
            </td>
          </tr>
          <tr>
            <td class="act">申请奖励金额(元)</td>
            <td colspan="3">
              <input
                v-model="form.declareReward"
                type="text"
                placeholder="请填写申请奖励金额"
              />
            </td>
          </tr>
          <tr style="display:none;">
            <td>核定奖励金额(元)</td>
            <td
              colspan="3"
              style="background-color: #fff; color: #848484; font-weight: 400"
            >
              此处由审核部门填写
            </td>
          </tr>
          <tr>
            <td>备注</td>
            <td colspan="3">
              <textarea
                v-model="form.remarks"
                placeholder="若有补充请填写备注,不超过200字"
                maxlength="200"
              ></textarea>
            </td>
          </tr>
        </table>

        <div class="title" style="text-align: left; margin: 0">
          请在下方区域上传相关文件
        </div>
        <div class="fileCon">
          <el-upload
            class="upload-demo"
            :action="action"
            :on-change="handleChange"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
        <p>
          各申报主体要准确填写银行基本户和银行账号信息，否则造成奖补资金拨付不到位的后果由申报主体自行承担。
        </p>
        <div class="btnCon">
          <!-- <el-button type="warning">暂存</el-button> -->
          <el-button @click="submit" type="primary">提交</el-button>
          <!-- <el-button type="info">返回</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "../../util/request/base";
export default {
  data() {
    return {
      action: base.uploadFile,
      fileList: [],
      // show: true,
      form: {
        policy: "",
        creditCode: "",
        companyName: "",
        ascriptionAddress: "",
        companyCity: "",
        companyArea: "",
        legalPerson: "",
        legalPersonPhone: "",
        contacts: "",
        contactsMail: "",
        bankDeposit: "",
        bankCard: "",
        evidence: "",
        declareReward: "",
        remarks: "",
        applyEnclosure: "",
        userId: "",
      },
      wenjian: [],
      show1: false,
      nowCity: "",
      nowArea: "",
      citys: [],
      options: [],
      value: "",
      citysChilds: [],
    };
  },
  mounted() {
    this.form.policy = this.$route.params.id; //获取详情id
    this.applyspolicy();
    this.getCitys(); //获取城市列表
    let userId = this.$store.state.userInfo;

    if (userId == null) {
      this.$alert("请登录后再试", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.gotoLogin();
        },
      });
    } else {
      // 获取用户信息
      this.form.companyName = userId.companyName;
      this.form.creditCode = userId.companyCode;
      this.form.userId = userId.id;
    }
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login");
    },
    async getCitysChild(city) {
      let res = await this.$api.getArea({ cityId: city });
      this.citysChilds = res.result;
      // console.log(this.citysChilds);
    },
    async getCitys() {
      let res = await this.$api.getCity({ provinceId: 130000 });
      this.citys = res.result;
    },
    async applyspolicy() {
      let res = await this.$api.applyspolicy();
      for (let item of res.result) {
        this.options.push({
          value: item.id,
          label: item.title,
        });
      }
    },
    // 补贴名称的change事件
    handleCommissioer(label) {
      for (var i = 0; i < this.options.length; i++) {
        if (this.options[i].value == label) {
          this.form.evidence = this.options[i].label;
          this.form.policy = this.options[i].value;
        }
      }
    },
    // 上传文件
    handleChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        file = null;
        fileList = [];
        this.$message.error("上传文件大小不能超过 20MB");
      } else {
        this.fileList = fileList.slice(-3);
        if (file.response) {
          if (this.form.applyEnclosure.length > 0) {
            this.form.applyEnclosure += "," + file.response.message;
          } else {
            this.form.applyEnclosure += file.response.message;
          }
        }
      }
    },
    async submit() {
      this.form.companyCity = this.nowCity;
      this.form.companyArea = this.nowArea;
      if (this.form.policy == "") {
        this.$message({
          message: "请选择补贴名称",
          center: true,
          type: "warning",
        });
        return false;
      } else if (this.form.ascriptionAddress == "") {
        this.$message({
          message: "注册地址不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.companyCity == "") {
        this.$message({
          message: "归属地址市级不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.companyArea == "") {
        this.$message({
          message: "归属地址区级不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.legalPerson == "") {
        this.$message({
          message: "法人不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.legalPersonPhone == "") {
        this.$message({
          message: "法人手机或固定电话不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.contacts == "") {
        this.$message({
          message: "联系人不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.contactsPhone == "") {
        this.$message({
          message: "联系人手机或固定电话不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.bankDeposit == "") {
        this.$message({
          message: "银行基本户不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.bankCard == "") {
        this.$message({
          message: "银行账号不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else if (this.form.declareReward == "") {
        this.$message({
          message: "申请奖励金额不能为空",
          center: true,
          type: "warning",
          offset: 250
        });
        return false;
      } else {
        let res = await this.$api.applys({ ...this.form });
        if (res.success) {
          this.$message({
            message: res.message,
            type: "success",
            center: true,
            offset: 250,
            onClose: () => {
              this.$router.push("/");
            },
          });
        }
      }
    },
  },
  watch: {
    nowCity: {
      handler(data) {
        //do something
        this.getCitysChild(data);
        // console.log(data);
      },
    },
  },
};
</script>

<style scoped>
.citys {
  display: flex;
}
.areabox {
  width: 100%;
  display: flex;
  align-items: center;
}

#declare {
  width: 100%;
}

.banner {
  width: 100%;
}

.banner img {
  width: 100%;
  height: auto;
}

.container {
  width: 1200px;
  margin: auto;
}

/* 面包屑 */
.breadBox {
  width: 100%;
  margin: 10px 0;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.breadBox li {
  color: #333;
  font-size: 15px;
}

.content {
  width: 100%;
  position: relative;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 23px;
  color: #000;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 60px 0 10px;
}
.masking {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 101%;
  background-color: white;
  z-index: 20;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.25);
  flex-direction: column;
}
.masking span {
  margin-bottom: 20px;
}
table {
  width: 100%;
  border: 1px solid #ccc;
}

table tr {
  width: 100%;
  height: 40px;
}

table tr td {
  text-align: left;
  font-size: 16px;
  color: #000000;
  border: 1px solid #ccc;
  background-color: #e6f1f7;
  font-weight: 800;
  padding-left: 10px;
}

table tr td:first-child {
  width: 200px;
  padding-left: 10px;
  box-sizing: border-box;
  background-color: #fff;
  font-weight: 400;
}

.act:after {
  content: "*";
  font-size: 16px;
  color: red;
}

table tr td input,
table tr td textarea {
  width: 100%;
  height: 40px;
  background-color: #e6f1f7;
  font-size: 16px;
  color: #000;
  font-weight: 800;
}

table tr td textarea {
  height: 100px;
}

table tr td input::placeholder,
table tr td textarea::placeholder {
  font-weight: 400;
}

table tr td .dizhi {
  width: 480px;
  height: 40px;
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  position: relative;
}

table tr td select {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: none;
}

table tr td .dizhi img {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 10px;
}

/* 上传文件 */
.fileCon {
  width: 100%;
  min-height: 240px;
  border: 1px dashed #247bb0;
  margin: 10px 0 22px;
  padding: 20px;
  box-sizing: border-box;
}

p {
  font-size: 15px;
  color: #646464;
  text-align: center;
}

.btnCon {
  width: 100%;
  margin: 20px 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  width: 125px;
  /*height: 36px;*/
  /*border-radius: 5px;*/
  /*color: #fff;*/
  margin: 0 10px;
  font-size: 16px;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*background-color: #2695fe;*/
}

.mm {
  width: 125px;
  height: 36px;
  border-radius: 5px;
  position: relative;
}

.mm input {
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0;
  height: 100%;
}

.showFile {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.showFile li {
  width: 60%;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 5px 0;
}

.showFile li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.showFile li span {
  font-size: 16px;
  color: #000000;
}

.da {
  width: 33.3% !important;
}
</style>
