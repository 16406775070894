<template>
    <div class="contbox">
        <CenterTab2 :style="style" :tab-list="tabList" @change="changeTab"></CenterTab2>
        <div class="bbinnerCon">
            <div class="bbinnerbox zc_list">
                <div class="bbinners" v-for="(item,i) in list1" :key="i">
                    <router-link tag="a" :to="'/uliveDetail/' + item.rsdshowLineId">
                        <div class="bbinnertit">{{item.rsdshowLineId_dictText}}</div>
                    </router-link>
                    <div class="bbinnerdate">{{item.createTime}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CenterTab2 from "../../../components/CenterTab2";
    export default {
        name: "EventRegistration",
        data(){
            return {
                tabList:[
                    {id:1,label:'已报名'},
                    {id:2,label:'未报名'},
                    // {id:3,label:'已结束'},
                ],
                style:{width:'100%'},
                list1: []
            }
        },
        mounted() {
            this.getMsg();
        },
        computed:{
            userId(){
                return this.$store.state.userInfo.id;
            }
        },
        methods: {
            changeTab(e){
                // console.log(e);
            },
            async getMsg(){
                let userId = this.userId;
                let res = await this.$api.radshowSigup({userId});
                // console.log(res)
                this.list1 = res.result.records;
            }
        },
        components: {
            CenterTab2
        }
    }
</script>

<style scoped>
    .contbox{
        width: 100%;
        background-color: #fff;
    }
    .bbinnerbox .bbinners{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
        padding: 0 46px;
    }
    .bbinnerbox .bbinners .bbinnertit{
        font-size: 16px;
        color: #363636;
    }
    .bbinnerbox .bbinners .bbinnerdate{
        font-size: 16px;
        color: #9D9D9D;
    }
</style>