<template>
	<div id="zjj_index">
		<div class="banner">
			<!--    <img src="img/zjj_index_banner.png"/>-->
			<el-carousel height="537px">
				<el-carousel-item v-for="(item,i) in bannerList" :key="item.id">
					<a v-if="item.linkUrl" target="_blank" :href="item.linkUrl"><img :src="item.imgUrl|imgUrl" /></a>
					<img v-else :src="item.imgUrl|imgUrl" />
				</el-carousel-item>
			</el-carousel>
		</div>
		<section id="section1" class="w">
			<div class="bottom">
				<div class="h1box">
					<h2 :class="{'active':toptab==2}" @click="changeToptab(2)">工作动态</h2>
					<h2 :class="{'active':toptab==1}" @click="changeToptab(1)">扶持政策</h2>
				</div>
				<div class="content">
					<div class="contentinbox">
						<ul class="left" v-if="toptabList.length>0">
							<li v-for="(item,i) in toptabList" :key="item.id">
								<div class="zcasbox">
									<span class="icon"></span>
									<router-link tag="a" :to="'/detail/1/'+item.id" v-if="toptab==2" class="fonts">
										{{item.title}}
									</router-link>
									<router-link tag="a" :to="'/detail/2/'+item.id" v-else class="fonts">
										{{item.title}}
									</router-link>
								</div>
								<p class="text-right">{{item.releaseTime}}</p>
							</li>
							<router-link tag="a" to="/workNews" class="fr more leftaa" v-if="toptab==2">更多动态>>
							</router-link>
							<router-link tag="a" to="/policy" class="fr more leftaa" v-else>更多政策>></router-link>
						</ul>
						<dataNull v-else />
					</div>

					<div class="right">
						<router-link tag="a" to="/CompanyService/1349914829750788098" class="imgs">
							<img src="../../static/img/zfzj_01.png" class="margin" />
						</router-link>
						<router-link tag="a" to="/roadShow" class="imgs"><img
								src="../../static/img/zjj_index_img3.png" /></router-link>
						<router-link to='/companyLibrary' class="imgs" style="margin-top:31px;"><img
								src="../../static/img/ssfw_t.jpg" /></router-link>


					</div>
				</div>
			</div>
		</section>
		<section id="section2">
			<div class="chanpin w">
				<h2>投融资动态</h2>
				<div class="content1">
					<FinancingBox></FinancingBox>
					<div class="right1">
						<div class="bg1">
							<p class="ll">
								<!-- <span class="jr_icon">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#iconjinrong"></use>
                                </svg>
                            </span> -->
								融资服务
							</p>
							<p class="service">提供稳定、高效、便捷的金融服务</p>
							<router-link tag="a" to="/mosService" class="go_on">立即前往 ></router-link>
						</div>
						<div class="bg2">
							<p class="ll">
								<!-- <span class="jr_icon">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#iconfaqishenqing"></use>
                                </svg>
                            </span> -->
								融资意向
							</p>
							<router-link to="/StartApply" tag="a" class="go_on orange">立即前往 ></router-link>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="section3">
			<div class="sec3_container w">
				<mapCpn></mapCpn>
				<div class="data" id="asdd" v-cloak>
					<div>
						<h2>
							<div class="sbznbox">
								<p @click="showtabb=1" :class="{'active':showtabb==1}">上市数据</p>
								<p @click="showtabb=2" :class="{'active':showtabb==2}">融资数据</p>
							</div>
						</h2>
						<ul class="data_dets" v-show="showtabb==1">
							<li class="clearfix">
								<div class="left fl">
									<img src="../../static/img/zjj_index_img6.png" width="73px" height="81px" />
									<div class="num">
										<strong>{{coachNum.shizhi}}</strong>
										<p>沪深京上市总市值（亿元）</p>
									</div>

								</div>
								<div class="right fr">
									<img src="../../static/img/zjj_index_img7.png" />
									<div class="num">
										<strong>{{coachNum.listCount}}</strong>
										<p>沪深京上市企业数</p>
										<!-- <a href="##" @click="openTable(1)">详情</a><span>></span> -->
									</div>

								</div>
							</li>
							<li class="clearfix">
								<div class="left fl">
									<img src="../../static/img/zjj_index_img8.png" width="73px" height="81px" />
									<div class="num">
										<strong>{{coachNum.jingwai}}</strong>
										<p>境外上市企业数</p>
										<!-- <a href="##" @click="openTable(9)">详情</a><span>></span> -->
									</div>

								</div>
								<div class="right fr">
									<img src="../../static/img/zjj_index_img9.png" />
									<div class="num">
										<strong>{{coachNum.thirdCount}}</strong>
										<p>新三板企业数</p>
										<!-- <a href="##" @click="openTable(2)">详情</a><span>></span> -->
									</div>

								</div>
							</li>
							<li class="clearfix">
								<div class="left fl">
									<img src="../../static/img/zjj_index_img8.png" width="73px" height="81px" />
									<div class="num">
										<strong>{{coachNum.sjsguapai}}</strong>
										<p>河交所挂牌数</p>
										<!-- <a href="##" @click="openTable(10)">详情</a><span>></span> -->
									</div>

								</div>
								<div class="right fr">
									<img src="../../static/img/zjj_index_img9.png" />
									<div class="num">
										<strong>{{coachNum.trailCount}}</strong>
										<p>在审企业数</p>
										<!-- <a href="##" @click="openTable(3)">详情</a><span>></span> -->
									</div>

								</div>
							</li>
							<li class="clearfix">
								<div class="left fl">
									<img src="../../static/img/zjj_index_img10.png" width="73px" height="81px" />
									<div class="num">
										<strong>{{coachNum.coachCount}}</strong>
										<p>在辅导企业数</p>
										<!-- <a href="##" @click="openTable(4)">详情</a><span>></span> -->
									</div>

								</div>
								<!-- <div class="right fr">
                                    <img src="../../static/img/zjj_index_img11.png"/>
                                    <div class="num">
                                        <strong>{{coachNum.houbei}}</strong>
                                        <p>后备企业数</p>
                                        <a href="##" @click="openTable(5)">详情</a><span>></span>
                                    </div>

                                </div> -->
							</li>

						</ul>
						<ul class="data_dets" v-show="showtabb==2">
							<li class="clearfix">
								<div class="left fl">
									<img src="../../static/img/zjj_index_img6.png" width="73px" height="81px" />
									<div class="num">
										<strong>{{ipoData.iIpoFinancing}}</strong>
										<p>首发融资总额(亿元)</p>
										<!-- <a href="##" @click="openTable(6)">详情</a><span>></span> -->
									</div>

								</div>
								<div class="right fr">
									<img src="../../static/img/zjj_index_img7.png" />
									<div class="num">
										<strong>{{ipoData.ipoRefinancing}}</strong>
										<p>股权再融资总额(亿元)</p>
										<!-- <a href="##" @click="openTable(7)">详情</a><span>></span> -->
									</div>

								</div>
							</li>
							<li class="clearfix">
								<div class="left fl">
									<img src="../../static/img/zjj_index_img8.png" width="73px" height="81px" />
									<div class="num">
										<strong>{{ipoData.ipoBond}}</strong>
										<p>债券融资总额(亿元)</p>
										<!-- <a href="##" @click="openTable(8)">详情</a><span>></span> -->
									</div>

								</div>
							</li>
							<p style="margin-top:140px;font-size:14px;">注：融资数据统计起始日期：2020-01-01；</p>
							<p style="font-size:14px">数据来源于沪深京交易所、港交所、新三板等交易场所数据</p>
						</ul>
						<!-- <h5>数据统计来源：河北辖区证券期货市场概况</h5> -->
						<HomeTableBox ref="table"></HomeTableBox>
					</div>

				</div>
			</div>
		</section>
		<FriendLink></FriendLink>
	</div>
</template>

<script>
	import "swiper/dist/css/swiper.min.css"
	import FinancingBox from "../../components/FinancingBox";
	import FriendLink from "../../components/FriendLink";
	import dataNull from "../../components/dataNull";
	import mapCpn from "../../components/mapCpn";
	import HomeTableBox from "./HomeTableBox";

	export default {
		name: "Home",
		data() {
			return {
				toptab: 2,
				showtabb: 1,
				toptabList: [],
				dataName: 'listedCompany',
				coachNum: {}, //公司总数
				ipoData: {},
				bannerList: [],
			}
		},
		mounted() {
			this.getNews();
			this.getinitDatas();
		},
		methods: {
			toDeclare() {
				this.$router.push({
					name: 'declare',
					params: {
						id: ''
					}
				})
			},
			openTable(i) {
				this.$refs.table.openTable(i);
			},
			changeToptab(i) {
				this.toptab = i;
				this.getNews();
			},
			async getNews() {
				//获取新闻政策
				let newType = this.toptab;
				let res = await this.$api.policyNews({
					newType: newType,
					pageSize: 5,
					pageNo: 1
				});
				this.toptabList = res.result.records;
				// console.log(res);
			},
			//并行获取数据
			async getinitDatas() {
				let res = await Promise.all([
					this.$api.coachNum(), //上市数据各个总数
					this.$api.bannerImg({
						positions: 1
					}),
					this.$api.queryIPO()
				])
				this.coachNum = res[0].result;
				this.bannerList = res[1].result;
				this.ipoData = res[2].result;
			},
		},
		components: {
			FinancingBox,
			FriendLink,
			dataNull,
			mapCpn,
			HomeTableBox
		}
	}
</script>

<style scoped>
	#zjj_index .banner {
		width: 100%;
		height: 537px;
	}

	#zjj_index .banner img {
		min-width: 1200px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	#zjj_index .banner .swiper-container {
		width: 100%;
		height: 100%;
	}

	#zjj_index #section1 {
		padding: 28px 0 33px 0;
		/*height: 693px;*/
		/*background-color: paleturquoise;*/
	}

	#zjj_index #section1 .top {
		display: flex;
		justify-content: space-between;
	}

	#zjj_index #section1 .top .zhengce {
		border: 1px #3ca9fb solid;
		border-radius: 12px;
		width: 588px;
		height: 95px;
		padding: 8px 15px;
		cursor: pointer;
	}

	#zjj_index #section1 .top .zhengce .zhengce_con {
		background-color: #3ca9fc;
		width: 100%;
		height: 100%;
		border-radius: 12px;
		font-size: 17px;
		color: #fff;
		padding-left: 174px;
		/*text-align: center;*/
		/*line-height: 79px;*/
	}

	#zjj_index #section1 .top .zhengce .zhengce_con .zc_icon {
		font-size: 50px;
		margin-right: 25px;
	}

	#zjj_index #section1 .top .zhengce .zhengce_con .flexs {
		display: flex;
		align-items: center;
	}

	#zjj_index #section1 .bottom {
		/* padding-top: 24px; */
	}

	#zjj_index #section1 .bottom .h1box {
		display: flex;
	}

	#zjj_index #section1 .bottom .h1box h2 {
		font-size: 23px;
		padding: 12px 0;
		margin-right: 40px;
		color: rgb(196, 196, 196);
		cursor: pointer;
		transition: all 0.3s;
	}

	#zjj_index #section1 .bottom .h1box .active {

		color: #3ea8fc;
		font-weight: bold;
	}

	#zjj_index #section1 .bottom .content {
		padding-top: 22px;
		display: flex;
		justify-content: space-between;
		border-top: 2px #999999 solid
	}

	.contentinbox {
		width: 739px;
		height: 458px;
	}

	#zjj_index #section1 .bottom .content .left {
		border: 2px #f2f6f9 solid;
		height: 458px;
		width: 787px;
		padding: 31px 28px 21px 16px;

	}

	#zjj_index #section1 .bottom .content .left li {
		font-size: 14px;
		color: #383838;
		/* height: 30px; */
		padding-bottom: 55px;
		/* margin-bottom: 50px; */
		display: flex;
		align-items: center;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#zjj_index #section1 .bottom .content .left li span {
		display: inline-block;
		width: 6px;
		height: 6px;
		background-color: #3CA9FB;
		border-radius: 50%;
		margin-right: 8px;
		vertical-align: middle;
	}

	.zcasbox {
		display: flex;
		align-items: center;
	}

	#zjj_index #section1 .bottom .content .left a.fonts {
		font-size: 16px;
		color: #383838;
	}

	#zjj_index #section1 .bottom .content .left a.fonts:hover {
		color: #999;
	}

	#zjj_index #section1 .bottom .content .left a.more:hover {
		color: #7db6ee;
	}

	#zjj_index #section1 .bottom .content .left li p {
		font-size: 12px;
		color: #999;
	}

	#zjj_index #section1 .bottom .content .left a {
		color: #3cabfc;
		font-size: 17px;
		/* margin-top: -16px; */
		display: inline-block;
		width: 501px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.leftaa {
		text-align: right;
		margin-right: 30px;
	}

	#zjj_index #section1 .bottom .content .right {
		height: 458px;
		width: 378px;
	}

	#zjj_index #section1 .bottom .content .right a.imgs {
		display: block;
	}

	#zjj_index #section1 .bottom .content .right a.imgs:hover {
		opacity: 0.7;
		transition: all 0.5s;
	}

	#zjj_index #section1 .bottom .content .right img {
		width: 380px;
		height: 132px;
	}

	#zjj_index #section1 .bottom .content .right img.margin {

		margin-bottom: 31px;
	}

	#zjj_index #section2 {
		width: 100%;
		height: 574px;
		padding: 27px 0;
		background-color: #d8edfe;
	}

	#zjj_index #section2 .chanpin h2 {
		font-size: 23px;
		color: #3ea8fc;
		font-weight: bold;
		border-bottom: 2px #999999 solid;
		padding-bottom: 2px;
	}

	#zjj_index #section2 .content1 {
		padding: 60px 0 29px 0;
		display: flex;
		justify-content: space-between;
	}

	#zjj_index #section2 .content1 .right1 {
		width: 581px;
		height: 428px;
	}

	#zjj_index #section2 .content1 .right1 .bg1 {
		width: 581px;
		height: 210px;
		background-image: url(../../static/img/zjj_index_img4.png);
		background-repeat: no-repeat;
		margin-bottom: 16px;
		padding-top: 44px;
		padding-left: 70px;
	}

	#zjj_index #section2 .content1 .right1 .ll {
		color: #454648;
		font-size: 18px;
		height: 28px;
	}

	#zjj_index #section2 .content1 .right1 .bg1 .service {
		color: #999999;
		font-size: 15px;
		margin-top: 23px;
		margin-bottom: 27px;
	}

	#zjj_index #section2 .content1 .right1 .go_on {
		width: 100px;
		height: 32px;
		display: block;
		background-color: #1492ff;
		border-radius: 4px;
		text-align: center;
		line-height: 32px;
		color: #fff;
		font-size: 15px;
	}

	#zjj_index #section2 .content1 .right1 a.orange {
		background-color: #ff8f1f;
		margin-top: 42px;
	}

	#zjj_index #section2 .content1 .right1 .jr_icon {
		/*color: #1492ff;*/
		font-size: 25px;
		/*margin-right: 8px;*/
	}

	#zjj_index #section2 .content1 .right1 .bg2 {
		display: block;
		width: 581px;
		height: 210px;
		background-image: url(../../static/img/zjj_index_img5.png);
		background-repeat: no-repeat;
		padding-top: 44px;
		padding-left: 70px;
	}

	#zjj_index #section3 {
		width: 100%;
		height: 881px;
	}

	#zjj_index #section3 .sec3_container {
		display: flex;
		justify-content: space-between;
		padding-top: 49px;
	}

	#zjj_index #section3 .sec3_container .echarts {
		width: 590px;
		height: 747px;
	}

	#zjj_index #section3 .sec3_container .echarts img {
		width: 590px;
		height: 747px;
	}

	#zjj_index #section3 .sec3_container .data {
		width: 582px;
		height: 747px;
		padding-top: 13px;
	}

	#zjj_index #section3 .sec3_container .data h2 {
		height: 53px;
		border-bottom: 6px #f0f0f0 solid;
		position: relative;
	}

	#zjj_index #section3 .sec3_container .data h2 .sbznbox {
		position: absolute;
		bottom: -6px;
		height: 52px;
		text-align: center;
		line-height: 52px;
		color: #fff;
		font-size: 21px;
		display: flex;
	}

	#zjj_index #section3 .sec3_container .data h2 .sbznbox p {
		width: 167px;
		height: 52px;
		color: rgb(149, 149, 149);
		text-align: center;
		line-height: 52px;
		cursor: pointer;
		font-size: 21px;
		transition: all 0.3s;
	}

	#zjj_index #section3 .sec3_container .data h2 .sbznbox .active {
		background-color: #dc333a;
		color: #fff;
		font-weight: bold;
	}

	#zjj_index #section3 .sec3_container .data .data_dets {}

	#zjj_index #section3 .sec3_container .data .data_dets li {
		margin-top: 95px;
		display: flex;
		justify-content: space-between;
	}

	#zjj_index #section3 .sec3_container .data .data_dets .left,
	#zjj_index #section3 .sec3_container .data .data_dets .right {
		display: flex;
		justify-content: flex-start;
		color: #3f3f3f;
		font-size: 16px;
		width: 317px;
	}

	#zjj_index #section3 .sec3_container .data .data_dets .right {
		width: 244px;
	}

	#zjj_index #section3 .sec3_container .data .data_dets .right img {
		width: 77.8px;
		height: 81px;
	}

	#zjj_index #section3 .sec3_container .data .data_dets strong {
		font-size: 23px;
	}

	#zjj_index #section3 .sec3_container .data .data_dets .num {
		margin-left: 24px;
		text-align: center;
	}

	#zjj_index #section3 .sec3_container .data .data_dets a {
		color: #959daa;
		text-decoration: underline;
	}

	#zjj_index #section3 .sec3_container .data .data_dets span {
		color: #959daa;
	}

	#zjj_index #section3 .sec3_container .data h5 {
		text-align: center;
		margin-top: 96px;
		font-size: 18px;
		color: #98999b;
		font-weight: normal;
	}

	.tablebox {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
	}
</style>
