<template>
    <div>
        <!-- 主体内容 -->
        <section id="lzy">
            <img src="../../static/img/lzy_03_01.jpg" alt="" style="width: 100%;">
            <div class="main w">
                <div class="box1">
                    <div>企业简介</div>
                </div>

                <div class="box2-main" v-if="status == 3">
                   <div class="block-box1">
                       <div>企业信息</div>
                   </div>
                   <div class="jianjiebox">
                       <div class="line">
                           <div class="linechild">
                               <div class="childName">企业简称：</div>
                               <div class="childtext">{{details.companyAbbreviation}}</div>
                           </div>
                           <div class="linechild">
                               <div class="childName">证券代码：</div>
                               <div class="childtext">{{details.prodectCode}}</div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild">
                               <div class="childName">企业全称：</div>
                               <div class="childtext">{{details.companyName}}</div>
                           </div>
                           <div class="linechild">
                               <div class="childName">统一社会信用代码：</div>
                               <div class="childtext">{{details.compnayCode}}</div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild">
                               <div class="childName">城市：</div>
                               <div class="childtext">{{details.city}}</div>
                           </div>
                           <div class="linechild">
                               <div class="childName">地区：</div>
                               <div class="childtext">{{details.area}}</div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild">
                               <div class="childName">注册资本：</div>
                               <div class="childtext">{{details.capital}}元</div>
                           </div>
                           <div class="linechild">
                               <div class="childName">注册时间：</div>
                               <div class="childtext">{{details.time}}</div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild">
                               <div class="childName">企业类型：</div>
                               <div class="childtext">{{details.companyType}}</div>
                           </div>
                           <div class="linechild">
                               <div class="childName">股改时间：</div>
                               <div class="childtext">{{details.ggTime}}</div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild">
                               <div class="childName">行业：</div>
                               <div class="childtext">{{details.industry}}</div>
                           </div>
                           <div class="linechild">
                               <div class="childName">高新企业：</div>
                               <div class="childtext"><block v-if="details.isEnterprises == 1">是</block><block v-else>否</block></div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild" style="width:100%;">
                               <div class="childName">河交所挂牌：</div>
                               <div class="childtext"><block v-if="details.isListing == 1">已挂牌</block><block v-else>未挂牌</block></div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild" style="width:100%;">
                               <div class="childName">注册地址：</div>
                               <div class="childtext">{{details.address}}</div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild" style="width:100%;">
                               <div class="childName">官网地址：</div>
                               <div class="childtext">{{details.linkUrl}}</div>
                           </div>
                       </div>
                       <div class="line">
                           <div class="linechild linechild2" style="width:100%;">
                               <div class="childName">经营范围：</div>
                               <div class="childtext">{{details.businessOrientation}}</div>
                           </div>
                       </div>

                  </div>
                </div>
                <div class="box2-main" v-html="details" v-else></div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "CompanyProfiles",
        data(){
            return {
                id:'',
                status:'',
                details:'',
                msg:{}
            }
        },
        mounted() {
            this.id = this.$route.params.id;
            this.status = this.$route.params.status;
            this.getMsg();
        },
        methods: {
            async getMsg(){
                let res = await this.$api.fudaoCompanyDetail({companyId:this.id,status:this.status})
                console.log(res);
                this.details = res.result.details;
            }
        }
    }
</script>

<style scoped>
    section{
        margin-bottom: 62px;
    }
    #lzy .main > .box1{
        width: 100%;
    }
    #lzy .main > .box1 > div{
        width: 363px;
        height: 72px;
        margin: auto;
        font-weight: bold;
        font-size: 29px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2695fe;
        border-radius: 4px;
        margin-top: 20px;
        margin-bottom: 18px;
    }
    #lzy .main > .box2-main{
        width: 100%;
        background-color: #d8edfe;
        padding-top: 43px;
        box-shadow: 1px 3px 10px #cecece;
    }
    #lzy .main > .box2-main .block-box1{
        height: 57px;
        width: 100%;
        color: #094067;
        padding-left: 36px;
    }
    #lzy .main > .box2-main .block-box1 > div{
        height: 100%;
        width: 129px;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-bottom: 3px solid #094067;
        border-top: 3px solid transparent;
    }
    .jianjiebox{
        margin-top: 20px;
    }
    .jianjiebox .line{
        display: flex;
        align-items: center;
    }
    .jianjiebox .line .linechild{
        width: 50%;
        min-height: 90px;
        display: flex;
        padding: 10px 0;
        padding-left: 36px;
        align-items: center;

        border-bottom: 1px solid #fff;

    }
    .jianjiebox .line .linechild2{
        align-items: start;
        width: 100%;
        padding-top: 30px;
    }
    .jianjiebox .line .childName{
        width: 218px;
        color: rgb(61,171,255);
        font-size: 24px;
        font-weight: bold;
        flex-shrink: 0;
    }
    .jianjiebox .line .childtext{
        color: rgb(99,99,99);
        line-height: 2;
    }


</style>
