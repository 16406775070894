<template>
    <div class="bbtabbarbox ">
        <div class="bbtabbarchil">
            <div class="bbttabbars butie bbtc2"
                 v-for="(item,i) of tabList" :key="item.id"
                 :class="{'bbactive':currval==i}"
                 @click="changeCur(i)">
                {{item.label}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CenterTab",
        props:{
          tabList:{
              type:Array,
              default(){
                  return []
              }
          }
        },
        data(){
            return {
                currval:0
            }
        },
        methods: {
            changeCur(i){
                this.currval = i;
                this.$emit('change',i)
            }
        }
    }
</script>

<style scoped>
    .bbtabbarbox{
        font-size: 20px;
        color: #2A2A2A;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 65px;
        padding: 0 46px;
        border-bottom: 1px solid #ECECEC;
    }
    .bbttabbars{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
    }
    .bbactive{
        border-bottom: 2px solid #2695FE;
    }
    .bbinnerbox .bbinners{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
        padding: 0 46px;
    }
    .bbinnerbox .bbinners .bbinnertit{
        font-size: 16px;
        color: #363636;
    }
    .bbinnerbox .bbinners .bbinnerdate{
        font-size: 16px;
        color: #9D9D9D;
    }
    .bbtabbarchil{
        display: flex;
        height: 100%;
        align-items: center;
    }
    .bbtabbarchil .bbtc2{
        margin-right: 114px;
    }
    .bbmore{
        font-size: 16px;
        color: #2695FE;
    }
    .bbbtmbox{

    }
    .bbbtmbox .bbbtms{
        padding: 20px 40px;
        border: 1px solid #ECECEC;
    }
    .bbbtmbox .bbbtms .bbbtmtop{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #2A2A2A;
    }
    .bbbtmbox .bbbtms .bbbtmtopr{
        font-size: 16px;
        color: #2695FE;
    }
    .bbbtmbox .bbbtms .bbbtmbtm{
        display: flex;
        align-items: center;
        margin-top: 12px;
        font-size: 12px;
        color: #7E7E7E;
    }
    .bbbtmbtmmiddle{
        margin: 0 97px 0 65px;
    }
    .imgbox{
        text-align: center;
        margin: 0 auto;
        background: #F6F6F6;
    }
</style>