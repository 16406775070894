<template>
    <div>
        <div class="ppt"></div>
        <!--导航条-->
        <div class="details_nav w">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/mosService' }">融资服务</el-breadcrumb-item>
                <el-breadcrumb-item
                        v-if="id=='1349914694723559425'"
                >股权服务</el-breadcrumb-item>
                <el-breadcrumb-item
                        v-else-if="id=='1349914776202108930'"
                >债券服务</el-breadcrumb-item>
                <el-breadcrumb-item
                        v-else-if="id=='1349914829750788098'"
                >银行助贷</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="boxcontain">
            <div>
                <div class="leftlist">
                    <div class="tarbox"
                    v-for="(item,i) in tarbarList" :key="i"
                         :class="{active:currenti == i}"
                         @click="currenti = i"
                    >{{item.serviceFinancingName}}</div>
                </div>
            </div>
            <div class="rightList">
                <div class="rightbox">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox" v-if="tarbarList[currenti]">
                                <img :src="tarbarList[currenti].imageUrl|imgUrl" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="innerbox imgWidth" v-if="tarbarList[currenti]" v-html="tarbarList[currenti].details"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EquityServices",
        data(){
            return {
                currenti:0,
                tarbarList:[],
                id:'1349914694723559425'
            }
        },
        mounted() {
            this.id = this.$route.params.id;
          this.getMsg();
        },
        methods: {
            async getMsg(){
                let res = await this.$api.financingServiceIntroduce({serviceFinancingId:this.id});
                this.tarbarList = res.result
            }
        }
    }
</script>

<style src="../../static/css/service.css" scoped>
  .imgWidth {
      width: 100%;
      height: 100%;
  }
</style>
