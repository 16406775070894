<template>
    <div>
        <div class="ppt"></div>
        <!--导航条-->
        <div class="details_nav w">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/mosService' }">融资服务</el-breadcrumb-item>
                <el-breadcrumb-item>银行助贷</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="boxcontain">
            <div>
                <div class="leftlist">
                    <div class="tarbox"
                         v-for="(item,i) in tarbarList" :key="i"
                         :class="{active:currenti == i}"
                         @click="currenti = i"
                    >{{item}}</div>
                </div>
            </div>
            <div class="rightList">
                <div class="rightbox" v-show="currenti==0">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox">
                                <img src="../../static/img/yhdk.jpg" alt="">
                                <!-- <div class="asddd">
                                    股权变更融资是融资方以其自身极第三人持有依法可以转让和出质的上市公司非上市股份有限公司有限责任公司的服权作为质押物
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">业务介绍</div>
                        <div class="boxcont">
                            小微企业“上市贷”业务主要针对已在或拟在新三板等股权交易市场上市或挂牌的小微企业，依据企业经营情况发放信用贷款，或与股权投资机构合作，按照风险共担的方式为企业发放贷款，以满足已（拟）上市企业的流动资金周转需求。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">精选案例</div>
                        <div class="boxcont">
                            2016年6月21日，唐山市盛川农产品股份有限公司是通过石交所与省建行合作推出的“上市贷”产品，成功获得信用贷款300万元，解决了企业资金周转的需求。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">在线申请</div>
                        <a href="lky_index.html"><div class="sqbotn">在线申请</div></a>
                    </div>
                </div>
                <div class="rightbox" v-show="currenti==1">
                    <div class="innerbox">
                        <div class="boxcont">
                            <div class="asdddbox">
                                <img src="../../static/img/yhdk.jpg" alt="">

                            </div>
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">业务介绍</div>
                        <div class="boxcont">
                            该产品一次授信可长期使用，授信以自然人或公司法人为借款主体，基于住宅、办公楼、商铺等易估值、易处置类不动产的足额有效抵押担保，通过简化授信资料、额度循环使用、专享优惠定价、授信年审制等环节创新，实现优化客户体验、免去重复办理抵押手续，降低客户融资成本。同时，该分行对产品进行优先审查审批，加快审批速度，提高了小微信贷的投放效率。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">精选案例</div>
                        <div class="boxcont">
                            2020年4月27日，河北**公司通过河北省金融服务平台贷款185万元。
                        </div>
                    </div>
                    <div class="innerbox">
                        <div class="boxtitle">在线申请</div>
                        <a href="lky_index.html"><div class="sqbotn" style="color:#000;">在线申请</div></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "BankService",
        data(){
            return {
                currenti:0,
                tarbarList:['上市贷','快捷抵押贷']
            }
        }
    }
</script>

<style src="../../static/css/service.css" scoped>

</style>