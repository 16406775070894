export default {
    listedCompanyColumn: [ //上市公司数据
        {prop: 'companyName', label: '公司名称', width: '120'},
        {prop: 'ipoCode', label: '证券代码', width: '120'},
        {prop: 'companyAddress', label: '注册地', width: '120'},
        {prop: 'time', label: '上市日期', width: '120'},
        {prop: 'plate', label: '上市版块', width: '120'},
        {prop: 'industry', label: '所属行业', width: '120'},
        {prop: 'ompanyType', label: '企业性质', width: '120'},
        {prop: 'equity', label: '总股本(万)', width: '120'},
        {prop: 'market', label: '总市值(万)', width: '120'},
        {prop: 'firstMoney', label: '首发募集资金总额(万)', width: '120'},
        {prop: 'cumulativeMoney', label: '累计增发募集资金总额(万)', width: '120'},
        {prop: 'winningRate', label: '市盈率', width: '120'},
        {prop: 'netRate', label: '市净率', width: '120'},
        {prop: 'remarks', label: '备注', width: '180'},
    ],
    NEEQColumn: [  //新三板企业数
        {prop: 'companyName', label: '公司名称', width: '120'},
        {prop: 'ipoCode', label: '证券代码', width: '120'},
        {prop: 'address', label: '注册地', width: '120'},
        {prop: 'time', label: '挂牌日期', width: '120'},
        {prop: 'industry', label: '所属行业', width: '120'},
        {prop: 'hierarchy', label: '层级', width: '120'},
        {prop: 'companyStatus', label: '企业异动', width: '120'},
        {prop: 'remarks', label: '备注', width: '180'},
    ],
    examinationColumn: [ //在审企业数据
        {prop: 'companyName', label: '公司名称', width: '120'},
        {prop: 'address', label: '注册地', width: '120'},
        {prop: 'plate', label: '上市板块', width: '120'},
        {prop: 'staus', label: '上市进程', width: '120'},
        {prop: 'mechanism', label: '保荐机构', width: '120'},
        {prop: 'time', label: '受理日期', width: '120'},
        {prop: 'industry', label: '所属行业', width: '120'},
    ],
    counselingColumn: [ //在辅导企业数据
        {prop: 'companyName', label: '公司名称', width: '120'},
        {prop: 'comapnyAddress', label: '注册地', width: '120'},
        {prop: 'time', label: '辅导备案日期', width: '120'},
        {prop: 'zhengjianju', label: '所属证监局', width: '120'},
        {prop: 'mechanism', label: '保荐机构', width: '120'},
        {prop: 'plate', label: '拟申报板块', width: '120'},
    ],
    reserveColumn: [ //后备企业数据
        {prop: 'companyName', label: '企业名称', width: '120'},
        {prop: 'companyProvince_dictText', label: '注册地', width: '120'},
        {prop: 'companyType_dictText', label: '企业性质', width: '120'},
        {prop: 'companyIndustryId', label: '所属行业', width: '120'}
    ],
    FinancingColumn: [ //首发融资总额
        {prop: 'financingCode', label: '证券代码', width: '120'},
        {prop: 'financingName', label: '证券简称', width: '120'},
        {prop: 'financingPrice', label: 'IPO融资额（亿元）', width: '120'},
        {prop: 'shangshiTime', label: '上市日期', width: '120'},
        {prop: 'shangshiAddress_dictText', label: '上市地点', width: '120'},
        {prop: 'organizationName', label: '机构名称', width: '120'},
    ],
    RefinancingColumn: [  //股权再融资总额
        {prop: 'financingCode', label: '证券代码', width: '120'},
        {prop: 'financingName', label: '证券名称', width: '120'},
        // {prop: 'issueTime', label: '发行日期', width: '120'},
        {prop: 'shangshiTime', label: '增发日期', width: '120'},
        {prop: 'type_dictText', label: '事项类型', width: '120'},
        {prop: 'issueScale', label: '发行规模(亿元)', width: '120'},
    ],
    ipoBondColumn: [  //债权融资总额
        {prop: 'bondCode', label: '代码', width: '120'},
        {prop: 'bondName', label: '名称', width: '120'},
        {prop: 'issuePrice', label: '发行金额（亿元）', width: '120'},
        {prop: 'year', label: '期限（年）', width: '120'},
        {prop: 'interestRate', label: '票面利率（%）', width: '120'},
        {prop: 'issueTime', label: '发行日期', width: '120'},
        {prop: 'issuePerson', label: '发行人', width: '120'},
    ],
    abroadColumn: [ // 境外
        {prop: 'securityCode', label: '证券代码', width: '120'},
        {prop: 'securityAbbreviation', label: '证券简称', width: '120'},
        {prop: 'listAddress', label: '上市地点', width: '120'},
        {prop: 'totalMarket', label: '总市值(万元)', width: '120'},
        {prop: 'listTime', label: '首发上市日期', width: '120'},
        {prop: 'raising', label: '首发募集资金(万元)', width: '120'},
        {prop: 'industry', label: '行业名称', width: '120'},
        {prop: 'city_dictText', label: '城市', width: '120'},
        {prop: 'delistingTime', label: '退市日期', width: '120'},
    ],
    shingleColumn: [ // 石交所
        {prop: 'ipoCode', label: '证券代码', width: '120'},
        {prop: 'companyAbbreviation', label: '公司简称', width: '120'},
        {prop: 'city_dictText', label: '城市', width: '120'},
        {prop: 'industry', label: '行业', width: '120'},
        // {prop: 'enterprises', label: '高新企业', width: '120'},
        {prop: 'gpTime', label: '挂牌时间', width: '120'},
        {prop: 'hierarchy', label: '板块', width: '120'},
        {prop: 'companyStatus', label: '企业异动', width: '120'},
    ]
}