<template>
  <!--    //概览-->
  <div id="zjj_index">
    <section id="section3">
      <div class="w">
        <div class="data" id="asdd" v-cloak>
          <ul class="tabBox">
            <li :class="{ active: current == 1 }" @click="change(1)">
              首发融资
            </li>
            <li :class="{ active: current == 2 }" @click="change(2)">
              股权再融资
            </li>
            <li :class="{ active: current == 3 }" @click="change(3)">
              债券融资
            </li>
          </ul>
          <!-- 融资总额 -->
          <div class="total" v-if="current == 1">总额：{{ipoData.iIpoFinancing}}亿元</div>
          <div class="total" v-if="current == 2">总额：{{ipoData.ipoRefinancing}}亿元</div>
          <div class="total" v-if="current == 3">总额：{{ipoData.ipoBond}}亿元</div>
          <!-- 对应的table -->
          <div class="tableBox">
            <!-- <el-dialog
            width="95%"
            top="5vh"
          > -->
            <!--                上市公司数据-->
            <el-table :data="CompanyData" v-loading="loading" border>
              <el-table-column
                :align="align"
                type="index"
                label="序号"
                width="40"
              ></el-table-column>
              <el-table-column
                v-for="(item, i) in currTable.column"
                :key="item.data"
                :align="align"
                :prop="item.prop"
                :label="item.label"
              >
              </el-table-column>
            </el-table>
            <div class="paginationbox">
              <el-pagination
                background
                :page-count="pageCount"
                :current-page="currentPage"
                @current-change="currentChange"
                layout="prev, pager, next"
                :page-size="pageSize"
              >
              </el-pagination>
            </div>
            <!-- </el-dialog> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import clmData from "../../../util/datas/ColumnData";
export default {
  name: "Survey",
  data() {
    return {
      current: 1,
      tableShow: false,
      CompanyData: [],
      currTable: [],
      align: "center",
      loading: false,
      pageSize: 10, //每页显示数量
      pageCount: 0, //总页数
      currentPage: 1,
      tableConfig: [
        {
          id: 1,
          name: "Financing",
          column: clmData.FinancingColumn,
          api: "ipoFinancing",
        }, //首发融资总额
        {
          id: 2,
          name: "Refinancing",
          column: clmData.RefinancingColumn,
          api: "ipoRefinancing",
        }, //股权再融资总额
        {
          id: 3,
          name: "ipoBond",
          column: clmData.ipoBondColumn,
          api: "ipoBond",
        },
      ],
      ipoData: {},
    };
  },
  mounted() {
    this.change(1);
    this.getinitDatas();
  },
  methods: {
    change(i) {
      this.current = i;
      this.currTable = this.tableConfig.filter((res) => {
        //找出要弹出的table
        return res.id == i;
      })[0];
      this.tableShow = true;
      this.currentPage = 1;
      this.getTableMsg();
    },
    currentChange(e) {
      //页数改变
      this.currentPage = e;
      this.getTableMsg();
    },
    async getTableMsg() {
      //获取弹出table数据

      let pageNo = this.currentPage;
      this.loading = true;
      let res = await this.$api[this.currTable.api]({ pageNo });
      // if (this.currTable.api == "ipoFinancing") {
      this.loading = false;
      this.pageCount = res.result.pages;
      this.CompanyData = res.result.records;
      this.CompanyData.forEach((item, index) => {
        if (item.gpTime != null) {
          this.CompanyData[index].gpTime = item.gpTime.substring(0, 4);
        }
      });
    },
    async getinitDatas() {
      let res = await this.$api.queryIPO();
      this.ipoData = res.result;
    },
  },
};
</script>

<style scoped>
.data {
  width: 1200px;
  margin: 20px auto 0;
  padding: 20px;
  background: #fff;
}
.tabBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-bottom: 20px; */
}
.tabBox li {
  width: 100px;
  padding: 10px 0;
  text-align: center;
  color: #545454;
  font-size: 16px;
  cursor: pointer;
}
.tabBox li.active {
  border-bottom: 2px solid #1452b4;
}
.tableBox {
  width: 100%;
}
.el-pagination {
  margin: 20px 0;
}
.total {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  margin: 20px 0;
}
</style>
