<template>
  <div id="lzy">
    <!-- 主题内容 -->
    <section style="background: #F6F6F6;height:100%;">
      <div class="big_img">
        <img src="../../../static/img/lzy_05_1.png" alt="" />
      </div>
      <!--导航条-->
      <div class="details_nav w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>账号中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="contentboxx">
        <centerTabs class="leftTab"></centerTabs>
        <router-view></router-view>
      </div>
      <CenterSwiper v-if="isAccount" class="swbox"></CenterSwiper>

      <!-- <router-link to="/govermentCenter">政府中心-------</router-link> -->
    </section>
  </div>
</template>

<script>
import CenterSwiper from "../../../components/CenterSwiper";
import centerTabs from "./centerTabs";

export default {
  name: "PersonCenter",
  data() {
    return {};
  },
  mounted() {
    let userId = this.$store.state.userInfo;
    if (userId == null) {
      this.gotoLogin();
    }
  },
  computed: {
    isAccount() {
      return this.$route.name == "account" ? true : false;
    },
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login");
    },  
  },
  components: {
    centerTabs,
    CenterSwiper,
  },
};
</script>

<style scoped>
#lzy section .big_img img {
  width: 100%;
}
.contentboxx {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.leftTab {
  flex-shrink: 0;
  margin-right: 40px;
}
.swbox {
  width: 1200px;
  margin: 0 auto;
}
</style>
