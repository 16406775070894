<template>
    <div id="HRX">
        <section class="clearfix w">
            <TopBannerBox positions="8"></TopBannerBox>
            <div id="box2" class="clearfix w">
                <div id="fenlei" class="w">
                    <ul class="dhang" id="oul">
                        <span class="jigou">机构分类：</span>
                        <li class="l" :class="{active:currsel == 0}"
                            @click="Search(0,'')">全部</li>
                        <li class="l" :class="{active:currsel == i+1}"
                            v-for="(item,i) in types" :key="item.id"
                        @click="Search(i+1,item.id)">{{item.name}}</li>
                    </ul>
                </div>
                <div id="hname" class="clearfix w hhh">
                    <ul class="w po" >
                        <li id="hblue" v-for="(item,i) in dataList" :key="i">
                            <img class="imgboxaa" :src="item.imageUrl|imgUrl">
                            <div id="wenzi" class="clearfix">
                                <p class="yi">{{item.name}}</p>
                                <!-- <p class="er">联系方式：13127363838</p> -->
                                <button type="button" class="xqbtn">
                                    <router-link tag="a" :to="'/detail/5/'+item.id">机构详情</router-link>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="paginationbox">
                    <el-pagination
                            background
                            :page-size="pageSize"
                            @current-change="currentChange"
                            layout="prev, pager, next"
                            :page-count="pageCount">
                    </el-pagination>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import TopBannerBox from "../../components/TopBannerBox";
    export default {
        name: "ResourceLibrary",
        data(){
            return {
                pageSize:12, //每页显示数量
                pageCount:1, //页数
                currentPage:1,
                dataList:[],
                currsel:0,
                types:[],
                projectDicServiceorganizationId:''
            }
        },
        mounted(){
            this.getmsg();
            this.getOptions();
        },
        methods: {
            currentChange(e){
                //页数改变
                this.currentPage = e;
                this.getmsg();
            },
            async getmsg(){
                let pageNo = this.currentPage;
                let projectDicServiceorganizationId = this.projectDicServiceorganizationId
                let pageSize = this.pageSize;
                let res = await this.$api.serviceOrganization({pageNo,projectDicServiceorganizationId,pageSize});
                this.dataList = res.result.records;
                this.pageCount = res.result.pages;
                console.log(res);
            },
            Search(i,id){
              this.currsel = i;
              this.projectDicServiceorganizationId = id;
              this.currentPage = 1;
                this.getmsg();
            },
            async getOptions(){
                let res = await this.$api.projectDic({type:7});
                this.types = res.result;
            }
        },
        components:{
            TopBannerBox
        }
    }
</script>

<style scoped>
    .imgboxaa{
        width: 238px;
        height: 154px;
        object-fit: contain;
    }
    .active{
        background-color: #1786e0;
        color: #fff!important;
    }
    #HRX  section{
        width: 100%;
        /* 	background-color: aqua; */
    }
    #HRX #box{
        /* 	background-color: red; */
    }
    #HRX .width{
        width: 100%;
        height: 340px;
        min-width:1200px;
    }
    #HRX  #box2{
        /*  background-color: #00FFFF; */
        position: relative;
    }
    #HRX #fenlei{
        height: 100px;
        padding-left: 10px;
        border-bottom: 1px dotted #f6f6f6;
    }
    #HRX #fenlei .l{
        cursor: pointer;
    }
    #HRX  .dhang li{
        float: left;
        font-size:13px;
        height: 31px;
        padding: 7px 11px;
        font-size: 13px;
        color: black;
        margin-left: 20px;
        margin-top: 35px;
    }
    #HRX #hname{
        border-top: 1px dotted #d9d9d9;
        border-bottom: 1px dotted #d9d9d9;
        /* 	background-color: black; */
        /*height: 1410px;*/
        overflow: hidden;
        margin-bottom: 37px;
    }
    #HRX .jigou{
        font-size: 13px;
        /* 	padding: 8px; */
        /*  background-color: red; */
        float: left;
        line-height: 100px;
    }
    #HRX #hblue{
        width: 281px;
        height: 321px;
        background-color: #3ca9fc;
        border-radius: 12px;
        float: left;
        margin-right: 25px;
        padding: 25px 21px;
        margin-top: 20px;
        border-bottom: 1px dotted #d9d9d9;
    }
    #HRX #hblue:nth-child(4n){
        margin-right: 0;
    }
    #HRX #wenzi{
        width: 100%;
    }
    #HRX #wenzi p{
        width: 100%;
        font-size: 16px;
        color: white;
        float: left;
    }
    #HRX #wenzi .yi{
        margin-top: 30px;
        margin-bottom: 24px;
        text-align: center;
    }
    #HRX #wenzi .er{
        margin: 0px;
        margin-bottom: 17px;
    }
    #HRX .xqbtn{
        width: 167px;
        height: 34px;
        border-radius:4px;
        display: block;
        margin:  0 auto;
        margin-bottom: 0px;
        color:#3ca9fc;
        cursor: pointer;
        background-color: #fff;
    }
    .button-set{
        font-size: 12px;
    }

    #HRX .xqbtn a{
        color:#3ca9fc;
        display: block;
    }

</style>
