var checkPhone = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('手机号不能为空'));
    } else {
        const reg = /^1[3|4|5|7|6|8|9][0-9]\d{8}$/
        // console.log(reg.test(value));
        if (reg.test(value)) {
            callback();
        } else {
            return callback(new Error('请输入正确的手机号'));
        }
    }
};
var checkPhone2 = (rule, value, callback) => { //不必填
    const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
    // console.log(reg.test(value));
    if (reg.test(value) || value=='') {
        callback();
    } else {
        return callback(new Error('请输入正确的手机号'));
    }
};

export default {
    checkPhone,checkPhone2
}