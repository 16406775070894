<template>
    <!-- 头部代码 -->
    <header>
        <div class="head w">
            <div class="box1">
                <div class="left-text">
                    您好，欢迎来到河北省企业股改上市服务平台
                </div>
                <div class="right-img">
                    <img src="../static/img/heaedr-right.jpg" alt="">
                </div>
            </div>
            <div class="box2">
                <router-link to="/" tag="div" class="left">
                    <img src="../static/img/logo.jpg" alt="">
                    <span>河北省企业股改上市服务平台</span>
                </router-link>
                <div class="right" v-if="!token">
                    <p><a target="_blank" href="http://ipo.hbqyipo.com/">注册</a><span>/</span> <a  target="_blank"  href="http://ipo.hbqyipo.com/">登录</a></p>
                    <img src="../static/img/user.jpg" alt="">
                </div>
                <div class="right" v-else>
                    <p><a @click="toCenter(userInfo.type)"><if condition="userInfo.companyName neq ''">{{userInfo.companyName}}</if></a>
                        <span>/</span>
                        <a @click="logOut">退出登录</a></p>
                    <img src="../static/img/user.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="nav">
            <div class="nav-list w">
                <router-link tag="a" to="/" >
                    <div :class="{ 'big-border':activeRouter=='home'}" >首页</div>
                </router-link>
                <!-- <router-link tag="a" to="/policy" >
                    <div :class="{ 'big-border':activeRouter=='policy'}" >政策动态</div>
                </router-link> -->
				<div class="upbox" @mouseenter="child3=true" @mouseleave="child3=false">
				    <div class="up-list" :class="{ 'big-border':activeRouter=='policy'}" @click="to('/workNews')">
				        政策动态
				    </div>
				    <transition name="downs">
				        <div class="down-list-1" v-show="child3">
				            <div  @click="to('/workNews')">工作动态</div>
				            <div  @click="to('/policy')">扶持政策</div>
				        </div>
				    </transition>
				</div>
                <!-- <router-link tag="a" to="/tutoring" >
                    <div :class="{ 'big-border':activeRouter=='tutoring'}" >上市辅导</div>
                </router-link>  -->
                <div class="upbox" @mouseenter="child2=true" @mouseleave="child2=false">
                    <div class="up-list" :class="{ 'big-border':activeRouter=='tutoring'}" @click="to('/tutoring')">
                        上市辅导
                    </div>
                    <transition name="downs">
                        <div class="down-list-1" v-show="child2">
                            <div  @click="to('/tutoring')">上市进程</div>
                            <div  @click="to('/companyLibrary')">辅导机构</div>
                        </div>
                    </transition>
                </div>
                <router-link tag="a" to="/mosService" >
                    <div :class="{ 'big-border':activeRouter=='mosService'}" >融资服务</div>
                </router-link>
                <div class="upbox" @mouseenter="child1=true" @mouseleave="child1=false">
<!--                    @mouseenter="child1=true" @mouseleave="child1=false"-->
                    <div class="up-list" :class="{ 'big-border':activeRouter=='roadShow'}" @click="to('/roadShow')">
                        培训路演
                    </div>
                    <transition name="downs">
                        <div class="down-list-1" v-show="child1">
                            <div @click="to('/roadShow')">企业培训</div>
<!--                            <span></span>-->
                            <div @click="to('/training')">上市路演</div>
                        </div>
                    </transition>
                </div>
<!--                <a href="zsy_08.html">-->
<!--                    <div>股权托管</div>-->
<!--                </a>-->
                <!-- <router-link tag="a" to="/companyLibrary" >
                    <div :class="{ 'big-border':activeRouter=='companyLibrary'}" >辅导机构库</div>
                </router-link> -->
                <!-- <div class="upbox" @mouseenter="child2=true" @mouseleave="child2=false">
                    <div class="up-list" :class="{ 'big-border':activeRouter=='agencyLibrary'}" @click="to('/agencyLibrary')">
                        辅导机构库
                    </div>
                    <transition name="downs">
                        <div class="down-list-1" v-show="child2">
                            <div  @click="to('/agencyLibrary')">平台企业后备库</div>
                            <div  @click="to('/companyLibrary')">平台服务机构库</div>
<!--                            <div>企业需求发布</div>-->
                        <!-- </div>
                    </transition>
                </div> -->

<!--                <a href="zhl_zqztc.html">-->
<!--                    <div>政企直通车</div>-->
<!--                </a>-->
                <router-link tag="a" to="/consult">
                    <div :class="{ 'big-border':activeRouter=='consult'}">线上咨询</div>
                </router-link>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "Header",
        data(){
            return {
                name:'',
                child1:false,
                child2:false,
				child3:false,
            }
        },
        mounted(){

        },
        methods:{
            to(path){
                this.$router.push({path})
            },
            toCenter(type){
                if(type==1){
                    this.to('/govermentCenter') //政府
                }else if(type==2){
                    this.to('/companyCenter') //企业
                }
            },
            logOut(){
                window.sessionStorage.clear();//清除session所有数据
                window.localStorage.clear()
                this.$store.commit('setToken','');
                this.$store.commit('setUserInfo','');
                this.to('/login') //登录
                location.reload()
            }

        },
        computed:{
            token(){
                return this.$store.state.token;
            },
            userInfo(){
                return this.$store.state.userInfo;
            },
            activeRouter(){
                if(this.$route.meta.tabbar_active){
                    return this.$route.meta.tabbar_active
                }else {
                    return this.$store.state.activePage
                }
            }

        },
    }
</script>

<style scoped>
    /* 这是header头部代码 */
    header a {
        color: black;
    }

    header .head {
        padding-top: 50px;
    }

    header .box1 {
        height: 54px;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
    }

    header .box1 .left-text {
        font-size: 20px;
        padding-top: 12px;
    }

    header .box2 {
        height: 136px;
        width: 100%;
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
    }

    header .box2 .left {
        cursor: pointer;
    }

    header .box2 .left > img {
        margin-right: 26px;
    }

    header .box2 .left > span {
        font-size: 22px;
    }

    header .box2 .right {
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        align-items: center;
    }

    header .box2 .right > p > a {
        color: #999;
        cursor: pointer;
    }

    header .box2 .right > p > a:hover {
        color: #333;
    }

    header .box2 .right > p > span {
        color: #f7f7f7;
        margin-right: 4px;
        margin-left: 4px;
    }

    header .box2 .right > img {
        margin-left: 7px;
    }

    header .nav {
        height: 66px;
        width: 100%;
        border-top: 1px solid #094067;
    }

    header .nav-list {
        position: relative;
        height: 100%;
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-evenly;
    }

    header .nav-list > a {
        height: 100%;
    }

    header .nav-list > a > div {
        width: 160px;
        min-width: 112px;
        height: 100%;
        display: flex;
        display: -webkit-box;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s;
        border-bottom: 5px solid transparent;
        cursor: pointer;
        font-size: 18px;
    }

    /* 列表border的高亮 */
    header .nav .big-border {
        /*border-top: 5px solid transparent;*/
        border-bottom: 5px solid #3ea8fc;
    }

    header .nav-list > a:hover > div {
        border-bottom: 5px solid #3ea8fc;
    }

    /* 下拉菜单样式 */
    .upbox{
        height: 100%;
        position: relative;
        z-index: 10;
    }
    .up-list {
        /*position: relative;*/
        width: 160px;
        min-width: 112px;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s;
        border-bottom: 5px solid transparent;
        cursor: pointer;
        font-size: 18px;
    }
    .up-list:hover {
        border-bottom: 5px solid #3ea8fc;
    }
    .down-list-1{
        /*top: 65px;*/
        width: 160px;
        min-width: 114px;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 8px;
        /*position: relative;*/
        /*display: none;*/
        /*z-index: 8;*/
        cursor: pointer;

    }
    .down-list-1 >div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-bottom: 1px solid #989a97;
        font-size: 14px;
        text-align: center;
        /*background-color: rgba(255, 255, 255, 0.7);*/


        /*display: none;*/

        cursor: pointer;
    }
    .down-list-1 >div:last-child{
        border-bottom: none;
    }



    /*.href-1:nth-child(2n+1) {*/
    /*    min-width: 98px;*/
    /*    width: 98px;*/
    /*    height: 32px;*/
    /*    display: flex;*/
    /*    display: -webkit-box;*/
    /*    display: -webkit-flex;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    font-size: 14px;*/
    /*}*/

    /*.href-1:nth-child(2n) {*/
    /*    min-width: 96px;*/
    /*    width: 96px;*/
    /*    height: 1px;*/
    /*    background-color: #989a97;*/
    /*}*/

    .down-list-1 div:nth-child(2n+1) {
        transition: all 0.3s;
        cursor: pointer;
    }

    .down-list-1 div:hover {
        color: #3ea8fc;
    }

    /* 下拉菜单的高亮 */
    .light-herf {
        color: #3ea8fc;
    }

    .downs-enter ,.downs-leave-to {
        opacity: 0;
        top: 50px;
    }
    .downs-enter-active ,.downs-leave-active {
        transition: all 0.3s ease;

    }

</style>
