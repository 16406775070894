<template>
  <div>
    <div class="ppt">
      <div id="certify">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, i) in swiperList"
              :key="item.id"
            >
              <img :src="item.imgUrl | imgUrl" />
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <section class="s1">
      <div class="s1in w">
        <p class="p1">培训中心</p>
        <p class="p2">为您提供全方位的精彩优质活动</p>
        <div class="anniuzu">
          <router-link tag="a" to="/listedTraining" class="anniu">
            <span class="svg1">
              <img src="../../static/img/asd1.png" alt="" />
            </span>
            <p class="p3">上市培训</p>
          </router-link>
          <router-link tag="a" to="/ulive" class="anniu">
            <span class="svg2">
              <img src="../../static/img/asd2.png" alt="" />
            </span>
            <p class="p3">云直播</p>
          </router-link>
          <router-link tag="a" to="/onlineCourses" class="anniu">
            <span class="svg3">
              <img src="../../static/img/asd3.png" alt="" />
            </span>
            <p class="p3">线上课程</p>
          </router-link>
        </div>
      </div>
    </section>
    <section class="s2 w clearfix">
      <div class="s2left">
        <div class="s2left1 clearfix">
          <div class="s2title">
            活动预告
            <div class="lbzu clearfix">
              <div
                class="lbanniu"
                v-if="i < 5"
                v-for="(item, i) in calendar"
                :key="item.id"
                :class="{ dianji: activeSwiper == i }"
                @click="changeSwiper(i)"
              ></div>
            </div>
          </div>
          <div class="lunbobox">
            <div class="lunboyincang clearfix">
              <el-carousel
                indicator-position="outside"
                @change="changeSw"
                ref="carousel"
              >
                <el-carousel-item v-for="(item, i) in calendar" :key="item.id">
                  <div
                    class="s2leftbom1 clearfix"
                    @click="to(item.type, item.id)"
                  >
                    <img :src="item.image | imgUrl" class="img1" />
                    <div class="s2leftbom11">
                      <p class="p4 line-1">{{ item.title }}</p>
                      <p class="p5" v-if="item.type == 1">
                        主办单位：{{ item.organizer }}
                      </p>
                      <p class="p5" v-if="item.type==1">直播时间：{{ item.beginTime }} - {{item.endTime}}</p>
                      <p class="p5">{{ item.content }}</p>
                      <div class="huigu" v-if="item.uLiveState">
                        <span v-if="item.uLiveState == 1">预约中</span>
                        <span v-else-if="item.uLiveState == 2">直播中</span>
                        <span v-else-if="item.uLiveState == 3">已结束</span>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="s2left2">
          <div class="s2title clearfix">活动专题</div>
          <div class="s2leftpic">
            <div class="picgroupw clearfix">
              <div class="picgroup clearfix">
                <div v-for="(item, i) in projectDic" :key="i">
                  <img
                    class="zting"
                    :src="item.imageUrl | imgUrl"
                    @click="toDetail(item.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="s2right">
        <div class="s2title">
          活动日历
        </div>
        <div class="s2rightbom">
          <div class="s2rightbomright">
            <div
              class="s2rbr1"
              v-for="(item, i) in calendar"
              :key="item.id"
              @click="to(item.type, item.id)"
            >
              <!-- <div class="riqi">{{ item.times }}</div> -->
              <div class="s2rbr11">
                <p class="p6 line-1">{{ item.title }}</p>
                <!-- <p class="p7">{{ item.organizer }}</p> -->
              </div>
              <div class="fangkuai"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import methods from "../../util/methods";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  name: "RoadShow",
  data() {
    return {
      activeSwiper: 0,
      calendar: [],
      swiperList: [],
      projectDic: [],
    };
  },
  mounted() {
    this.initSwiper();
    this.initMsg();
  },
  methods: {
    initSwiper() {
      new Swiper("#certify .swiper-container", {
        watchSlidesProgress: true,
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper

        autoplay: 3000,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        pagination: ".swiper-pagination",
        paginationClickable: true, //此参数设置为true时，点击分页器的指示点分页器会控制Swiper切换。
        autoplayDisableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。 如果设置为false，用户操作swiper之后自动切换不会停止，每次都会重新启动autoplay。
        onProgress: function(swiper, progress) {
          for (let i = 0; i < swiper.slides.length; i++) {
            var slide = swiper.slides.eq(i);
            var slideProgress = swiper.slides[i].progress;
            let modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
            let translate = slideProgress * modify * 700 + "px";
            let scale = 1 - Math.abs(slideProgress) / 5;
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform(
              "translateX(" + translate + ") scale(" + scale + ")"
            );
            slide.css("zIndex", zIndex);
            slide.css("opacity", 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css("opacity", 0);
            }
          }
        },
        onSetTransition: function(swiper, transition) {
          for (var i = 0; i < swiper.slides.length; i++) {
            var slide = swiper.slides.eq(i);
            slide.transition(transition);
          }
        },
        // 处理分页器bug
        onSlideChangeStart: function(swiper) {
          if (swiper.activeIndex == 4) {
            swiper.bullets.eq(9).addClass("swiper-pagination-bullet-active");
            console.log(swiper.bullets.length);
          }
        },
        onSlideChangeEnd: function(swiper) {
          swiper.update();
          swiper.startAutoplay();
          swiper.reLoop();
        },
      });
    },
    changeSw(i) {
      this.activeSwiper = i;
    },
    changeSwiper(i) {
      this.$refs.carousel.setActiveItem(i);
    },
    // async getcalendar(){
    //     //活动日历
    //     let res = await this.$api.calendar();
    //     this.calendar = res.result;
    //     console.log(res);
    // },
    async initMsg() {
      let res = await Promise.all([
        this.$api.calendar(), //活动日历
        this.$api.bannerImg({ positions: 5 }), //轮播
        this.$api.projectDic({ type: 6 }), //活动专题
      ]);
      this.calendar = [];
      for (let item of res[0].result) {
        item.uLiveState = methods.uLiveState(item.beginTime, item.endTimes);
        this.calendar.push(item);
      }
      this.swiperList = res[1].result;
      this.projectDic = res[2].result;
      console.log(res[2].result);
    },
    toDetail(id) {
      //跳转活动专题列表
      this.$router.push("/roadShowDic/" + id);
    },
    to(type, id) {
      if (type == 1) {
        this.$router.push("/uliveDetail/" + id);
      } else {
        this.$router.push("/detail/3/" + id);
      }
    },
  },
};
</script>

<style scoped>
/* CSS Document */
.zting {
  width: 287px;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
}
#certify {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}

#certify .swiper-container {
  padding-bottom: 60px;
}

#certify .swiper-slide {
  width: 520px;
  height: 408px;
  background: #fff;
  box-shadow: 0 1px 6px #ddd;
}
#certify .swiper-slide img {
  display: block;
}



#certify .swiper-slide p {
  line-height: 98px;
  padding-top: 0;
  text-align: center;
  color: #636363;
  font-size: 1.1em;
  margin: 0;
}

#certify .swiper-pagination {
  width: 100%;
  bottom: 20px;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
  border: 3px solid #fff;
  background-color: #d5d5d5;
  width: 10px;
  height: 10px;
  opacity: 1;
}

#certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
  border: 3px solid #00aadc;
  background-color: #fff;
}

#certify .swiper-button-prev {
  left: -30px;
  width: 45px;
  height: 45px;
  background: url(../../static/img/wm_button_icon.png) no-repeat;
  background-position: 0 0;
  background-size: 100%;
}

#certify .swiper-button-prev:hover {
  background-position: 0 -46px;
  background-size: 100%;
}

#certify .swiper-button-next {
  right: -30px;
  width: 45px;
  height: 45px;
  background: url(../../static/img/wm_button_icon.png) no-repeat;
  background-position: 0 -93px;
  background-size: 100%;
}

#certify .swiper-button-next:hover {
  background-position: 0 -139px;
  background-size: 100%;
}

#certify .swiper-slide img {
  width: 100% !important;
}
#certify {
  /*width: 1200px;*/
}
#certify .swiper-container {
  padding-bottom: 0px;
}
.swiper-slide-duplicate-active {
  width: 960px !important;
}
#certify .swiper-slide {
  width: 960px !important;
  height: 385px !important;
}
.ppt {
  height: 540px;
  width: 100%;
  padding-top: 60px;
  background-color: #0682fc;
}
.s1 {
  width: 100%;
  height: 335px;
  background-color: #f6f6f6;
  padding-top: 54px;
  text-align: center;
}
.p1 {
  font-size: 33px;
  color: #020202;
  font-weight: bold;
  line-height: 33px;
}
.p2 {
  font-size: 17px;
  color: #75767a;
  margin-top: 26px;
}
.anniuzu {
  display: inline-block;
  height: 165px;
  width: 100%;
  margin-top: 34px;
}
.anniu {
  width: 225px;
  height: 150px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 1px #e1e1e1;
  text-align: center;
  padding-top: 35px;
  float: left;
  background-color: #fff;
  margin-right: 262px;
  cursor: pointer;
  transition: opacity 0.5s;
}
.anniu:hover {
  opacity: 0.4;
}
.anniu:nth-child(3) {
  margin-right: 0px;
}
.svg1 img,
.svg2 img,
.svg3 img {
  width: 50px;
}
.svg1 {
  color: #fc3c31;
  font-size: 50px;
}
.svg2 {
  color: #38c35c;
  font-size: 50px;
  display: block;
}
.svg3 {
  color: #5a52dc;
  font-size: 50px;
  display: block;
}
.p3 {
  color: #7a6672;
  font-size: 16px;
  line-height: 16px;
  margin-top: 16px;
}
.s2 {
  padding-top: 57px;
  margin-bottom: 77px;
}
.s2left {
  width: 902px;
  height: 551px;
  float: left;
}
.s2title {
  font-size: 30px;
  line-height: 30px;
  height: 62px;
}

.s2leftbom1 {
  width: 902px;
  height: 274px;
  box-shadow: 0px 0px 5px 1px #e1e1e1;
  padding: 10px;
  float: left;
}
.lunbobox {
  width: 902px;
  height: 274px;
  box-shadow: 0px 0px 5px 1px #e1e1e1;
  overflow: hidden;
}
.lunboyincang {
  /*width: 600%;*/
  height: 274px;
  transition: margin-left 1s;
}
.img1 {
  float: left;
  width: 438px;
}
.s2leftbom11 {
  width: 369px;
  height: 100%;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
}
.p4 {
  font-size: 24px;
  color: #323232;
  font-weight: bold;
  line-height: 24px;
}
.p5 {
  font-size: 12px;
  color: #676767;
  line-height: 12px;
  margin-top: 17px;
}
.huigu {
  width: 120px;
  height: 40px;
  border: 1px #d69a51 solid;
  color: #f79307;
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  margin-top: 75px;
  border-radius: 3px;
}
.s2left2 {
  margin-top: 37px;
}
.s2leftpic {
  overflow: hidden;
  width: 902px;
}
.picgroupw {
  width: 200%;
  transition: margin-left 0.5s;
}
.picgroup {
  float: left;
  width: 902px;
}
.picgroup img {
  float: left;
  margin-right: 10px;
  width: 287px;
}
.picgroup :nth-child(3) {
  margin-right: 0px;
}
.s2right {
  width: 280px;
  height: 551px;
  float: right;
}
.s2rightbom {
  padding-left: 11px;
  overflow: scroll;
  height: 489px;
  overflow-x: hidden;
}
.s2rightbom::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.s2rightbom::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.s2rightbom::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background: #ededed;
}
.s2rightbomright {
  border-left: 1px #e7e7e7 solid;
}
.s2rbr1 {
  width: 258px;
  height: 60px;
  padding-left: 17px;
  padding-top: 2px;
  margin-bottom: 28px;
  position: relative;
  cursor: pointer;
}
.riqi {
  color: #9c9d9f;
  font-size: 16px;
  line-height: 16px;
}
.s2rbr11 {
  width: 230px;
  box-shadow: 0px 0px 5px 1px #e1e1e1;
  margin-top: 10px;
  padding: 10px;
  color: #3c3839;
  line-height: 20px;
  font-size: 15px;
}
.p7 {
  font-size: 12px;
  color: #b2bcc5;
  line-height: 12px;
  margin-top: 16px;
}
.fangkuai {
  width: 8px;
  height: 10px;
  border: 2px #e7e7e7 solid;
  position: absolute;
  top: 6px;
  left: -4px;
}
.lunboanniu {
  height: 10px;
}
.lbzu {
  float: right;
  margin-top: 25px;
}
.lbanniu {
  width: 10px;
  height: 10px;
  background-color: #d0d0d0;
  border-radius: 50%;
  float: left;
  margin-right: 8px;
}
.fyanniuzu {
  float: right;
}

.xiangzuo {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px #717171 solid;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  color: #717171;
  vertical-align: middle;
}
.xiangyou {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px #717171 solid;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  color: #717171;
  vertical-align: middle;
}
.spn1,
.spn2,
.spn3 {
  display: inline-block;
  font-size: 11px;
  vertical-align: middle;
}
.dianji {
  background-color: #f99209;
}
.hz {
  font-size: 15px;
  color: #ff9a14;
}
</style>
