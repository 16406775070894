<template>
    <div class="echarts" id="mainn"></div>
</template>

<script>
    import hebei from "../util/datas/hebei.json"
    import * as echarts from "echarts";

    export default {
        name: "mapCpn",
        props:{
            title:{
                type:Boolean, //是否显示title
                default(){
                    return false
                }
            }
        },
        data() {
            return {
                mapDta: []
            }
        },
        beforeDestroy() {
            if (!this.chart) {
                return;
            }
            this.chart.dispose();
            this.chart = null;
        },
        async mounted() {
            let res = await this.getMsg();
            this.canvaschengduMap(this.mapDta);
        },
        methods: {
            async getMsg() {
                let res = await this.$api.cityNum();
                for (let item of res.result) {
                    this.mapDta.push({
                        name: item.city,
                        value: 38000,
                        tooltip: {
                            formatter: `{b}<br />沪深京上市企业: ${item.listCount}家<br />
                            境外上市企业：${item.jingwai}家<br />
                            新三板公司: ${item.thirdCount}家<br />
                            河交所挂牌: ${item.guapai}家<br />
                            在审企业: ${item.trailCount}家<br />
                            在辅导企业: ${item.coachCount}家`
                        }
                    })
                }
            },
            //绘制中国地图,传入鼠标移入时显示的省份数据
            async canvaschengduMap(mapDta) {

                // 基于准备好的dom，初始化echarts实例
                var myChart = echarts.init(document.getElementById("mainn"));
                myChart.showLoading();
                //注意这里的zigong与下面的series.map一致
                echarts.registerMap('hebei', hebei);
                myChart.hideLoading();
                // 绘制图表
                let option = {
                    title: {
                        text: this.title?'上市区域分布图':''
                    },
                    //参考配置文档：https://echarts.baidu.com/option.html#tooltip
                    tooltip: {
                        trigger: 'item',
                        formatter: '{b}<br/>{c} ',
                        extraCssText: 'width:187px;height:214px;background:rgba(122,190,253,0.8);font-size:18px;line-height:1.5;color:#fff;border:none'
                    },
                    //参考配置文档：https://echarts.baidu.com/option.html#series-map.type
                    series: [{
                        type: 'map',
                        roam: false,
                        selectedMode: 'single',
                        map: 'hebei',
                        itemStyle: {
                            normal: {
                                label: {show: false},
                                areaColor: "#a3d7ff",
                                borderColor: "#fff",
                                borderWidth: "0.8",
                            },
                            emphasis: {
                                label: {show: true,},
                                areaColor: "#ffdf34",
                            }
                        },
                        data: mapDta
                    }]
                };
                myChart.setOption(option);
            }
        }
    }
</script>

<style scoped>
    .echarts {
        width: 590px;
        height: 747px;
    }
</style>
