<template>
  <el-form
    style="margin-top: 20px"
    ref="form"
    :inline="true"
    :model="form"
    size="mini"
    label-width="130px"
  >
  <!-- <el-form
    style="margin-top: 20px"
    ref="form"
    :inline="true"
    :model="form"
    :rules="rules"
    size="mini"
    label-width="130px"
  > -->
    <el-form-item label="企业名称:" prop="companyName">
      <!-- <el-form-item label="企业名称:" prop="companyName" :rules="rules.companyName"> -->
      <el-input v-model="form.companyName"></el-input>
    </el-form-item>
    <el-form-item label="企业性质:" prop="companyType">
      <el-select v-model="form.companyType" placeholder="请选择">
        <el-option
          v-for="(item, i) of companyTypeOptions"
          :key="item.id"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <div style="width: 100%">
      <el-form-item label="注册省份:" prop="companyProvince">
        <!-- <el-form-item label="注册省份:" prop="companyProvince" :rules="rules.companyProvince"> -->
        <el-select
          v-model="form.companyProvince"
          placeholder="请选择"
          @change="registProvinceChange"
        >
          <el-option
            v-for="item in provinceOptions"
            :key="item.id"
            :label="item.province"
            :value="item.provinceid + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册城市:" prop="companyCity">
        <!-- <el-form-item label="注册城市:" prop="companyCity" :rules="rules.companyCity"> -->
        <el-select
          v-model="form.companyCity"
          placeholder="请选择"
          @change="registCityChange"
        >
          <el-option
            v-for="item in cityOptions"
            :key="item.id"
            :label="item.city"
            :value="item.cityid + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册地区:" prop="companyArea">
      <!-- <el-form-item label="注册地区:" prop="companyArea" :rules="rules.companyArea"> -->
        <el-select v-model="form.companyArea" placeholder="请选择">
          <el-option
            v-for="item in areaOptions"
            :key="item.id"
            :label="item.area"
            :value="item.areaid + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册详细地址:" prop="companyAddess">
        <!-- <el-form-item label="注册详细地址:" prop="companyAddess" :rules="rules.companyAddess"> -->
        <el-input v-model="form.companyAddess"></el-input>
      </el-form-item>
    </div>
    <el-form-item label="成立时间:" prop="companyTime">
      <!-- <el-form-item label="成立时间:" prop="companyTime" :rules="rules.companyTime"> -->
      <el-date-picker
        type="date"
        placeholder="选择日期"
        v-model="form.companyTime"
        value-format="yyyy-MM-dd"
        style="width: 100%;"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="统一社会信用代码:" prop="companyCode">
      <!-- <el-form-item label="统一社会信用代码:" prop="companyCode" :rules="rules.companyCode"> -->
      <el-input v-model="form.companyCode"></el-input>
      <el-checkbox-group v-model="form.isCompanyRedchip">
        <el-checkbox
          style="color: #1a49cd"
          label="红筹企业"
          name="type"
        ></el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item label="行业分类:" prop="companyIndustryId">
      <!-- <el-form-item label="行业分类:" prop="companyIndustryId" :rules="rules.companyIndustryId"> -->
      <el-select v-model="form.companyIndustryId" placeholder="请选择">
        <el-option
          v-for="(item, i) of companyIndustryOptions"
          :key="item.id"
          :label="item.industryName"
          :value="item.industryName"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="战略新兴产业:" prop="companyIndustries">
      <!-- <el-form-item label="战略新兴产业:" prop="companyIndustries" :rules="rules.companyIndustries"> -->
      <el-select v-model="form.companyIndustries" placeholder="请选择">
        <el-option
          v-for="(item, i) of companyIndustriesOptions"
          :key="item.id"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否新三板企业:" prop="isCompanyThird">
      <!-- <el-form-item label="是否新三板企业:" prop="isCompanyThird" :rules="rules.isCompanyThird"> -->
      <el-radio v-model="form.isCompanyThird" label="1">是</el-radio>
      <el-radio v-model="form.isCompanyThird" label="2">否</el-radio>
      <el-radio v-model="form.isCompanyThird" label="3">已摘牌</el-radio>
    </el-form-item>
    <el-form-item label="新三板代码:" prop="companyThirdCode" v-if="showThree">
      <!-- <el-form-item label="新三板代码:" prop="companyThirdCode" v-if="showThree" :rules="rules.companyThirdCode"> -->
      <el-input v-model="form.companyThirdCode"></el-input>
    </el-form-item>
    <el-form-item label="是否为科技型企业:" prop="isCompanyOriented">
      <!-- <el-form-item label="是否为科技型企业:" prop="isCompanyOriented" :rules="rules.isCompanyOriented"> -->
      <el-radio v-model="form.isCompanyOriented" label="1">是</el-radio>
      <el-radio v-model="form.isCompanyOriented" label="2">否</el-radio>
    </el-form-item>
    <el-form-item label="是否高新技术企业:" prop="isCompanyHightech">
      <!-- <el-form-item label="是否高新技术企业:" prop="isCompanyHightech" :rules="rules.isCompanyHightech"> -->
      <el-radio v-model="form.isCompanyHightech" label="1">是</el-radio>
      <el-radio v-model="form.isCompanyHightech" label="2">否</el-radio>
    </el-form-item>
    <el-form-item label="是否国防科工企业:" prop="isCompanyCorporation">
    <!-- <el-form-item label="是否国防科工企业:" prop="isCompanyCorporation" :rules="rules.isCompanyCorporation"> -->
      <el-radio v-model="form.isCompanyCorporation" label="1">是</el-radio>
      <el-radio v-model="form.isCompanyCorporation" label="2">否</el-radio>
    </el-form-item>
    <el-form-item label="已获得的发明专利数量:" prop="companyGetpatentNum">
      <!-- <el-form-item label="已获得的发明专利数量:" prop="companyGetpatentNum" :rules="rules.companyGetpatentNum"> -->
      <el-input
        type="number"
        v-model.number="form.companyGetpatentNum"
      ></el-input>
    </el-form-item>
    <el-form-item label="在审发明专利数量:" prop="companyTrialpantentNum">
      <!-- <el-form-item label="在审发明专利数量:" prop="companyTrialpantentNum" :rules="rules.companyTrialpantentNum"> -->
      <el-input
        type="number"
        v-model.number="form.companyTrialpantentNum"
      ></el-input>
    </el-form-item>
    <el-form-item label="研发人员数量:" prop="companyDevelopmentNum">
      <!-- <el-form-item label="研发人员数量:" prop="companyDevelopmentNum" :rules="rules.companyDevelopmentNum"> -->
      <el-input
        type="number"
        v-model.number="form.companyDevelopmentNum"
      ></el-input>
    </el-form-item>
    <el-form-item label="研发人员占比(%):" prop="companyDevelopmentProportion">
      <!-- <el-form-item label="研发人员占比(%):" prop="companyDevelopmentProportion" :rules="rules.companyDevelopmentProportion"> -->
      <el-input
        type="number"
        v-model="form.companyDevelopmentProportion"
      ></el-input>
    </el-form-item>
    <div style="width: 100%">
      <el-form-item label="办公省份:" prop="companyWorkProvince">
        <!-- <el-form-item label="办公省份:" prop="companyWorkProvince" :rules="rules.companyWorkProvince"> -->
        <el-select
          v-model="form.companyWorkProvince"
          placeholder="请选择"
          @change="workProvinceChange"
        >
          <el-option
            v-for="item in provinceOptions"
            :key="item.id"
            :label="item.province"
            :value="item.provinceid + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="办公城市:" prop="companyWorkCity">
        <!-- <el-form-item label="办公城市:" prop="companyWorkCity" :rules="rules.companyWorkCity"> -->
        <el-select
          v-model="form.companyWorkCity"
          placeholder="请选择"
          @change="workCityChange"
        >
          <el-option
            v-for="item in cityOptions"
            :key="item.id"
            :label="item.city"
            :value="item.cityid + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="办公地区:" prop="companyWorkArea">
        <!-- <el-form-item label="办公地区:" prop="companyWorkArea" :rules="rules.companyWorkArea"> -->
        <el-select v-model="form.companyWorkArea" placeholder="请选择">
          <el-option
            v-for="item in areaOptions"
            :key="item.id"
            :label="item.area"
            :value="item.areaid + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="办公详细地址:" prop="companyWorkAddress">
        <!-- <el-form-item label="办公详细地址:" prop="companyWorkAddress" :rules="rules.companyWorkAddress"> -->
        <el-input v-model="form.companyWorkAddress"></el-input>
      </el-form-item>
    </div>
    <el-form-item label="企业网址:" prop="companyWeb">
       <!-- <el-form-item label="企业网址:" prop="companyWeb" :rules="rules.companyWeb"> -->
      <el-input v-model="form.companyWeb"></el-input>
    </el-form-item>
    <el-form-item label="法定代表人:" prop="companyLegalperson">
      <!-- <el-form-item label="法定代表人:" prop="companyLegalperson" :rules="rules.companyLegalperson"> -->
      <el-input v-model="form.companyLegalperson"></el-input>
    </el-form-item>
    <el-form-item label="法定代表人职务:" prop="companyLegalpersonPost">
      <!-- <el-form-item label="法定代表人职务:" prop="companyLegalpersonPost" :rules="rules.companyLegalpersonPost"> -->
      <el-input v-model="form.companyLegalpersonPost"></el-input>
    </el-form-item>
    <el-form-item label="法定代表人座机:" prop="companyLegalpersonTelephone">
      <!-- <el-form-item label="法定代表人座机:" prop="companyLegalpersonTelephone" :rules="rules.companyLegalpersonTelephone"> -->
      <el-input v-model="form.companyLegalpersonTelephone"></el-input>
    </el-form-item>
    <el-form-item label="法定代表人手机:" prop="companyLegalpersonPhone">
    <!-- <el-form-item label="法定代表人手机:" prop="companyLegalpersonPhone" :rules="rules.companyLegalpersonPhone"> -->
      <el-input v-model="form.companyLegalpersonPhone"></el-input>
    </el-form-item>
    <el-form-item label="联系人:" prop="companyContacts">
      <!-- <el-form-item label="联系人:" prop="companyContacts" :rules="rules.companyContacts"> -->
      <el-input v-model="form.companyContacts"></el-input>
    </el-form-item>
    <el-form-item label="联系人职务:" prop="companyContactsPost">
    <!-- <el-form-item label="联系人职务:" prop="companyContactsPost" :rules="rules.companyContactsPost"> -->
      <el-input v-model="form.companyContactsPost"></el-input>
    </el-form-item>
    <el-form-item label="联系人手机:" prop="companyContactsPhone">
    <!-- <el-form-item label="联系人手机:" prop="companyContactsPhone" :rules="rules.companyContactsPhone"> -->
      <el-input v-model="form.companyContactsPhone"></el-input>
    </el-form-item>
    <el-form-item label="联系人邮箱:" prop="companyContactsMeailbox">
    <!-- <el-form-item label="联系人邮箱:" prop="companyContactsMeailbox" :rules="rules.companyContactsMeailbox"> -->
      <el-input v-model="form.companyContactsMeailbox"></el-input>
    </el-form-item>
    <div style="width: 100%;">
      <el-form-item label="附件:" prop="name1">
      <!-- <el-form-item label="附件:" prop="name1" :rules="rules.name1"> -->
        <el-upload
          class="upload-demo"
          :action="action"
          :on-change="handleChange"
          :file-list="fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <div class="btmtip">
        <span
          >请上传注册人身份证正反面、公司营业执照副本复印件、公司承诺书;
          上传的公司承诺书需要pdf格式。
        </span>
        <a href="http://ipo.hebotc.com/guquan/sys/common/static/%E4%BC%81%E4%B8%9A%E6%89%BF%E8%AF%BA%E4%B9%A6.docx" target="_blank">公司承诺书模板</a>
      </div>
    </div>
  </el-form>
</template>

<script>
import validators from "../../../../util/validators.js";
import base from "../../../../util/request/base";
export default {
  name: "Form1",
  data() {
    var companyCode = (rule, value, callback) => {
      if (!value && !this.form.isCompanyRedchip) {
        return callback(new Error("请填写信用代码或选择红筹企业"));
      } else {
        callback();
      }
    };
    return {
      form: {
        id: "", //公司id
        companyName: "", //公司名称
        companyType: "", //企业性质1，中央国企2，地方国企3，民营企业4，外商独资5，中外合资6，其他
        companyProvince: "", //注册省份
        companyCity: "", //注册城市
        companyArea: "", //注册地区
        companyAddess: "", //注册详细地址
        companyTime: "", //成立时间
        companyCode: "", //信用代码
        isCompanyRedchip: "", //红筹企业1红筹2非红筹
        companyIndustryId: "", //公司行业类别
        companyIndustries: "", //战略新兴产业
        isCompanyThird: "", //是否新三板企业
        companyThirdCode: "", //是否新三板代码
        isCompanyOriented: "", //是否科技型企业状态
        isCompanyHightech: "", //是否高新技术企业
        isCompanyCorporation: "", //是否国防科工企业
        companyGetpatentNum: "", //已获得专利数量
        companyTrialpantentNum: "", //在审专利数量
        companyDevelopmentNum: "", //研发人员数量
        companyDevelopmentProportion: "", //研发人员占比
        companyWorkProvince: "", //公司办公省
        companyWorkCity: "", //公司办公市
        companyWorkArea: "", //公司办公地区
        companyWorkAddress: "", //公司办公详细地址
        companyWeb: "", //企业网址
        companyLegalperson: "", //法定代表人
        companyLegalpersonPost: "", //法定代表人职务
        companyLegalpersonTelephone: "", //法定代表人座机
        companyLegalpersonPhone: "", //法定代表人手机
        companyContacts: "", //公司联系人
        companyContactsPost: "", //公司联系人职务
        companyContactsPhone: "", //公司联系人手机号
        companyContactsMeailbox: "", //公司联系人邮箱
        companyEnclosure: "", //附件
      },
      // rules: {
      //   companyName: [
      //     { required: true, message: "请输入公司名称", trigger: "blur" },
      //   ],
      //   companyType: [
      //     {
      //       required: true,
      //       message: "请选择企业性质",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyProvince: [
      //     {
      //       required: true,
      //       message: "请选择注册省份",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyCity: [
      //     {
      //       required: true,
      //       message: "请选择注册城市",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyArea: [
      //     {
      //       required: true,
      //       message: "请选择注册地区",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyAddess: [
      //     { required: false, message: "请输入注册详细地址", trigger: "blur" },
      //   ],
      //   companyTime: [
      //     {
      //       required: true,
      //       message: "请选择成立时间",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyCode: [{ validator: companyCode, trigger: ["blur", "change"] }],
      //   companyIndustryId: [
      //     { required: true, message: "证监会行业为必填项", trigger: "blur" },
      //   ],
      //   companyIndustries: [
      //     { required: false, message: "战略新兴产业", trigger: "blur" },
      //   ],
      //   isCompanyThird: [
      //     {
      //       required: true,
      //       message: "请选择新三板企业状态",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyThirdCode: [
      //     {
      //       required: false,
      //       message: "请选择新三板代码",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   isCompanyOriented: [
      //     {
      //       required: true,
      //       message: "请选择科技型企业状态",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   isCompanyHightech: [
      //     {
      //       required: true,
      //       message: "请选择高新技术企业状态",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   isCompanyCorporation: [
      //     {
      //       required: false,
      //       message: "请选择国防科工企业",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyGetpatentNum: [
      //     { required: true, message: "请输入已获得专利数量", trigger: "blur" },
      //   ],
      //   companyTrialpantentNum: [
      //     { required: true, message: "请输入在审专利数量", trigger: "blur" },
      //   ],
      //   companyDevelopmentNum: [
      //     { required: true, message: "请输入研发人员数量", trigger: "blur" },
      //   ],
      //   companyDevelopmentProportion: [
      //     { required: true, message: "请输入研发人员占比", trigger: "blur" },
      //   ],
      //   companyWorkProvince: [
      //     {
      //       required: true,
      //       message: "请选择办公省份",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyWorkCity: [
      //     {
      //       required: true,
      //       message: "请选择办公城市",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyWorkArea: [
      //     {
      //       required: true,
      //       message: "请选择办公地区",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   companyWorkAddress: [
      //     { required: false, message: "请输入办公详细地址", trigger: "blur" },
      //   ],
      //   companyWeb: [
      //     { required: false, message: "请输入办公详细地址", trigger: "blur" },
      //   ],
      //   companyLegalperson: [
      //     { required: true, message: "法定代表人为必填项", trigger: "blur" },
      //   ],
      //   companyLegalpersonPost: [
      //     { required: false, message: "法定代表人职务", trigger: "blur" },
      //   ],
      //   companyLegalpersonTelephone: [
      //     { required: false, message: "法定代表人座机", trigger: "blur" },
      //   ],
      //   companyLegalpersonPhone: [
      //     { validator: validators.checkPhone2, trigger: ["blur", "change"] },
      //   ],
      //   companyContacts: [
      //     { required: true, message: "公司联系人", trigger: "blur" },
      //   ],
      //   companyContactsPost: [
      //     { required: true, message: "公司联系人职务", trigger: "blur" },
      //   ],
      //   companyContactsPhone: [
      //     { validator: validators.checkPhone, trigger: ["blur", "change"] },
      //   ],
      //   companyContactsMeailbox: [
      //     { required: true, message: "公司联系人邮箱", trigger: "blur" },
      //   ],
      // },
      companyTypeOptions: [
        { id: 1, label: "中央国企", value: "1" },
        { id: 2, label: "地方国企", value: "2" },
        { id: 3, label: "民营企业", value: "3" },
        { id: 4, label: "外商独资", value: "4" },
        { id: 5, label: "中外合资", value: "5" },
        { id: 6, label: "其他", value: "6" },
      ],
      companyIndustriesOptions: [
        { id: 1, label: "新一代信息技术产业", value: "1" },
        { id: 2, label: "高端装备制造产业", value: "2" },
        { id: 3, label: "新材料产业", value: "3" },
        { id: 4, label: "生物产业", value: "4" },
        { id: 5, label: "新能源汽车产业", value: "5" },
        { id: 6, label: "新能源产业", value: "6" },
        { id: 7, label: "节能环产业", value: "7" },
        { id: 8, label: "数字创意产业", value: "8" },
        { id: 9, label: "相关服务业", value: "9" },
      ],
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      companyIndustryOptions: [],
      fileList: [],
      action: base.uploadFile,
    };
  },
  computed: {
    showThree() {
      let data = this.form.isCompanyThird;
      return data == 1 || data == 3 ? true : false;
    },
  },
  mounted() {
    this.industryList();
    this.companyMessage();
    this.getProvince();
    this.getCity();
    this.getArea();
  },
  methods: {
    onSubmit() {
      console.log(this.form.id)
    //   this.$refs.form.validate((valid) => {
    //     if (valid) {
    //       //验证表单
    //       this.$emit("submit", this.form, 1);
    //     } else {
    //       // console.log(this.form)
    //       // this.$message({
    //       //   message: "请填写完整",
    //       //   center: true,
    //       // });
    //       return false;
    //     }
    //   });
    },
    registProvinceChange(provinceId) {
      this.form.companyArea = this.form.companyCity = "";
      this.getCity(provinceId);
      // console.log(this.cityOptions);
    },
    registCityChange(cityId) {
      this.form.companyArea = "";
      this.getArea(cityId);
    },
    workProvinceChange(provinceId) {
      this.form.companyWorkCity = this.form.companyWorkArea = "";
      this.getCity(provinceId);
    },
    workCityChange(cityId) {
      this.form.companyWorkArea = "";
      this.getArea(cityId);
    },
    async getArea(cityId) {
      let res = await this.$api.getArea({ cityId });
      this.areaOptions = res.result;
      // console.log(res);
    },
    async getCity(provinceId) {
      let res = await this.$api.getCity({ provinceId });
      this.cityOptions = res.result;
    },
    async getProvince() {
      let res = await this.$api.getProvince();
      this.provinceOptions = res.result;
    },
    // 行业分类
    async industryList() {
      let res = await this.$api.industryList();
      this.companyIndustryOptions = res.result;
    },
    // 获取初始信息
    async companyMessage() {
      let userId = this.$store.state.userInfo.id;
      let res = await this.$api.companyMessage({ userId });
      for (let key in res.result.company) {
        //回显数据
        for (key in this.form) {
          this.form[key] = res.result.company[key];
        }
      }
      //根据id查省份
      let proId = this.form.companyProvince;
      let proId1 = this.form.companyWorkProvince;
      let cityId = this.form.companyCity;
      let cityId1 = this.form.companyWorkCity;
      let areaId = this.form.companyArea;
      let areaId1 = this.form.companyWorkArea;
      this.checkProName(proId);
      this.checkProName(proId1);
      this.getCity(proId);
      this.getCity(proId1);
      this.checkCityName(cityId);
      this.checkCityName(cityId1);
      this.getArea(cityId);
      this.getArea(cityId1);
      this.checkAreaName(areaId);
      this.checkAreaName(areaId1);
    },
    // 根据id查名称,m为要查的数组数组
    checkProName(m) {
      for (var i = 0; i < this.provinceOptions.length; i++) {
        if (m == this.provinceOptions[i].provinceid) {
          m = this.provinceOptions[i].province;
        }
      }
    },
    checkCityName(n) {
      for (var i = 0; i < this.cityOptions.length; i++) {
        if (n == this.cityOptions[i].cityid) {
          n = this.cityOptions[i].city;
        }
      }
    },
    checkAreaName(p) {
      for (var i = 0; i < this.areaOptions.length; i++) {
        if (p == this.areaOptions[i].areaid) {
          p = this.areaOptions[i].area;
        }
      }
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      if (file.response) {
        if (fileList.length > 0) {
          this.form.companyEnclosure += "," + file.response.message;
        } else {
          this.form.companyEnclosure += file.response.message;
        }
      }
      this.form.companyEnclosure = this.form.companyEnclosure.slice(10);
    },
  },
};
</script>

<style scoped>
.btmtip {
  font-size: 14px;
}

.inline {
  display: flex;
}

.inline .el-form-item {
  flex: 1;
}

.tagBox {
  margin: 15px 0;
}

.tableBox th {
  margin-bottom: 15px;
}

.exprenceTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exprenceBox {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.el-form {
  display: flex;
  flex-wrap: wrap;
}

.el-form-item {
  width: 48%;
}

.el-select,
.el-date-editor.el-input,
.el-input {
  width: 185px;
}
</style>
