<template>
    <div>
        <div class="support_banner">
            <img src="../../static/img/zjj_support_banner.png"/>
        </div>
        <div class="boxcont">
            <!--导航条-->
            <div class="details_nav w">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/roadShow' }">培训路演</el-breadcrumb-item>
                    <el-breadcrumb-item>活动专题</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="listCount">
                <div class="lists" v-for="(item,i) in list" :key="i" @click="toDetail(item.id,item.type)">
                    <div class="leftbox">
                        <div class="data">{{item.time.slice(6,10)}}</div>
                        <div class="year">{{item.time.slice(0,4)}}</div>
                    </div>
                    <div class="rightbox">
                        <div class="title">{{item.title}}</div>
                        <div class="city">{{item.city}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RoadShowDic",
        data(){
            return {
                list:[]
            }
        },
        mounted() {
            this.id = this.$route.params.id
            this.getMsg();

        },
        methods: {
            async getMsg(){
                let res = await this.$api.querySpecial({specialId:this.id});
                this.list = res.result;
                console.log(res);
            },
            toDetail(id,type){
                    // type 3  线上课程 2上市培训  1直播
                if(type==3){
                    this.$router.push('/detail/4/' + id)
                }else if(type==2){
                    this.$router.push('/detail/3/' + id)
                }else if(type==1){
                    this.$router.push('/uliveDetail/' + id)
                }
            }
        }
    }
</script>

<style scoped>
.boxcont{
    width: 1200px;
    margin: 0 auto;
}
.support_banner {
    width: 100%;
    height: 340px;
}

.support_banner img {
    width: 100%;
    height: 100%;
    min-width: 1200px;
}
    .listCount{

    }
    .lists{
        display: flex;
        margin-bottom: 40px;
        cursor: pointer;
        transition: all 0.2s;
    }
    .lists:hover{
        background: #eee;
    }
    .leftbox{
        width: 70px;
        height: 70px;
        padding: 10px 10px;
        margin-right: 30px;
       background: #075aa6;
        color: #fff;
    }
    .data{
        font-size: 23px;
    }
    .year{
        font-size: 15px;
    }
    .rightbox{
        padding-top: 15px;
    }
    .title{
        font-size: 18px;
    }
    .city{
        font-size: 12px;
        color: #666;
    }
</style>