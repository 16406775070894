<template>
  <div>
    <div class="support_banner">
      <img src="../../static/img/zjj_support_banner.png" />
    </div>
    <div class="ccbox">
      <!--导航条-->
      <div class="details_nav w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/roadShow' }"
            >培训路演</el-breadcrumb-item
          >
          <el-breadcrumb-item>机构报名</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="innercont">
        <div class="boxTop">
          <div class="boxs" :class="{ active: form.type == 1 }" @click="jigou()">
            <img src="../../static/img/jigou-active.png" v-if="form.type == 1">
            <img src="../../static/img/jigou.png" v-else>
            <div>机构</div>
          </div>
          <div class="boxs" :class="{ active: form.type == 2 }" @click="qiye()">
              <img src="../../static/img/qiye-zctive.png" alt="" v-if="form.type == 2">
              <img src="../../static/img/../../static/img/qiye.png" alt="" v-else>
            <div>企业</div>
          </div>
        </div>
        <div class="formbox">
          <el-form
            style="margin-top: 20px;"
            ref="form"
            :model="form"
            :rules="rules"
            label-width="130px"
          >
            <el-form-item label-width="0" prop="radshowId">
              <el-select style="width:100%;" v-model="form.radshowId">
                <el-option
                  v-for="item in periodsNum"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id + ''"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="0" prop="name">
              <el-input
                placeholder="请输入企业名称"
                v-model="form.name"
              ></el-input>
            </el-form-item>
            <el-form-item label-width="0" prop="person">
              <el-input
                placeholder="请输入联系人"
                v-model="form.person"
              ></el-input>
            </el-form-item>
            <el-form-item label-width="0" prop="phone">
              <el-input
                placeholder="请输入联系电话"
                v-model="form.phone"
              ></el-input>
            </el-form-item>
            <el-form-item label-width="0" prop="smsCode">
              <div class="codediv">
                <el-input
                  class="codeinput"
                  type="number"
                  v-model="form.smsCode"
                  placeholder="请输入验证码"
                ></el-input>
                <CodeBtn :phone="form.phone" codeType="getCode4"></CodeBtn>
              </div>
            </el-form-item>
            <el-button style="width: 100%" @click="submit" type="primary"
              >提交</el-button
            >
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeBtn from "../../components/logincpn/CodeBtn";
import validators from "../../util/validators";
import methods from "../../util/methods";
export default {
  name: "Registration",
  data() {
    return {
      code: "",
      id: "",
      form: {
        radshowId: "",
        name: "",
        person: "",
        phone: "",
        smsCode: "",
        userId: "",
        type: 1,
      },
      rules: {
        radshowId: [
          {
            required: true,
            message: "请选择报名期数",
            trigger: ["blur", "change"],
          },
        ],
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        person: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        phone: [
          { validator: validators.checkPhone, trigger: ["blur", "change"] },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      periodsNum: [],
    };
  },
  mounted() {
    this.id = this.$route.params.id; //获取详情id
    let userInfo = this.$store.state.userInfo;
    if (!userInfo) {
      this.$alert("请登录后再试", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.gotoLogin();
        },
      });
    } else {
      this.form.userId = userInfo.id;
    }
    this.getData();
  },
  components: {
    CodeBtn,
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login");
    },
    jigou(){
        this.form.type = 1
    },
    qiye(){
        this.form.type = 2
    },
    getCode(code) {
      this.code = code;
    },
    async sendMsg() {
      //获取详情
      let res = await this.$api.radshowsignup(this.form);
      this.info = res.result;
      if (res.success) {
        this.$message({
          message: "发送成功",
          type: "success",
          center: true,
          offset: 250
        });
        this.$router.go(-1);
      }
    },
    async getData() {
      let res = await this.$api.radshowBannerImg();
      this.periodsNum = res.result;
      // console.log(this.periodsNum)
    },
    //提交
    submit(formName) {
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          //验证表单
          this.sendMsg();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.codediv {
  display: flex;
}
.formbox {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 100px;
}
.support_banner {
  width: 100%;
  height: 340px;
}

.support_banner img {
  width: 100%;
  height: 100%;
  min-width: 1200px;
}
.ccbox {
  width: 1200px;
  margin: 0 auto;
}
.innercont {
  width: 520px;
  margin: 0 auto;
  .boxTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .boxs {
      width: 190px;
      height: 166px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 22px;
      img {
        width: 50px;
        object-fit: contain;
        margin-bottom: 14px;
      }
    }
    .active {
      background: #448beb;
      color: #fff;
    }
  }
}
</style>
