<template>
    <!--主体代码-->
   <div id="zhl_12">
       <div id="box">
           <div class="nr di clearfix">
               <div class="duibi clearfix">
                   <span>沪深京交易所上市条件对比</span>
<!--                   <div class="jiaoliu"><a href="">在线咨询交流</a></div>-->
               </div>
               <div class="aniu clearfix">
                   <ul class="aniu1">
                       <li v-for="(item,i) in menus" :key="i"
                       @click="currIndex = i" :class="{'on':currIndex==i}">{{item}}</li>
                   </ul>
               </div>

               <div class="cyb clearfix" v-show="currIndex==0">
                   <div class="zou">
                       <div class="tou1">上海证券交易所</div>
                       <div class="zoul">主板</div>
                       <div class="your">科创板</div>
                       <div class="zoul2"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="your2"></div>
                       <div class="zoul3">
                           <p>发行前三年的累计净利润超过3,000万人民币；发行前三年累计净经营性现金流超过5,000万人民币或累计营业收入超过3亿元；无形资产与净资产比例不超过20%；过去三年的财务报告中无虚假记载。</p>
                       </div>
                       <div class="your3">
                           <p>符合下列标准中的一项:</p>
                           <p>（一）预计市值不低于人民币10亿元，最近两年净利润均为正且累计净利润不低于人民币5000万元，或者预计市值不低于人民币10亿元，最近一年净利润为正且营业收入不低于人民币1亿元；</p>
                           <p>（二）预计市值不低于人民币15亿元，最近一年营业收入不低于人民币2亿元，且最近三年累计研发投入占最近三年累计营业收入的比例不低于15%；</p>
                           <p>...</p>
                       </div>
                   </div>
                   <div class="zhong">
                       <div class="tou2">深圳证券交易所</div>
                       <div class="zzoul">主板</div>
                       <div class="zyour">创业板</div>
                       <div class="zhong3z"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong3r"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong4z">
                           最近3个会计年度净利润均为正数且累计超过3000万元；最近3个会计年度经营活动产生的现金流量净额累计超过5000万元，或者最近3个会计年度营业收入累计超过3亿元；最近一期末不存在未弥补亏损；最近一期末无形资产占净资产的比例不高于20%；发行前股本总额不少于3000万元。
                           </p>
                       </div>
                       <div class="zhong4r">

                           <p>
                               最近两年连续盈利，最近两年净利润累计超过1000万元，且持续增长；或者最近一年盈利，且净利润不少于500万元，最近一年营业收入不少于5000万元，最近两年营业收入增长率均不低于30％；最近一期末不存在未弥补亏损；最近一期末净资产不少于2000万元。
                           </p>
                       </div>
                   </div>
                   <div class="you">
                       <div class="tou">北京证券交易所</div>
                       <div class="you2">主板</div>
                       <div class="you3"><b>经营时间：<br /></b>发行人为在全国股转系统连续挂牌满12个月的创新层挂牌公司</div>
                       <div class="you4">
						   <p>(一)预计市值不低于2亿元，最近两年净利润均不低于1500 万元且加权平均净资产收益率平均不低于8%，或者最近一年净利润不低于2500万元且加权平均净资产收益率不低于 8%;(二)预计市值不低于4亿元，最近两年营业收入平均不低于1亿元，且最近一年营业收入增长率不低于 30%，最近一年经营活动产生的现金流量净额为正;(三)预计市值不低于8亿元，最近一年营业收入不低于2亿元，最近两年研发投入合计占最近两年营业收入合计比例不低于 8%;(四)预计市值不低于15亿元，最近两年研发投入合计不低于5000万元。</p>
                           <!-- <p>发行人应当具备持续盈利能力，财务状况良好。</p>
                           <p>发行人最近3年财务会计文件无虚假记载。</p>
                           <p>
                               最近一年期末净资产不低于5000万元:公开发行的股份不少于100万股。发行对象不少于100人;公开发行后，公司股本总额不少于3,000万元;公开发行后，公司股东人数不少于200人，公众殷东持股比例不低于公司股本总额的25%;公司股本总额超过4亿元的，公众股东持股比例不低于公司股本总额的10%。
                           </p> -->
                       </div>
                   </div>
               </div><!--
                              	作者：offline
                              	时间：2020-11-17
                              	描述：内容1
                              -->

               <div class="cyb clearfix"  v-show="currIndex==1">
                   <div class="zou">
                       <div class="tou1">上海证券交易所</div>
                       <div class="zoul">主板</div>
                       <div class="your">科创板</div>
                       <div class="zoul2"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="your2"></div>
                       <div class="zoul3">
                           <p>
                               发行前不少于3,000万股；上市股份公司股本总额不低于人民币5,000万元；公众持股至少为25%；如果发行时股份总数超过4亿股，发行比例可以降低，但不得低于10%；发行人的股权清晰，控股股东和受控股股东、实际控制
                               人支配的股东持有的发行人股份不存在重大权属纠纷。</p>
                       </div>
                       <div class="your3">
                           <p>发行后股本总额不低于人民币3000万元；公开发行的股份达到公司股份总数的25%以上；公司股本总额超过人民币4亿元的，公开发行股份的比例为10%以上。</p>
                       </div>
                   </div>
                   <div class="zhong">
                       <div class="tou2">深圳证券交易所</div>
                       <div class="zzoul">主板</div>
                       <div class="zyour">科创板</div>
                       <div class="zhong3z"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong3r"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong4z">发行后的股本总额不少于3000万元。</div>
                       <div class="zhong4r">
                           <p>发行后的股本总额不少于5000万元。</p>
                       </div>
                   </div>
                   <div class="you">
                       <div class="tou">北京证券交易所</div>
                       <div class="you2">主板</div>
                       <div class="you3"><b>经营时间：<br /></b>发行人为在全国股转系统连续挂牌满 12 个月的创新层挂牌公司</div>
                       <div class="you4">
						    <p>公开发行后，公司股本总额不少于3000万元</p>
                           <!-- <p>发行人应当具备持续盈利能力，财务状况良好。</p>
                           <p>发行人最近3年财务会计文件无虚假记载。</p>
                           <p>
                               最近一年期末净资产不低于5000万元:公开发行的股份不少于100万股。发行对象不少于100人;公开发行后，公司股本总额不少于3,000万元;公开发行后，公司股东人数不少于200人，公众殷东持股比例不低于公司股本总额的25%;公司股本总额超过4亿元的，公众股东持股比例不低于公司股本总额的10%。
                           </p> -->
                       </div>
                   </div>
               </div><!--
                              	作者：offline
                              	时间：2020-11-17
                              	描述：内容2
                              -->

               <div class="cyb clearfix" v-show="currIndex==2">
                   <div class="zou">
                       <div class="tou1">上海证券交易所</div>
                       <div class="zoul">主板</div>
                       <div class="your">科创板</div>
                       <div class="zoul2"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="your2"></div>
                       <div class="zoul3">
                           <p>应具有完整的业务体系和直接面向市场独立经营的能力；资产应当完整；人员、财务、机构以及业务必须独立。与控股股东、实际控制人及其控制的其他企业间不得有同业竞争；募集资金投资项目实施后，也不会产生同业竞争。</p>
                       </div>
                       <div class="your3">

                       </div>
                   </div>
                   <div class="zhong">
                       <div class="tou2">深圳证券交易所</div>
                       <div class="zzoul">主板</div>
                       <div class="zyour">科创板</div>
                       <div class="zhong3z"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong3r"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong4z">应当主要经营一种业务。</div>
                       <div class="zhong4r">
                           <p>完整的业务体系， 直接面向市场独立经营的能力。</p>
                       </div>
                   </div>
                   <div class="you">
                       <div class="tou">北京证券交易所</div>
                       <div class="you2">主板</div>
                       <div class="you3"><b>经营时间：<br /></b>发行人为在全国股转系统连续挂牌满 12 个月的创新层挂牌公司</div>
                       <div class="you4">
						  
                           <!-- <p>发行人应当具备持续盈利能力，财务状况良好。</p>
                           <p>发行人最近3年财务会计文件无虚假记载。</p>
                           <p>
                               最近一年期末净资产不低于5000万元:公开发行的股份不少于100万股。发行对象不少于100人;公开发行后，公司股本总额不少于3,000万元;公开发行后，公司股东人数不少于200人，公众殷东持股比例不低于公司股本总额的25%;公司股本总额超过4亿元的，公众股东持股比例不低于公司股本总额的10%。
                           </p> -->
                       </div>
                   </div>
               </div>
               <!--
                   作者：offline
                   时间：2020-11-17
                   描述：内容3
               -->
               <div class="cyb clearfix" v-show="currIndex==3">
                   <div class="zou">
                       <div class="tou1">上海证券交易所</div>
                       <div class="zoul">主板</div>
                       <div class="your">科创板</div>
                       <div class="zoul2"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="your2"></div>
                       <div class="zoul3">
                           <p>
                               发行人已经依法建立健全股东大会、董事会、监事会、独立董事、董事会秘书制度，相关机构和人员能够依法履行职责；发行人董事、监事和高级管理人员符合法律、行政法规和规章规定的任职资格；发行人的董事、监事和高级管理人员已经了解与股票发行上市有关的法律法规，知悉上市公司及其董事、监事和高级管理人员的法定义务和责任；内部控制制度健全且被有效执行，能够合理保证财务报告的可靠性、生产经营的合法性、营运的效率与效果。
                           </p>
                       </div>
                       <div class="your3">
                           <!-- <p>符合下列标准中的一项:</p>
                           <p>(一)预计市值不低于人民币10亿元，最近两年净利润均为正且累计净利润不低于人民币5000万元。或者预计市值不低于人民币10亿元，最近一年净利润为正且营业收入不低于人民币1亿元;</p>
                           <p>(二)预计市值不低于人民币15亿元，最近一年营业收入不低于人民币2亿元，且最近三年累计研发投入占最近三年累计营业收入的比例不低于15%;</p>
                           <p>.......</p> -->
                       </div>
                   </div>
                   <div class="zhong">
                       <div class="tou2">深圳证券交易所</div>
                       <div class="zzoul">主板</div>
                       <div class="zyour">科创板</div>
                       <div class="zhong3z"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong3r"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong4z">具有完善的公司治理结构，依法建立健全股东大会、董事会、监事会以及独立董事、董事会秘书、审计委员会制度，相关机构和人员能够依法履行职责。</div>
                       <div class="zhong4r">
                           <p>最近3年主营业务、董事和高级管理人员无
                               重大变动，实际控制人没有变更；董事会下设战略、审计、薪酬委员会，各委员会至少指定一名独立董事会成员担任委员；至少三分之一的董事会成员为独立董事。</p>
                       </div>
                   </div>
                   <div class="you">
                       <div class="tou">北京证券交易所</div>
                       <div class="you2">主板</div>
                       <div class="you3"><b>经营时间：<br /></b>发行人为在全国股转系统连续挂牌满 12 个月的创新层挂牌公司</div>
                       <div class="you4">
                           <p>
                               具备健全且运行良好的组织机构。最近3年内，公司及其控股股东、实际控制人不存在贪污、贿赂、侵占财产、挪用财产或者破坏社会主义市场经济秩序的刑事犯罪，不存在欺诈发行、重大信息披露违法或者其他涉及国家安全、公共安全、生态安全、生产安全、公众健康安全等领域的重大违法行为，最近12个月内未受到中国证监会行政处罚。
                           </p>
                       </div>
                   </div>
               </div>
               <div class="cyb clearfix" v-show="currIndex==4">
                   <div class="zou">
                       <div class="tou1">上海证券交易所</div>
                       <div class="zoul">主板</div>
                       <div class="your">科创板</div>
                       <div class="zoul2"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="your2"></div>
                       <div class="zoul3">
                           <p>
                               发行人最近三年内主营业务和董事、高级管理人员没有发生重大变化，实际控制人没有发生变更；发行人的注册资本已足额缴纳，发起人或者股东用作出资的资产的财产权转移手续已办理完毕，发行人的主要资产不存在重大权属纠纷；发行人的生产经营符合法律、行政法规
                               和公司章程的规定，符合国家产业政策；最近三年内不得有重大违法行为。</p>
                       </div>
                       <div class="your3">
                           <!-- <p>符合下列标准中的一项:</p>
                           <p>(一)预计市值不低于人民币10亿元，最近两年净利润均为正且累计净利润不低于人民币5000万元。或者预计市值不低于人民币10亿元，最近一年净利润为正且营业收入不低于人民币1亿元;</p>
                           <p>(二)预计市值不低于人民币15亿元，最近一年营业收入不低于人民币2亿元，且最近三年累计研发投入占最近三年累计营业收入的比例不低于15%;</p>
                           <p>.......</p> -->
                       </div>
                   </div>
                   <div class="zhong">
                       <div class="tou2">深圳证券交易所</div>
                       <div class="zzoul">主板</div>
                       <div class="zyour">科创板</div>
                       <div class="zhong3z"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong3r"><b>经营时间：<br /></b>持续三年以上</div>
                       <div class="zhong4z"></div>
                       <div class="zhong4r">
                           <!-- <p>最近两年连续盈利，最近两年净利润累计超</p>
                           <p>过1000万元，且持续增长﹔或者最近一年盈利，且净利润不少于500万元，最近一年营业收入不少于5000万元，最近两年营业收入增长率均不低于30%;最近一期末不存在未弥补亏损;最近一期末净资产不少于2000万元。
                           </p> -->
                       </div>
                   </div>
                   <div class="you">
                       <div class="tou">北京证券交易所</div>
                       <div class="you2">主板</div>
                       <div class="you3"><b>经营时间：<br /></b>发行人为在全国股转系统连续挂牌满 12 个月的创新层挂牌公司</div>
                       <div class="you4">
                           <!-- <p>发行人应当具备持续盈利能力，财务状况良好。</p>
                           <p>发行人最近3年财务会计文件无虚假记载。</p>
                           <p>
                               最近一年期末净资产不低于5000万元:公开发行的股份不少于100万股。发行对象不少于100人;公开发行后，公司股本总额不少于3,000万元;公开发行后，公司股东人数不少于200人，公众殷东持股比例不低于公司股本总额的25%;公司股本总额超过4亿元的，公众股东持股比例不低于公司股本总额的10%。
                           </p> -->
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</template>

<script>
    export default {
        name: "contrast",
        data(){
            return {
                currIndex:0,
                menus:['财务要求','股本要求','业务经营','公司管理','其他要求']
            }
        },
        methods: {
            changeBox(e){
                console.log(e);
            }
        }
    }
</script>

<style scoped>
    #zhl_12 #box {
        width: 100%;
        /*height:2367px;*/
        margin-bottom: 50px;
        min-width: 1200px;
        width: expression_r(document.body.clientWidth < 1200 ? "1200px": "auto" );
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #zhl_12 .jiaoliu, .aniu1 li {
        cursor: pointer;
    }

    #zhl_12 .nr {
        width: 1200px;
        /*height: 2500px;*/
        margin: 0 auto;
        position: relative;
    }

    .banner img {
        width: 100%;
        height: 340px;
        min-width: 1485px;
        width: expression_r(document.body.clientWidth < 1200 ? "1200px": "auto" );
    }

    .duibi {
        width: 100%;
        height: 120px;
        text-align: center;
        color: #000000;
        font-size: 22px;
        line-height: 100px;
    }

    .duibi span {
        display: block;
        float: left;
        margin-left: 510px;
    }

    .jiaoliu {
        width: 205px;
        height: 45px;
        background-color: #34a8ff;
        color: #FFFFFF;
        line-height: 45px;
        text-align: center;
        border-radius: 5px;
        margin-top: 25px;
        float: right;
        margin-right: 160px;
        font-weight: bold;
    }

    .jiaoliu a {
        color: #FFFFFF;
    }

    .zhl_btn {
        width: 46.4px;
        height: 835px;
    }

    .aniu {
        width: 46.4px;
        height: 852px;
        display: block;
        float: left;
        margin-top: 22px;
        padding-left: 5px;
    }

    .aniu ul li {
        display: block;
        width: 46.4px;
        height: 143px;
        padding: 10px 14px 0px 13px;
        background-color: #bfbfbf;
        margin-bottom: 23px;
        color: #FFFFFF;
        border-radius: 5px 0px 0px 5px;
        font-size: 24px;
    }

    #zhl_12 li.on {
        background-color: #34a8ff !important;
    }

    .aniu {
        position: absolute;
        top: 380px;
        font-weight: bold;
    }

    .cyb {
        width: 1160px;
        height: 1127px;
        display: block;
        float: right;
        left: 60px;
        margin: 0 auto;
        padding-left: 11px;
    }

    .none {
        display: none;
    }

    #zhl_12 div.on {
        opacity: 1;
    }

    .zou {
        width: 417.6px;
        height: 100%;
        float: left;
    }

    .zhong {
        width: 480px;
        height: 100%;
        float: left;
        margin-left: 18px;
    }

    .you {
        width: 212.8px;
        height: 100%;
        float: left;
        margin-left: 18px;
    }

    .tou {
        width: 212.8px;
        height: 70px;
        background-color: #34a8ff;
        color: #FFFFFF;
        line-height: 70px;
        text-align: center;
        font-size: 25px;
    }

    .zoul {
        width: 236px;
        height: 60px;
        float: left;
        background-color: #d8edfe;
        text-align: center;
        line-height: 60px;
        color: #35a9fe;
        font-weight: bold;
    }

    .your {
        width: 179.2px;
        height: 60px;
        float: right;
        background-color: #d8edfe;
        text-align: center;
        line-height: 60px;
        color: #35a9fe;
        font-weight: bold;
    }

    .zoul2 {
        width: 236px;
        height: 146px;
        float: left;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 15px;
        line-height: 35px;
    }

    .your2 {
        width: 179.2px;
        height: 146px;
        float: right;
        background-color: #d8edfe;
        text-align: center;
        line-height: 60px;
        color: #35a9fe;
        margin-top: 2px;
    }

    .zoul3 {
        width: 236px;
        height: 849px;
        float: left;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 60px 15px 0px 15px;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }

    .your3 {
        width: 179.2px;
        height: 849px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        text-align: justify;
        line-height: 30px;
        margin-top: 2px;
        padding: 60px 25px 0px 10px;
        text-indent: 2em;
    }

    .zzoul {
        width: 243.2px;
        height: 60px;
        background-color: #d8edfe;
        float: left;
        text-align: center;
        line-height: 60px;
        color: #35a7ff;
        font-weight: bold;
    }

    .zyour {
        width: 233.6px;
        height: 60px;
        background-color: #d8edfe;
        float: right;
        text-align: center;
        line-height: 60px;
        color: #35a7ff;
        font-weight: bold;
    }

    .zhong3z {
        width: 243.2px;
        height: 146px;
        float: left;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 15px;
        line-height: 35px;
    }

    .zhong3r {
        width: 233.6px;
        height: 146px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 15px;
        line-height: 30px;
    }

    .zhong4z {
        width: 243.2px;
        height: 849px;
        float: left;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 60px 30px 0px 30px;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }

    .zhong4r {
        width: 233.6px;
        height: 849px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 60px 30px 0px 30px;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }

    .you2 {
        width: 100%;
        height: 60px;
        background-color: #d8edfe;
        float: left;
        text-align: center;
        line-height: 60px;
        color: #35a7ff;
        font-weight: bold;
    }

    .you3 {
        width: 100%;
        height: 146px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 15px 15px 10px 15px;
        line-height: 30px;
    }

    .you4 {
        width: 100%;
        height: 849px;
        float: right;
        background-color: #d8edfe;
        color: #000000;
        margin-top: 2px;
        padding: 60px 20px 0px 20px;
        line-height: 30px;
        text-indent: 2em;
        text-align: justify;
    }

    .nr2 {
        width: 1160px;
        height: 1150px;
        margin: 0 auto;
        margin-top: 1220px;
    }

    .title {
        width: 100%;
        height: 38px;
    }

    .title_z {
        font-size: 24px;
        color: #3ca9fb;
        font-weight: bold;
        line-height: 38px;
    }

    .title_y {
        display: block;
        float: right;
        line-height: 38px;
    }

    .title_y a {
        color: #000000;
    }

    .wenti {
        width: 100%;
        height: 640px;
        margin-top: 40px;
        font-size: 16px;
        padding-left: 75px;
        line-height: 30px;
    }

    .huifu {
        color: #3fa9ff;
        font-size: 14px;
        text-align: right;
        margin-right: 60px;
    }

    .huifu a {
        color: #3fa9ff;
    }

    .xian {
        width: 95%;
        height: 1px;
        background-color: #d2d2d2;
        margin-top: 45px;
    }

    .faxing {
        color: #3ca9fc;
        font-size: 18px;
    }

    .wenti2 {
        width: 100%;
        height: 400px;
        font-size: 16px;
        padding-left: 75px;
        margin-top: 143px;
        line-height: 30px;
    }

    .jdb {
        margin-top: 18px;
        margin-bottom: 50px;
    }

    .di {
        min-width: 1200px;
        width: expression_r(document.body.clientWidth < 1200 ? "1200px": "auto" );
    }

    .tou1 {
        width: 417.6px;
        height: 70px;
        background-color: #34a8ff;
        color: #FFFFFF;
        line-height: 70px;
        text-align: center;
        font-size: 25px;
    }

    .tou2 {
        width: 480px;
        height: 70px;
        background-color: #34a8ff;
        color: #FFFFFF;
        line-height: 70px;
        text-align: center;
        font-size: 25px;
    }

    .tubiao {
        width: 50px;
        height: 1200px;
        position: absolute;
        left: px;
        top: 1400px;
        font-size: 45px;
        color: #3ca9fc;
    }

    #zhl_12 {
        width: 100%;

    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }



</style>
