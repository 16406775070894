<template>
    <div id="yyj_1">
        <section class="cc">
            <img src="../../static/img/yyj_tu5.png" class="ce"/>

            <div class="yzb">云直播</div>
            <div class="cd w">
                <div class="ci">
                    <span class="ee8">直播专题：</span>
                    <a href="javascript:;"
                       :class="{Any:currIndex ==0}" @click="Search('',0,1)"
                    >不限</a>
                    <a href="javascript:;"
                       v-for="(item,i) in areas" :key="item.id"
                       :class="{Any:currIndex ==i+1}" @click="Search(item.id,i+1,1)"
                    >{{item.name}}</a>
                </div>
                <div class="ci">
                    <span class="ee8">直播类型：</span>
                    <a href="javascript:;"
                       :class="{Any:currIndex3 ==0}" @click="Search('',0,3)"
                    >不限</a>
                    <a href="javascript:;"
                       v-for="(item,i) in types" :key="item.id"
                       :class="{Any:currIndex3 ==i+1}" @click="Search(item.id,i+1,3)"
                    >{{item.name}}</a>
                </div>
                <div class="ci">
                    <span class="ee8">直播时间：</span>
                    <a href="javascript:;"
                       :class="{Any:currIndex2 ==0}" @click="Search('',0,2)"
                    >不限</a>
                    <a href="javascript:;"
                       v-for="(item,i) in times" :key="item.id"
                       :class="{Any:currIndex2 ==i+1}" @click="Search(item.id,i+1,2)"
                    >{{item.name}}</a>

                </div>
                <div class="sc">
                    <input type="text" v-model="form.keyWord" placeholder="请输入关键字查询" class="sd">
                    <img src="../../static/img/lzy_03_2.png"  @click="toSearch"  style="width: 16px;cursor: pointer">
                </div>
                <!--                <div class="ch">展开筛选条件</div>-->
            </div>
        </section>


        <section id="lbs" class="dd w">
            <div class="bs2">
                <div class="bs3">
                    <span class="lbss3" :class="{'active':sort==0}" @click="changeSort('default')">默认排序</span>
                </div>
                <div class="bs4">
                        <span class="lbss3" :class="{'active':sort!=0}" @click="changeSort('time')">
                            <span>活动开始时间</span>
                            <img class="jiantou" :class="{'jiantouTop':sort==2}"
                                 src="../../static/img/pxdown.png" alt="">
                        </span>
                </div>
                <div class="bs5">
                    <div class="bs7">
                        <p>为您找到<span class="bs77">{{total}}</span>条结果</p>
                    </div>
                </div>
            </div>
            <!--换-->
            <div class="asdbig clearfix">
                <div class="asdwid clearfix">
                    <div class="asd">
                        <div class="da" v-for="(item,i) in dataList" :key="item.id">
                            <router-link tag="a" :to="'/uliveDetail/'+item.id" class="aaa">
                                <img class="imgboxss" :src="item.imageUrl|imgUrl"/>
                            </router-link>
                            <div class="ee">
                                <span class="ee1">{{item.title}}</span><br/>
                                <span class="ee2">主办单位：</span>
                                <span class="ee3">{{item.organizer}}</span><br/>
                                <span class="ee2">直播时间：</span>
                                <span class="ee3">{{item.beginTime}}</span><br/>
                                <span class="ee3 overhid">{{item.content}}</span><br/>
                                <span class="ee4">{{item.keyWord}}</span><br/>

<!--                                <a href="##">-->
<!--                                    <span v-if="item.uLiveState==1" class="ee7">预约报名</span>-->
<!--                                    <span v-else-if="item.uLiveState==2" class="ee7">直播中</span>-->
<!--                                    <span v-else-if="item.uLiveState==3" class="ee5">已结束</span>-->
<!--                                </a>-->
                                <div v-if="item.uLiveState==1">
                                    <router-link tag="a" :to="'/uliveDetail/'+item.id">
                                        <span class="ee7">预约报名</span
                                        ></router-link>
                                    <span class="ee6 flexbox">距离开始：
                                        <CountDown :time1="new Date(item.beginTime).valueOf()"></CountDown>
                                    </span>
                                </div>
                                <div v-else-if="item.uLiveState==2">
                                    <router-link tag="a" :to="'/uliveDetail/'+item.id">
                                        <span class="ee7">直播中</span>
                                    </router-link>
                                    <span class="ee6 flexbox">已开始：
                                        <CountDown :time2="new Date(item.beginTime).valueOf()"></CountDown>
                                    </span>
                                </div>
                                <div v-else-if="item.uLiveState==3">
                                    <router-link tag="a" :to="'/uliveDetail/'+item.id">
                                        <span class="ee7">已结束</span>
                                    </router-link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="paginationbox">
                <el-pagination
                        background
                        :current-page="form.pageNo"
                        :page-size="pageSize"
                        @current-change="currentChange"
                        layout="prev, pager, next"
                        :page-count="pageCount">
                </el-pagination>
            </div>
        </section>

    </div>
</template>

<script>
    import methods from "../../util/methods";
    import CountDown from "../../components/CountDown";

    export default {
        name: "ULive",
        data() {
            return {
                form: {
                    searcheTime: '',
                    projectTypeId: '',
                    projectSpecialId: '',
                    keyWord: '',
                    pageNo: 1,
                    daoxu:'',
                    zhengxu:''
                },
                dataList: [],
                currIndex: 0,
                currIndex3: 0,
                currIndex2: 0,
                times: [{id: 1, name: '今天'}, {id: 2, name: '明天'}, {id: 3, name: '本周'}, {id: 4, name: '本月'}],
                sort:0,
                areas: [],
                types: [],
                pageSize: 10, //每页显示数量
                pageCount: 1, //页数.
                total: 0
            }
        },
        mounted() {
            this.getMsg();
            this.getOptions();
        },
        components:{
            CountDown
        },
        methods: {
            //daoxu不为空就是倒序，zhengxu不为空就是正序，都不为空或都为空就是默认。后台写的操蛋逻辑
            changeSort(type){ //default默认，time时间
                if(type=='default'){
                    this.sort = 0;
                    this.form.zhengxu = this.form.daoxu = '';
                } else if(type=='time'){
                    if(this.sort==1){
                        this.sort = 2;
                        this.form.zhengxu= '1';
                        this.form.daoxu = '';
                    }else{
                        this.sort = 1;
                        this.form.zhengxu= '';
                        this.form.daoxu = '1';
                    }
                }
                this.form.pageNo = 1;
                this.getMsg();
            },
            currentChange(e) {
                //页数改变
                this.form.pageNo = e;
                this.getMsg();
            },
            toSearch(){
                this.form.pageNo = 1;
                this.getMsg();
            },
            async Search(val, index, type) {
                if (type == 1) {
                    this.currIndex = index
                    this.form.projectSpecialId = val
                } else if (type == 2) {
                    this.currIndex2 = index;
                    this.form.searcheTime = val
                }else if(type == 3) {
                    this.currIndex3 = index;
                    this.form.projectTypeId = val
                }
                this.form.pageNo = 1;
                this.getMsg();
            },
            async getMsg() {
                let res = await this.$api.radshowLine(this.form);
                console.log(this.form)
                this.pageCount = res.result.pages
                this.dataList = [];
                for(let item of res.result.records){
                    item.uLiveState = methods.uLiveState(item.beginTime,item.endTime)
                    this.dataList.push(item)
                }
                this.total = res.result.total
            },
            async getOptions() {
                let res = await this.$api.projectDic({type: 6});
                this.areas = res.result;
                let res1 = await this.$api.projectDic({type: 5});
                this.types = res1.result;
            }
        }
    }
</script>

<style scoped>
    /*body*/
    .flexbox{
        display: flex!important;
    }
    span {
        display: inline-block;
    }

    #yyj_1 {
        height: 100%;
        overflow-x: hidden;
        background-color: #ffffff;
    }

    #yyj_1 .cc {
        width: 100%;
        height: 341px;
        margin-bottom: 0px;
    }

    #yyj_1 .cc .cd {
        background-color: #fff;
        height: 320px;
        border: 1px #dcdcdc solid;
        padding-left: 17px;
        border-radius: 15px;
        box-shadow: 1px 1px 6px 1px #CCCCCC;
        position: relative;
        bottom: 118px;
        box-shadow: 1px 1px 6px 1px #d6d6d6;
    }

    .imgboxss {
        width: 410px;
        height: 258px;
        object-fit: cover;
    }

    #yyj_1 .yzb {
        font-size: 45px;
        color: #FFFFFF;
        position: absolute;
        top: 406px;
        left: 37px;
        left: 392px;
    }

    #yyj_1 .ch {
        font-size: 13px;
        margin: 0 auto;
        position: relative;
        left: 500px;
        top: 29px;
    }

    #yyj_1 .cc .ce {
        width: 100%;
        height: 336px;
    }

    #yyj_1 .cc .cd {
        padding-top: 33px;

    }

    #yyj_1 .cc .cd .ci {
        width: 1170px;
        height: 67px;
        /*padding-top: 15px;*/
        display: flex;
        align-items: center;
        border-bottom: 1px #CCCCCC dashed;

    }

    #yyj_1 .ee8 {
        color: #716768;
        font-size: 12px;
    }

    #yyj_1 .ci a {
        border-radius: 18px;
        padding: 6px 14px;
        color: #000000;
        font-size: 12px;

        margin-right: 10px;
    }

    #yyj_1 .ci a:first-child {
        margin-left: 8px;
    }

    #yyj_1 .ci a.Any {
        color: #fff;
        background-color: #3494ff;
    }

    #yyj_1 .ci a:hover {
        color: #fff;
        background-color: #3494ff;

    }

    .cd .sc {
        width: 224px;
        height: 38px;
        border-radius: 19px;
        border: 1px #cccccc solid;
        background-color: #fff;
        margin: 20px 0;
        margin-left: 918px;
        display: flex;
        align-items: center;
    }

    .cd .sc .sd {
        width: 160px;
        height: 36px;
        margin-left: 24px;
        color: #b8b6b9;
        font-size: 15px;
        line-height: 36px;
    }

    #yyj_1 .cc .cd .ci span {
        display: inline-block;
    }

    #yyj_1 .cc .cd .ci .ck {
        width: 253px;
        height: 40px;
        border: 2px #CCCCCC solid;
        margin-left: 118px;
        margin-bottom: 0px;
        padding-top: 9px;
        position: relative;
        bottom: 2px;

    }

    #yyj_1 .cc .cd .ci .ck input {
        padding-left: 10px;
    }


    #yyj_1 .dd {
        /*height: 932px;*/
        background-color: #FFFFFF;
        border: 1px #dcdcdc solid;
        margin-top: 220px;
        margin-bottom: 100px;
        border-radius: 9px;
        box-shadow: 0px 2px 10px -1px #CCCCCC;
        /*padding-bottom: 76px;*/
    }

    #yyj_1 .dd ul {

    }

    #yyj_1 .dd ul li {
        float: left;
    }

    #yyj_1 .dd .de {
        width: 102px;
        height: 60px;
        border-bottom: 2px #f0f0f0 solid;
        border-right: 1px #f0f0f0 solid;
        text-align: center;
        line-height: 60px;
    }

    #yyj_1 .dd .df {
        width: 165px;
        height: 60px;
        border-bottom: 2px #f0f0f0 solid;
        border-right: 1px #f0f0f0 solid;
        border-left: 1px #EEEEEE solid;
        text-align: center;
        line-height: 60px;
    }

    #yyj_1 .dd .dg {
        width: 685px;
        height: 60px;
        border-bottom: 2px #f0f0f0 solid;
        border-right: 1px #f0f0f0 solid;
        border-left: 1px #EEEEEE solid;
        text-align: right;
        line-height: 60px;
        padding-right: 15px;
    }

    #yyj_1 .dd span {
        display: inline-block;
    }

    #yyj_1 .dd .dh {
        width: 237px;
        height: 60px;
        border-bottom: 2px #f0f0f0 solid;
        border-left: 1px #EEEEEE solid;
        text-align: right;
        padding-right: 30px;
        line-height: 60px;
    }

    #yyj_1 .fg {
        position: relative;
        bottom: 4px;
    }

    #yyj_1 .fh {
        position: relative;
        bottom: 4px;
    }

    /*点击按钮轮换*/
    #yyj_1 .asdbig {
        width: 1198px;
        /*height: 762px;*/
        /*overflow: hidden;*/
        position: relative;
    }

    #yyj_1 .asdwid {
        transition: all 1s;
        /*max-height: 725px;*/
        /*overflow: hidden;*/
        /*position: absolute;*/
        /*left: 0;*/
    }

    .asd {
        float: left;
        width: 1198px;
        height: 100%;

    }

    #yyj_1 .sdf {
        font-weight: bold;
    }

    #yyj_1 .dd .da {
        width: 1147px;
        height: 307px;
        padding: 23px 25px 25px 23px;
        border: 1px #eeeeee solid;
        margin-left: 28px;
        margin-top: 26px;
        margin-bottom: 74px;
        box-shadow: 1px 0px 14px 3px #CCCCCC;
    }

    #yyj_1 .dd .da img {
        transition: all 0.6s;
    }

    #yyj_1 .dd .da img:hover {
        opacity: 0.7;
        cursor: pointer;
    }

    #yyj_1 .ee {
        width: 655px;
        height: 237px;
        float: right;
        margin-top: 16PX;
        position: relative;
    }

    #yyj_1 .ee1 {
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
    }

    #yyj_1 .ee2 {
        font-size: 13px;
        color: #bcbcbe;
        line-height: 29PX;
    }

    #yyj_1 .ee3 {
        font-size: 13px;
        color: #858585;
        line-height: 29PX;
    }
    .overhid{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box!important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    #yyj_1 .ee4 {
        font-size: 13px;
        color: #767477;

    }

    #yyj_1 .ee5 {

        font-size: 17px;
        color: #FFFFFF;
        background-color: #CCCCCC;
        padding: 12px 30px;
        position: absolute;
        bottom: 0;
    }

    #yyj_1 .ee6 {

        font-size: 12px;
        position: absolute;
        bottom: 12px;
        right: 0;
    }

    #yyj_1 .ee7 {
        font-size: 17px;
        color: #C20000;
        border: 1px #c20000 solid;
        padding: 12px 30px;
        position: absolute;
        bottom: 0;
    }

    #yyj_1 .icon {
        fill: #737373;
        height: 18px;
        width: 18px;
        position: absolute;
        right: 16px;
    }

    .aaa {
        display: inline-block;
    }

    #lbs .bs2 {
        width: 100%;
        height: 60px;
        border-bottom: 2px #e8e8e8 solid;
    }

    #lbs .bs3 {
        float: left;
        font-size: 14px;
        text-align: center;
        line-height: 60px;
        width: 105px;
        height: 100%;
        border-right: 1px #e8e7ec solid;
    }

    #lbs .lbss3 {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .jiantou {
        width: 23px;
        height: 23px;
        object-fit: contain;
    }

    .jiantouTop {
        transform: rotate(180deg);
    }

    #lbs .active {
        color: #007bff;
    }

    #lbs .bs4 {
        line-height: 60px;
        border-right: 1px #e8e7ec solid;
        text-align: center;
        font-size: 14px;
        width: 165px;
        float: left;
        color: #9c9c9e;
    }

    #lbs .bs5 {
        line-height: 60px;
        border-right: 1px #e8e7ec solid;
        font-size: 14px;
        float: left;
        width: 680px;
        height: 100%;
    }

    #lbs .bs6 {
        width: 116px;
        height: 60px;
        float: left;
        margin-left: 40px;
    }

    #lbs .bs6 span {
        float: left;
        color: #7e7d79;
        margin-left: 8px;
    }

    #lbs .bs66 {
        float: left;
        margin-top: 20px;
        height: 18px;
        width: 18px;
    }

    #lbs .bs7 {
        color: #3e414a;
        float: right;
        margin-right: 10px;
    }

    #lbs .bs77 {
        font-weight: bold;
    }

</style>
