<template>
    <div>
        <el-dialog :visible.sync="tableShow" width="95%" top="5vh" @close="tableShow=false">
            <!--                上市公司数据-->
            <el-table :data="CompanyData" v-loading="loading" border>
                <el-table-column :align="align" type="index" label="序号" width="40"></el-table-column>
                <el-table-column v-for="(item,i) in currTable.column" :key="item.data"
                                 :align="align"
                                 :prop="item.prop"
                                 :label="item.label">
                </el-table-column>
            </el-table>
            <div class="paginationbox">
                <el-pagination
                        background
                        :page-count="pageCount"
                        :current-page="currentPage"
                        @current-change="currentChange"
                        layout="prev, pager, next"
                        :page-size="pageSize">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import clmData from "../../util/datas/ColumnData"  //表头数据
    export default {
        name: "HomeTableBox",
        data(){
            return {
                tableShow:false,
                CompanyData: [],
                loading:false,
                align: 'center',
                currTable:[],
                pageSize: 10, //每页显示数量
                pageCount: 0, //总页数
                currentPage: 1,
                tableConfig:[
                    {id:1,name:'listedCompany',column:clmData.listedCompanyColumn,api:'listedCompany'},//上市公司数据
                    {id:2,name:'NEEQ',column:clmData.NEEQColumn,api:'NEEQ'},//新三板企业数据
                    {id:3,name:'examination',column:clmData.examinationColumn,api:'examination'},//在审企业数据
                    {id:4,name:'counseling',column:clmData.counselingColumn,api:'counseling'},//在辅导企业数据
                    
                    // {id:5,name:'houbeiCompany',column:clmData.reserveColumn,api:'houbeiCompany'},//后备企业数据
                    {id:6,name:'Financing',column:clmData.FinancingColumn,api:'ipoFinancing'},//首发融资总额
                    {id:7,name:'Refinancing',column:clmData.RefinancingColumn,api:'ipoRefinancing'},//股权再融资总额
                    {id:8,name:'ipoBond',column:clmData.ipoBondColumn,api:'ipoBond'},//债权融资总额
                    {id:9,name:'abroad',column:clmData.abroadColumn,api:'abroad'},//境外上市企业数
                    {id:10,name:'shingle',column:clmData.shingleColumn,api:'shingle'},//石交所挂牌数
                ]
            }
        },
        methods:{
            openTable(data) {
                this.currTable = this.tableConfig.filter((res) => { //找出要弹出的table
                    return  res.id == data
                })[0]
                this.tableShow = true;
                this.currentPage = 1;
                this.getTableMsg();
            },
            currentChange(e) {
                //页数改变
                this.currentPage = e;
                this.getTableMsg();
            },
            async getTableMsg() { //获取弹出table数据
                
                let pageNo = this.currentPage;
                this.loading = true;
                let res = await this.$api[this.currTable.api]({pageNo});
                if(this.currTable.api == "shingle") {
                    this.loading = false;
                    this.pageCount = res.result.pages;
                    this.CompanyData = res.result.records;
                    this.CompanyData.forEach((item,index)=> {
                        if(item.gpTime != null) {
                            this.CompanyData[index].gpTime = item.gpTime.substring(0,4)
                        }  
                    })
                }else {
                    this.loading = false;
                    this.pageCount = res.result.pages;
                    this.CompanyData = res.result.records;
                }
                
                // console.log(this.CompanyData);
            },
        }
    }
</script>

<style scoped>

</style>