import Vue from 'vue'
import VueRouter from 'vue-router'

//#region 引入组件列表
import Home from '../views/home/Home.vue'
import SupportivePolicy from "../views/policy/SupportivePolicy";
import Login from "../views/login/Login";
import Register from "../views/login/Register";
import RoadShow from "../views/roadshow/RoadShow";
import DynamicDetail from "../views/details/DynamicDetail";
import CompanyServiceDetail from "../views/details/CompanyServiceDetail";
import Declare from "../views/policy/Declare.vue";
import Personal from "../views/Personal.vue";
import GovermentCenter from "../views/centers/govermentCenter/GovermentCenter";
import CompanyCenter from "../views/centers/companyCenter/CompanyCenter";
import DebtService from "../views/mos/DebtService";
import BankService from "../views/mos/BankService";
import UliveDetail from "../views/details/UliveDetail";
import StartApply from "../views/home/StartApply";
import Tutoring from "../views/ipo/Tutoring";
import CompanyProfiles from "../views/ipo/CompanyProfiles";
import EquityService from "../views/mos/EquityService";
import CompanyService from "../views/mos/CompanyService";
import MosService from "../views/mos/MosService";
import Training from "../views/roadshow/Training";
import Consult from "../views/consult/consult/Consult";
import AgencyLibrary from "../views/resourceLibrary/AgencyLibrary";
import CompanyLibrary from "../views/resourceLibrary/CompanyLibrary";
import ListedTraining from "../views/roadshow/ListedTraining";
import OnlineCourses from "../views/roadshow/OnlineCourses";
import ULive from "../views/roadshow/ULive";
import RoadShowDetail from "../views/details/RoadShowDetail";
import workNews from "../views/policy/workNews";
import mosNews from "../views/mos/workNews";
import policyList from "../views/policy/policyList";
import Account from "../views/centers/companyCenter/account";
import Information from "../views/centers/companyCenter/infomationForm/Information";
import Subsidy from "../views/centers/companyCenter/Subsidy";
import EventRegistration from "../views/centers/companyCenter/EventRegistration";
import Security from "../views/centers/companyCenter/Security";
import Survey from "../views/centers/govermentCenter/Survey";
import Finance from "../views/centers/govermentCenter/Finance";
import AreaCount from "../views/centers/govermentCenter/AreaCount";
import IndustryCount from "../views/centers/govermentCenter/IndustryCount";
import Coach from "../views/centers/govermentCenter/Coach";
import Reserve from "../views/centers/govermentCenter/Reserve";
import Intest from "../views/centers/govermentCenter/Intest";
import RoadShowDic from "../views/details/RoadShowDic";
import TrainingList from "../views/roadshow/TrainingList";
import roadShowSwiperDetail from "../views/details/roadShowSwiperDetail";
import Registration from "../views/roadshow/Registration";
import userService from '../views/login/children/userService.vue';
import userPolicy from "../views/login/children/userPolicy.vue";
import AboutUs  from "../views/home/aboutUs.vue";
//#endregion
Vue.use(VueRouter)

const routes = [
    //#region tabbar路由
    {
        path: '/userService',   // 用户服务协议
        name: 'userService',
        component: userService
    },
    {
        path: '/userPolicy',   // 隐私政策
        name: 'userPolicy',
        component: userPolicy
    },
    {
        path: '/',   // 首页
        name: 'home',
        component: Home,
        meta: {
            keepAlive: false,
            tabbar_active: 'home'
        }
    },
    {
        path: '/aboutUs',   // 首页
        name: 'aboutUs',
        component: AboutUs,
        meta: {
            keepAlive: false,
            tabbar_active: 'home'
        }
    },
    {
        path: '/policy',  //扶持政策
        name: 'policy',
        component: SupportivePolicy,
        meta: {
            keepAlive: true,
            tabbar_active: 'policy'
        }
    },
    {
        path: '/tutoring',  //上市辅导
        name: 'tutoring',
        component: Tutoring,
        meta: {
            keepAlive: false,
            tabbar_active: 'tutoring'
        }
    },
    {
        path: '/mosService',  //融资服务
        name: 'mosService',
        component: MosService,
        meta: {
            keepAlive: false,
            tabbar_active: 'mosService'
        }
    },
    {
        path: '/roadShow',  //培训路演
        name: 'roadShow',
        component: RoadShow,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
	{
		path:"/mosNews/:type",
		name:"mosNews",
		component:mosNews,
		meta:{
			keepAlive:false,
			tabbar_active:""
		}
	},
    {
        path: '/agencyLibrary',  //上市资源库
        name: 'agencyLibrary',
        component: AgencyLibrary,
        meta: {
            keepAlive: false,
            tabbar_active: 'agencyLibrary'
        }
    },
    {
        path: '/consult',  //线上咨询
        name: 'consult',
        component: Consult,
        meta: {
            keepAlive: false,
            tabbar_active: 'consult'
        }
    },
    //#endregion

    //region新闻.
    {
        path: '/workNews',  //扶持政策
        name: 'workNews',
        component: workNews,
        meta: {
            keepAlive: false,
            tabbar_active: 'policy'
        }
    },
    {
        path: '/policyList/:type',  //扶持政策
        name: 'policyList',
        component: policyList,
        meta: {
            keepAlive: false,
            tabbar_active: 'policy'
        }
    },
    //endregion

    //#region 上市资源
    {
        path: '/companyLibrary',  //平台服务机构库
        name: 'companyLibrary',
        component: CompanyLibrary,
        meta: {
            keepAlive: false,
            tabbar_active: 'agencyLibrary'
        }
    },
    //##endregion

    //#region 详情页
    {
        path: '/detail/:type/:id',  //
        name: 'detail',
        component: DynamicDetail,
        meta: {
            keepAlive: false,
            tabbar_active: ''
        }
    },
	{
		path:"/companyFinalDetail/:type/:id",
		name:"companyFinalDetail",
		component:CompanyServiceDetail,
		meta: {
		    keepAlive: false,
		    tabbar_active: ''
		}
	},
    {
        path: '/uliveDetail/:id',  //直播详情
        name: 'uliveDetail',
        component: UliveDetail,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    {
        path: '/roadShowDetail/:id',  //路演企业详情
        name: 'roadShowDetail',
        component: RoadShowDetail,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShowDetail'
        }
    },
    {
        path: '/companyProfiles/:id/:status',  //企业简介
        name: 'companyProfiles',
        component: CompanyProfiles,
        meta: {
            keepAlive: false,
            tabbar_active: 'tutoring'
        }
    },
    {
        path: '/roadShowSwiperDetail/:id',  //企业简介
        name: 'roadShowSwiperDetail',
        component: roadShowSwiperDetail,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    //#endregion

    //#region 培训路演子页面
    {
        path: '/training',  //上市路演
        name: 'training',
        component: Training,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    {
        path: '/trainingList',  //路演企业精选
        name: 'trainingList',
        component: TrainingList,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    {
        path: '/listedTraining',  //上市培训
        name: 'listedTraining',
        component: ListedTraining,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    {
        path: '/ulive',  //云直播
        name: 'ulive',
        component: ULive,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    {
        path: '/onlineCourses',  //线上课程
        name: 'onlineCourses',
        component: OnlineCourses,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    {
        path: '/roadShowDic/:id',  //活动专题
        name: 'roadShowDic',
        component: RoadShowDic,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    {
        path: '/registration/',  //机构报名
        name: 'registration',
        component: Registration,
        meta: {
            keepAlive: false,
            tabbar_active: 'roadShow'
        }
    },
    //#endregion

    //#region 融资服务子页面
    {
        path: '/equityService/:id',  //
        name: 'equityService',
        component: EquityService,
        meta: {
            keepAlive: false,
            tabbar_active: 'mosService'
        }
    },
	{
	    path: '/CompanyService/:id',  //
	    name: 'CompanyService',
	    component: CompanyService,
	    meta: {
	        keepAlive: false,
	        tabbar_active: 'mosService'
	    }
	},
    {
        path: '/bankService',  //
        name: 'bankService',
        component: BankService,
        meta: {
            keepAlive: false,
            tabbar_active: 'mosService'
        }
    },
    {
        path: '/debtService',  //
        name: 'debtService',
        component: DebtService,
        meta: {
            keepAlive: false,
            tabbar_active: 'mosService'
        }
    },

    //#endregion

    //#region 个人中心
    {
        path: '/personal',  //个人中心
        name: 'personal',
        component: Personal,
        meta: {
            keepAlive: false,
            tabbar_active: 'personal'
        }
    },

    {
        path: '/govermentCenter',  //
        name: 'govermentCenter',
        redirect:'/govermentCenter/survey',
        component: GovermentCenter,
        meta: {
            keepAlive: false,
            childDisScroll: true, //禁止子路由跳转滚动
            tabbar_active: 'govermentCenter'
        },
        children: [
            {
                path: '/govermentCenter/survey',  //个人中心上市概览
                name: 'survey',
                component: Survey,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'survey'
                }
            },
            {
                path: '/govermentCenter/areacount',  //个人中心区域统计
                name: 'areacount',
                component: AreaCount,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'areacount'
                }
            },
            {
                path: '/govermentCenter/industryCount',  //个人中心行业统计
                name: 'industryCount',
                component: IndustryCount,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'industryCount'
                }
            },
            {
                path: '/govermentCenter/coach',  //个人中心上市辅导
                name: 'coach',
                component: Coach,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'coach'
                }
            },
            {
                path: '/govermentCenter/reserve',  //个人中心后备企业
                name: 'reserve',
                component: Reserve,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'reserve'
                }
            },
            {
                path: '/govermentCenter/intest',  //个人中心上市智测
                name: 'intest',
                component: Intest,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'intest'
                }
            },
            {
                path: '/govermentCenter/finance',  //个人中心上市智测
                name: 'finance',
                component: Finance,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'finance'
                }
            },
        ]
    },
    {
        path: '/companyCenter',  //
        name: 'companyCenter',
        redirect:'/companyCenter/account',
        component: CompanyCenter,
        meta: {
            keepAlive: false,
            childDisScroll: true, //禁止子路由跳转滚动
            tabbar_active: 'companyCenter'
        },
        children:[
            {
                path: '/companyCenter/account',  //个人中心账户中心
                name: 'account',
                component: Account,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'account'
                }
            },
            {
                path: '/companyCenter/information',  //个人中心企业信息
                name: 'information',
                component: Information,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'information'
                }
            },
            {
                path: '/companyCenter/subsidy',  //个人中心上市补贴
                name: 'subsidy',
                component: Subsidy,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'subsidy'
                }
            },
            {
                path: '/companyCenter/eventRegistration',  //个人中心活动报名
                name: 'eventRegistration',
                component: EventRegistration,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'eventRegistration'
                }
            },
            {
                path: '/companyCenter/security',  //个人中心账户安全
                name: 'security',
                component: Security,
                meta: {
                    keepAlive: false,
                    tabbar_active: 'security'
                }
            },

        ],
    },
    //#endregion

    //#region 申报/登录注册
    {
        path: '/declare',  //网上申报
        name: 'declare',
        component: Declare,
        meta: {
            keepAlive: false,
            tabbar_active: 'policy'
        }
    },
    {
        path: '/startApply',  //融资申请
        name: 'startApply',
        component: StartApply,
        meta: {
            keepAlive: false,
            tabbar_active: 'mosService'
        }
    },
    {
        path: '/register',  //注册
        name: 'register',
        component: Register,
        meta: {
            keepAlive: false,
            auto:true, //不登录也能访问该页面
            tabbar_active: 'register'
        }
    },
    {
        path: '/login',  //登录
        name: 'login',
        component: Login,
        meta: {
            keepAlive: false,
            auto:true, //不登录也能访问该页面
            tabbar_active: 'login'
        }
    },
    //#endregion

]


const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) { //解决路由跳转后不置顶问题
        if(!to.matched[0].meta.childDisScroll){ //判断路由的父路由childDisScroll为true则刷新该页面不置顶，
            if (savedPosition) {
                return savedPosition
            }
            return {
                x: 0,
                y: 0
            }
        }
    },
    mode:"history",
    base: process.env.BASE_URL,
    routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
