<template>
    <div class="textright1">
        <CenterTab2 :tab-list="tabList" @change="changeTab"></CenterTab2>
        <el-form :model="form1" :rules="rules1" v-show="currtab==0"
                 style="width: 50%"
                 label-position="top"
                 ref="form1" label-width="100px">
            <el-form-item label="新手机号" prop="phone">
                <el-input v-model="form1.phone"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="smsCode">
                <div class="codediv">
                    <el-input class="codeinput" type="number" v-model="form1.smsCode" placeholder="请输入验证码"></el-input>
                    <CodeBtn :phone="form1.phone" codeType="getCode3"></CodeBtn>
                </div>
            </el-form-item>
            <el-button type="primary" @click="resetPhone()">确认</el-button>
        </el-form>
        <el-form :model="form2" :rules="rules2" v-show="currtab==1"
                 style="width: 50%;margin-left:30px;"
                 label-position="top"
                 ref="form2" label-width="100px">
            <el-form-item label="旧密码" prop="password">
                <el-input type="password" v-model="form2.password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model="form2.newPassword"></el-input>
            </el-form-item>
            <el-button type="primary" @click="resetPwd()">确认</el-button>
        </el-form>
    </div>
</template>

<script>
    import CodeBtn from "../../../components/logincpn/CodeBtn";
    import CenterTab2 from "../../../components/CenterTab2";
    import validators from '../../../util/validators.js'
    import methods from "../../../util/methods";
    export default {
        name: "Security",
        data(){
            return {
                currtab:0,
                form1:{
                    phone:'',
                    smsCode:''
                },
                form2:{
                    password:'',
                    newPassword:''
                },
                rules1:{
                    phone: [{validator: validators.checkPhone, trigger: ['blur', 'change']},],
                    smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' },],
                },
                rules2:{
                    password: [{ required: true, message: '请输入旧密码', trigger: 'blur' },],
                    newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' },],
                },
                tabList:[
                    {id:1,label:'更换手机号'},
                    {id:2,label:'更改密码'},
                ],
            }
        },
        computed:{
            userId(){
                return this.$store.state.userInfo.id;
            }
        },
        methods: {
            changeTab(e){
                this.currtab = e;
                console.log(e);
            },
            resetPhone(){
                this.$refs.form1.validate((valid) => {
                    if (valid) { //验证表单
                       this.changePhone();
                    } else {
                        return false;
                    }
                });
            },
            resetPwd(){
                this.$refs.form2.validate((valid) => {
                    if (valid) { //验证表单
                        this.changePassword()
                    } else {
                        return false;
                    }
                });
            },
            async changePassword(){
                let userId = this.userId;
                let res = await this.$api.changePassword({...this.form2,userId});
                if(res.success){
                    methods.toLogin(res.message)
                }
                console.log(res);
            },
            async changePhone(){
                let userId = this.userId;
                let res = await this.$api.changePhone({...this.form1,userId});
                if(res.success){
                    methods.toLogin(res.message)
                }
                console.log(res);
            },
        },
        components: {
            CodeBtn,CenterTab2
        }
    }
</script>

<style scoped>
    .codediv{
        display: flex;
    }
    .textright1{
        width: 818px;
        /*margin-left: 72px;*/
        height: 581px;
        background-color: #FFF;
    }

    .form1 p{
        color: #6c6c6c;
        margin-bottom: 5px;
        font-size: 14px;
    }
</style>