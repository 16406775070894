<template>
  <!--    //发送验证码按钮组件-->
  <div v-loading="loading">
    <el-button
      type="primary"
      @click="sendcode"
      :disabled="disabled"
      v-if="disabled == false"
      >发送验证码
    </el-button>
    <el-button
      type="button"
      @click="sendcode"
      :disabled="disabled"
      v-if="disabled == true"
      >{{ btntxt }}
    </el-button>
	<Vcode :show="isShow" @success="successCode" @close="closeCode" />
  </div>
</template>

<script>
	import Vcode from "vue-puzzle-vcode";
export default {
  name: "CodeBtn",
  components:{
	  Vcode
  },
  props: {
    phone: String,
    codeType: {
      //getCode注册，getCode2登录，getCode3重置手机号，getCode4路演报名,getCode5融资申请
      type: String,
      default: "getCode",
    },
  },
  data() {
    return {
      disabled: false,
      btntxt: "重新发送",
      time: 0,
	  isShow: false,
      loading: false,
    };
  },
  methods: {
	  successCode() {
		this.isShow = false;
		const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
		if (this.phone == "") {
		  this.$message({
		    message: "手机号不能为空",
		    center: true,
		  });
		  return;
		}
		if (!reg.test(this.phone)) {
		  this.$message({
		    message: "请输入正确的手机号",
		    center: true,
		  });
		  return;
		} else {
		  this.getCode();
		}
	  },
	  closeCode() {
		this.isShow = false;
	  },
    async getCode() {
      let phone = this.phone;
      this.loading = true;
      let res = await this.$api[this.codeType]({ phone });
      this.loading = false;
      if (res.success) {
        this.$message({
          message: "发送成功",
          type: "success",
          center: true,
        });
        this.time = 60;
        this.disabled = true;
        this.timer();
      }
    },
    sendcode() {
      this.isShow = true;
    },
    //60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
  },
};
</script>

<style>
	.vue-puzzle-vcode{
		z-index: 9999;
	}
</style>
