<template>
    <div>
        <div id="asdd">
            <div  class="contbox" style="">
                <div data-v-5570930e="" class="dashboard-box">
                    <div data-v-5570930e="" class="dashboard-title">
                        首次公开发行股票并上市基础测评
                        <p data-v-5570930e="" class="describe">智启未来，策动上市</p></div>
                    <div data-v-5570930e="">
                        <button data-v-5570930e="" @click="open" type="button" class="el-button el-button--primary el-button--medium"
                                style="width: 240px; height: 40px; color: #fff; margin-top: 15px; margin-bottom: 15px; background: rgb(20, 188, 245); border: none;">
                            <!----><!----><span>开始测评</span></button>
                    </div>
                    <div data-v-5570930e=""><span data-v-5570930e=""
                                                  style="font-size: 16px; color: rgb(51, 51, 51);">最新版本 1.1.0</span>
                        <div data-v-5570930e="" class="updateBox">
                            <div data-v-5570930e="" class="updateTitle">有更新</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Intest",
        data(){
            return {

            }
        },
        methods: {
            open() {
                this.$confirm('数据对接中，稍后开放', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.$message({
                    //     type: 'success',
                    //     message: '删除成功!'
                    // });
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            }
        }
    }
</script>

<style scoped>
    .contbox{
        height: 920px;
        width: 100%;
        position: relative;
        background: url(../../../static/img/indexmapbg.jpg) no-repeat;
    }
    .dashboard-box{
        position: absolute;
        left: 69%;
        top: 189px;
        min-width: 450px;

        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    .dashboard-title{
        font-size: 28px;
        color: #333333;
        font-weight: 500;
    }
    .dashboard-title .describe {
        font-size: 16px;
        line-height: 25px;
        color: #666666;
    }
    .updateBox {
        display: inline-block;
    }
    .updateTitle {
        display: inline-block;
        background-color: #FE5461;
        border-radius: 2px;
        line-height: 20px;
        font-size: 12px;
        padding: 2px 7px;
        border-radius: 3px;
        font-weight: 500;
        color: #ffffff;
    }
</style>