<template>
	<footer>
		<div class="block-1 w">
			<div class="box1">
				友情链接
			</div>
			<div class="w1200">
				<vue-seamless-scroll :data="list" class="seamless-warp" :class-option="classOption">
				    <div class="box2">
				    	<a target="_blank" v-for="(item,i) in list" :key="i" :href="item.linkUrl">
				    		<img class="imgbox" :src="item.imageUrl|imgUrl" alt="">
				    	</a>
				    </div>
				</vue-seamless-scroll>
			</div>
		</div>
	</footer>
</template>

<script>
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		name: "FriendLink",
		components: {
			vueSeamlessScroll
		},
		data() {
			return {
				list: []
			}
		},
		computed: {
		 classOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 2, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动)
					waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
				}
			}
		},
		mounted() {
			this.getlinkUrl();
		},
		methods: {
			async getlinkUrl() {
				let res = await this.$api.linkUrl();
				this.list = res.result;
				console.log(this.list);
			}
		}
	}
</script>

<style scoped>
	
	.w1200{
		width: 1200px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
	}
	
	.seamless-warp{
		
	}
	
	footer {
		background-color: #fff;
		height: auto;
	}

	footer .block-1 {
		height: 160px;
	}

	footer .block-1 .box1 {
		font-size: 24px;
		color: #3ea9fb;
		font-weight: bold;
		width: 100%;
		text-align: center;
		margin-bottom: 30px;
	}

	footer .block-1 .box2 {
		height: 60px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.imgbox {
		width: 200px;
		height: 60px;
		display: inline-block;
		/* background: #ddd; */
		object-fit: contain;
	}
</style>
