<template>
    <div class="account">
        <div class="account_head">
            <div class="account_logo"><img src="../../../static/img/new_02.png" /></div>
            <div class="account_tag">{{userInfo.companyName}}</div>
        </div>
        <ul class="account_list">
            <li class="account_item"
                @click="changeTab(item.url,item.activeRouter)"
                :class="{'active':currtab ==item.activeRouter}"
                v-for="(item,i) of tablist" :key="item.id">
                <a>
                    <img :src="item.imgSrc" alt="">
                    <span>{{item.name}}</span>
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#iconiconfontjiantou5"></use>
                    </svg>
                </a>
            </li>
        </ul>

    </div>
</template>

<script>
    export default {
        name: "centerTabs",
        data(){
            return {
                currtab:'account',
                tablist:[
                    {id:1,activeRouter:'account',url:'/companyCenter/account',name:'账户中心',imgSrc:''},
                    {id:2,activeRouter:'information',url:'/companyCenter/information',name:'企业信息',imgSrc:''},
                    {id:3,activeRouter:'subsidy',url:'/companyCenter/subsidy',name:'上市补贴',imgSrc:''},
                    {id:4,activeRouter:'eventRegistration',url:'/companyCenter/eventRegistration',name:'活动报名',imgSrc:''},
                    {id:5,activeRouter:'security',url:'/companyCenter/security',name:'账户安全',imgSrc:''},
                ]
            }
        },
        mounted() {
            this.currtab = this.$route.meta.tabbar_active;
        },
        methods: {
            changeTab(url,activeRouter){
                this.currtab = activeRouter;
                this.$router.push(url)
            }
        },
        computed: {
            token(){
                 console.log(this.$store.state)
                return this.$store.state.token;
            },
            userInfo(){
               
                return this.$store.state.userInfo;
            },
        }
    }
</script>

<style scoped>
    /*账号中心*/
    .account{
        height:600px;
        width: 301px;
        background-color: #3d455a;
    }
    .account_head{
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    /* .account_logo{
        position: absolute;
        left: 114px;
        top: 16px;
    } */
    .account_tag{
        /* position: absolute; */
        /* left: 122px; */
        /* bottom: 0; */
        margin-top: 20px;
        color: #fff;
        font-size: 14px;
    }
    .account_list{
        /* margin-top: 77px; */
        width: 100%;
    }
    .account_list .account_item{
        height: 61px;
        line-height: 61px;
        width: 100%;
        text-align: center;
        font-size: 20px;
    }
    .account_item:hover,.account_item:active{
        background-color: #252a3e;
        cursor: pointer;
    }
    .account_item a{
        color: #fff;
    }
    .mr5{
        margin-right: 5px;
    }
    .account_item a span{
        /* margin-right: 40px; */
    }
    .account_list .active{
        background-color: #252a3e;
    }
</style>