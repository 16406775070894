//获取数据的方法
import base from "./base";
import axios from "./axios";

export default {
    async testPost(params = {}){
        let res = await axios.post(base.test,params)
        return res;
    },
    async getProvince(params = {}){
        let res = await axios.get(base.province,params)
        return res;
    },
    async getCity(params = {}){
        let res = await axios.get(base.city,params)
        return res;
    },
    async getArea(params = {}){
        let res = await axios.get(base.area,params)
        return res;
    },
    async getCode(params = {}){
        let res = await axios.post(base.getCode,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async getCode2(params = {}){
        let res = await axios.post(base.getCode2,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async getCode4(params = {}){
        let res = await axios.post(base.getCode4,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async getCode5(params = {}){
        let res = await axios.post(base.getCode5,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async registor(params = {}){
        let res = await axios.post(base.registor,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async loginInPwd(params = {}){
        let res = await axios.post(base.loginInPwd,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async radshowsignup(params = {}){
        let res = await axios.post(base.radshowsignup,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async loginInPwd2(params = {}){
        let res = await axios.post(base.loginInPwd2,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async financingServiceIntroduce(params = {}){
        let res = await axios.get(base.financingServiceIntroduce,params)
        return res;
    },
    async editCompanyMessage(params = {}){
        let res = await axios.post(base.editCompanyMessage,params)
        return res;
    },
    async loginInCode(params = {}){
        let res = await axios.post(base.loginInCode,params)
        return res;
    },
    async companyMessage(params = {}){
        let res = await axios.get(base.companyMessage,params)
        return res;
    },
    async linkUrl(params = {}){
        let res = await axios.get(base.linkUrl,params)
        return res;
    },
    async ipoFinancing(params = {}){
        let res = await axios.get(base.ipoFinancing,params)
        return res;
    },
    async applyspolicy(params = {}){
        let res = await axios.get(base.applyspolicy,params)
        return res;
    },
    async radshowSigup(params = {}){
        let res = await axios.get(base.radshowSigup,params)
        return res;
    },
    async applyed(params = {}){
        let res = await axios.get(base.applyed,params)
        return res;
    },
    async noapply(params = {}){
        let res = await axios.get(base.noapply,params)
        return res;
    },
    async ipoRefinancing(params = {}){
        let res = await axios.get(base.ipoRefinancing,params)
        return res;
    },
    async getCode3(params = {}){
        let res = await axios.post(base.getCode3,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async changePhone(params = {}){
        let res = await axios.post(base.changePhone,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async changePassword(params = {}){
        let res = await axios.post(base.changePassword,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async ipoBond(params = {}){
        let res = await axios.get(base.ipoBond,params)
        return res;
    },
    async queryPlateNumber(params = {}){
        let res = await axios.get(base.queryPlateNumber,params)
        return res;
    },
    async companyStatisticsList(params = {}){
        let res = await axios.get(base.companyStatisticsList,params)
        return res;
    },
    async IndustryNumber(params = {}){
        let res = await axios.get(base.IndustryNumber,params)
        return res;
    },
    async querySpecial(params = {}){
        let res = await axios.get(base.querySpecial,params)
        return res;
    },
    async randomImg(params = {}){
        let res = await axios.get(base.randomImg,params)
        return res;
    },
    async fudaoCompanyDetail(params = {}){
        let res = await axios.get(base.fudaoCompanyDetail,params)
        return res;
    },
    async radshowTran(params = {}){
        let res = await axios.get(base.radshowTran,params)
        return res;
    },
    async radshowLine(params = {}){
        let res = await axios.get(base.radshowLine,params)
        return res;
    },
    async radshowOnlineCourses(params = {}){
        let res = await axios.get(base.radshowOnlineCourses,params)
        return res;
    },
    async policyNews(params = {}){
        let res = await axios.get(base.policyNews,params)
        return res;
    },
    async radshowCompany(params = {}){
        let res = await axios.get(base.radshowCompany,params)
        return res;
    },
    async readshowNumber(params = {}){
        let res = await axios.get(base.readshowNumber,params)
        return res;
    },
    async radshowLink(params = {}){
        let res = await axios.get(base.radshowLink,params)
        return res;
    },
    async radshowDemeanor(params = {}){
        let res = await axios.get(base.radshowDemeanor,params)
        return res;
    },
    async listedCompany(params = {}){
        let res = await axios.get(base.listedCompany,params)
        return res;
    },
    async NEEQ(params = {}){
        let res = await axios.get(base.NEEQ,params)
        return res;
    },
    async abroad(params = {}){
        let res = await axios.get(base.abroad,params)
        return res;
    },
    async shingle(params = {}){
        let res = await axios.get(base.shingle,params)
        return res;
    },
    async examination(params = {}){
        let res = await axios.get(base.examination,params)
        return res;
    },
    async counseling(params = {}){
        let res = await axios.get(base.counseling,params)
        return res;
    },
    async enclosureApply(params = {}){
        let res = await axios.post(base.enclosureApply,params)
        return res;
    },
    async radshowApply(params = {}){
        let res = await axios.post(base.radshowApply,params,'application/x-www-form-urlencoded',true)
        return res;
    },
    async questime(params = {}){
        let res = await axios.post(base.questime,params)
        return res;
    },
    async applys(params = {}){
        let res = await axios.post(base.applys,params)
        return res;
    },
    async commonProblem(params = {}){
        let res = await axios.get(base.commonProblem,params)
        return res;
    },
    async bannerDetail(params = {}){
        let res = await axios.get(base.bannerDetail,params)
        return res;
    },
    async radshowDetail(params = {}){
        let res = await axios.get(base.radshowDetail,params)
        return res;
    },
    async serviceOrganization(params = {}){
        let res = await axios.get(base.serviceOrganization,params)
        return res;
    },
    async policyDetail(params = {}){
        let res = await axios.get(base.policyDetail,params)
        return res;
    },
	async companyFinalDetail(params = {}){
	    let res = await axios.get(base.companyFinalDetail,params)
	    return res;
	},
    async radshowTranDetail(params = {}){
        let res = await axios.get(base.radshowTranDetail,params)
        return res;
    },
    async radshowOncurDetail(params = {}){
        let res = await axios.get(base.radshowOncurDetail,params)
        return res;
    },
    async serviceOrganizationDetail(params = {}){
        let res = await axios.get(base.serviceOrganizationDetail,params)
        return res;
    },
    async radshowLineDetail(params = {}){
        let res = await axios.get(base.radshowLineDetail,params)
        return res;
    },
    async calendar(params = {}){
        let res = await axios.get(base.calendar,params)
        return res;
    },
    async coachNum(params = {}){
        let res = await axios.get(base.coachNum,params)
        return res;
    },
    async cityNum(params = {}){
        let res = await axios.get(base.cityNum,params)
        return res;
    },
    async bannerImg(params = {}){
        let res = await axios.get(base.bannerImg,params)
        return res;
    },
    async radshowBannerImg(params = {}){
        let res = await axios.get(base.radshowBannerImg,params)
        return res;
    },
    async projectDic(params = {}){
        let res = await axios.get(base.projectDic,params)
        return res;
    },
    async fudaoCompany(params = {}){
        let res = await axios.get(base.fudaoCompany,params)
        return res;
    },
    async houbeiCompany(params = {}){
        let res = await axios.get(base.houbeiCompany,params)
        return res;
    },
    async getenclosureApply(params = {}){
        let res = await axios.get(base.getenclosureApply,params)
        return res;
    },
    async queryIPO(params = {}){
        let res = await axios.get(base.queryIPO,params)
        return res;
    },
    async industryList(params = {}){
        let res = await axios.get(base.industryList,params)
        return res;
    },
    async getTotal(params = {}){
        let res = await axios.get(base.getTotal,params)
        return res;
    },
    async loginLog(params = {}){
        let res = await axios.post(base.loginLog,params)
        return res;
    },
	async queryFundCompanyList(params){
		return await axios.get(base.queryFundCompanyList,params)
	},
	async queryFundDetailsList(params){
		let res = await axios.get(base.queryFundDetailsList,params)
		return res;
	},
	//获取基金政策
	async queryFundNews(params){
		let res = await axios.get(base.queryFundNews,params)
		return res;
	},
	//获取基金来源
	async queryNewsSource(params){
		let res = await axios.get(base.queryNewsSource,params)
		return res;
	},
}