<template>
  <div>
    <el-form
      :model="form"
      size="mini"
      ref="form"
      label-width="140px"
      class="demo-ruleForm"
    >
      <!--            上市筹备情况-->
      <div>
        <el-tag class="tagBox" effect="dark" type="info">上市筹备情况</el-tag>
        <div>
          <div class="inline">
            <el-form-item label="拟上市时间:" prop="makeTime">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="form.makeTime"
                value-format="yyyy-MM-dd"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="拟申报板块:" prop="makePlate">
              <el-select v-model="form.makePlate" placeholder="请选择">
                <el-option
                  v-for="(item, i) of makePlateOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </div>
      <!--            中介机构-->
      <div>
        <el-tag class="tagBox" effect="dark" type="info">中介机构</el-tag>
        <div>
          <div class="inline">
            <el-form-item label="券商状态:" prop="makeIntermediaryBrokerStatus">
              <el-select
                v-model="form.makeIntermediaryBrokerStatus"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, i) of makeIntermOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="券商:" prop="makeIntermediaryBroker">
              <el-input v-model="form.makeIntermediaryBroker"></el-input>
            </el-form-item>
          </div>
          <div class="inline">
            <el-form-item label="签字保代:" prop="makeIntermediaryBaodai">
              <el-input v-model="form.makeIntermediaryBaodai"></el-input>
            </el-form-item>
            <el-form-item label="保代手机:" prop="makeIntermediaryBaodaiPhoen">
              <el-input v-model="form.makeIntermediaryBaodaiPhoen"></el-input>
            </el-form-item>
          </div>
          <div class="inline">
            <el-form-item
              label="项目负责人:"
              prop="makeIntermediaryProjectPerson"
            >
              <el-input v-model="form.makeIntermediaryProjectPerson"></el-input>
            </el-form-item>
            <el-form-item
              label="负责人手机:"
              prop="makeIntermediaryProjectPersonPhone"
            >
              <el-input
                v-model="form.makeIntermediaryProjectPersonPhone"
              ></el-input>
            </el-form-item>
          </div>
          <div class="inline">
            <el-form-item
              label="会计师事务所状态:"
              prop="makeIntermediaryAccountingaffairsStatus"
            >
              <el-select
                v-model="form.makeIntermediaryAccountingaffairsStatus"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, i) of makeIntermOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="会计师事务所:"
              prop="makeIntermediaryAccountingaffairs"
            >
              <el-input
                v-model="form.makeIntermediaryAccountingaffairs"
              ></el-input>
            </el-form-item>
          </div>
          <div class="inline">
            <el-form-item
              label="签字会计师:"
              prop="makeIntermediaryAccountingaffairsPerson"
            >
              <el-input
                v-model="form.makeIntermediaryAccountingaffairsPerson"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="会计师手机:"
              prop="makeIntermediaryAccountingaffairsPersonPhone"
            >
              <el-input
                v-model="form.makeIntermediaryAccountingaffairsPersonPhone"
              ></el-input>
            </el-form-item>
          </div>
          <div class="inline">
            <el-form-item
              label="律师事务所状态:"
              prop="makeIntermediaryLawfirmStatus"
            >
              <el-select
                v-model="form.makeIntermediaryLawfirmStatus"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, i) of makeIntermOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="律师事务所:" prop="makeIntermediaryLawfirm">
              <el-input v-model="form.makeIntermediaryLawfirm"></el-input>
            </el-form-item>
          </div>
          <div class="inline">
            <el-form-item
              label="签字律师:"
              prop="makeIntermediaryLawfirmPerson"
            >
              <el-input v-model="form.makeIntermediaryLawfirmPerson"></el-input>
            </el-form-item>
            <el-form-item
              label="律师手机:"
              prop="makeIntermediaryLawfirmPersonPhone"
            >
              <el-input
                v-model="form.makeIntermediaryLawfirmPersonPhone"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <!--            企业上市优势-->
      <div>
        <el-tag class="tagBox" effect="dark" type="info">企业上市优势</el-tag>
        <el-form-item label="技术优势:" prop="makeAdvantageTechnology">
          <el-input
            type="textarea"
            placeholder="请输入技术优势"
            v-model="form.makeAdvantageTechnology"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业地位:" prop="makeAdvantageIndustry">
          <el-input
            type="textarea"
            placeholder="请输入行业地位"
            v-model="form.makeAdvantageIndustry"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品优势:" prop="makeAdvantageProduct">
          <el-input
            type="textarea"
            placeholder="请输入产品优势"
            v-model="form.makeAdvantageProduct"
          ></el-input>
        </el-form-item>
        <el-form-item label="商业模式:" prop="makeAdvantageBusiness">
          <el-input
            type="textarea"
            placeholder="请输入商业模式"
            v-model="form.makeAdvantageBusiness"
          ></el-input>
        </el-form-item>
        <el-form-item label="营销团队与服务优势:" prop="makeAdvantageMarketing">
          <el-input
            type="textarea"
            placeholder="请输入营销团队与服务优势"
            v-model="form.makeAdvantageMarketing"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户和供应商优势:" prop="makeAdvantagePerson">
          <el-input
            type="textarea"
            placeholder="请输入客户和供应商优势"
            v-model="form.makeAdvantagePerson"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="管理团队和人才队伍优势:"
          prop="makeAdvantageAdministration"
        >
          <el-input
            type="textarea"
            placeholder="请输入管理团队和人才队伍优势"
            v-model="form.makeAdvantageAdministration"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="其他方面的差异化核心竞争力:"
          prop="makeAdvantageOrther"
        >
          <el-input
            type="textarea"
            placeholder="请输入其他方面的差异化核心竞争力"
            v-model="form.makeAdvantageOrther"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import validators from "../../../../util/validators";

export default {
  name: "Form3",
  data() {
    return {
      form: {
        makeTime: "", //上市时间
        makePlate: "", //申报板块
        makeIntermediaryBrokerStatus: "", //券商状态
        makeIntermediaryBroker: "", //券商
        makeIntermediaryBaodai: "", //签字保代
        makeIntermediaryBaodaiPhoen: "", //保代手机
        makeIntermediaryProjectPerson: "", //项目负责人
        makeIntermediaryProjectPersonPhone: "", //项目负责人手机
        makeIntermediaryAccountingaffairsStatus: "", //会计师事务所状态
        makeIntermediaryAccountingaffairs: "", //会计事务所名称
        makeIntermediaryAccountingaffairsPerson: "", //会计事务所会计签名
        makeIntermediaryAccountingaffairsPersonPhone: "", //会计师手机
        makeIntermediaryLawfirmStatus: "", //律师事务所状态
        makeIntermediaryLawfirm: "", //律师事务所
        makeIntermediaryLawfirmPerson: "", //律师事务所律师签名
        makeIntermediaryLawfirmPersonPhone: "", //律师事务所律师手机号
        makeAdvantageTechnology: "", //技术优势
        makeAdvantageIndustry: "", //行业地位
        makeAdvantageProduct: "", //产品优势
        makeAdvantageBusiness: "", //商业模式

        makeAdvantageMarketing: "", //营销团队与服务优势
        makeAdvantagePerson: "", //客户和供应商优势
        makeAdvantageAdministration: "", //管理团队和人才队伍优势
        makeAdvantageOrther: "", //其他方面的差异化核心竞争力
      },
      // rules: {
      //   makePlate: [
      //     {
      //       required: true,
      //       message: "拟申报板块为必填项",
      //       trigger: ["blur", "change"],
      //     },
      //   ],
      //   makeIntermediaryBaodaiPhoen: [
      //     { validator: validators.checkPhone2, trigger: ["blur", "change"] },
      //   ],
      //   makeIntermediaryProjectPersonPhone: [
      //     { validator: validators.checkPhone2, trigger: ["blur", "change"] },
      //   ],
      //   makeIntermediaryAccountingaffairsPersonPhone: [
      //     { validator: validators.checkPhone2, trigger: ["blur", "change"] },
      //   ],
      //   makeIntermediaryLawfirmPersonPhone: [
      //     { validator: validators.checkPhone2, trigger: ["blur", "change"] },
      //   ],
      // },
      makePlateOptions: [
        { id: 1, label: "中小板", value: "1" },
        { id: 2, label: "创业板", value: "2" },
        { id: 3, label: "深交所主板", value: "3" },
        { id: 4, label: "上交所主板", value: "4" },
        { id: 5, label: "科创板", value: "5" },
        { id: 6, label: "新三板精选层", value: "6" },
        { id: 7, label: "境外上市", value: "7" },
        { id: 8, label: "股交中心", value: "8" },
        { id: 9, label: "其他", value: "9" },
      ],
      makeIntermOptions: [
        { id: 1, label: "未开始", value: "1" },
        { id: 2, label: "初步意向", value: "2" },
        { id: 3, label: "已签协议", value: "3" },
        { id: 4, label: "正式进场", value: "4" },
      ],
    };
  },
  mounted() {
    this.companyMessage();
  },
  methods: {
    async companyMessage() {
      let userId = this.$store.state.userInfo.id;
      let res = await this.$api.companyMessage({ userId });
      for (let key in res.result.company) {
        //回显数据
        if (key in this.form) {
          this.form[key] = res.result.company[key];
        }
      }
      // console.log(res);
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //验证表单
          this.$emit("submit", this.form, 3);
        } else {
          // this.$message({
          //   message: "请填写完整",
          //   center: true,
          // });
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.inline {
  display: flex;
}

.inline .el-form-item {
  width: 50%;
}

.tagBox {
  margin: 15px 0;
}

.tableBox th {
  margin-bottom: 15px;
}

.exprenceTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exprenceBox {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}
</style>
