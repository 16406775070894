<template>
    <div class="ipoListingStand" id="imgcontbox">
        <div class="swiperbox">
            <div class="swipercontain ">
                <div class="titleClass ">
                    <span>IPO上市标准</span>
                    <div></div>
                </div>
                <div id="fade">
                    <div class="swiper-container" @mouseenter="showjt=true" @mouseleave="showjt=false">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="divStyle">
                                    <table cellspacing="0" cellpadding="0" class="table2" style="height: 384px;">
                                        <tr style="font-size: 14px; text-align: center;">
                                            <th rowspan="4" style="font-weight: bold; width: 240px;">财务与会计
                                                <p style="font-size: 12px; font-weight: normal; color: rgb(170, 170, 170);">
                                                    至少符合右侧<br>&nbsp;&nbsp;标准中的一项：</p>
                                            </th>
                                            <th style="width: 190px;">标准</th>
                                            <th style="width: 190px;">预计市值</th>
                                            <th style="width: 270px;">净利润</th>
                                            <th style="width: 300px;">营业收入</th>
                                        </tr>
                                        <tr style="font-size: 14px; text-align: center;">
                                            <td>标准一</td>
                                            <td></td>
                                            <td>最近两年均&gt;0，且累计≥5000万元</td>
                                            <td></td>
                                        </tr>
                                        <tr style="font-size: 14px; text-align: center;">
                                            <td>标准二</td>
                                            <td>≥10亿元</td>
                                            <td>最近一年&gt;0</td>
                                            <td>≥1亿元</td>
                                        </tr>
                                        <tr style="font-size: 14px; text-align: center;">
                                            <td>标准三</td>
                                            <td>≥50亿元</td>
                                            <td></td>
                                            <td>≥3亿元</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td style="font-weight: bold; text-align: center; font-size: 14px;">
                                                主体资格
                                            </td>
                                            <td colspan="4"> 依法设立且合法存续的股份有限公司</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td style="font-weight: bold; text-align: center; font-size: 14px;">
                                                经营年限
                                            </td>
                                            <td colspan="4"> 持续经营3年以上，
                                                有限责任公司按原账面净资产值折股整体变更为股份有限公司的，持续经营时间可以从有限责任公司成立之日起计算
                                            </td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td style="font-weight: bold; text-align: center; font-size: 14px;">
                                                股本要求
                                            </td>
                                            <td colspan="4"> 发行后股本不少于3,000万股。</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td style="font-weight: bold; text-align: center; font-size: 14px;">
                                                主营业务
                                            </td>
                                            <td colspan="4">
                                                创业板定位于深入贯彻创新驱动发展战略，适应发展更多依靠创新、创造、创意的大趋势，主要服务成长型创新创业企业，并支持传统产业与新技术、新产业、新业态、新模式深度融合。
                                            </td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td style="font-weight: bold; text-align: center; font-size: 14px;">
                                                规范运行
                                            </td>
                                            <td colspan="4"> 发行人应披露股东大会、董事会、监事会、独立董事、董事会秘书制度的建立健全及运行情况。</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td style="font-weight: bold; text-align: center; font-size: 14px;">
                                                控制权和管理团队
                                            </td>
                                            <td colspan="4"> 最近2年内董事、高级管理人员没有发生重大变化，实际控制人没有发生变更。</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="divStyle">
                                    <table cellspacing="0" cellpadding="0" class="table3" style="height: 384px;">
                                        <tr class="ipoLableClass">
                                            <th rowspan="6" style="font-weight: bold; width: 140px;">
                                                财务与会计
                                                <p style="font-size: 12px; font-weight: normal; color: rgb(170, 170, 170);">
                                                    至少符合右侧<br>&nbsp;&nbsp;标准中的一项：</p>
                                            </th>
                                            <th style="width: 200px;">标准</th>
                                            <th style="width: 220px;">预计市值</th>
                                            <th style="width: 170px;">净利润</th>
                                            <th style="width: 180px;">营业收入</th>
                                            <th style="width: 150px;">研发投入</th>
                                            <th style="width: 130px;">经营性现金流</th>
                                        </tr>
                                        <tr style="font-size: 12px; text-align: center;">
                                            <td>标准一</td>
                                            <td>≥10亿元</td>
                                            <td colspan="2">最近两年净利润均&gt;0且累计净利润≥5000万元，或者最近一年净利润&gt;0且营业收入≥1亿元</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr style="font-size: 12px; text-align: center;">
                                            <td>标准二</td>
                                            <td>≥15亿元</td>
                                            <td></td>
                                            <td>最近一年≥2亿元</td>
                                            <td>最近3年累计研发投入占累计营业收入≥15%</td>
                                            <td></td>
                                        </tr>
                                        <tr style="font-size: 12px; text-align: center;">
                                            <td>标准三</td>
                                            <td>≥20亿元</td>
                                            <td></td>
                                            <td>最近一年≥3亿元</td>
                                            <td></td>
                                            <td>最近3年累计≥1亿元</td>
                                        </tr>
                                        <tr style="font-size: 12px; text-align: center;">
                                            <td>标准四</td>
                                            <td>≥35亿元</td>
                                            <td></td>
                                            <td>最近一年≥3亿元</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr style="font-size: 12px; text-align: center;">
                                            <td>标准五</td>
                                            <td>≥40亿元</td>
                                            <td colspan="4">
                                                主要业务或产品需经国家有关部门批准，市场空间大，目前已取得阶段性成果。医药行业企业需至少有一项核心产品获准开展二期临床试验，其他符合科创板定位的企业需具备明显的技术优势并满足相应条件。
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="divStyle">
                                    <div style="border: 1px solid rgb(238, 238, 238); height: 384px; background: rgb(255, 255, 255);">
                                        <div class="ipoLableClass"
                                             style="width: 100%; height: 40px; padding-top: 10px; font-size: 14px; border-bottom: 1px solid rgb(238, 238, 238);">
                                            科创属性
                                        </div>
                                        <div class="pbox" style="display: flex; font-size: 13px; color: rgb(51, 51, 51);">
                                            <div
                                                    style="border-right: 1px solid rgb(238, 238, 238); width: 550px; height: 344px; padding: 14px;">
                                                <p>一、 支持和鼓励科创板定位规定的相关行业领域中，同时符合下列3项指标的企业申报科创板上市： </p>
                                                <p>（1）最近三年研发投入占营业收入比例5%以上，或最近三年研发投入金额累计在6000万元以上；</p>
                                                <p>（2）形成主营业务收入的发明专利5项以上；</p>
                                                <p>（3）最近三年营业收入复合增长率达到20%，或最近一年营业收入金额达到3亿元。</p>
                                                <p>
                                                    采用《上海证券交易所科创板股票发行上市审核规则》第二十二条第（五）款规定的上市标准申报科创板的企业可不适用上述第（3）项指标中关于“营业收入”的规定；软件行业不适用上述第（2）项指标的要求，研发占比应在10%以上。
                                                </p>
                                            </div>
                                            <div style="width: 640px; padding: 14px;">
                                                <p>二、
                                                    支持和鼓励科创板定位规定的相关行业领域中，虽未达到前述指标，但符合下列情形之一的企业申报科创板上市：</p>
                                                <p>（1）发行人拥有的核心技术经国家主管部门认定具有国际领先、引领作用或者对于国家战略具有重大意义；</p>
                                                <p>
                                                    （2）发行人作为主要参与单位或者发行人的核心技术人员作为主要参与人员，获得国家科技进步奖、国家自然科学奖、国家技术发明奖，并将相关技术运用于公司主营业务；</p>
                                                <p>（3）发行人独立或者牵头承担与主营业务和核心技术相关的“国家重大科技专项”项目；</p>
                                                <p>
                                                    （4）发行人依靠核心技术形成的主要产品（服务），属于国家鼓励、支持和推动的关键设备、关键产品、关键零部件、关键材料等，并实现了进口替代；</p>
                                                <p>（5）形成核心技术和主营业务收入的发明专利（含国防专利）合计50项以上。</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="divStyle">
                                    <table cellspacing="0" cellpadding="0" class="table1" style="height: 384px;">
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass" style="width: 240px;">主体资格</td>
                                            <td style="width: 950px;"> 依法设立且合法存续的股份有限公司</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass"> 经营年限</td>
                                            <td>
                                                持续经营3年以上，有限责任公司按原账面净资产值折股整体变更为股份有限公司的，
                                                持续经营时间可以从有限责任公司成立之日起计算
                                            </td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">股本要求</td>
                                            <td> 发行后股本不少于3,000万股</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">主营业务</td>
                                            <td>
                                                主要服务于符合国家战略、突破关键核心技术、市场认可度高的科技创新企业。重点支持新一代信息技术、高端装备、新材料、新能源、节能环保以及生物医药等高新技术产业和战略性新兴产业
                                            </td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">规范运行</td>
                                            <td> 发行人应披露股东大会、董事会、监事会、独立董事、董事会秘书制度的建立健全及运行情况</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">控制权和管理团队</td>
                                            <td> 最近2年实际控制人没有发生变更，不存在导致可能变更的重大权属纠纷</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="divStyle">
                                    <table cellspacing="0" cellpadding="0" class="table2" style="height: 384px;">
                                        <tr class="ipoLableClass">
                                            <th rowspan="2" style="width: 240px;">财务与会计</th>
                                            <th style="width: 300px;">净利润</th>
                                            <th style="width: 350px;">现金流量净额/营业收入</th>
                                            <th style="width: 300px;">无形资产</th>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td> 最近3年&gt;0，且累计&gt;3000万元 净利润以扣除非经常性损益前后较低者为计算依据</td>
                                            <td> 最近3个会计年度经营活动产生的现金流量净额累计超过人民币5000万元； 或者最近3个会计年度营业收入累计超过人民币3亿元</td>
                                            <td>最近一期末无形资产（扣除土地使用权、水面养殖权和采矿等后）占净资产的比例不高于20％</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">主体资格</td>
                                            <td colspan="3">依法设立且合法存续的股份有限公司</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">经营年限</td>
                                            <td colspan="3">持续经营3年以上，有限责任公司按原账面净资产值折股整体变更为股份有限公司的，
                                                持续经营时间可以从有限责任公司成立之日起计算
                                            </td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">股本要求</td>
                                            <td colspan="3"> 发行前股本总额不少于人民币3,000万元，发行后不少于5,000万股</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">主营业务</td>
                                            <td colspan="3"> 生产经营符合法律、行政法规和公司章程的规定，符合国家产业政策；最近3年内主营业务没有发生重大变化</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">规范运行</td>
                                            <td colspan="3"> 发行人已经依法建立健全股东大会、董事会、监事会、独立董事、董事会秘书制度，相关机构和人员能够依法履行职责。
                                            </td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">控制权和管理团队</td>
                                            <td colspan="3"> 最近3年内董事、高级管理人员没有发生重大变化，实际控制人没有发生变更</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="divStyle">
                                    <table cellspacing="0" cellpadding="0" class="table2" style="height: 384px;">
                                        <tr class="ipoLableClass">
                                            <th rowspan="2" style="width: 240px;">财务与会计</th>
                                            <th style="width: 300px;">净利润</th>
                                            <th style="width: 350px;">现金流量净额/营业收入</th>
                                            <th style="width: 300px;">无形资产</th>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td> 最近3年&gt;0，且累计&gt;3000万元 净利润以扣除非经常性损益前后较低者为计算依据</td>
                                            <td> 最近3个会计年度经营活动产生的现金流量净额累计超过人民币5000万元； 或者最近3个会计年度营业收入累计超过人民币3亿元</td>
                                            <td>最近一期末无形资产（扣除土地使用权、水面养殖权和采矿等后）占净资产的比例不高于20％</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">主体资格</td>
                                            <td colspan="3">依法设立且合法存续的股份有限公司</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">经营年限</td>
                                            <td colspan="3">持续经营3年以上，有限责任公司按原账面净资产值折股整体变更为股份有限公司的，
                                                持续经营时间可以从有限责任公司成立之日起计算
                                            </td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">股本要求</td>
                                            <td colspan="3"> 发行前股本总额不少于人民币3,000万元，发行后不少于5,000万股</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">主营业务</td>
                                            <td colspan="3"> 生产经营符合法律、行政法规和公司章程的规定，符合国家产业政策；最近3年内主营业务没有发生重大变化</td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">规范运行</td>
                                            <td colspan="3"> 发行人已经依法建立健全股东大会、董事会、监事会、独立董事、董事会秘书制度，相关机构和人员能够依法履行职责。
                                            </td>
                                        </tr>
                                        <tr style="font-size: 14px;">
                                            <td class="ipoLableClass">控制权和管理团队</td>
                                            <td colspan="3"> 最近3年内董事、高级管理人员没有发生重大变化，实际控制人没有发生变更</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="circleStyle" @click="prev" v-show="showjt" style="left: 12px;"><i
                                class="el-icon-arrow-left"></i></div>
                        <div class="circleStyle" @click="next" v-show="showjt" style="right: 12px;"><i
                                class="el-icon-arrow-right"></i></div>
                    </div>
                    <div style="padding-top: 14px;">
                        <div style="display: flex; padding-left: 430px;">
                            <div class="plateClass">
                                <div class="fontStyle" :class="{'fontOtherStyle':SwiperIndex==1}" @click="dotchange(1)">创业板</div>
                                <div class="iconfont" :class="{'circleOtherStyle':SwiperIndex==1}" @click="dotchange(1)"></div>
                            </div>
                            <div class="plateClass">
                                <div class="fontStyle" :class="{'fontOtherStyle':SwiperIndex==2||SwiperIndex==3||SwiperIndex==4}"
                                     @click="dotchange(2)">科创板</div>
                                <div style="display: flex;">
                                    <div class="iconOtherfont" :class="{'circleOtherStyle':SwiperIndex==2}" style="left: 5px;"
                                         @click="dotchange(2)"></div>
                                    <div class="iconOtherfont" :class="{'circleOtherStyle':SwiperIndex==3}" style="left: 15px;"
                                         @click="dotchange(3)"></div>
                                    <div class="iconOtherfont" :class="{'circleOtherStyle':SwiperIndex==4}" style="left: 24px;"
                                         @click="dotchange(4)"></div>
                                </div>
                            </div>
                            <div class="plateClass">
                                <div class="fontStyle" :class="{'fontOtherStyle':SwiperIndex==5}" @click="dotchange(5)">沪主板</div>
                                <div class="iconfont" :class="{'circleOtherStyle':SwiperIndex==5}" @click="dotchange(5)"></div>
                            </div>
                            <div class="plateClass">
                                <div class="fontStyle" :class="{'fontOtherStyle':SwiperIndex==6}" @click="dotchange(6)">中小板</div>
                                <div class="iconfont" :class="{'circleOtherStyle':SwiperIndex==6}" @click="dotchange(6)"></div>
                            </div>
                        </div>
                        <!---->
                        <!---->
                    </div>
                </div>
            </div>
        </div>
        <!--				<div class="imgbox"><img src="img/aaaaa2.png" alt=""></div>-->
        <div class="imgbox"><img src="../static/img/aaaaa1.png" alt=""></div>
    </div>
</template>

<script>
    export default {
        name: "CenterSwiper",
        data() {
            return {
                SwiperIndex: 1,
                showjt: false,
                mySwiper:''
            }
        },
        mounted() {
            this.mySwiper = new Swiper('.swiper-container', {
                autoplay: false,//可选选项，自动滑动
                loop: true,
            })
            this.mySwiper.detachEvents(); //移除所有监听事件
        },
        methods: {
            dotchange(i) {
                this.mySwiper.slideTo(i, 500, false);
                this.SwiperIndex = i;
            },
            prev() {
                this.mySwiper.slidePrev();
                this.SwiperIndex = (this.mySwiper.activeIndex - 1 + 6) % (6) + 1
            },
            next() {
                // this.$refs.hits.$swiper.slidePrev();
                this.mySwiper.slideNext();
                this.SwiperIndex = (this.mySwiper.activeIndex - 1 + 6) % (6) + 1
            }
        }
    }
</script>

<style scoped>
    .swiperbox{
        /*margin-bottom: 20px;*/
    }
    .ipoListingStand{
        background: url(../static/img/swiperbg.png) no-repeat;
        background-size: cover;
        width: 1200px;
        /*height: 500px;*/
    }
    .ipoListingStand .titleClass {
        padding: 20px 25px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #181818;
    }
    .ipoListingStand table {
        background: #fff;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
    .ipoListingStand #fade {
        padding: 0 25px;
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
    .swiper-container .swiper-container-horizontal:hover .circleStyle{
        display: block;
    }
    .divStyle{
        /*position: absolute;*/
        background: #fff;
        border-left: 1px solid #eee;
        border-bottom: 1px solid #eee;
        height: 384px;
    }
    .ipoListingStand table td, .ipoListingStand th {
        border-right: 1px solid #eee;
        border-top: 1px solid #eee;
    }
    .ipoListingStand .table3 td, .ipoListingStand th {
        padding: 8px;
    }
    .ipoListingStand .ipoLableClass {
        font-weight: 700;
        text-align: center;
        font-size: 14px;
    }
    .pbox p{
        margin: 13px 0;
    }
    .ipoListingStand .plateClass {
        margin-right: 45px;
    }
    .ipoListingStand .fontStyle {
        padding-bottom: 6px;
        cursor: pointer;
    }
    .ipoListingStand .iconfont, .ipoListingStand .iconOtherfont {
        position: relative;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #b7b7b7;
    }
    .ipoListingStand .iconfont {
        left: 21px;
    }
    .ipoListingStand .fontOtherStyle {
        color: #14bcf5;
    }
    .ipoListingStand .circleOtherStyle {
        width: 10px;
        height: 10px;
        border: 2px solid #14bcf5;
        background: #fff;
    }
    .ipoListingStand .circleStyle {
        position: absolute;
        top: 170px;
        cursor: pointer;
        font-size: 20px;
        background: #333;
        z-index: 5;
        width: 32px;
        height: 32px;
        opacity: .3;
        border-radius: 50%;
        color: #fff;
    }
    .ipoListingStand .el-icon-arrow-left, .ipoListingStand .el-icon-arrow-right {
        position: relative;
        top: 5px;
        left: 6px;
    }

</style>