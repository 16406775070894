<template>
	<div id="zsy_07">
		<div class="swipercont">
			<div class="support_banner">
				<el-carousel height="340px" :arrow="imgUrlList.length<=1?'never':''"
					:indicator-position="imgUrlList.length<=1?'none':''">
					<el-carousel-item v-for="(item,i) in imgUrlList" :key="item.id">
						<router-link tag="a" :to="'/roadShowSwiperDetail/' +  item.id">
							<img :src="item.imgUrl|imgUrl" />
						</router-link>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<section>
			<!--路演流程-->
			<div class="set1 w">
				<div class="set1_p">
					<h2 class="s1_h2">路演流程</h2>
					<img src="../../static/img/07_03.png" alt="" />
				</div>
			</div>
			<!--路演预告-->
			<div class="set2">
				<div class="set2_t w">
					<h2 class="s2_h2">路演企业精选</h2>
					<div class="s2_t clearfix">
						<ul class="s2_ul">
							<li class="clearfix" v-for="(item,i) in readshowNumberList" :key="item.id" v-if="i<3">
								<div class="set2_time">
									<b>{{item.rasshowTime|day}}</b>
									<span>{{item.rasshowTime|year}}</span>
								</div>
								<div class="set2_r">
									<p>
										<router-link tag="a" :to="'/roadShowDetail/'+item.id">{{item.rasshowName}}
										</router-link>
									</p>
								</div>
							</li>
						</ul>
						<img src="../../static/img/07_07.png" class="set2_img" />
					</div>
					<router-link tag="a" to="/trainingList" class="set2_a">查看更多 ></router-link>
				</div>
			</div>
			<!--往期路演风采-->
			<div class="set3 w">
				<h2 class="s3_h2">往期路演风采</h2>
				<ul id="s3_ul" class="set3_ul clearfix">
					<!-- <li class="active">上交所</li>
                    <li>深交所</li>
                    <li>新三板</li> -->
				</ul>
				<!--路演详情-->
				<div class="s3 w">
					<vue-seamless-scroll :data="roadshowList" class="seamless-warp" :class-option="classOption">
						<ul class="set3_ul2 clearfix">
							<li v-for="(item,i) in roadshowList" :key="item.i"><a :href="item.linkUrl"
									target="_blank"><img :src="item.imageUrl|imgUrl" /><span
										class="s3_ul_span">路演详情</span></a></li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</div>
			<!--团队、机构、媒体-->
			<div class="set4">
				<div class="set4_t w">
					<div class="set4_nav1">
						<ul class="set4_ul clearfix">
							<li :class="{active2:currval == 1}" @click="currval=1">
								<div class="set4_btn">专家团队</div>
								<div class="o"></div>
							</li>
							<li :class="{active2:currval == 2}" @click="currval=2">
								<div class="set4_btn">服务机构</div>
								<div class="o"></div>
							</li>
							<li :class="{active2:currval == 3}" @click="currval=3">
								<div class="set4_btn">合作媒体</div>
								<div class="o"></div>
							</li>
						</ul>
					</div>
					<div class="set4_pt">
						<!--专家团队-->
						<ul class="set4_ul2 clearfix">
							<li v-for="(item,i) in team" :key="i">
								<figure>
									<img class="imagesc" :src="item.imageUrl|imgUrl" alt="" />
									<figcaption>
										<p>{{item.name}}</p>
										<span>{{item.content}}</span>
									</figcaption>
								</figure>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import TopBannerBox from "../../components/TopBannerBox";
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		name: "TrainingRoadshow",

		data() {
			return {
				currval: 1,
				team: [],
				roadshowList: [],
				readshowNumberList: [],
				imgUrlList: [],
			}
		},
		computed: {
			imgg() {
				return this.imgUrlList[this.imgUrlList.length - 1];
			},
			classOption() {
				return {
					step: 0.3, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 2, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动)
					waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
				}
			}
		},
		mounted() {
			this.getBanner();
			// this.radshowCompany();
			this.readshowNumber();
			this.radshowLink({
				type: this.currval
			});
			this.radshowDemeanor();
		},
		methods: {
			//banner图
			async getBanner() {
				let res = await this.$api.radshowBannerImg()
				this.imgUrlList = res.result;
			},
			//参加路演企业列表查询
			async radshowCompany() {
				let res = await this.$api.radshowCompany();
				// console.log(res);
			},
			//参加路演期数列表查询
			async readshowNumber() {
				let res = await this.$api.readshowNumber();
				this.readshowNumberList = res.result
				// console.log(res);
			},
			//参加路演赞助人物/机构媒体
			async radshowLink(params) {
				let res = await this.$api.radshowLink(params);
				this.team = res.result;
				// console.log(res);
			},
			//参加路演风采
			async radshowDemeanor() {
				let res = await this.$api.radshowDemeanor();
				this.roadshowList = res.result;
			},
		},
		filters: {
			year(data) {
				return data.slice(0, 4)
			},
			day(data) {
				return data.slice(5)
			}
		},
		watch: {
			currval(data) {
				let type = data;
				this.radshowLink({
					type
				})
			}
		},
		components: {
			TopBannerBox,
			vueSeamlessScroll
		}
	}
</script>

<style scoped>
	.imagesc {
		width: 166px;
		height: 224px;
		object-fit: contain;
	}

	.swipercont {
		position: relative;
	}

	.swiperContBox {
		position: absolute;
		right: 200px;
		bottom: 40px;
		z-index: 99;
		display: flex;
	}

	.br_btn {
		height: 72px;
		width: 165px;
		border: 2px solid #a3813a;
		border-radius: 14px;
		background-color: #003d6a;
		/*position: absolute;*/
		/*bottom: 37px;*/
		/*left: 845px;*/
		margin-right: 15px;
		cursor: pointer;
	}

	.br_btn2 {
		height: 72px;
		width: 165px;
		border: 2px solid #a3813a;
		border-radius: 14px;
		background-color: #003d6a;
		/*position: absolute;*/
		/*bottom: 37px;*/
		/*left: 1024px;*/
		cursor: pointer;
	}

	.br_btn button,
	.br_btn2 button {
		width: 155px;
		height: 60px;
		background-color: #cb902c;
		color: #fff;
		font-size: 24px;
		font-weight: bold;
		margin: 4px 3px;
		border-radius: 10px;
		cursor: pointer;
	}



	#zsy_07 section {
		width: 100%;
		margin-bottom: 76px;

	}

	#zsy_07 .set1 {
		height: 494px;
	}

	#zsy_07 .set1_nav {
		height: 68px;
		border-bottom: 1px solid #dbdcde;
		padding: 8px 0 0 11px;
		font-size: 16px;
		line-height: 60px;
		color: #141b21;
		white-space: normal;
	}

	#zsy_07 .set1_nav a {
		color: #141b21;
	}

	#zsy_07 .set1_nav .sj {
		margin-left: 4px;
		width: 11px;
		height: 12px;
		background: url(../../static/img/07.png)no-repeat center center;
		display: inline-block;
	}

	#zsy_07 .set1_p {
		text-align: center;
	}

	#zsy_07 .s1_h2 {
		line-height: 125px;
		font-size: 30px;
	}

	/*路演预告*/
	#zsy_07 .set2 {
		height: 703px;
		width: 100%;
		background-color: #d8edfe;
		padding-bottom: 55px;
		padding-top: 49px;
	}

	#zsy_07 .set2 .set2_t {
		height: 599px;
		background-color: #fff;
		text-align: center;
	}

	#zsy_07 .s2_h2 {
		margin-top: 6px;
		font-size: 30px;
		line-height: 120px;
		text-align: center;
	}

	#zsy_07 .s2_t {
		min-width: 1200px;
	}

	#zsy_07 .s2_ul {
		float: left;
		margin-left: 38px;
		width: 550px;
	}

	#zsy_07 .s2_ul li {
		padding: 26px 90px 26px 0;
		border-bottom: 1px dashed #c3c3c3;
	}

	#zsy_07 .s2_ul li:last-child {
		border-bottom: none;
	}

	#zsy_07 .set2_time {
		width: 80px;
		height: 80px;
		background-color: #f2f2f2;
		text-align: center;
		float: left;
	}

	#zsy_07 .set2_time b {
		display: block;
		font-size: 15px;
		color: #676767;
		padding-top: 16px;
	}

	#zsy_07 .set2_time span {
		font-size: 11px;
		color: #999999;
	}

	#zsy_07 .set2_r {
		float: left;
		padding: 14px 0 0 20px;
		width: 360px;
		font-size: 16px;
		text-align: left;
	}

	#zsy_07 .set2_r p:hover a,
	#zsy_07 .set2_r p:active a {
		color: #676767;
		cursor: pointer;
		transition: 0.5s;
	}

	#zsy_07 .set2_r p a {
		color: #282828;
	}

	#zsy_07 .set2_img {
		float: right;
		margin: 26px 29px 0 0px;
	}

	#zsy_07 .set2_a {
		width: 150px;
		height: 40px;
		display: inline-block;
		border: 1px solid #dddddd;
		color: #999999;
		vertical-align: middle;
		line-height: 38px;
		cursor: pointer;
		font-size: 11px;
		background-color: #f2f2f2;
	}

	/*往期路演风采*/
	#zsy_07 .set3 {
		text-align: center;
	}

	#zsy_07 .set3 .s3_h2 {
		margin-top: 66px;
		font-size: 29px;
		line-height: 59px;
	}

	#zsy_07 .set3_ul {
		margin-bottom: 16px;
		margin-bottom: 50px;
		min-width: 1200px;
	}

	#zsy_07 .set3_ul li {
		float: left;
		width: 113px;
		height: 45px;
		border-radius: 4px;
		background: #bfbfbf;
		color: #fff;
		font-size: 18px;
		font-weight: bold;
		line-height: 45px;
		margin-right: 7px;
		cursor: pointer;
	}

	#zsy_07 .set3_ul li:first-child {
		margin-left: 425px;
	}

	#zsy_07 .set3_ul .active {
		background-color: #3ca9fc;
	}

	#zsy_07 .s3 {
		height: 424px;
		overflow: hidden;
		margin-bottom: 99px;
	}

	#zsy_07 .set3_ul2 {
		display: flex;
		flex-flow: row nowrap;
	}

	#zsy_07 .set3_ul2 li {
		margin-right: 23px;
		border-radius: 4px;
		position: relative;
	}

	#zsy_07 .set3_ul2 li img {
		width: 277px;
		height: 425px;
		text-align: center;
		object-fit: cover;
	}

	/* #zsy_07 .set3_ul2 li:last-child {
		margin-right: 0;
	} */

	#zsy_07 .set3_ul2 li:hover,
	#zsy_07 .set3_ul2 li:active {
		opacity: 0.8;
		transition: 0.5s;
	}

	#zsy_07 .set3_ul2 li .s3_ul_span {
		width: 160px;
		height: 52px;
		position: absolute;
		background-color: #3da9fc;
		color: #FFFFFF;
		font-size: 18px;
		padding-top: 2px;
		line-height: 50px;
		border-radius: 4px;
		left: 65px;
		bottom: 32px;
		cursor: pointer;
	}

	/*团队、机构、媒体*/
	#zsy_07 .set4 {
		width: 100%;
		background-color: #d8edfe;
		height: 635px;
		padding-bottom: 75px;
		padding-top: 76px;
	}

	#zsy_07 .set4_t {
		background-color: #fff;
		height: 485px;
		overflow: hidden;
	}

	#zsy_07 .set4_nav1 {
		height: 89px;
		border-bottom: 1px solid #c6c6c6;
		margin-bottom: 45px;
		min-width: 1200px;
	}

	#zsy_07 .set4_ul {
		margin: 0 170px 0 143px;
	}

	#zsy_07 .set4_ul li {
		float: left;
		width: 162px;
		height: 40px;
		background-color: #d8edfe;
		margin: 24px 67px;
		position: relative;
	}

	#zsy_07 .set4_ul .set4_btn {
		font-size: 26px;
		color: #242424;
		font-weight: bold;
		text-align: center;
		cursor: pointer;
	}

	#zsy_07 .set4_ul .o {
		width: 16px;
		height: 16px;
		background-color: #f5f5f5;
		border: 2px solid #c6c6c6;
		border-radius: 8px;
		position: absolute;
		left: 73px;
		bottom: -33px;
	}

	#zsy_07 .set4_ul .active2 {
		width: 237px;
		height: 57px;
		background-color: #34a8ff;
		color: #fff;
		border-radius: 3px;
		line-height: 55px;
		margin: 16px 29px;
	}

	#zsy_07 .set4_ul .active2 .set4_btn {
		color: #fff;
	}

	#zsy_07 .set4_ul .active2 .o {
		width: 16px;
		height: 16px;
		background-color: #fff;
		border: 2px solid #34a8ff;
		border-radius: 8px;
		position: absolute;
		left: 110px;
		bottom: -25px;
	}

	#zsy_07 .set4_pt {
		margin: 0 8px 0 14px;
		height: 340px;
		white-space: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
		min-width: 1178px;
	}

	#zsy_07 .set4_ul2 li {
		margin-right: 30px;
		display: inline-block;
		margin-bottom: 1000px;
		cursor: pointer;
		border: 1px solid #f0f0f0;
		width: 168px;
		height: 299px;
		vertical-align: top;
	}

	#zsy_07 .set4_ul2 li:hover,
	#zsy_07 .set4_ul2 li:active {
		opacity: 0.8;
		transition: 0.5s;
	}

	#zsy_07 .set4_ul2 li figure img {
		width: 166px;
	}

	#zsy_07 .set4_ul2 li figure figcaption {
		text-align: center;
		padding-top: 15px;
		height: 100px;
		width: 168px;
	}

	#zsy_07 .set4_ul2 li figure figcaption p {
		color: #ff752c;
		font-size: 18px;
	}

	#zsy_07 .set4_ul2 li figure figcaption span {
		color: #4c4d51;
		font-size: 12px;
	}
</style>
