<template>
	<div id="app">
		<Header></Header>
		<div class="routerViewBox">
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive"></router-view>
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive"></router-view>
		</div>
		<Footer class="footerbox"></Footer>
	</div>
</template>
<script>
	import Header from "./components/Header";
	import Footer from "./components/Footer";
	export default {
		components: {
			Header,
			Footer,
		},
		mounted() {

		},
		created() {
			var _t = this;
			if (!!sessionStorage.getItem("reload")) {
				//刚刚解析了因为我实现的是刷新返回到上一页，所以刷新的时候我存了个sessionStorage，然后进来判断有这个就返回上一页，然后把sessionStorage去掉
				_t.$router.go(-1);
				sessionStorage.clear();
			}
			window.addEventListener("unload", (e) => this.unloadFn());
			window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
		},
		methods: {
			unloadFn() {
				this._gap_time = new Date().getTime() - this._beforeUnload_time;
				console.log(this._gap_time)
				if (this._gap_time <= 5) {
					//关闭浏览器执行的代码或者接口
					window.sessionStorage.clear(); //清除session所有数据
					window.localStorage.clear();
				} else {
					//刷新我需要做的事情
					// sessionStorage.setItem("reload", 1);
				}
			},
			beforeunloadFn(e) {
				this._beforeUnload_time = new Date().getTime();
				e = e || window.event;
				if (e) {
					window.location.reload()
					// e.returnValue = "您是否确认离开此页面-您输入的数据可能不会被保存";
				}
				return "您是否确认离开此页面-您输入的数据可能不会被保存";
			},
		},
		destroyed() {
			window.removeEventListener("unload", e => this.unloadFn());
			window.removeEventListener("beforeunload", e => this.beforeunloadFn());
		}
	};
</script>
<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #2c3e50;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	.routerViewBox {
		min-height: 18vh;
	}

	/*edit at 2017/4/28 13:42*/

	/*reset*/

	* {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	html,
	body {
		height: 100%;
		position: relative;
		-webkit-touch-callout: none;
		-webkit-text-size-adjust: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		/*-webkit-user-select: none;*/
		font-family: sans-serif;
	}

	html,
	body,
	header,
	section,
	footer,
	div,
	ul,
	ol,
	li,
	img,
	a,
	span,
	em,
	del,
	legend,
	strong,
	fieldset,
	form,
	label,
	dl,
	dt,
	dd,
	cite,
	input,
	button,
	textarea,
	hr,
	time,
	mark,
	code,
	figcaption,
	figure,
	textarea,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin: 0;
		padding: 0;
		border: 0;
	}

	input,
	button,
	textarea,
	select {
		-webkit-appearance: none;
		outline: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	a {
		outline: 0;
		text-decoration: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	ol,
	ul,
	li,
	dl,
	dt {
		list-style: none;
	}

	.line-1 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/*base module*/

	.word-wrap {
		word-wrap: break-word;
		word-break: break-all;
	}

	.clearfix::after {
		content: " ";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.text-center {
		text-align: center;
	}

	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}

	.fl {
		float: left;
	}

	.fr {
		float: right;
	}

	.icon {
		width: 1em;
		height: 1em;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	img {
		vertical-align: middle;
	}

	.w {
		width: 1200px;
		margin: 0 auto;
		min-width: 1200px;
	}

	//elementui设置
	.el-pagination {
		text-align: center;
		margin: 50px 0;
	}

	//面包屑
	.details_nav {
		height: 80px;
		display: flex;
		align-items: center;
	}
</style>
