<template>
    <div id="zjj_support">
		<TopBannerBox positions="2"></TopBannerBox>
        <section id="section1">

			<el-form :inline="true" label-width="70px" :model="form" class="demo-form-inline w formcont">
                <div class="box mb">
					<el-form-item label="关键字">
						<el-input v-model="form.keyWord" placeholder="关键字"></el-input>
					</el-form-item>
					<el-form-item label="政策类别">
						<el-select v-model="form.policyType" placeholder="政策类别">
							<el-option value="">全部</el-option>
							<el-option
									v-for="(item,i) in options[0].result" :key="item.id"
									:label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="发布机构">
						<el-select v-model="form.releaseMechanismId" placeholder="发布机构">
							<el-option value="">全部</el-option>
							<el-option
									v-for="(item,i) in options[1].result" :key="item.id"
									:label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
                </div>
				<div class="box">
					<el-form-item label="发布地区">
						<el-select v-model="form.city" placeholder="发布地区">
							<el-option
									v-for="(item,i) in options[2].result" :key="item.id"
									:label="item.city" :value="item.cityid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="政策范围">
						<el-select v-model="form.type" placeholder="政策范围">
							<el-option label="全部" value="">全部</el-option>
							<el-option label="国家政策" value="4">国家政策</el-option>
							<el-option label="省级政策" value="1">省级政策</el-option>
							<el-option label="厅局政策" value="2">厅局政策</el-option>
							<el-option label="地区政策" value="3">地区政策</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="发布日期">
						<el-date-picker
								v-model="date"
								type="daterange"
								align="right"
								unlink-panels
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd"
								@change="changeDate"
								:picker-options="pickerOptions">
						</el-date-picker>
					</el-form-item>
				</div>
				<div class="box" style="display:flex;justify-content: flex-end;">
						
					<el-form-item style="margin-right:27px;">

							<el-button @click="clearForm">清空</el-button>
							<el-button type="primary" @click="onSubmit">查询</el-button>

					</el-form-item>
				</div>
			</el-form>
        </section>
		<section id="section2">
			<div class="w" v-if="show1">
				<policyInit></policyInit>
			</div>
			<div class="container w" v-if="!show1">
				<!-- //有查询的时候显示 -->
				<h4><span>查询结果：</span><span>政策数量：<b>&nbsp;&nbsp;{{total}}&nbsp;</b>条</span></h4>
				<ul>
					<li class="h128" v-for="(item,i) in datalist" :key="item.id">
						<p class="p1">
						<span>
							<span><img src="../../static/img/fczcIcon.png" alt=""></span>
							{{item.title}}
						</span>
							<span>
								<label>{{item.releaseMechanism_dictText == null ? '' : '来自：'+item.releaseMechanism_dictText}}</label>
								<label>{{item.updateTime}}</label>
							</span>
							
						</p>
						<p class="p2" v-if="item.isOnlineapplication==1">
							<span>{{item.title}}</span>
							<router-link tag="a" :to="'/detail/2/'+item.id" class="shenqing">查看详情</router-link>
						</p>
						<p class="p2" v-else>
							<span>{{item.title}}</span>
							<a @click="toDeclare(item.id)" class="shenqing">在线申请</a>
						</p>
					</li>
				</ul>
				<div class="paginationbox">
					<el-pagination
							background
							:current-page="form.currentPage"
							:page-size="pageSize"
							@current-change="currentChange"
							layout="prev, pager, next"
							:page-count="pageCount">
					</el-pagination>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import policyInit from "../../components/policyInit";
	import TopBannerBox from "../../components/TopBannerBox";
    export default {
        name: "policy",
        data() {
            return {
				pageSize:10, //每页显示数量
				pageCount:0, //总页数
				total:0, //数据总数
                show1: true,
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
				options:[{result:''},{result:''},{result:''}],
				form:{},
				initForm:{
					keyWord:'',
					policyType:'',
					releaseMechanismId:'',
					city:'',
					releaseTimeBegin:'',
					releaseTimeEnd:'',
					pageNo:1,
					newType:'1',
					type:''
				},
				datalist:[],
				date:''
            }
        },
		mounted() {
			this.init();
			this.getOptions();
		},
		methods: {
			toDeclare(id){
				this.$router.push({
					name: 'declare',
					params: {id}
				})
			},
            clearForm(){ //清空
				this.init();
				this.show1 = true;
			},
			onSubmit(){ //提交
				console.log(this.form);
				this.form.pageNo = 1;
				this.show1 = false;
				this.getNews();
			},
			async getNews(){
				let res = await this.$api.policyNews(this.form);
				this.pageCount = res.result.pages;
				this.total = res.result.total;
				this.datalist = res.result.records;
				// this['news'+type] = res.result.records;
			},
			init(){
				this.form = {...this.initForm};
				this.date = '';
			},
			currentChange(e){
            	//页数改变
				this.form.pageNo = e;
				this.getNews();
			},
			changeDate(e){
            	//改变日期
				this.form.releaseTimeBegin = e[0];
				this.form.releaseTimeEnd = e[1];
			},
			async getOptions(){
				let res = await Promise.all([
					this.$api.projectDic({type:8}),
					this.$api.projectDic({type:9}),
					this.$api.getCity({provinceId:130000}),
				])
				this.options = res;
				this.options[2].result.unshift({
					city:'全国',
					cityid:'',

				})
			}
        },
		components: {
			policyInit,TopBannerBox
		}
    }
</script>

<style scoped>

	.el-input{
		width: 221.4px;
	}
	.el-form--inline .el-form-item{
		margin-right: 90px;
	}
	.el-form--inline .el-form-item:nth-child(3n){
		margin-right: 0;
	}


    #zjj_support #section1 {
        width: 100%;
        /*height: 184px;*/
    }

    /*#zjj_support #section1 form {*/
    /*    padding: 45px 0 57px 0;*/
    /*    font-size: 13px;*/
    /*    color: #6c6b69;*/
    /*    border-bottom: 1px solid #cccccc;*/
    /*}*/

    /*#zjj_support #section1 form .box {*/
    /*    display: flex;*/
    /*    justify-content: space-between;*/
    /*}*/

    /*#zjj_support #section1 form .mb {*/
    /*    margin-bottom: 19px;*/
    /*}*/

    #zjj_support #section1 form .box .box_son {
        width: 372px;
    }

    #zjj_support #section1 form .box div.fabu_data {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    #zjj_support #section1 form .box div.fabu_data span {
        display: inline-block;
		flex-shrink: 0;
        height: 31px;
        line-height: 31px;
        color: #6b6a68;
    }

    #zjj_support #section1 form .box .box_son .data {
        display: flex;
        justify-content: space-between;
        /*border: 1px #e8e8e8 solid;*/
        /*border-radius: 3px;*/
        /*width: 304px;*/
        height: 31px;
        /*padding-left: 12px;*/
        font-size: 13px;
        color: #6b6a68;
        /*overflow: hidden;*/
    }

    #zjj_support #section1 form .box .box_son input,
    #zjj_support #section1 form .box .box_son select {
        border: 1px #e8e8e8 solid;
        border-radius: 3px;
        width: 304px;
        height: 40px;
        padding-left: 12px;
        font-size: 13px;
        outline: none;
        color: #6b6a68;
    }

    #zjj_support #section1 form .box .box_son input.diqu {
        color: #c5c6cb;
    }

    #zjj_support #section1 form .box .box_son input.data_son {
        height: 31px;
        width: 125px;
        padding: 0;
        border: none;
        color: #c4cad6;
        font-size: 13px;
        text-align: center;
    }

    #zjj_support #section1 form .box div.btn {
        text-align: right;
    }

    #zjj_support #section1 form .box div.pos {
        position: relative;
    }

    #zjj_support #section1 form .box .box_son .fuhao {
        position: absolute;
        top: 0;
        right: 0;
        border: 1px #e8e8e8 solid;
        border-radius: 3px;
        text-align: center;
        width: 45px;
        height: 31px;
        line-height: 31px;
        background-color: #f5f6f8;
        color: #b4a7b0;
    }

    #zjj_support #section1 form .box .box_son button {
        width: 68px;
        height: 36px;
        border: 1px #eaebef solid;
        border-radius: 3px;
        color: #616469;
        font-size: 12px;
        background-color: #fff;
        cursor: pointer;
    }

    #zjj_support #section1 form .box .box_son button.search {
        background-color: #1351b4;
        color: #fff;
        border: 1px #0242ae solid;
        margin-left: 14px;
    }

	#zjj_support #section2{
		/* margin-bottom: 39px; */
	}
	.sda{
		margin-top: 39px!important;
	}
	#zjj_support #section2 .container h4{
		height: 87px;
		font-size: 13px;
		color: #272725;
		padding-top: 29px;
		font-weight: normal;
		border-bottom: 1px solid #e4e4e4;
		padding-left: 29px;
	}
	#zjj_support #section2 .container h4 span{
		margin-right: 15px;
	}
	#zjj_support #section2 .container h4 span b{
		color: #fe0000;
		font-weight: normal;
	}
	#zjj_support #section2 .container ul li{
		padding-left: 29px;
		padding-right: 28px;
		font-size: 12px;
		border-bottom: 1px solid #e4e4e4;
	}
	#zjj_support #section2 .container ul li label{
		margin: 0 10px;
	}
	#zjj_support #section2 .container ul li .p1{
		display: flex;
		justify-content: space-between;
		color: #717173;
		margin-bottom: 12px;
	}
	#zjj_support #section2 .container ul li .p2{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		color: #333436;
	}
	#zjj_support #section2 .container ul li .p2 .shenqing{
		display: inline-block;
		width: 70px;
		height: 25px;
		background-color: #3ea9fb;
		color: #fff;
		text-align: center;
		line-height: 25px;
		border-radius: 3px;
		cursor: pointer;

	}
	#zjj_support #section2 .container ul li .p2 .chakan{
		display: inline-block;
		width: 70px;
		height: 25px;
		background-color: #999999;
		color: #fff;
		text-align: center;
		line-height: 25px;
		border-radius: 3px;
	}
	#zjj_support #section2 .container ul .h117{
		padding-top: 28px;
		height: 117px;
	}
	#zjj_support #section2 .container ul .h128{
		padding-top: 32px;
		height: 128px;
	}

	.formcont{
		padding: 45px 0 37px 0;
		border-bottom: 1px solid #cccccc;
	}
	/deep/ .el-range-separator{
		width: 20px !important;
	}
</style>
