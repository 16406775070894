<template>
  <div>
    <top-banner positions="3"></top-banner>
    <!-- 主体部分 -->
    <section id="lzy">
      <div class="main w">
        <div class="box1">
          <div class="l-box">
            <span>上市进程:</span>
          </div>
          <div class="r-box">
            <div>
              <div
                class="up-box-1"
                :class="{ sel_active: type == '' }"
                @click="changeType('')"
              >
                全部
              </div>
            </div>
            <!-- <div><div class="up-box-1" :class="{'sel_active':type==2}" @click="changeType('2')">签约</div></div> -->
            <div>
              <div
                class="up-box-1"
                :class="{ sel_active: type == 3 }"
                @click="changeType('3')"
              >
                股改
              </div>
            </div>
            <div>
              <div
                class="up-box-1"
                :class="{ sel_active: type == 4 }"
                @click="changeType('4')"
              >
                辅导
              </div>
            </div>
            <div>
              <div
                class="up-box-1"
                :class="{ sel_active: type == 5 }"
                @click="changeType('5')"
              >
                申报
              </div>
            </div>
            <div>
              <div
                class="up-box-1"
                :class="{ sel_active: type == 6 }"
                @click="changeType('6')"
              >
                上市
              </div>
            </div>
          </div>
        </div>
        <div class="box2">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="margin-left: 12px;"
          >
          </el-date-picker>
          <el-input placeholder="请输入内容" style="margin-left:10px;" v-model="companyName">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button type="primary" @click="getMsg">搜索</el-button>
        </div>
        <tutoring-table
          ref="tutoringTable"
          :companyName="companyName"
          :type="type"
          :time="time"
        ></tutoring-table>
      </div>
    </section>
  </div>
</template>

<script>
import TutoringTable from "./tutoringTable";
import topBanner from "../../components/TopBannerBox";
export default {
  name: "IpoCatch",
  data() {
    return {
      companyName: "",
      type: "",
      time: [],
    };
  },
  methods: {
    changeType(type) {
      this.type = type;
      this.time = []
    },
    getMsg() {
      this.$refs.tutoringTable.getMsg();
    },
  },
  components: {
    topBanner,
    TutoringTable
  },
};
</script>

<style scoped>
/* 主体部分 */
#lzy .main {
  margin-top: 45px;
}
#lzy .main > .box1 {
  width: 100%;
  height: 32px;
  display: flex;
  padding-left: 19px;
  margin-bottom: 24px;
}
#lzy .main .box1 > div {
  height: 100%;
  display: flex;
  align-items: center;
}
#lzy .main .box1 .l-box {
  font-size: 16px;
}
#lzy .main .box1 .l-box > span {
  width: 70px;
}
#lzy .main .box1 .l-box > div {
  width: 51px;
  min-width: 51px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #1685de;
}
#lzy .main .box1 .r-box > div {
  /*min-width: 140px;*/
  /*width: 140px;*/
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 52px;
}
#lzy .main .box1 .r-box > div > span {
  margin-right: 19px;
}
#lzy .main .box1 .r-box > div > div {
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;
  min-width: 82px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  background: #ccc;
}
#lzy .main .box1 .r-box .sel_active {
  background-color: #1685de;
}
#lzy .main .box1 .r-box > div > div > a {
  font-size: 10px;
  margin-left: 7px;
}
.el-input {
  width: 290px;
  margin-right: 10px;
}
#lzy .main > .box2 {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 128px;
  border-bottom: 2px dashed #d2d2d2;
}

/* 签约下拉样式 */
.down-block-1 {
  position: absolute;
  min-width: 82px;
  width: 82px;
  top: 32px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(150, 148, 148);
  border-top: 1px solid transparent;
  flex-direction: column;
  display: none;
}
.down-block-1 span {
  height: 32px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
#lzy .main .box4 .block-text span {
  display: flex;
  justify-content: flex-start;
}
/*#lzy .main .box4 .block-text span .dg {*/
/*    width: 171px !important;*/
/*    min-width: 100px !important;*/
/*    padding-left: 0 !important;*/
/*    display: flex;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*}*/
/*#lzy .main .box4 .block-text span .dg:first-child {*/
/*    padding-left: 10px !important;*/
/*    font-weight: 400 !important;*/
/*}*/
/*#lzy .main .box4 .block-text span .dg:nth-child(2n) {*/
/*    padding-left: 5px !important;*/
/*}*/
.serchbox {
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.box2 el-date-picker input:last-child {
    margin-left: 6px;
}
</style>
